import { endPoints } from 'api/';
import { GET_USER_FROM_SESSION } from 'actions/types';

/**
  @description Get user from session
  @returns A user object
 */
function getUser() {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_USER_FROM_SESSION,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const user = await apiClient(endPoints.GET_USER);

        return user;
      },
    };

    dispatch(action);
  };
}

export default getUser;
