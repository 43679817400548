import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { func, number, shape } from 'prop-types';

import { geocodeAddress } from 'utils/';
import { SearchBox, Marker } from 'components/Map/';

const center = {
  lat: -1.286389,
  lng: 36.817223,
};

/**
 * @param {{
 * centerLatLng : {
 * lat : number
 * lng : number
 * }
 * onCoordinatesChanged : Function
 * }} param
 */
function GoogleMap({ onCoordinatesChanged, centerLatLng = center }) {
  if (typeof centerLatLng === 'string') {
    centerLatLng = JSON.parse(centerLatLng);
  }

  const [mapZoom, setMapZoom] = useState(11);
  const [streetName, setStreetName] = useState('');
  const [mapInstance, setMapInstance] = useState(null);
  const [isDraggable, setIsDraggable] = useState(true);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState(centerLatLng);
  const [mapApiInstance, setMapApiInstance] = useState(null);
  const [coordinates, setCoordinates] = useState(centerLatLng);

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const apiProps = {
    language: 'en',
    key: googleMapApiKey,
    libraries: ['places', 'geometry', 'drawing', 'visualization'],
  };
  const mapProps = {
    center: {
      lat: -1.286389,
      lng: 36.817223,
    },
    zoom: 11,
    hoverDistance: 30,
  };
  function mapOptions() {
    return {
      panControl: true,
      scrollwheel: true,
      mapTypeControl: false,
      styles: [
        {
          stylers: [
            { gamma: 0.8 },
            { lightness: 4 },
            { saturation: 0 },
            { visibility: 'on' },
          ],
        },
      ],
    };
  }

  function onGoogleApiLoaded(map, maps) {
    setMapApiLoaded(true);
    setMapInstance(map);
    setMapApiInstance(maps);
  }

  function onPlacesChanged(places) {
    const place = places && places[0];
    const { formatted_address: street } = place;
    setStreetName(street);
    if (!place.geometry) {
      return;
    }
    const latLng = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    setCoordinates({ ...latLng });
    onCoordinatesChanged(latLng, street);
    if (place.geometry.viewport) {
      mapInstance.fitBounds(place.geometry.viewport);
    } else {
      mapInstance.setZoom(mapProps.zoom);
      mapInstance.setCenter(place.geometry.location);
    }
  }

  function onMapClick({ lat, lng }) {
    setCoordinates({ lat, lng });
    geocodeAddress(mapApiInstance, coordinates, function (street) {
      setStreetName(street);
    });
    onCoordinatesChanged({ lat, lng }, streetName);
  }

  function onMarkerInteraction(mouse) {
    setIsDraggable(false);
    setCoordinates({
      lat: mouse.lat,
      lng: mouse.lng,
    });
  }

  function onMarkerInteractionMouseUp() {
    setIsDraggable(false);
    geocodeAddress(mapApiInstance, coordinates, function (street) {
      setStreetName(street);
    });
  }

  function onMapChange({ center, zoom }) {
    setMapZoom(zoom);
    setMapCenter(center);
  }

  function onChildClick() {}

  return (
    <>
      {mapApiLoaded && (
        <SearchBox
          mapInstance={mapInstance}
          mapApiInstance={mapApiInstance}
          onPlacesChanged={onPlacesChanged}
          placeholder="Search for places..."
        />
      )}
      <GoogleMapReact
        zoom={mapZoom}
        center={mapCenter}
        options={mapOptions}
        onClick={onMapClick}
        onChange={onMapChange}
        draggable={isDraggable}
        bootstrapURLKeys={apiProps}
        defaultZoom={mapProps.zoom}
        onChildClick={onChildClick}
        defaultCenter={mapProps.center}
        yesIWantToUseGoogleMapApiInternals
        hoverDistance={mapProps.hoverDistance}
        onChildMouseDown={onMarkerInteraction}
        onChildMouseMove={onMarkerInteraction}
        onChildMouseUp={onMarkerInteractionMouseUp}
        onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
      >
        <Marker text={streetName} {...coordinates} />
      </GoogleMapReact>
    </>
  );
}

GoogleMap.propTypes = {
  onCoordinatesChanged: func,
  centerLatLng: shape({ lat: number, lng: number }),
};

export default GoogleMap;
