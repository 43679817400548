import styled from 'styled-components';

import { maroon } from 'constants/color';

export const StyledInput = styled.input`
  --primary: ${({ theme }) => theme.primaryColor};
  width: 100%;
  outline: none;
  font-size: 14px;
  padding: 5px 4px;
  border: 1px solid var(--primary);
  &:focus {
    box-shadow: none;
    border: 1px solid var(--primary);
  }
`;

export const StyledButton = styled.button`
  right: 0;
  height: 100%;
  border: none;
  color: white;
  padding: 0 10px;
  cursor: pointer;
  position: absolute;
  background: ${({ theme }) => theme.primaryColor};
`;

export const StyledBody = styled.div`
  margin-bottom: 30px;
`;

export const ErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledFormGroup = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;
