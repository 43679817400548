import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledMessage = styled(motion.label)`
  margin-right: 20px;
  text-overflow: ellipsis;
`;

export const StyledHeader = styled(motion.div)`
  display: flex;
  padding-left: 10px;
  padding-bottom: 5px;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  ${function ({ hasError }) {
    return hasError
      ? css`
          border-bottom: 1px solid #c4c4c4;
        `
      : css`
          padding-top: 2px;
        `;
  }};
`;

export const StyledBody = styled(motion.div)`
  display: flex;
  margin-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const StyledError = styled(motion.label)`
  width: 100%;
`;

export const StyledNotify = styled(motion.section)`
  --color: ${({ $color }) => $color};

  width: auto;
  right: 30px;
  bottom: 70px;
  z-index: 1010;
  display: flex;
  padding: 5px 0;
  position: fixed;
  font-size: 15px;
  max-width: 500px;
  min-width: 300px;
  text-align: left;
  font-weight: 400;
  color: var(--color);
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--color);
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: ${({ hasError }) => (hasError ? '60px' : '30px')};

  ${StyledHeader} {
    svg {
      fill: var(--color);
    }
  }

  /* Small screens */
  @media only screen and (max-width: 600px) {
    width: 90%;
    right: 20px;
    bottom: 10px;
  }
`;

export const StyledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  font-size: 15px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
  background-color: transparent;

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;
