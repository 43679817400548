import React from 'react';

import {
  StyledTable,
  StyledWrapper,
  StyledTableRow,
  StyledTableHead,
  StyledTableCell,
  StyledTableBody,
  StyledTableHeader,
} from './AssignedInfo.styled';
import { formatDate } from 'utils/';

/**
 * @param {{}} param
 */
const OrderDetail = ({ assets }) => {
  return (
    <StyledWrapper>
      {assets && assets.length > 0 ? (
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableHeader>Asset Name</StyledTableHeader>
              <StyledTableHeader>Status</StyledTableHeader>
              <StyledTableHeader>Date Assigned</StyledTableHeader>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {assets.map(function (item) {
              const { id, asset, status, date_assigned: assignedOn } = item;
              const { item_name: name } = asset;
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell>{name}</StyledTableCell>
                  <StyledTableCell>{status}</StyledTableCell>
                  <StyledTableCell>
                    {formatDate({ date: +assignedOn })}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      ) : null}
    </StyledWrapper>
  );
};

export default OrderDetail;
