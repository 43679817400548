import React from 'react';
import { node, number, string } from 'prop-types';

import {
  StyledHeader,
  StyledWrapper,
} from 'components/Widgets/Tile/Tile.styled';

/**
 * @param {{
 *  children : JSX
 *  caption : string
 *  maxHeight : number
 *  minHeight : number
 * }} param
 */
function Tile({ children, caption, maxHeight, minHeight }) {
  const animation = {
    whileHover: {
      scale: 0.99,
    },
    whileTap: {
      scale: 0.95,
    },
  };
  return (
    <StyledWrapper {...animation} minHeight={minHeight} maxHeight={maxHeight}>
      <StyledHeader {...animation}>{caption}</StyledHeader>
      {children}
    </StyledWrapper>
  );
}

Tile.defaultProps = {
  maxHeight: 500,
  minHeight: 300,
};

Tile.propTypes = {
  caption: string,
  minHeight: number,
  maxHeight: number,
  children: node.isRequired,
};

export default Tile;
