import { endPoints } from 'api/';
import { GET_ORDER_HISTORY } from 'actions/types';

/**
 * @description Get a list of a specific order history logs
 * @param {{
 *   orderId: string
 *   companyId: string,
 * }} params
 * @returns Array of order history objects
 */
function getHistory({ orderId, companyId }) {
  const query = `?where={"company_id": "${companyId}", "reference_table": "orders", "reference_id": "${orderId}"}`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_ORDER_HISTORY,
      /**
       *  @param {Function} apiClient
       */
      request: async function (apiClient) {
        const history = await apiClient(endPoints.GET_LOGS, {}, query);
        return history;
      },
    };
    dispatch(action);
  };
}

export default getHistory;
