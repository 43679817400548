import { endPoints } from 'api/';
import { clearModal } from 'actions/modals/';
import { getCompany } from 'actions/company/';
import { UPDATE_COMPANY } from 'actions/types';
import { COMPANY_MODAL } from 'constants/modal';

/**
 * @description Updates company profile.
 * @param {string} companyId
 * @param {{}} payload
 */
function update(companyId, payload) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_COMPANY,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const company = await apiClient(
          endPoints.UPDATE_COMPANY,
          payload,
          companyId,
        );
        dispatch(getCompany(companyId));
        dispatch(clearModal(COMPANY_MODAL));
        return company;
      },
    };
    dispatch(action);
  };
}

export default update;
