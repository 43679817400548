/**
 *
  @param {File} file
  @returns Promise
 */
function getDataUrlFromFile(file) {
  return new Promise((ful) => {
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      ful(e.target.result);
    };
    fileReader.readAsDataURL(file);
  });
}

export default getDataUrlFromFile;
