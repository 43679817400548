import React from 'react';

import WezzaLogo from 'img/wezza.gif';
import { LoaderWrapper, LoaderImage } from './Loader.styled';

const Loader = () => {
  return (
    <LoaderWrapper>
      <LoaderImage src={WezzaLogo} alt="WezzaLogo" />
    </LoaderWrapper>
  );
};

export default Loader;
