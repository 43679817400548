import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { disabled } from 'constants/status';
import { showModal, clearModal } from 'actions/modals/';
import { getWarehouses, updateWarehouse } from 'actions/warehouses/';
import { WAREHOUSE_LIST_MODAL, WAREHOUSE_MODAL } from 'constants/modal';
import WarehouseList from 'components/Warehouses/WarehouseList/WarehouseList';

const WarehouseListModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: warehouses, isLoading } = useSelector(
    ({ warehouses }) => warehouses,
  );

  const isOpen = modal?.type === WAREHOUSE_LIST_MODAL;
  const modalCaption = 'Warehouses';

  const onModalClose = () => {
    dispatch(clearModal(WAREHOUSE_LIST_MODAL));
  };

  /**
    @param {{}} payload
   */
  const onEditClick = (payload) => {
    dispatch(showModal(WAREHOUSE_MODAL, payload));
  };

  /**
    @param {{
     id : string,
     name : string,
     company_id : string
     }} warehouse
   */
  const onDeleteClick = (warehouse) => {
    const { id: warehouseId, name, company_id: companyId } = warehouse;
    const formData = new FormData();

    formData.append('name', name);
    formData.append('id', warehouseId);
    formData.append('status', disabled);

    dispatch(updateWarehouse(warehouseId, formData, companyId, true));
  };

  function onLoadData({ lowerDate, upperDate }) {
    const { company_id: companyId } = user;
    dispatch(getWarehouses({ companyId, lowerDate, upperDate }));
  }

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;

      dispatch(getWarehouses({ companyId }));
    }
  }, [dispatch, user]);

  return (
    <Modal
      modalTop={10}
      modalLeft={10}
      isOpen={isOpen}
      modalWidth={80}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <WarehouseList
        isOnBoarding={false}
        isLoading={isLoading}
        onLoadData={onLoadData}
        warehouses={warehouses}
        onEditClick={onEditClick}
        onModalClose={onModalClose}
        onDeleteClick={onDeleteClick}
      />
    </Modal>
  );
};

export default WarehouseListModal;
