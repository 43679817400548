import styled, { css } from 'styled-components';

import { maroon, primary, mediumGrey } from 'constants/color';

export const StyledTableRow = styled.tr`
  ${function ({ $sub }) {
    return (
      !$sub &&
      css`
        &:not(:first-child) {
          & td {
            border-top: 1px solid ${primary};
          }
        }
      `
    );
  }}
  &:last-child {
    & td {
      border-bottom: 1px solid ${primary};
    }
  }
`;

export const StyledErrorMessage = styled.p`
  font-size: 12px;
  font-size: 14px;
  color: ${maroon};
  font-weight: 400;
  margin: 2px 0 0 0;
  padding: 5px 10px;
  margin: 5px 0 10px 5px;
  background-color: #f8d7da;
`;

export const StyledWrapper = styled.div`
  padding-top: 10px;
`;

export const StyledSubTableData = styled.td`
  height: 30px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  padding-left: 20px;
  font-weight: normal;
  font-weight: normal;
  ${function ({ $last }) {
    return (
      !$last &&
      css`
        &:not(:first-child) {
          border-bottom: 1px solid ${mediumGrey};
        }
      `
    );
  }}
`;
