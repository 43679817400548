import React from 'react';
import { number, string } from 'prop-types';

import {
  StyledProgress,
  StyledProgressBar,
  StyledProgressValue,
} from 'components/ProgressBar/ProgressBar.styled';

/**
 * @param {{
 * progress :number
 * barColor : string
 * barWidth : number
 * barHeight : number
 * valueColor : string
 * }} param
 */
function ProgressBar({ progress, barColor, barWidth, barHeight, valueColor }) {
  return (
    <StyledProgressBar barWidth={barWidth} barHeight={barHeight}>
      <StyledProgress
        progress={progress}
        barColor={barColor}
        barHeight={barHeight}
      >
        <StyledProgressValue valueColor={valueColor}>
          {progress}%
        </StyledProgressValue>
      </StyledProgress>
    </StyledProgressBar>
  );
}

ProgressBar.defaultProps = {
  barHeight: 10,
  valueColor: '#ffffff',
};

ProgressBar.propTypes = {
  barWidth: number,
  barHeight: number,
  valueColor: string,
  barColor: string.isRequired,
  progress: number.isRequired,
};

export default ProgressBar;
