import { primary } from 'constants/color';

/**
 * 
   @param {[]} data 
   @param {[]} labels 
   @param {string} title 
   @param {string} legend 
   @param {string} subTitle 
   @param {boolean} inverted 
   @param {string} xAxisCaption 
   @param {string} yAxisCaption 
 */
function getBarChartOptions(
  data,
  title,
  legend,
  labels,
  inverted,
  subTitle,
  xAxisCaption,
  yAxisCaption,
) {
  const styles = {
    color: primary,
    fontSize: '12px',
    fontWeight: 'normal',
  };

  const options = {
    title: {
      text: title,
      style: styles,
    },
    credits: {
      enabled: false,
    },
    chart: {
      inverted,
      type: 'column',
    },
    colors: [primary],
    series: [
      {
        data,
        name: legend,
      },
    ],
    subtitle: {
      style: styles,
      text: subTitle,
    },
    xAxis: {
      crosshair: true,
      categories: labels,
      title: {
        style: styles,
        text: xAxisCaption,
      },
    },
    yAxis: {
      min: 0,
      title: {
        style: styles,
        text: yAxisCaption,
      },
    },
    plotOptions: {
      column: {
        pointWidth: 15,
        borderWidth: 0,
        pointMargin: 0,
        pointPadding: 0,
        groupPadding: 0.1,
      },
      series: {
        pointWidth: 15,
        borderWidth: 0,
        pointMargin: 0,
        pointPadding: 0,
        groupPadding: 0.1,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: `
          <span 
          style='font-size:12px;color:{series.color};'>
          {point.key}
          </span>
          <table>`,
      footerFormat: '</table>',
      pointFormat: `
          <tr>
          <td style='font-size:12px;color:{series.color};padding:0'>
          {series.name} :
          </td>
          <td style='font-size:12px;padding:0;'>
          {point.y:.1f}
          </td>
          </tr>`,
    },
  };

  return options;
}

export default getBarChartOptions;
