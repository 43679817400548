import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'components/Company/';
import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import currencies from 'constants/currencies';
import { COMPANY_MODAL } from 'constants/modal';
import { getCompany, updateCompany } from 'actions/company/';

function CompanyModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const company = useSelector(({ company }) => company);
  const isOpen = modal?.type === COMPANY_MODAL;
  const modalCaption = 'Company Details';

  function onModalClose() {
    dispatch(clearModal(COMPANY_MODAL));
  }

  /**
   * @param {{}} payload
   * @param {string} companyId
   */
  function onSubmit(payload, companyId) {
    dispatch(updateCompany(companyId, payload));
  }

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;
      dispatch(getCompany(companyId));
    }
  }, [dispatch, user]);

  return (
    <Modal
      modalTop={15}
      modalLeft={25}
      isOpen={isOpen}
      modalWidth={50}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Form
        onSubmit={onSubmit}
        initialValue={company}
        currencies={currencies}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default CompanyModal;
