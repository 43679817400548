import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';

import rootReducer from 'reducers';
import { clearTokenCookie } from 'utils/';
import apiMiddleware from 'middleware/apiMiddleware';
import authMiddleware from 'middleware/authMiddleware';

function createConfiguredStore() {
  const history = createBrowserHistory();
  const initialState = {};
  const enhancers = [];

  const middleware = [thunk, apiMiddleware, authMiddleware];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  const store = createStore(rootReducer(), initialState, composedEnhancers);

  store.subscribe(() => {
    const { isLoggedOut } = store.getState();

    if (isLoggedOut && window.location.pathname.indexOf('login') === -1) {
      clearTokenCookie();
    }
  });

  return {
    store,
    history,
  };
}

const { store, history } = createConfiguredStore();

export { history, createConfiguredStore };

export default store;
