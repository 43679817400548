import { endPoints } from 'api/';
import { AUTHENTICATE_USER } from 'actions/types';

/**
  @description Authenticates user with `email` and `password`
  @param {string} email
  @param {string} password
  @returns User object with token
 */
function authenticate(email, password) {
  const payload = { email, password };

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: AUTHENTICATE_USER,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const user = await apiClient(endPoints.AUTHENTICATE, payload);

        return user;
      },
    };

    dispatch(action);
  };
}

export default authenticate;
