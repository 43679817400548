import {
  SHOW_MODAL,
  CLEAR_MODAL,
  EMAIL_ORDER,
  CREATE_USER,
  UPDATE_USER,
  CREATE_TASK,
  UPDATE_TASK,
  CREATE_ITEM,
  UPDATE_ITEM,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
  ASSIGN_ASSET,
  CREATE_ORDER,
  UPDATE_ORDER,
  CREATE_PEOPLE,
  UPDATE_PEOPLE,
  CREATE_STORAGE,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  UPDATE_ASSIGNED_ASSET,
} from 'actions/types';
import { transformActions } from 'utils/';

const initialState = {
  type: '',
  initialValue: null,
};

/**
  @description Sets modal state shown | hidden
  @param {{}} state
  @param {{}} action
  @returns {{}}
 */
function modal(state = initialState, action) {
  const { type: actionType, payload } = action;
  const actions = [
    EMAIL_ORDER,
    CREATE_USER,
    UPDATE_USER,
    CREATE_TASK,
    UPDATE_TASK,
    CREATE_ITEM,
    UPDATE_ITEM,
    ASSIGN_ASSET,
    CREATE_ASSET,
    UPDATE_ASSET,
    DELETE_ASSET,
    CREATE_ORDER,
    UPDATE_ORDER,
    CREATE_PEOPLE,
    UPDATE_PEOPLE,
    CREATE_STORAGE,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    CREATE_WAREHOUSE,
    UPDATE_WAREHOUSE,
    UPDATE_ASSIGNED_ASSET,
  ];
  const success = transformActions(actions, 'SUCCESS');

  if (actionType === SHOW_MODAL) {
    return payload;
  }

  if (actionType === CLEAR_MODAL) {
    if (state && payload.type === state.type) return initialState;
  }

  if (success.includes(actionType)) {
    return {
      type: '',
      initialValue: null,
    };
  }

  return state;
}

export default modal;
