import { func, object } from 'prop-types';
import React, { useState, useEffect } from 'react';

import { APP_SUPPORT_LINK } from 'constants/general';
import { FlexCol, FlexRow } from 'components/Layout/';
import {
  StyledUserType,
  StyledAppSupport,
  StyledRequestChange,
  StyledAppSupportLink,
  StyledUserTypeWrapper,
} from 'components/Users/UserProfile/UserProfile.styled';
import { PhotoInput, TextInput } from 'components/Inputs/';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';
import { validateEmail, capitalizeFirstLetter, openExternalLink } from 'utils/';

/**
 * @param {{
 *  user : {}
 *  onSubmit : Function
 *  onModalClose : Function
 *  }} param
 */
function UserProfile({ onSubmit, user, onModalClose }) {
  const {
    phone,
    email,
    photo,
    usertype,
    last_name: lastName,
    first_name: firstName,
  } = user || {};
  const [userState, setUserState] = useState({
    phone,
    email,
    lastName,
    firstName,
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  });

  const [userErrors, setUserErrors] = useState({
    phone: '',
    email: '',
    lastName: '',
    firstName: '',
    newPassword: '',
    currentPassword: '',
    confirmPassword: '',
  });
  const userType = capitalizeFirstLetter(usertype);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(photo);
  const [showSupportTip, setShowSupportTip] = useState(false);

  /**
   * @param {{
   *  currentTarget: {
   *  name: string
   *  value: string
   *  }
   *  }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setUserState({
      ...userState,
      [name]: value,
    });
  }

  function onSubmitUser() {
    setIsSubmitting(true);
    const isValidForm = validateUserForm();
    if (isValidForm) {
      const { email, phone, lastName, firstName } = userState;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('last_name', lastName);
      formData.append('photo', profilePhoto);
      formData.append('first_name', firstName);
      onSubmit(formData);
    }
  }

  function validateUserForm() {
    let isFormValid = true;
    const optionalFields = [
      'newPassword',
      'currentPassword',
      'confirmPassword',
    ];
    let errorObject = { ...userErrors };
    for (const [key, value] of Object.entries(userState)) {
      if (!value && !optionalFields.includes(key)) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setUserErrors(errorObject);
    return isFormValid;
  }

  function onRequestChange() {
    setShowSupportTip(true);
  }

  useEffect(() => {
    if (isSubmitting) {
      validateUserForm();
    }
  }, [userState, isSubmitting]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                inputType="text"
                name="firstName"
                inputId="firstName"
                caption="First Name"
                onChange={onInputChange}
                value={userState.firstName}
                placeholder="Enter first name"
                errorMessage={userErrors.firstName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="lastName"
                inputType="text"
                inputId="lastName"
                caption="Last Name"
                onChange={onInputChange}
                value={userState.lastName}
                placeholder="Enter last name"
                errorMessage={userErrors.lastName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="email"
                inputId="email"
                inputType="text"
                caption="Email Address"
                value={userState.email}
                onChange={onInputChange}
                errorMessage={userErrors.email}
                placeholder="Enter email address"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                caption="Phone"
                inputType="text"
                value={userState.phone}
                onChange={onInputChange}
                placeholder="e.g +254712345678"
                errorMessage={userErrors.phone}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                disableAutoComplete
                inputType="password"
                name="currentPassword"
                onChange={onInputChange}
                inputId="currentPassword"
                caption="Current Password"
                value={userState.currentPassword}
                placeholder="Enter current password"
                errorMessage={userErrors.currentPassword}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                name="newPassword"
                disableAutoComplete
                inputType="password"
                inputId="newPassword"
                caption="New Password"
                onChange={onInputChange}
                value={userState.newPassword}
                placeholder="Enter new password"
                errorMessage={userErrors.newPassword}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                disableAutoComplete
                inputType="password"
                name="confirmPassword"
                onChange={onInputChange}
                inputId="confirmPassword"
                caption="Confirm Password"
                value={userState.confirmPassword}
                placeholder="Enter confirm password"
                errorMessage={userErrors.confirmPassword}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <PhotoInput
                id="profilePicture"
                imageUri={profilePhoto}
                caption="Profile Picture"
                onChange={setProfilePhoto}
                dataTestId="profilePicture"
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow marginBottom={20}>
        <FlexCol flexWidth={12}>
          <StyledUserTypeWrapper>
            <StyledUserType>{`Status : ${userType}`}</StyledUserType>
            <StyledRequestChange onClick={onRequestChange}>
              (Request Change)
            </StyledRequestChange>
          </StyledUserTypeWrapper>
        </FlexCol>
      </FlexRow>
      {showSupportTip && (
        <FlexRow marginBottom={20}>
          <FlexCol flexWidth={12}>
            <StyledAppSupport>
              Please contact your admin or reach out to us via
              <StyledAppSupportLink
                data-testid="app-support"
                onClick={openExternalLink.bind(null, APP_SUPPORT_LINK)}
              >
                support
              </StyledAppSupportLink>{' '}
              or email
              <StyledAppSupportLink>support@henga.co</StyledAppSupportLink>
            </StyledAppSupport>
          </FlexCol>
        </FlexRow>
      )}
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          flexWidth={100}
          icon={<ArrowIcon />}
          onClick={onSubmitUser}
          className="modal-button"
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

UserProfile.propTypes = {
  user: object,
  onSubmit: func,
  onModalClose: func,
};

export default UserProfile;
