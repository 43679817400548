import { SHOW_MODAL } from 'actions/types';

/**
  @description Set modal to be rendered
  @param {string} type Modal to display
  @param {{} | string} initialValue Payload to be passed to modal while editing
 */
function showModal(type, initialValue = null) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: SHOW_MODAL,
      payload: {
        type,
        initialValue,
      },
    };

    dispatch(action);
  };
}

export default showModal;
