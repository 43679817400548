import { func, string } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import React, { useState, useEffect, useRef } from 'react';

import {
  StyledUserName,
  StyledUserTitle,
  StyledMenuWrapper,
  StyledDropdownMenu,
  StyledUserNameWrapper,
} from 'components/Navbar/DropdownMenu/DropdownMenu.styled';
import { ReactComponent as LockIcon } from 'icons/lock.svg';
import { ReactComponent as UserIcon } from 'icons/user.svg';
import { ReactComponent as SignOutIcon } from 'icons/signout.svg';
import DropDownItem from 'components/Navbar/DropDownItem/DropDownItem';

/**
 * @param {{
 * userName: string,
 * userType: string,
 * onSignOut: Function,
 * onShowProfileModal: Function,
 * }} param
 */
function DropdownMenu({
  userName,
  userType,
  onSignOut,
  onLockScreen,
  onShowProfileModal,
}) {
  const dropdownRef = useRef(null);
  const [menuHeight, setMenuHeight] = useState(0);
  const [activeMenu, setActiveMenu] = useState('main');

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild?.offsetHeight);
  }, []);

  function calcHeight(element) {
    const height = element?.offsetHeight || 0;
    setMenuHeight(height);
  }

  return (
    <StyledDropdownMenu style={{ height: menuHeight }} ref={dropdownRef}>
      <CSSTransition
        timeout={500}
        unmountOnExit={true}
        onEnter={calcHeight}
        classNames="menu-primary"
        in={activeMenu === 'main'}
      >
        <StyledMenuWrapper>
          <StyledUserNameWrapper>
            <StyledUserName>{userName}</StyledUserName>
            <StyledUserTitle>{userType}</StyledUserTitle>
          </StyledUserNameWrapper>

          <DropDownItem
            leftIcon={<UserIcon />}
            onClick={onShowProfileModal}
            setActiveMenu={setActiveMenu}
          >
            Profile
          </DropDownItem>
          <DropDownItem
            onClick={onLockScreen}
            leftIcon={<LockIcon />}
            setActiveMenu={setActiveMenu}
          >
            Lock Screen
          </DropDownItem>
          <DropDownItem
            onClick={onSignOut}
            leftIcon={<SignOutIcon />}
            setActiveMenu={setActiveMenu}
          >
            Log Out
          </DropDownItem>
        </StyledMenuWrapper>
      </CSSTransition>
    </StyledDropdownMenu>
  );
}

DropdownMenu.defaultProps = {
  userName: '',
  userType: '',
};

DropdownMenu.propTypes = {
  userName: string,
  userType: string,
  onLockScreen: func,
  onShowProfileModal: func,
  onSignOut: func.isRequired,
};

export default DropdownMenu;
