import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';

import 'css/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import App from 'app/App';
import {
  LOCK_SCREEN_PATH,
  ACCOUNT_SIGNUP_PATH,
  PASSWORD_RESET_PATH,
} from 'constants/general';
import { getUser } from 'actions/users/';
import reportWebVitals from 'reportWebVitals';
import { createConfiguredStore } from 'store/store';
import { ErrorBoundary } from 'components/ErrorBoundary/';

const unauthenticatedPaths = [
  LOCK_SCREEN_PATH,
  ACCOUNT_SIGNUP_PATH,
  PASSWORD_RESET_PATH,
]; // publicly available paths
const { pathname } = window.location;
const { store } = createConfiguredStore();
const rootElement = window.document.querySelector('#root');
const shouldLoginIn = !unauthenticatedPaths.includes(pathname);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ReduxProvider store={store} context={ReactReduxContext}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReduxProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  rootElement,
);

/**
  Dispatching this conditionally since `getUser` action will 
  automatically redirect to login page for all unauthenticated users.
  We want this route to be rendered for unauthenticated users as well so they can signup.
 */
if (shouldLoginIn) {
  store.dispatch(getUser());
}

reportWebVitals();
