import React from 'react';

import { formatDate } from 'utils/';
import {
  StyledInfo,
  StyledTable,
  StyledTitle,
  StyledDetail,
  StyledWrapper,
  StyledTableRow,
  StyledTableHead,
  StyledTableCell,
  StyledTableBody,
  StyledTableHeader,
} from 'components/Order/Detail/Detail.styled';

/**
 * @param {{}} param
 */
function Detail({ order }) {
  const {
    name,
    items,
    notes,
    status,
    customer,
    total_cost: cost,
    createdat: orderDate,
  } = order;
  const { name: customerName, email, phone, address } = customer || {};
  const customerInfo = `${customerName} | ${email} | ${phone}`;
  return (
    <StyledWrapper>
      <StyledDetail>
        <StyledTitle>Order name</StyledTitle>
        <StyledInfo>{name}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Status</StyledTitle>
        <StyledInfo>{status}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Cost</StyledTitle>
        <StyledInfo>{cost}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Customer</StyledTitle>
        <StyledInfo>{customerInfo}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Address</StyledTitle>
        <StyledInfo>{unescape(address)}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Order Date</StyledTitle>
        <StyledInfo>{formatDate({ date: orderDate })}</StyledInfo>
      </StyledDetail>
      <StyledDetail>
        <StyledTitle>Note</StyledTitle>
        <StyledInfo>{unescape(notes)}</StyledInfo>
      </StyledDetail>
      {items && items.length > 0 ? (
        <StyledTable>
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableHeader>Item Name</StyledTableHeader>
              <StyledTableHeader>Quantity</StyledTableHeader>
            </StyledTableRow>
          </StyledTableHead>
          <StyledTableBody>
            {items.map(function (item) {
              const { id, quantity, name } = item;
              return (
                <StyledTableRow key={id}>
                  <StyledTableCell>{name}</StyledTableCell>
                  <StyledTableCell>{quantity}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </StyledTable>
      ) : null}
    </StyledWrapper>
  );
}

export default Detail;
