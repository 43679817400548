import { SET_ACTIVE_MENU } from 'actions/types';

/**
  @description Sets current active side nav
  @param {string} menuButton
 */
function setActiveMenu(menuButton) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: SET_ACTIVE_MENU,
      payload: menuButton,
    };

    dispatch(action);
  };
}

export default setActiveMenu;
