import { useEffect, useState } from 'react';

import { SIDE_NAV_LOCAL_STORAGE } from 'constants/general';
import useMobileView from 'hooks/useMobileView/useMobileView';

/**
  @description This hook persists `SideNav` state in browser local storage.
 */
function useSideNav() {
  const isMobileView = useMobileView();
  const [sideNavOpen, setSideNavOpen] = useState(isMobileView);

  const onSideNavToggle = () => {
    const sideNavState = !sideNavOpen;

    window.localStorage.setItem(
      SIDE_NAV_LOCAL_STORAGE,
      JSON.stringify(sideNavState),
    );

    setSideNavOpen(sideNavState);
  };

  useEffect(() => {
    const localSideNavState = window.localStorage.getItem(
      SIDE_NAV_LOCAL_STORAGE,
    );
    if (isMobileView) {
      setSideNavOpen(false);
    } else if (localSideNavState) {
      setSideNavOpen(localSideNavState === 'true');
    }
  }, [isMobileView]);

  return [sideNavOpen, onSideNavToggle];
}

export default useSideNav;
