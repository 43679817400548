const hoverAndTap = {
  whileHover: {
    scale: 0.99,
  },
  whileTap: {
    scale: 1.01,
  },
};

export { hoverAndTap };
