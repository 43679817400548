import React from 'react';

import {
  StyledInput,
  StyledLabel,
  StyledCaption,
  StyledAsterisk,
  StyledIndicator,
} from 'components/Inputs/ToggleSwitch/ToggleSwitch.styled';

function ToggleSwitch({ checked, caption, onChange, isRequired }) {
  return (
    <>
      <StyledCaption htmlFor="checkbox">{caption}</StyledCaption>
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      <StyledLabel htmlFor="checkbox" checked={checked}>
        <StyledInput
          id="checkbox"
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <StyledIndicator checked={checked}>
          {checked ? 'YES' : 'NO'}
        </StyledIndicator>
      </StyledLabel>
    </>
  );
}

export default ToggleSwitch;
