const apiEndpoints = {
  // LOGS
  GET_LOGS: 'GET_LOGS',
  // ITEMS
  GET_ITEMS: 'GET_ITEMS',
  UPLOAD_ITEM: 'UPLOAD_ITEM',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_ITEM: 'UPDATE_ITEM',
  GET_STOCK_MOVE: 'GET_STOCK_MOVE',
  GET_ITEM_UPLOAD: 'GET_ITEM_UPLOAD',
  BULK_UPDATE_ITEM: 'BULK_UPDATE_ITEM',
  UPDATE_ITEM_UPLOAD: 'UPDATE_ITEM_UPLOAD',
  // TAGS
  GET_TAGS: 'GET_TAGS',
  CREATE_TAG: 'CREATE_TAG',
  UPDATE_TAG: 'UPDATE_TAG',
  // USERS
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  AUTHENTICATE: 'AUTHENTICATE',
  // TASKS
  GET_TASKS: 'GET_TASKS',
  CREATE_TASK: 'CREATE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  // ORDERS
  GET_ORDERS: 'GET_ORDERS',
  SHARE_ORDER: 'SHARE_ORDER',
  CREATE_ORDER: 'CREATE_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  ASSIGN_ORDER: 'ASSIGN_ORDER',
  RETURN_ORDER: 'RETURN_ORDER',
  BULK_UPDATE_ORDER: 'BULK_UPDATE_ORDER',
  GET_ASSIGNED_ORDERS: 'GET_ASSIGNED_ORDERS',
  UPDATE_ASSIGNED_ORDER: 'UPDATE_ASSIGNED_ORDER',
  // CONDITION
  GET_CONDITION: 'GET_CONDITION',
  CREATE_CONDITION: 'CREATE_CONDITION',
  UPDATE_CONDITION: 'UPDATE_CONDITION',
  // COMPANIES
  GET_COMPANY: 'GET_COMPANY',
  GET_COMPANIES: 'GET_COMPANIES',
  CREATE_COMPANY: 'CREATE_COMPANY',
  UPDATE_COMPANY: 'UPDATE_COMPANY',
  // STORAGES
  GET_STORAGES: 'GET_STORAGES',
  CREATE_STORAGE: 'CREATE_STORAGE',
  UPDATE_STORAGE: 'UPDATE_STORAGE',
  // CUSTOMERS
  GET_CUSTOMERS: 'GET_CUSTOMERS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  // CATEGORIES
  GET_CATEGORIES: 'GET_CATEGORIES',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  // WAREHOUSES
  GET_WAREHOUSES: 'GET_WAREHOUSES',
  CREATE_WAREHOUSE: 'CREATE_WAREHOUSE',
  UPDATE_WAREHOUSE: 'UPDATE_WAREHOUSE',
  GET_WAREHOUSE_USAGE: 'GET_WAREHOUSE_USAGE',
  // ASSETS
  GET_ASSETS: 'GET_ASSETS',
  CREATE_ASSET: 'CREATE_ASSET',
  UPDATE_ASSET: 'UPDATE_ASSET',
  ASSIGN_ASSET: 'ASSIGN_ASSET',
  GET_ASSIGNED_ASSETS: 'GET_ASSIGNED_ASSETS',
  UPDATE_ASSIGNED_ASSET: 'UPDATE_ASSIGNED_ASSET',
  // PASSWORD
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  // NOTIFICATION
  GET_NOTIFICATION: 'GET_NOTIFICATION',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  // ATTRIBUTE
  GET_ATTRIBUTE: 'GET_ATTRIBUTE',
  CREATE_ATTRIBUTE: 'CREATE_ATTRIBUTE',
  UPDATE_ATTRIBUTE: 'UPDATE_ATTRIBUTE',
};

export default apiEndpoints;
