import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { white, primary } from 'constants/color';

export const StyledCheckMark = styled(motion.span)`
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 50%;
  background-color: #eee;
  &:after {
    content: '';
    display: none;
    position: absolute;
  }
  ${function ({ checked }) {
    return (
      checked &&
      css`
        :after {
          display: block;
        }
        background-color: ${primary};
      `
    );
  }}
`;

export const StyledInput = styled(motion.input)`
  width: 0;
  height: 0;
  opacity: 0;
  cursor: pointer;
  position: absolute;
`;

export const StyledCaption = styled(motion.label)`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  vertical-align: text-top;
`;

export const StyledWrapper = styled(motion.label)`
  --color: ${({ $header }) => ($header ? white : primary)};
  font-size: 22px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding-left: 20px;
  font-style: normal;
  margin-bottom: 12px;
  font-weight: normal;
  vertical-align: text-top;
  margin-right: ${({ $marginRight }) => ($marginRight ? $marginRight : '0')}px;
  &:hover {
    ${StyledCheckMark} {
      background-color: transparent;
    }
  }
  ${StyledCheckMark} {
    color: red;
    width: 15px;
    height: 15px;
    border-radius: 0;
    background-color: transparent;
    border: 1px solid var(--color);
    &:after {
      top: 0;
      left: 4px;
      color: red;
      width: 4px;
      height: 9px;
      transform: rotate(45deg);
      border: solid var(--color);
      -ms-transform: rotate(45deg);
      border-width: 0px 3px 3px 0px;
      -webkit-transform: rotate(45deg);
    }
  }
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;

export const StyledDescription = styled.p`
  color: #5d5d5d;
  font-size: 10px;
  margin-top: 0px;
  font-weight: 300;
  line-height: 12px;
`;
