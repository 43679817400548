import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { disabled } from 'constants/status';
import { showModal, clearModal } from 'actions/modals/';
import { getStorages, updateStorage } from 'actions/storages/';
import { STORAGE_LIST_MODAL, STORAGE_MODAL } from 'constants/modal';
import StorageList from 'components/Storages/StorageList/StorageList';

const StorageListModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: storages, isLoading } = useSelector(({ storages }) => storages);

  const isOpen = modal?.type === STORAGE_LIST_MODAL;
  const modalCaption = 'Storages';

  const onModalClose = () => {
    dispatch(clearModal(STORAGE_LIST_MODAL));
  };

  /**
    @param {{}} payload
   */
  const onEditClick = (payload) => {
    dispatch(showModal(STORAGE_MODAL, payload));
  };

  /**
    @param {{
     id : string,
     name : string,
     company_id : string
     }} storage
   */
  const onDeleteClick = (storage) => {
    const { id: storageId, name, company_id: companyId } = storage;
    const formData = new FormData();

    formData.append('name', name);
    formData.append('id', storageId);
    formData.append('status', disabled);

    dispatch(updateStorage(storageId, formData, companyId, true));
  };

  function onLoadData({ lowerDate, upperDate }) {
    const { company_id: companyId } = user;
    dispatch(getStorages({ companyId, lowerDate, upperDate }));
  }

  useEffect(() => {
    if (user) {
      const { company_id: companyId } = user;

      dispatch(getStorages({ companyId }));
    }
  }, [dispatch, user]);

  return (
    <Modal
      modalTop={10}
      modalLeft={10}
      isOpen={isOpen}
      modalWidth={80}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <StorageList
        storages={storages}
        isOnBoarding={false}
        isLoading={isLoading}
        onLoadData={onLoadData}
        onEditClick={onEditClick}
        onModalClose={onModalClose}
        onDeleteClick={onDeleteClick}
      />
    </Modal>
  );
};

export default StorageListModal;
