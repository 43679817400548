import React, { useRef, useMemo, useState, useEffect, createRef } from 'react';

import {
  getOrderNumber,
  objectHasProperty,
  capitalizeFirstLetter,
} from 'utils/';
import { history } from 'store/store';
import {
  StyledTable,
  StyledTableBody,
  StyledTableHead,
} from 'components/Table/Table/Table.styled';
import { RETURN_ORDER } from 'actions/types';
import { CheckBox } from 'components/Inputs/';
import { Loading } from 'components/Loaders/';
import {
  StyledTableHeader,
  StyledTableHeadRow,
} from 'components/Table/Header/Header.styled';
import {
  StyledArrow,
  StyledInput,
  StyledHeader,
  StyledSelect,
  StyledOption,
  StyledMessage,
  StyledWrapper,
  StyledTableRow,
  StyledTableData,
  StyledButtonRow,
  StyledSrOnlyLabel,
  StyledButtonWrapper,
} from 'components/Order/Return/Return.styled';
import { invalid, primary } from 'constants/color';
import { EDIT_ORDER_PATH } from 'constants/general';
import { useMobileView, useHttpStatus } from 'hooks/';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { RoundButton, CancelButton } from 'components/Buttons/';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';
import { LEASE, LEASE_OPTIONS, SALE_OPTIONS } from 'constants/inventory';

function Return({ order, onSubmit }) {
  const { id, items: catalogue = [], order_type: type = '' } = order;
  const inputRefs = useRef({});
  const { response } = useHttpStatus({
    actions: [RETURN_ORDER],
  });
  const isMobileView = useMobileView();
  const marginRight = isMobileView ? 5 : 20;
  const [message, setMessage] = useState('');
  const [checked, setChecked] = useState(false);
  const [items, setItems] = useState(catalogue);
  const hasOrder = objectHasProperty(order, 'id');
  const [checkedIds, setCheckedIds] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const orderNumber = getOrderNumber({ id, prefix: '#' });
  const options = type === LEASE ? LEASE_OPTIONS : SALE_OPTIONS;

  const allIds = useMemo(() => {
    if (items && items.length > 0) {
      const ids = items.flatMap(function (item) {
        const itemIds = [item.id];
        if (item.attributes && item.attributes.length) {
          const attributeIds = item.attributes.map(function (attr) {
            return attr.id;
          });
          return itemIds.concat(attributeIds);
        }
        return itemIds;
      });
      return ids;
    }
    return [];
  }, [items, checkedIds]);

  /**
   * @param {string} id
   */
  function onToggleExpandRow(id) {
    setExpandedRows(function (prev) {
      return prev.includes(id)
        ? prev.filter(function (item) {
            return item !== id;
          })
        : [...prev, id];
    });
  }

  function onSelectChange(event) {
    const { id, value } = event.target;
    setItems(function (prev) {
      return prev.map(function (item) {
        if (item.id === id) {
          return {
            ...item,
            condition: value,
          };
        }
        if (item.attributes && item.attributes.length) {
          const attributes = item.attributes.map(function (attr) {
            if (attr.id === id) {
              return {
                ...attr,
                condition: value,
              };
            }
            return attr;
          });
          return {
            ...item,
            attributes,
          };
        }
        return item;
      });
    });
  }

  /**
   * @param {string} id
   */
  function onCheckChange(id) {
    if (id === 'all') {
      setCheckedIds(function (prev) {
        return prev.length === allIds.length ? [] : allIds;
      });
    } else {
      onToggleExpandRow(id);
      setCheckedIds(function (prev) {
        const index = prev.indexOf(id);
        if (index === -1) {
          return [...prev, id];
        } else {
          return prev.filter(function (item) {
            return item !== id;
          });
        }
      });
    }
  }

  /**
   * @param {string} id
   */
  function selectInput(id) {
    return (
      <>
        <StyledSrOnlyLabel htmlFor={id}>condition-{id}</StyledSrOnlyLabel>
        <StyledSelect id={id} onChange={onSelectChange}>
          {options.map(function ({ value, label }) {
            return (
              <StyledOption key={value} value={value}>
                {label}
              </StyledOption>
            );
          })}
        </StyledSelect>
      </>
    );
  }

  function onQuantityChange(data, event) {
    const { id, attrId, quantity } = JSON.parse(data);
    const inputId = attrId ? attrId : id;
    const returned = +event.target.value;
    const inputRef = inputRefs.current[inputId];
    if (returned > quantity) {
      setMessage('Returned quantity cannot be greater than original quantity');
      if (inputRef && inputRef.current) {
        inputRef.current.select();
        inputRef.current.style.borderColor = invalid;
      }
      return;
    } else {
      setMessage('');
      if (inputRef && inputRef.current) {
        inputRef.current.style.borderColor = primary;
      }
    }
    setItems(function (prev) {
      return prev.map(function (item) {
        if (item.id === id) {
          return {
            ...item,
            returned,
          };
        }
        if (item.attributes && item.attributes.length) {
          const attributes = item.attributes.map(function (attr) {
            if (attr.id === attrId) {
              return {
                ...attr,
                returned,
              };
            }
            return attr;
          });
          return {
            ...item,
            attributes,
          };
        }
        return item;
      });
    });
  }

  function onCancel() {
    const path = EDIT_ORDER_PATH.replace(':id', id);
    history.push(path);
  }

  function onReturnOrder() {
    const filtered = items.filter(function (item) {
      const { id, attributes = [] } = item;
      const attrs = attributes.filter(function (attr) {
        return checkedIds.includes(attr.id);
      });
      return checkedIds.includes(id) || attrs.length > 0;
    });
    if (filtered.length > 0) {
      setMessage('');
      const items = filtered.map(function (item) {
        const { returned, attributes = [], ...rest } = item;
        return {
          ...rest,
          quantity: returned,
          attributes: attributes.map(function (attr) {
            const { returned, ...rest } = attr;
            return {
              ...rest,
              quantity: returned,
            };
          }),
        };
      });
      onSubmit({ id, items: items });
    } else {
      setMessage('Please select at least one item to return');
    }
  }

  useEffect(() => {
    if (response && response.id) {
      onCancel();
    }
  }, [response]);

  useEffect(() => {
    allIds.forEach(function (id) {
      inputRefs.current[id] = inputRefs.current[id] || createRef();
    });
  }, [allIds]);

  useEffect(() => {
    setChecked(checkedIds.length === allIds.length && checkedIds.length > 0);
  }, [items, allIds, checkedIds]);

  useEffect(() => {
    const items = order && order.items ? order.items : [];
    setItems(items);
  }, [order]);

  if (!hasOrder) {
    return <Loading />;
  }

  return (
    <StyledWrapper>
      <StyledHeader>
        {`Return ${capitalizeFirstLetter(type)} Order ${orderNumber}`}
      </StyledHeader>
      {message ? <StyledMessage>{message}</StyledMessage> : null}
      <StyledTable>
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeader>
              <CheckBox
                isHeader
                isChecked={checked}
                showCaption={false}
                marginRight={marginRight}
                caption="check all items"
                onChange={onCheckChange.bind(null, 'all')}
              />
              Name
            </StyledTableHeader>
            <StyledTableHeader>Original Qty</StyledTableHeader>
            <StyledTableHeader>Returned Qty</StyledTableHeader>
            <StyledTableHeader>Condition</StyledTableHeader>
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {items.map(function (item) {
            const { id, name, quantity, returned, attributes = [] } = item;
            const data = JSON.stringify({
              id,
              quantity,
            });
            const inputId = `returned-${id}`;
            const isExpanded = expandedRows.includes(id);
            const hasAttributes = attributes && attributes.length > 0;
            return (
              <>
                <StyledTableRow key={id}>
                  <StyledTableData
                    $first
                    onClick={onToggleExpandRow.bind(null, id)}
                  >
                    <CheckBox
                      showCaption={false}
                      marginRight={marginRight}
                      caption={`check ${id}-${name}`}
                      isChecked={checkedIds.includes(id)}
                      onChange={onCheckChange.bind(null, id)}
                    />
                    {name}
                    {hasAttributes ? (
                      <StyledArrow $open={isExpanded}>
                        <ChevronIcon />
                      </StyledArrow>
                    ) : null}
                  </StyledTableData>
                  <StyledTableData>{quantity}</StyledTableData>
                  <StyledTableData>
                    <StyledSrOnlyLabel htmlFor={inputId}>
                      returned-{id}
                    </StyledSrOnlyLabel>
                    <StyledInput
                      id={inputId}
                      type="number"
                      max={quantity}
                      value={returned}
                      ref={inputRefs.current[id]}
                      onChange={onQuantityChange.bind(null, data)}
                    />
                  </StyledTableData>
                  <StyledTableData>{selectInput(id)}</StyledTableData>
                </StyledTableRow>
                {!isExpanded
                  ? null
                  : attributes.map(function (attr) {
                      const { id: attrId, quantity, returned } = attr;
                      const inputId = `returned-${attrId}`;
                      const data = JSON.stringify({
                        id,
                        attrId,
                        quantity,
                      });
                      const name = `${attr.attribute_name}-${attr.attribute_value}`;
                      return (
                        <StyledTableRow key={attrId}>
                          <StyledTableData $sub>
                            <CheckBox
                              showCaption={false}
                              caption={`check ${id}`}
                              marginRight={marginRight}
                              isChecked={checkedIds.includes(attrId)}
                              onChange={onCheckChange.bind(null, attrId)}
                            />
                            {name}
                          </StyledTableData>
                          <StyledTableData>{quantity}</StyledTableData>
                          <StyledTableData>
                            <StyledSrOnlyLabel htmlFor={inputId}>
                              returned-{attrId}
                            </StyledSrOnlyLabel>
                            <StyledInput
                              id={inputId}
                              type="number"
                              max={quantity}
                              value={returned}
                              ref={inputRefs.current[attrId]}
                              onChange={onQuantityChange.bind(null, data)}
                            />
                          </StyledTableData>
                          <StyledTableData>
                            {selectInput(attrId)}
                          </StyledTableData>
                        </StyledTableRow>
                      );
                    })}
              </>
            );
          })}
        </StyledTableBody>
      </StyledTable>
      <StyledButtonRow>
        <StyledButtonWrapper>
          <RoundButton
            caption="Save"
            flexWidth={100}
            icon={<TickIcon />}
            onClick={onReturnOrder}
          />
        </StyledButtonWrapper>
        <StyledButtonWrapper>
          <CancelButton onClick={onCancel} />
        </StyledButtonWrapper>
      </StyledButtonRow>
    </StyledWrapper>
  );
}

export default Return;
