import { array, func, bool } from 'prop-types';
import React, { useRef, useMemo } from 'react';

import Loader from 'img/loader.gif';
import { Table } from 'components/Table/';
import { InfoAlert } from 'components/Alerts/';
import { DateFilter } from 'components/Reusable/';
import { CancelButton } from 'components/Buttons/';
import { FlexCol, FlexRow } from 'components/Layout/';
import { formatDate, getDateStamp, parseJSON } from 'utils/';
import {
  StyledLoader,
  StyledTableWrapper,
  StyledLoaderWrapper,
  StyledWarehouseName,
} from 'components/Warehouses/WarehouseList/WarehouseList.styled';

/**
 * @param {{
 *  warehouses : []
 *  isLoading : boolean
 *  onEditClick : Function
 *  isOnBoarding : boolean
 *  onLoadData, : Function
 *  onModalClose : Function
 *  onDeleteClick : Function
 *  }} param
 */
function WarehouseList({
  isLoading,
  warehouses,
  onLoadData,
  onEditClick,
  onModalClose,
  isOnBoarding,
  onDeleteClick,
}) {
  const tableRef = useRef();
  const columns = useMemo(function () {
    return [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: function ({ row: { original } }) {
          return (
            <StyledWarehouseName onClick={onEditClick.bind(null, original)}>
              {original.name}
            </StyledWarehouseName>
          );
        },
      },
      {
        meta: {
          date: true,
        },
        header: 'Date Created',
        accessorKey: 'createdat',
        cell: function ({ getValue }) {
          return formatDate({
            standard: true,
            date: getValue(),
          });
        },
        accessorFn: function ({ createdat }) {
          return getDateStamp(new Date(createdat).getTime());
        },
      },
      {
        header: 'Location',
        accessorFn: function ({ settings }) {
          const settingsObj = parseJSON(settings);
          const { address = '' } = settingsObj;
          return address;
        },
      },
      {
        size: 150,
        header: 'Square Feet',
        cell: function ({ getValue }) {
          const val = parseInt(getValue());
          return isNaN(val) ? '' : val;
        },
        accessorFn: function ({ settings }) {
          const settingsObj = parseJSON(settings);
          const { feets } = settingsObj;
          return parseInt(feets);
        },
      },
      {
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
      },
    ];
  }, []);

  return (
    <>
      {isOnBoarding ? null : (
        <DateFilter isLoading={isLoading} onLoadData={onLoadData} />
      )}
      {isLoading ? (
        <StyledLoaderWrapper>
          <StyledLoader src={Loader} alt="Loader" />
        </StyledLoaderWrapper>
      ) : warehouses.length ? (
        <FlexRow marginBottom={20}>
          <FlexCol flexWidth={12}>
            <StyledTableWrapper>
              <Table
                width={95}
                data={warehouses}
                titleField="name"
                columns={columns}
                tableRef={tableRef}
                exportName="warehouses"
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            </StyledTableWrapper>
          </FlexCol>
        </FlexRow>
      ) : (
        <InfoAlert message="No Warehouse Data" />
      )}
      {onModalClose && (
        <FlexRow className="modal-buttons-row">
          <CancelButton onClick={onModalClose} />
        </FlexRow>
      )}
    </>
  );
}

WarehouseList.propTypes = {
  isLoading: bool,
  onLoadData: func,
  onEditClick: func,
  isOnBoarding: bool,
  onModalClose: func,
  onDeleteClick: func,
  warehouses: array.isRequired,
};

export default WarehouseList;
