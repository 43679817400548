import { white } from 'constants/color';
import { SET_THEME_STYLES } from 'actions/types';

const initialState = {
  containerBackColor: white,
};

/**
  @description Stores current theme details in redux state
  @param {{}} state
  @param {{}} action
  @returns {{}}
 */
function theme(state = initialState, action) {
  const { type: actionType, payload } = action;

  if (actionType === SET_THEME_STYLES) {
    return payload;
  }

  return state;
}

export default theme;
