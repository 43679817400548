import { transformActions } from 'utils/';
import { FORGOT_PASSWORD, RESET_PASSWORD } from 'actions/types';

const initialState = {
  message: '',
  successful: false,
  isLoading: false,
};

/**
  @description Tracks user password reset process.
  @param {{}} state
  @param {{
  payload : []
  type : string
   }} action
  @returns {{}}
 */
function password(state = initialState, action) {
  const { type, payload } = action;
  const actions = [RESET_PASSWORD, FORGOT_PASSWORD];
  const failure = transformActions(actions, 'FAILURE');
  if (actions.includes(type)) {
    return {
      message: '',
      isLoading: true,
      successful: false,
    };
  }
  if (type === `${FORGOT_PASSWORD}_SUCCESS`) {
    return {
      isLoading: false,
      message: payload,
      successful: true,
    };
  }
  if (type === `${RESET_PASSWORD}_SUCCESS`) {
    // TODO: this logic should be hoisted to API instead of parsing it in frontend
    let message = '';
    let successful = false;
    if (typeof payload === 'string') {
      message = payload;
      successful = false;
    }
    if (typeof payload === 'object') {
      if (payload.updatedValues && payload.updatedValues?.lastupdated) {
        successful = true;
        message = 'Password reset successful';
      } else {
        successful = false;
        message = 'Password reset failed';
      }
    }
    return {
      message,
      successful,
      isLoading: false,
    };
  }
  if (failure.includes(type)) {
    return {
      isLoading: false,
      message: payload,
      successful: false,
    };
  }
  return state;
}

export default password;
