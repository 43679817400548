/**
   @description Open external window and redirect user.
   @param { string } url 
 */
function openExternalLink(url) {
  if (url) {
    const win = window.open(url, '_blank');
    if (win) {
      win.opener = null;
      win.focus();
    }
  }
}

export default openExternalLink;
