import React, { useState } from 'react';

import { validateEmail } from 'utils/';
import {
  ErrorLabel,
  StyledBody,
  StyledInput,
  StyledButton,
  StyledFormGroup,
  StyledSrOnlyLabel,
} from 'components/Order/Email/Email.styled';

function Email({ order, onSubmit }) {
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState(order?.email || '');

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      onSendEmail();
    }
  }

  function onSendEmail() {
    if (email.length === 0) {
      setEmailError('Recipient email is required');
      return;
    }
    if (!validateEmail(email)) {
      setEmailError('Enter valid recipient email');
      return;
    }
    setEmailError('');
    const payload = { ...order, email };
    onSubmit(payload);
  }

  return (
    <StyledBody>
      <StyledFormGroup>
        <StyledSrOnlyLabel htmlFor="email">Recipient Email</StyledSrOnlyLabel>
        <StyledInput
          id="email"
          type="email"
          value={email}
          onKeyDown={onKeyDown}
          onChange={onEmailChange}
          placeholder="e.g hello@wezza.co"
        />
        <StyledButton onClick={onSendEmail}>Send</StyledButton>
      </StyledFormGroup>
      {emailError ? <ErrorLabel>{emailError}</ErrorLabel> : null}
    </StyledBody>
  );
}

export default Email;
