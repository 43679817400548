import styled, { css } from 'styled-components';

export const StyledSearch = styled.input`
  --primaryColor: ${({ theme }) => theme.primaryColor};
  height: 35px;
  display: block;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 500;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  transition: 500ms;
  padding-left: 12px;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  -webkit-appearance: none;
  -webkit-transition: 500ms;
  color: var(--primaryColor);
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  border: 1px solid var(--primaryColor);
  color: ${({ theme }) => theme.primaryColor};
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  border: ${({ theme }) => theme.primaryBorder};
  background-color: ${({ theme }) => theme.whiteColor};
  width: ${({ $width }) => ($width ? `${$width}px` : '200px')};
  background-color: ${({ theme }) => theme.tableSearchBackground};

  ${function ({ $filter }) {
    return (
      $filter &&
      css`
        height: 25px;
        margin-left: 0;
        font-size: 12px;
        border-radius: 0;
        padding-left: 3px;
      `
    );
  }}

  &:focus {
    outline: 0;
    box-shadow: none;
    border: ${({ theme }) => theme.secondaryBorder};
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    ${function ({ $filter }) {
      return (
        !$filter &&
        css`
          width: 360px;
          height: 30px;
          margin-top: 0;
          margin-left: 0;
          font-size: 14px;
        `
      );
    }}
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    ${function ({ $filter }) {
      return (
        !$filter &&
        css`
          margin-left: 25px;
        `
      );
    }}
  }
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;

export const StyledFormGroup = styled.div`
  margin-top: -13px;
`;
