import { endPoints } from 'api/';
import { GET_COMPANY } from 'actions/types';

/**
 * @description Get details for a specific company.
 * @param {string} companyId Current user's company id.
 */
function query(companyId) {
  const query = {
    where: {
      id: companyId,
    },
  };

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_COMPANY,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const company = await apiClient(endPoints.GET_COMPANY, {}, query);
        return company;
      },
    };
    dispatch(action);
  };
}

export default query;
