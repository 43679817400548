import Cookies from 'universal-cookie';

import { LOGIN_PATH, SIDE_NAV_ACTIVE_MENU } from 'constants/general';

export const tokenCookieName = 'wezza_access_token';

const cookies = new Cookies();

/**
  @description Saves JWT `token` in browser `cookie`
  @param {{
    token: string
    companyId: string 
  }} user
 */
export function setTokenCookie(user) {
  if (user) {
    cookies.set(tokenCookieName, JSON.stringify(user));
  }
}

/**
  @description Get JWT `token` saved in browser `cookie`
  @returns {{
    token: String
    companyId: String
  }}
 */
export function getTokenFromCookie() {
  const accessToken = cookies.get(tokenCookieName);
  return accessToken;
}

/**
  @description Clear token from browser cookie
 */
export function clearTokenCookie() {
  cookies.remove(tokenCookieName);
  window.localStorage.removeItem(SIDE_NAV_ACTIVE_MENU);

  if (window.location.pathname.indexOf('login') === -1) {
    window.location.assign(LOGIN_PATH);
  }
}
