import { CLEAR_MODAL } from 'actions/types';

/**
  @description Clear `modal` from `redux` state
  @param {string} type The modal to display
 */
function clearModal(type) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CLEAR_MODAL,
      payload: {
        type,
      },
    };

    dispatch(action);
  };
}

export default clearModal;
