import styled, { css } from 'styled-components';

export const IconWrapper = styled.label`
  --button-size: calc(${({ theme }) => theme.menuBarSize} * 0.5);

  color: ${({ theme }) => theme.primaryColor};

  width: var(--button-size);
  height: var(--button-size);

  margin: 2px;
  padding: 5px;
  border: none;
  filter: none;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  margin-right: 0.5rem;
  justify-content: center;
  transition: filter 300ms;

  svg {
    fill: ${({ theme }) => theme.textColor};

    width: 40px;
    height: 40px;
  }

  &:focus {
    outline: none;
  }
`;

export const MenuItemCaption = styled.label`
  font-size: 13px;
  cursor: pointer;
  margin-left: 10px;

  /* Mobile Devices */
  @media (max-width: 480px) {
    display: none;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    display: none;
  }
`;

export const MenuItemButton = styled.button`
  color: ${({ theme }) => theme.primaryColor};
  background-color: ${({ theme }) => theme.whiteColor};
  transition: background ${({ theme }) => theme.transitionSpeed};

  border: none;
  filter: none;
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;

  &:first-child {
    margin-left: 5px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    border-bottom: ${({ theme }) => theme.activeMenuBarBorder};

    filter: grayscale(0%) opacity(1);

    &[data-active='true'] {
      border-bottom: ${({ theme }) => theme.activeMenuBarBorder};
    }

    ${IconWrapper} {
      filter: brightness(1.2);
      transition: background-color 1000ms linear;
      -ms-transition: background-color 1000ms linear;
      -webkit-transition: background-color 1000ms linear;
    }

    ${MenuItemCaption} {
      filter: brightness(1.2);
    }
  }

  ${function ({ $active }) {
    return (
      $active &&
      css`
        border-bottom: ${({ theme }) => theme.activeMenuBarBorder};

        filter: grayscale(0%) opacity(1);
      `
    );
  }}

  /* Small screens */
  @media only screen and (max-width: 600px) {
    margin-right: 0;

    &:last-child {
      margin-right: 5px;
    }
  }
`;
