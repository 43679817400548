import React, { useState } from 'react';
import { arrayOf, func, object, bool } from 'prop-types';

import {
  StyledLoader,
  StyledCaption,
  StyledCategory,
  StyledDescription,
  StyledCategoryWrapper,
} from './Category.styled';
import Loader from 'img/loader.gif';
import { InfoAlert } from 'components/Alerts/';
import { CancelButton } from 'components/Buttons/';
import { active, disabled } from 'constants/status';
import { FlexCol, FlexRow } from 'components/Layout/';
import { InputButton, EditInput } from 'components/Inputs/';
import { ReactComponent as TickIcon } from 'icons/tick_grey.svg';
import { ReactComponent as EditIcon } from 'icons/pencil_grey.svg';
import { ReactComponent as DeleteIcon } from 'icons/cancel_sm.svg';

/**
  @param {{
  initialValue : []
  onSubmit : Function
  isLoading : boolean
  onModalClose : Function
  }} param
 */
const Category = ({ onSubmit, initialValue, isLoading, onModalClose }) => {
  const [category, setCategory] = useState('');
  const [categoryEdit, setCategoryEdit] = useState('');
  const [categoryError, setCategoryError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryErrorEdit, setCategoryErrorEdit] = useState('');

  const onCategoryCreate = () => {
    if (category) {
      const isEditing = false;
      const isDeleting = false;

      const payload = {
        status: active,
        category: category,
      };

      onSubmit(payload, isEditing, isDeleting);
    } else {
      setCategoryError('Enter category');
    }
    setSelectedCategory(null);
  };

  /**
    @param {{
    currentTarget : {
    value : string
    }
    }} event
   */
  const onCategoryChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter category';

    setCategory(value);

    setCategoryError(errorMsg);
  };

  /**
    @param {{
    key : string
    }} event
   */
  const onCategoryKeyDown = (event) => {
    if (event.key === 'Enter') {
      onCategoryCreate();
    }
  };

  /**
    @param {{
    category : string
    id : string
    }} data
   */
  const onCategoryClick = (data) => {
    const { category, id } = data;

    // Editing
    if (selectedCategory?.id === id) {
      // When user clicks edit, does nothing, then clicks update button
      if (categoryEdit === category) {
        setSelectedCategory(null);
      } else {
        if (categoryEdit) {
          const { id } = selectedCategory;
          const isEditing = true;
          const isDeleting = false;
          const payload = {
            id: id,
            category: categoryEdit,
          };

          onSubmit(payload, isEditing, isDeleting);

          setSelectedCategory(null);
        }
      }
    } else {
      setSelectedCategory(data);
      setCategoryEdit(unescape(category));
    }

    setCategoryErrorEdit('');
  };

  /**
    @param {{
    id : string
    }} data
   */
  const onDeleteClick = (data) => {
    const { id } = data;
    const isEditing = true;
    const isDeleting = true;
    const payload = {
      id: id,
      status: disabled,
    };

    onSubmit(payload, isEditing, isDeleting);
  };

  /**
    @param {{
    key : string
    }} event
   */
  const onCategoryEditKeyDown = (event) => {
    if (event.key === 'Enter') {
      onCategoryClick(selectedCategory);
    }
  };

  /**
    @param {{
    currentTarget : {
    value : string
    }
    }} event
   */
  const onCategoryEditChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter category';

    setCategoryEdit(value);

    setCategoryErrorEdit(errorMsg);
  };

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledCategoryWrapper>
            <StyledCaption>
              Name
              <StyledDescription>
                Food, Electronics, Furniture etc
              </StyledDescription>
            </StyledCaption>
            <InputButton
              value={category}
              dataTestId="category"
              onClick={onCategoryCreate}
              onChange={onCategoryChange}
              placeholder="Enter category"
              errorMessage={categoryError}
              onKeyDown={onCategoryKeyDown}
            />
            {isLoading && <StyledLoader src={Loader} alt="Loader" />}

            <StyledCategory>
              {!initialValue?.length ? (
                <InfoAlert message="No Category Data" />
              ) : (
                initialValue?.map((item) => {
                  const { category, id } = item;
                  const dataTestId = category.replace(' ', '-');

                  const inputTestId = `input-${dataTestId}`;
                  const isEditing = selectedCategory?.id === id;
                  const categoryTestId = `category-${dataTestId}`;
                  const editTitle = `Click to edit : ${category}`;
                  const value = isEditing ? categoryEdit : category;
                  const deleteTitle = `Click to delete : ${category}`;
                  const editButtonTestId = `edit-button-${dataTestId}`;
                  const deleteButtonTestId = `delete-button-${dataTestId}`;
                  const currentIcon = isEditing ? <TickIcon /> : <EditIcon />;

                  return (
                    <EditInput
                      key={id}
                      value={value}
                      isEditing={isEditing}
                      editIcon={currentIcon}
                      inputTestId={inputTestId}
                      deleteIcon={<DeleteIcon />}
                      editButtonTitle={editTitle}
                      data-testid={categoryTestId}
                      placeholder="Update category"
                      deleteButtonTitle={deleteTitle}
                      onChange={onCategoryEditChange}
                      onKeyDown={onCategoryEditKeyDown}
                      errorMessage={categoryErrorEdit}
                      editButtonTestId={editButtonTestId}
                      deleteButtonTestId={deleteButtonTestId}
                      onEditClick={onCategoryClick.bind(null, item)}
                      onDeleteClick={onDeleteClick.bind(null, item)}
                    />
                  );
                })
              )}
            </StyledCategory>
          </StyledCategoryWrapper>
        </FlexCol>
      </FlexRow>
      {onModalClose && (
        <FlexRow>
          <FlexCol flexWidth={1}>
            <CancelButton onClick={onModalClose} />
          </FlexCol>
        </FlexRow>
      )}
    </>
  );
};

Category.propTypes = {
  onSubmit: func,
  isLoading: bool,
  onModalClose: func,
  initialValue: arrayOf(object),
};

export default Category;
