import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { updateUser } from 'actions/users/';
import { clearModal } from 'actions/modals/';
import { USER_PROFILE_MODAL } from 'constants/modal';
import UserProfile from 'components/Users/UserProfile/UserProfile';

const UserProfileModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);

  const isOpen = modal?.type === USER_PROFILE_MODAL;
  const modalCaption = 'Your Profile';

  const onModalClose = () => {
    dispatch(clearModal(USER_PROFILE_MODAL));
  };

  /**
    @param {FormData} payload
   */
  const onSubmit = (payload) => {
    const { company_id: companyId, id: userId } = user;

    payload.append('id', userId);
    payload.append('company_id', companyId);

    dispatch(updateUser(userId, payload, companyId, false));

    // dispatch(clearModal(USER_PROFILE_MODAL));
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} caption={modalCaption}>
      <UserProfile
        user={user}
        onSubmit={onSubmit}
        onModalClose={onModalClose}
      />
    </Modal>
  );
};

export default UserProfileModal;
