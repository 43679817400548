import styled from 'styled-components';

export const StyledSearchInput = styled.input`
  color: ${({ theme }) => theme.textColor};
  border: ${({ theme }) => theme.inputBorder};
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};

  top: 0;
  width: 400px;
  height: 30px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  position: absolute;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  line-height: 1.42857143;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 95%;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 95%;
  }
`;
