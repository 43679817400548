import { SET_ACTIVE_TOP_MENU } from 'actions/types';

/**
  @description Sets menus to be rendered in top menu bar
  @param {{}} menuButton
 */
function setActiveTopMenu(menuButton) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: SET_ACTIVE_TOP_MENU,
      payload: menuButton,
    };

    dispatch(action);
  };
}

export default setActiveTopMenu;
