import styled from 'styled-components';
import { motion } from 'framer-motion';

import { white, darkGrey } from 'constants/color';

export const StyledWrapper = styled(motion.div)`
  background-color: ${white};
  min-height: ${({ minHeight }) => minHeight}px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  border-radius: ${({ theme }) => theme.borderRadius};

  width: 95%;
  padding: 1rem;

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 10px;
  }
`;

export const StyledHeader = styled(motion.div)`
  display: flex;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: ${darkGrey};
  font-style: normal;
  align-items: center;
  margin-bottom: 10px;
`;
