/**
   Checks whether specified field is of type string and parse it to object.
   @param {string | {}} field 
   @returns {{}}
 */
function parseJSON(field) {
  const shouldParse = typeof field === 'string' && field?.length > 0;

  return shouldParse ? JSON.parse(field) : field;
}

export default parseJSON;
