import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

export const StyledFormGroup = styled.div`
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

export const StyledLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledInput = styled.textarea`
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --focusedInputColor: ${({ theme }) => theme.focusedInputColor};

  width: 100%;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  height: ${({ height }) => height}px;
  color: ${({ theme }) => theme.textColor};
  border: ${({ theme }) => theme.inputBorder};
  padding-top: ${({ paddingTop }) => paddingTop}px;
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};

  &:disabled {
    ${({ theme }) => theme.disabled}
  }

  ${function ({ $isInvalid }) {
    return (
      $isInvalid &&
      css`
        color: var(--invalidColor);
        border: 1px solid var(--invalidColor);
      `
    );
  }}

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
    color: var(--focusedInputColor);
    ${function ({ $isInvalid }) {
      return $isInvalid
        ? ({ theme }) => theme.invalidColor
        : ({ theme }) => theme.secondaryColor;
    }};
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }
`;

export const InputErrorLabel = styled.label`
  font-size: 12px;
  color: ${maroon};
  font-weight: 400;
`;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;

export const StyledDescription = styled.p`
  color: #5d5d5d;
  font-size: 10px;
  margin-top: 0px;
  font-weight: 300;
  line-height: 12px;
`;
