/**
  @description Group array by given field.
  @param {[]} data Array to group.
  @param {string} field Data field to group by.
  @returns Grouped objects.
 */
function groupArrayBy(data, field) {
  return data?.reduce((accumulator, currentObject) => {
    const groupBy = currentObject[field];
    accumulator[groupBy] = currentObject;

    return accumulator;
  }, {});
}

export default groupArrayBy;
