import { transformActions } from 'utils/';
import { GET_STOCK_MOVE } from 'actions/types';

const initialState = {
  data: [],
  isLoading: false,
};

/**
  @description Stores items array details in redux state
  @param {[]} state
  @param {{}} action
  @returns {[]}
 */
function stockMove(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_STOCK_MOVE];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');

  if (type === GET_STOCK_MOVE) {
    return {
      data: [],
      isLoading: true,
    };
  }

  if (success.includes(type)) {
    return {
      data: payload,
      isLoading: false,
    };
  }

  if (failure.includes(type)) {
    return {
      data: [],
      isLoading: false,
    };
  }

  return state;
}

export default stockMove;
