import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledAlert,
  StyledWrapper,
  StyledContainer,
  SignInContainer,
} from './LockScreen.styled';
import { authenticate } from 'actions/users';
import LockForm from 'components/LockForm/LockForm';

const LockScreen = () => {
  const dispatch = useDispatch();

  const { logInStatus } = useSelector((state) => state);

  /**
    @param {{ 
     email : string, 
     password : string
    }} credentials
   */
  const onSubmit = (credentials) => {
    const { email, password } = credentials;

    dispatch(authenticate(email, password));
  };

  return (
    <SignInContainer>
      <StyledContainer>
        {logInStatus && <StyledAlert>{logInStatus}</StyledAlert>}
        <StyledWrapper>
          <LockForm onSubmit={onSubmit} />
        </StyledWrapper>
      </StyledContainer>
    </SignInContainer>
  );
};

export default LockScreen;
