import React, { useState } from 'react';
import { string, func, number, oneOfType, object } from 'prop-types';

import { getDataUrlFromFile } from 'utils/';
import { INVALID_URL } from 'constants/general';
import { hoverAndTap } from 'constants/animation';
import {
  StyledCaption,
  FileInputLabel,
  StyledFileInput,
  InputErrorLabel,
  ImagePlaceholder,
  FileInputWrapper,
  SelectedFileName,
  FileInputContainer,
  StyledPreviewImage,
  StyledRemoveButton,
  ImagePreviewWrapper,
} from 'components/Inputs/PhotoInput/PhotoInput.styled';
import picPlaceholder from 'icons/photo_placeholder.svg';
import { ReactComponent as PhotoPlaceholder } from 'icons/photo_placeholder.svg';

/**
 * @param {{
 * id: string,
 * caption: string,
 * imageUri: string,
 * maxSizeMB: number,
 * dataTestId: string,
 * buttonText: string,
 * onChange: Function,
 * description: string,
 * }} param
 */
function PhotoInput({
  id,
  caption,
  onChange,
  imageUri,
  maxSizeMB,
  dataTestId,
  buttonText,
}) {
  const [file, setFile] = useState(null);
  const maxSizeBytes = 1048576 * maxSizeMB || 2;
  const [imageTooLarge, setImageTooLarge] = useState(false);
  const [imagePreviewUri, setImagePreviewUri] = useState(null);
  const imgPreviewUri = INVALID_URL.includes(imageUri) ? null : imageUri;
  const imagePreviewUriToUse = imagePreviewUri || imgPreviewUri;

  /**
   * @param {{
   * preventDefault : Function
   * target : {
   *  files: [{
   *   size: number
   *   name: string
   *  }]
   * }
   * }} e
   */
  async function onPhotoChange(e) {
    e.preventDefault();
    const [file] = e.target.files;
    if (file?.size > maxSizeBytes) {
      setImageTooLarge(true);
      setFile(null);
    } else if (file) {
      setFile(file);
      onChange(file);
      const previewUri = await getDataUrlFromFile(file);
      setImagePreviewUri(previewUri);
      setImageTooLarge(false);
    }
  }

  function onClearPhoto() {
    setImagePreviewUri(picPlaceholder);
    setImageTooLarge(false);
    setFile(null);
    onChange(null);
  }

  return (
    <FileInputContainer>
      <StyledCaption>{caption ? caption : 'Photo'} (2mb max)</StyledCaption>
      <ImagePreviewWrapper>
        <ImagePreview imageUri={imagePreviewUriToUse} />
        <FileInputWrapper>
          <FileInputLabel
            htmlFor={id}
            hasFileName={file?.name}
            {...hoverAndTap}
          >
            {buttonText}
            <StyledFileInput
              id={id}
              type="file"
              invalid={imageTooLarge}
              onChange={onPhotoChange}
              data-testid={dataTestId}
              accept=".png, .jpeg, .jpg"
            />
          </FileInputLabel>
          {file ? (
            <>
              <SelectedFileName>{file?.ame}</SelectedFileName>
              <StyledRemoveButton onClick={onClearPhoto} {...hoverAndTap}>
                Remove
              </StyledRemoveButton>
            </>
          ) : null}
          {imageTooLarge ? (
            <InputErrorLabel>Image larger than 2mb</InputErrorLabel>
          ) : null}
        </FileInputWrapper>
      </ImagePreviewWrapper>
    </FileInputContainer>
  );
}
function ImagePreview({ imageUri }) {
  return (
    <>
      {imageUri ? (
        <StyledPreviewImage
          src={imageUri}
          {...hoverAndTap}
          alt="image preview"
        />
      ) : (
        <ImagePlaceholder {...hoverAndTap}>
          <PhotoPlaceholder />
        </ImagePlaceholder>
      )}
    </>
  );
}

const imageUriProps = oneOfType([string, object]);

ImagePreview.propTypes = {
  imageUri: imageUriProps,
};

PhotoInput.propTypes = {
  id: string,
  caption: string,
  maxSizeMB: number,
  dataTestId: string,
  buttonText: string,
  description: string,
  imageUri: imageUriProps,
  onChange: func.isRequired,
};

PhotoInput.defaultProps = {
  id: '',
  maxSizeMB: 2,
  imageUri: null,
  dataTestId: '',
  description: '',
};

export default PhotoInput;
