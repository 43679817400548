import React, { useState } from 'react';
import { arrayOf, func, object, bool } from 'prop-types';

import {
  StyledTag,
  StyledLoader,
  StyledCaption,
  StyledTagWrapper,
  StyledDescription,
} from 'components/Tags/Tag.styled';
import Loader from 'img/loader.gif';
import { primary } from 'constants/color';
import { InfoAlert } from 'components/Alerts/';
import { INVENTORY } from 'constants/inventory';
import { CancelButton } from 'components/Buttons/';
import { active, disabled } from 'constants/status';
import { FlexCol, FlexRow } from 'components/Layout/';
import { EditInput, InputButton } from 'components/Inputs/';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import { ReactComponent as EditIcon } from 'icons/pencil_grey.svg';
import { ReactComponent as DeleteIcon } from 'icons/cancel_sm.svg';

/**
 * @param {{
 *  initialValue : []
 *  onSubmit : Function
 *  isLoading : boolean
 *  onModalClose : Function
 *  }} param
 */
function Tag({ onSubmit, initialValue, isLoading, onModalClose }) {
  const [tag, setTag] = useState('');
  const [tagEdit, setTagEdit] = useState('');
  const [color, setColor] = useState(primary);
  const [tagError, setTagError] = useState('');
  const [tagErrorEdit, setTagErrorEdit] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);

  const onTagCreate = () => {
    if (tag) {
      const isEditing = false;

      const payload = {
        tagname: tag,
        status: active,
        tagcolor: color,
        tagtype: INVENTORY,
      };

      onSubmit(payload, isEditing);
    } else {
      setTagError('Enter tag');
    }
  };

  /**
    @param {{
     currentTarget : {
     value : string
     }
     }} event
   */
  const onTagChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter tag';

    setTag(value);

    setTagError(errorMsg);
  };

  /**
    @param {{
     key : string
     }} event
   */
  const onTagKeyDown = (event) => {
    if (event.key === 'Enter') {
      onTagCreate();
    }
  };

  /**
    @param {{
     tagname : string
     id: string
     tagcolor: string
     }} data
   */
  const onTagClick = (data) => {
    const { tagname, id, tagcolor } = data;

    // Editing
    if (selectedTag?.id === id) {
      // When user clicks edit, does nothing, then clicks update button
      if (tagEdit === tagname && tagcolor === color) {
        setSelectedTag(null);
      } else {
        if (tagEdit) {
          const { id } = selectedTag;
          const isEditing = true;
          const isDeleting = false;
          const payload = {
            id: id,
            tagname: tagEdit,
            tagcolor: color,
          };

          onSubmit(payload, isEditing, isDeleting);

          setSelectedTag(null);
        }
      }
    } else {
      setSelectedTag(data);
      setColor(tagcolor);
      setTagEdit(unescape(tagname));
    }

    setTagErrorEdit('');
  };

  /**
    @param {string} color
   */
  const onColorChange = (color) => {
    setColor(color);
  };

  /**
    @param {{
     key : string
     }} event
   */
  const onTagEditKeyDown = (event) => {
    if (event.key === 'Enter') {
      onTagClick(selectedTag);
    }
  };

  /**
    @param {{
     currentTarget : {
     value : string
     }
     }} event
   */
  const onTagEditChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter tag';

    setTagEdit(value);

    setTagErrorEdit(errorMsg);
  };

  /**
    @param {{
     id: string
     }} data
   */
  const onDeleteClick = (data) => {
    const { id } = data;
    const isEditing = true;
    const isDeleting = true;
    const payload = {
      id: id,
      status: disabled,
    };

    onSubmit(payload, isEditing, isDeleting);
  };

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={2}>
          <ColorPicker onChange={onColorChange} color={color} />
        </FlexCol>
        <FlexCol flexWidth={10}>
          <StyledTagWrapper>
            <StyledCaption>
              Name
              <StyledDescription>
                This is the physical location of an item
              </StyledDescription>
            </StyledCaption>
            <InputButton
              value={tag}
              dataTestId="tag"
              onClick={onTagCreate}
              onChange={onTagChange}
              placeholder="Enter tag"
              errorMessage={tagError}
              onKeyDown={onTagKeyDown}
            />
          </StyledTagWrapper>
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledTagWrapper>
            {isLoading && <StyledLoader src={Loader} alt="Loader" />}

            <StyledTag>
              {!initialValue?.length ? (
                <InfoAlert message="No Tag Data" />
              ) : (
                initialValue?.map((item) => {
                  const { tagname, id, tagcolor } = item;
                  const dataTestId = tagname.replace(' ', '-');

                  const tagTestId = `tag-${dataTestId}`;
                  const isEditing = selectedTag?.id === id;
                  const inputTestId = `input-${dataTestId}`;
                  const value = isEditing ? tagEdit : tagname;
                  const editTitle = `Click to edit : ${tagname}`;
                  const deleteTitle = `Click to delete : ${tagname}`;
                  const editButtonTestId = `edit-button-${dataTestId}`;
                  const deleteButtonTestId = `delete-button-${dataTestId}`;

                  return (
                    <EditInput
                      key={id}
                      value={value}
                      foreColor={tagcolor}
                      isEditing={isEditing}
                      data-testid={tagTestId}
                      editIcon={<EditIcon />}
                      placeholder="Update tag"
                      inputTestId={inputTestId}
                      onChange={onTagEditChange}
                      errorMessage={tagErrorEdit}
                      deleteIcon={<DeleteIcon />}
                      editButtonTitle={editTitle}
                      onKeyDown={onTagEditKeyDown}
                      deleteButtonTitle={deleteTitle}
                      editButtonTestId={editButtonTestId}
                      deleteButtonTestId={deleteButtonTestId}
                      onEditClick={onTagClick.bind(null, item)}
                      onDeleteClick={onDeleteClick.bind(null, item)}
                    />
                  );
                })
              )}
            </StyledTag>
          </StyledTagWrapper>
        </FlexCol>
      </FlexRow>
      {onModalClose && (
        <FlexRow className="modal-buttons-row">
          <CancelButton onClick={onModalClose} />
        </FlexRow>
      )}
    </>
  );
}

Tag.propTypes = {
  onSubmit: func,
  isLoading: bool,
  onModalClose: func,
  initialValue: arrayOf(object),
};

export default Tag;
