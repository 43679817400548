import { array, func, object } from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';

import {
  formatDate,
  getTaskStatus,
  getRandomString,
  capitalizeFirstLetter,
} from 'utils/';
import {
  StyledLabel,
  StyledCaption,
  StyledCheckList,
  StyledFormGroup,
  StyledProgressWrapper,
  StyledCheckListCaption,
  StyledCheckListWrapper,
} from './TaskForm.styled';
import {
  TextArea,
  EditInput,
  InputButton,
  SelectInput,
} from 'components/Inputs/';
import { black } from 'constants/color';
import { active } from 'constants/status';
import { FlexCol, FlexRow } from 'components/Layout/';
import DatePicker from 'components/DatePicker/DatePicker';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { ReactComponent as AcceptIcon } from 'icons/accept.svg';
import { ReactComponent as DeleteIcon } from 'icons/cancel_sm.svg';
import { ReactComponent as EditIcon } from 'icons/pencil_grey.svg';
import { ReactComponent as AcceptedIcon } from 'icons/accepted.svg';

/**
 * @param {{
 *  users: []
 *  initialValue: {}
 *  onSubmit: Function
 *  onModalClose: Function
 *  }} param
 */
function TaskForm({ onSubmit, users, initialValue, onModalClose }) {
  const {
    title,
    description,
    due_date: dueDate,
    assigned_to: handler,
    checklist: checkList,
    lastupdated: lastUpdated,
  } = initialValue || {};
  const isEditing = initialValue?.hasOwnProperty('id');
  const initialCheckList = Array.isArray(checkList) ? [...checkList] : [];
  const transformedCheckList = initialCheckList.map(({ subtask, done }) => ({
    id: getRandomString(),
    subTask: unescape(subtask),
    done: done,
  }));
  const initialTitle = title ? unescape(title) : '';
  const initialDate = dueDate ? new Date(dueDate) : new Date();
  const initialHandler = Array.isArray(handler) ? handler[0] : null;
  const initialDescription = description ? unescape(description) : '';

  const [taskState, setTaskState] = useState({
    title: initialTitle,
    dueDate: initialDate,
    handler: initialHandler,
    description: initialDescription,
    checkList: transformedCheckList,
  });
  const [taskErrors, setTaskErrors] = useState({
    title: '',
    handler: '',
    dueDate: '',
    checkList: '',
    description: '',
  });
  const [subTaskEdit, setSubTaskEdit] = useState('');
  const [checkListItem, setCheckListItem] = useState('');
  const [listItemError, setListItemError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [subTaskErrorEdit, setSubTaskErrorEdit] = useState('');
  const [selectedSubTask, setSelectedSubTask] = useState(null);
  const [checkListItems, setCheckListItems] = useState(transformedCheckList);

  let status;
  let progress;
  let formattedLastUpdated;

  if (isEditing) {
    formattedLastUpdated = formatDate({ date: lastUpdated, showTime: true });
    ({ status, progress } = getTaskStatus(initialValue));
  }

  /**
   * @param {{
   *  value : string
   *  }} selectedOption
   */
  function onHandlerChange(selectedOption) {
    let handler = null;
    setSelectedOption(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      handler = value;
    }
    setTaskState({
      ...taskState,
      handler,
    });
  }

  /**
   * @param {{
   *  currentTarget: {
   *  name: string
   *  value: string
   *  }
   *  }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setTaskState({
      ...taskState,
      [name]: value,
    });
  }

  /**
   * @param {string} date
   */
  function onDueDateChange(date) {
    setTaskState({
      ...taskState,
      dueDate: date,
    });
  }

  function onSubmitTask() {
    setIsSubmitting(true);
    const isValidForm = validateTaskForm();
    if (isValidForm) {
      const subTasks = checkListItems.map(function ({ subTask, done }) {
        return {
          subtask: subTask,
          done,
        };
      });
      const { title, dueDate, description, handler } = taskState;
      const taskObj = {
        title,
        description,
        status: active,
        due_date: dueDate,
        checklist: subTasks,
        assigned_to: [handler],
      };
      onSubmit(taskObj);
    }
  }

  function validateTaskForm() {
    let isFormValid = true;
    const ignoredFields = ['checkList'];
    let errorObject = { ...taskErrors };
    for (const [key, value] of Object.entries(taskState)) {
      const shouldValidate = !ignoredFields.includes(key);
      if (!value && shouldValidate) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }
    setTaskErrors(errorObject);
    return isFormValid;
  }

  useEffect(() => {
    if (isSubmitting) {
      validateTaskForm();
    }
  }, [taskState, isSubmitting]);

  const handlers = useMemo(() => {
    return users.map(function ({ id, name }) {
      return { value: id, label: name };
    });
  }, [users]);

  useEffect(() => {
    if (handler) {
      const taskHandler = users.find(({ id }) => id === handler[0]);
      if (taskHandler) {
        const { id, name } = taskHandler;
        const assigned = {
          value: id,
          label: name,
        };
        onHandlerChange(assigned);
      }
    }
  }, [handler]);

  const onSubTaskAdd = () => {
    if (checkListItem) {
      const itemObj = {
        id: getRandomString(),
        subTask: checkListItem,
        done: false,
      };
      setCheckListItems([...checkListItems, itemObj]);
      setListItemError('');
      setCheckListItem('');
    } else {
      setListItemError('Enter checklist');
    }
  };

  /**
    @param {{
     currentTarget: {
     name: string
     value: string
     }
     }} event
   */
  const onCheckListChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter checklist';

    setCheckListItem(value);

    setListItemError(errorMsg);
  };

  /**
    @param {{
     key : string
     }} e
   */
  const onCheckListKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubTaskAdd();
    }
  };

  /**
    @param {{
     id: string
     subTask: string
     }} data
   */
  const onSubTaskClick = (data) => {
    const { subTask, id } = data;

    // Editing
    if (selectedSubTask?.id === id) {
      // When user clicks edit, does nothing, then clicks update button
      if (subTaskEdit === subTask) {
        setSelectedSubTask(null);
      } else {
        if (subTaskEdit) {
          const checkList = checkListItems.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                subTask: subTaskEdit,
              };
            }

            return item;
          });

          setCheckListItems(checkList);
          setSelectedSubTask(null);
        }
      }
    } else {
      setSelectedSubTask(data);

      setSubTaskEdit(unescape(subTask));
    }

    setSubTaskErrorEdit('');

    setListItemError('');
    setCheckListItem('');
  };

  /**
    @param {{
     id: string
     done: boolean
     }} task
   */
  const onDeleteClick = (task) => {
    const { id } = task;
    const checkList = checkListItems.filter((item) => item.id !== id);

    setCheckListItems(checkList);
    setSelectedSubTask(null);
    setListItemError('');
    setCheckListItem('');
  };

  /**
    @param {{
     id: string
     done: boolean
     }} task
   */
  const onMarkAsDoneClick = (task) => {
    const { id, done } = task;
    const checkList = checkListItems.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          done: !done,
        };
      }

      return item;
    });

    setCheckListItems(checkList);
    setSelectedSubTask(null);
    setListItemError('');
    setCheckListItem('');
  };

  /**
    @param {{
     key: string
     }} e
   */
  const onSubTaskEditKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubTaskClick(selectedSubTask);
    }
  };

  /**
    @param {{
     currentTarget: {
     name: string
     value: string
     }
     }} event
   */
  const onSubTaskEditChange = (event) => {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter sub task';

    setSubTaskEdit(value);

    setSubTaskErrorEdit(errorMsg);
  };

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={6}>
          <TextArea
            isRequired
            height={35}
            name="title"
            inputId="title"
            caption="Title"
            inputType="text"
            value={taskState.title}
            onChange={onInputChange}
            placeholder="Enter task title"
            errorMessage={taskErrors.title}
          />
        </FlexCol>
        <FlexCol flexWidth={3}>
          <SelectInput
            isRequired
            caption="Handler"
            isLoading={false}
            name="taskHandler"
            options={handlers}
            isClearable={true}
            isSearchable={true}
            value={selectedOption}
            onChange={onHandlerChange}
            ariaLabelledBy="taskHandler"
            placeholder="Select handler"
            errorMessage={taskErrors.handler}
          />
        </FlexCol>
        <FlexCol flexWidth={3}>
          <DatePicker
            isRequired
            name="dueDate"
            inputId="dueDate"
            caption="Due Date"
            onChange={onDueDateChange}
            selected={taskState.dueDate}
            placeholder="Enter due date"
            errorMessage={taskErrors.dueDate}
          />
        </FlexCol>
      </FlexRow>
      {isEditing && (
        <FlexRow>
          <FlexCol flexWidth={2}>
            <StyledFormGroup>
              <StyledCaption>Last Update</StyledCaption>
              <StyledLabel color={black}>{formattedLastUpdated}</StyledLabel>
            </StyledFormGroup>
          </FlexCol>
          <FlexCol flexWidth={2}>
            <StyledFormGroup>
              <StyledCaption>Status</StyledCaption>
              <StyledLabel color={status.color}>{status.label}</StyledLabel>
            </StyledFormGroup>
          </FlexCol>
          <FlexCol flexWidth={3}>
            <StyledFormGroup>
              <StyledCaption>Progress</StyledCaption>
              <StyledProgressWrapper>
                <ProgressBar
                  barHeight={10}
                  barColor="#54CC7C"
                  progress={progress}
                />
              </StyledProgressWrapper>
            </StyledFormGroup>
          </FlexCol>
        </FlexRow>
      )}
      <FlexRow>
        <FlexCol flexWidth={12}>
          <TextArea
            isRequired
            paddingTop={5}
            name="description"
            inputId="description"
            caption="Description"
            onChange={onInputChange}
            value={taskState.description}
            placeholder="Enter description"
            errorMessage={taskErrors.description}
          />
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={6}>
          <StyledCheckListWrapper>
            <StyledCheckListCaption>
              Check List (Optional)
            </StyledCheckListCaption>
            <InputButton
              value={checkListItem}
              dataTestId="check-list"
              onClick={onSubTaskAdd}
              errorMessage={listItemError}
              onChange={onCheckListChange}
              placeholder="Enter checklist"
              onKeyDown={onCheckListKeyDown}
            />
            <StyledCheckList>
              {checkListItems.map((item) => {
                const { subTask, id, done } = item;
                const task = unescape(subTask);
                const isComplete = done === true;
                const dataTestId = task.replace(' ', '-');
                const inputTestId = `input-${dataTestId}`;
                const isEditing = selectedSubTask?.id === id;
                const editTitle = `Click to edit [ ${task} ]`;
                const deleteTitle = `Click to delete [ ${task} ]`;
                const editButtonTestId = `edit-button-${dataTestId}`;
                const tickButtonTestId = `mark-button-${dataTestId}`;
                const deleteButtonTestId = `delete-button-${dataTestId}`;
                const value = isEditing ? subTaskEdit : unescape(subTask);
                const icon = !isComplete ? <AcceptIcon /> : <AcceptedIcon />;
                const tickButtonTitle = `Click to mark [ ${task} ] as complete`;

                return (
                  <EditInput
                    key={id}
                    value={value}
                    tickIcon={icon}
                    isEditing={isEditing}
                    editIcon={<EditIcon />}
                    isComplete={isComplete}
                    inputTestId={inputTestId}
                    deleteIcon={<DeleteIcon />}
                    editButtonTitle={editTitle}
                    placeholder="Update checklist"
                    onChange={onSubTaskEditChange}
                    deleteButtonTitle={deleteTitle}
                    errorMessage={subTaskErrorEdit}
                    onKeyDown={onSubTaskEditKeyDown}
                    tickButtonTitle={tickButtonTitle}
                    editButtonTestId={editButtonTestId}
                    tickButtonTestId={tickButtonTestId}
                    deleteButtonTestId={deleteButtonTestId}
                    onEditClick={onSubTaskClick.bind(null, item)}
                    onDeleteClick={onDeleteClick.bind(null, item)}
                    onTickClick={onMarkAsDoneClick.bind(null, item)}
                  />
                );
              })}
            </StyledCheckList>
          </StyledCheckListWrapper>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          flexWidth={100}
          icon={<TickIcon />}
          onClick={onSubmitTask}
          className="modal-button"
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

TaskForm.propTypes = {
  users: array,
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default TaskForm;
