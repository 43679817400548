import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

export const StyledFormGroup = styled.div`
  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;
`;

export const StyledLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledInput = styled.input`
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};
  --focusedInputColor: ${({ theme }) => theme.focusedInputColor};

  width: 100%;
  height: 35px;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  color: ${({ theme }) => theme.textColor};
  border: ${({ theme }) => theme.inputBorder};
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};
  padding-left: ${({ $hasInputIcon }) => ($hasInputIcon ? '35px' : '12px')};

  &:disabled {
    ${({ theme }) => theme.disabled}
  }

  ${function ({ $isInvalid }) {
    return (
      $isInvalid &&
      css`
        color: var(--invalidColor);
        border: 1px solid var(--invalidColor);
      `
    );
  }}

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
    color: var(--focusedInputColor);
    ${function ({ $isInvalid }) {
      return $isInvalid
        ? ({ theme }) => theme.invalidColor
        : ({ theme }) => theme.secondaryColor;
    }};
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const StyledInputIcon = styled.span`
  top: 23px;
  z-index: 0;
  width: 34px;
  height: 34px;
  display: block;
  padding-left: 2px;
  line-height: 34px;
  position: absolute;
  text-align: center;
  pointer-events: none;

  svg {
    width: 34px;
    height: 34px;
    fill: ${({ theme }) => theme.inputIconColor};
  }
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;

export const StyledDescription = styled.p`
  color: #5d5d5d;
  font-size: 10px;
  margin-top: 0px;
  font-weight: 300;
  line-height: 12px;
`;
