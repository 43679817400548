import { array, bool, func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useEffect, useState } from 'react';

import { history } from 'store/store';
import {
  SUPPORT_PATH,
  APP_SUPPORT_LINK,
  ONBOARDING_STEPS,
  SIDE_NAV_ACTIVE_MENU,
} from 'constants/general';
import { openExternalLink } from 'utils/';
import menuList from 'constants/menuList';
import {
  StyledNav,
  StyledNavWrapper,
  StyledMenuSection,
} from 'containers/SideNav/SideNav.styled';
import { Loading } from 'components/Loaders/';
import { setActiveMenu } from 'actions/menus/';
import { useMobileView, useOutSideClick } from 'hooks/';
import SideNavTile from 'containers/SideNavTile/SideNavTile';
import useLocalStorage from 'hooks/useLocalStorage/useLocalStorage';
import SideNavItem from 'components/SideNav/SideNavItem/SideNavItem';

const defaultActiveMenu = menuList[0]; // assumes 'dashboard' as default

/**
 * @param {{
 *  navLinks : menuList
 *  sideNavOpen : boolean
 *  isSetupRoute : boolean
 *  onSideNavToggle : Function
 *  }} param
 */
function SideNav({
  sideNavOpen,
  isSetupRoute,
  onSideNavToggle,
  navLinks = menuList,
}) {
  const navRef = useRef(null);
  const dispatch = useDispatch();
  const isMobileView = useMobileView();
  const user = useSelector(({ user }) => user);
  const [, setLocalActiveMenu] = useLocalStorage(
    SIDE_NAV_ACTIVE_MENU,
    defaultActiveMenu,
  );
  const [isOnBoarding, setIsOnBoarding] = useState(false);
  const [isMenuRendered, setIsMenuRendered] = useState(false);
  const activeMenu = useSelector(({ activeMenu }) => activeMenu);
  const currentActiveMenu = activeMenu || defaultActiveMenu;
  const { step = 1, skip } = useSelector(({ onboarding }) => onboarding);

  const timeout = 2000;
  const title = 'title';
  const userType = user?.usertype;

  /**
   * @param {{
   *  name: string
   *  redirect: string
   *  }} menu
   */
  function onMenuClick(menu) {
    const { name, path } = menu;
    dispatch(setActiveMenu(menu));
    setLocalActiveMenu(name);
    if (path === SUPPORT_PATH) {
      openExternalLink(APP_SUPPORT_LINK);
    } else {
      history.push(path);
    }
    // Close the side navigation if on a mobile viewport
    if (isMobileView) {
      onSideNavToggle();
    }
  }

  useOutSideClick({
    ref: navRef,
    callBack: function () {
      if (isMobileView && sideNavOpen) {
        onSideNavToggle();
      }
    },
    dependencies: [sideNavOpen, isMobileView],
  });

  useEffect(() => {
    const setupRoute = isSetupRoute && step < ONBOARDING_STEPS && !skip;
    setIsOnBoarding(setupRoute);
  }, [isSetupRoute, step, skip]);

  useEffect(() => {
    const handle = window.setTimeout(function () {
      setIsMenuRendered(true);
    }, timeout);
    return function () {
      window.clearTimeout(handle);
    };
  }, []);

  return (
    <>
      <StyledNav ref={navRef} $sideNavOpen={sideNavOpen}>
        <StyledNavWrapper>
          {isOnBoarding ? (
            <SideNavTile />
          ) : isMenuRendered ? (
            navLinks?.map(function (menu) {
              const {
                type,
                name,
                path,
                hidden,
                caption,
                icon: Icon,
                buttonStyle,
                eligibleUsers,
              } = menu;
              if (hidden) {
                return null;
              }
              if (type === title) {
                return (
                  <StyledMenuSection key={name}>{caption}</StyledMenuSection>
                );
              }
              if (!eligibleUsers.includes(userType)) {
                return null;
              }
              return (
                <SideNavItem
                  key={path}
                  Icon={<Icon />}
                  caption={caption}
                  buttonStyle={buttonStyle}
                  onClick={onMenuClick.bind(null, menu)}
                  isActive={currentActiveMenu.name === name}
                />
              );
            })
          ) : (
            <Loading />
          )}
        </StyledNavWrapper>
      </StyledNav>
    </>
  );
}

SideNav.propTypes = {
  navLinks: array,
  sideNavOpen: bool,
  isSetupRoute: bool,
  onSideNavToggle: func,
};

export default SideNav;
