import { transformActions } from 'utils/';
import { GET_COMPANY, CREATE_COMPANY } from 'actions/types';

const initialState = {
  data: {},
  isLoading: false,
};

/**
 * @description Stores company object details in redux state
 * @param {{}} state
 * @param {{
 *  payload : []
 *  type : string
 *  }} action
 */
function company(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_COMPANY, CREATE_COMPANY];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');
  if (success.includes(type)) {
    return payload[0]; // Response is array, we grab object instead
  }
  if (failure.includes(type)) {
    return null;
  }
  return state;
}

export default company;
