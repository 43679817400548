import styled from 'styled-components';

import { black } from 'constants/color';

export const StyledWrapper = styled.div``;

export const StyledTable = styled.table`
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
`;

export const StyledTableCell = styled.td`
  font-size: 12px;
  color: ${black};
  padding: 5px 8px;
  border: 1px solid #dddddd;
`;

export const StyledTableHeader = styled.th`
  font-size: 12px;
  padding: 5px 8px;
  border: 1px solid #dddddd;
`;

export const StyledTableHead = styled.thead`
  text-align: left;
  border: 1px solid #dddddd;
`;

export const StyledTableRow = styled.tr`
  &:hover {
    background-color: #f4f4f4;
  }
`;

export const StyledTableBody = styled.tbody``;
