import styled from 'styled-components';

import { white } from 'constants/color';

export const StyledWrapper = styled.div`
  padding: 1rem;
  overflow: auto;
  margin-right: 10px;
  margin-bottom: 1rem;
  background-color: ${white};
  ${({ theme }) => theme.scrollbar};
  border-radius: ${({ theme }) => theme.borderRadius};
  /* Mobile Devices */
  @media (max-width: 480px) {
    padding-bottom: 10px;
    width: 100% !important;
  }
`;
