import styled from 'styled-components';

import { darkGrey, primary } from 'constants/color';

export const StyledLabel = styled.label`
  width: 60px;
  height: 25px;
  display: block;
  cursor: pointer;
  margin-top: 5px;
  position: relative;
  background: ${function ({ checked }) {
    return checked ? primary : darkGrey;
  }};
  &:after {
    top: 2px;
    width: 20px;
    content: '';
    height: 20px;
    background: #fff;
    transition: 0.3s;
    position: absolute;
    left: ${function ({ checked }) {
      return checked ? 'calc(100% - 22px)' : '2px';
    }};
  }
`;

export const StyledInput = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
`;

export const StyledIndicator = styled.span`
  top: 50%;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  pointer-events: none;
  left: ${function ({ checked }) {
    return checked ? '35%' : '65%';
  }};
  transform: translate(-50%, -50%);
`;

export const StyledCaption = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;
