import styled from 'styled-components';

import { primary, secondary } from 'constants/color';

const WIDTH = 300;

export const StyledWrapper = styled.div`
  top: -150px;
  height: auto;
  width: ${WIDTH}px;
  color: ${primary};
  left: ${-WIDTH / 2}px;
  border: 1px solid ${secondary};

  padding: 4;
  font-size: 10px;
  text-align: left;
  font-style: normal;
  position: absolute;
  font-weight: normal;
  background-color: white;
`;
