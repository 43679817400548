import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  StyledInput,
  StyledLabel,
} from 'components/Inputs/RadioButton/RadioButton.styled';

/**
 * @param {{
 * name: string,
 * value: string,
 * inputId: string,
 * caption: string,
 * isChecked: bool,
 * onChange: Function,
 * }} param
 */
function RadioButton({ name, value, caption, inputId, onChange, isChecked }) {
  return (
    <StyledLabel htmlFor={inputId}>
      <StyledInput
        name={name}
        id={inputId}
        type="radio"
        value={value}
        checked={isChecked}
        onChange={onChange}
      />
      {caption}
    </StyledLabel>
  );
}

RadioButton.propTypes = {
  name: string,
  value: string,
  onChange: func,
  inputId: string,
  caption: string,
  isChecked: bool,
};

export default RadioButton;
