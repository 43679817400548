import React from 'react';
import { string } from 'prop-types';

import { StyledAvatar } from './Avatar.styled';
import { hoverAndTap } from 'constants/animation';

/**
  @param {{
    onClick : Function
    imageSource : string
    imageAltText : string
    borderRadius : string
  }} param
 */
const Avatar = ({ imageSource, imageAltText, borderRadius, onClick }) => {
  return (
    <StyledAvatar
      {...hoverAndTap}
      src={imageSource}
      onClick={onClick}
      alt={imageAltText}
      borderRadius={borderRadius}
    ></StyledAvatar>
  );
};

Avatar.defaultProps = {
  imageAltText: 'wezza image',
};

Avatar.propTypes = {
  imageSource: string,
  imageAltText: string,
  borderRadius: string,
};

export default Avatar;
