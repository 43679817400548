import styled from 'styled-components';

import { StyledWrapper as Wrapper } from 'components/Wrapper//Wrapper.styled';

export const StyledLogoWrapper = styled.div`
  display: flex;
`;

export const StyledLogo = styled.img`
  height: 120px;
  margin: 0 auto;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  margin-right: -10px;

  button {
    min-width: 150px;
    width: 16% !important;
  }

  button:nth-child(2) {
    margin-left: auto;
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin: 10px;

    button {
      min-width: 150px;
      width: 45% !important;
    }
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;

    button {
      min-width: 150px;
      width: 45% !important;
    }
  }
`;

export const StyledWrapper = styled(Wrapper)`
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 90%;
    margin-left: 5px !important;
    margin-right: -20px !important;
    padding-right: 55px !important;
  }
`;
