import { endPoints } from 'api/';
import { getOrders } from 'actions/order/';
import { RETURN_ORDER } from 'actions/types';

/**
 * @description Returns order catalogue.
 * @param {{
 * payload: {}
 * companyId: string
 * }} payload
 */
function returnOrder({ payload, companyId }) {
  const data = {
    ...payload,
    company_id: companyId,
  };
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: RETURN_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const returned = await apiClient(endPoints.RETURN_ORDER, data);
        dispatch(getOrders({ companyId }));
        return returned;
      },
    };
    dispatch(action);
  };
}

export default returnOrder;
