import { endPoints } from 'api/';
import { GET_TASKS } from 'actions/types';
import { disabled, suspended } from 'constants/status';

/**
 * @description Get a list of all tasks.
 * @param {{
 *  companyId : string,
 *  lowerDate : string,
 *  upperDate : string,
 * }} params
 * @returns Array of task objects
 */
function query({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "notin::${disabled},${suspended}"${dateFilter}}`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_TASKS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const tasks = await apiClient(endPoints.GET_TASKS, {}, query);
        return tasks;
      },
    };
    dispatch(action);
  };
}

export default query;
