export const SALE = 'sale';
export const GOOD = 'Good';
export const LOST = 'Lost';
export const TASK = 'tasks';
export const LEASE = 'lease';
export const OTHER = 'Other';
export const EMAIL = 'email';
export const PRINT = 'print';
export const ORDER = 'orders';
export const PENDING = 'pending';
export const DURABLE = 'durable';
export const DAMAGED = 'Damaged';
export const RETURNED = 'returned';
export const ASSIGNED = 'assigned';
export const ARCHIVED = 'archived';
export const INVENTORY = 'inventory';
export const CANCELLED = 'cancelled';
export const PERISHABLE = 'perishable';
export const BROKEN = 'Broken Part(s)';
export const MISSING = 'Missing Part(s)';
export const FAILURE = 'Operation Failure';
export const LEASE_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    label: GOOD,
    value: GOOD,
  },
  {
    label: LOST,
    value: LOST,
  },
  {
    label: DAMAGED,
    value: DAMAGED,
  },
];
export const SALE_OPTIONS = [
  {
    value: '',
    label: 'Select',
  },
  {
    label: MISSING,
    value: MISSING,
  },
  {
    label: BROKEN,
    value: BROKEN,
  },
  {
    label: FAILURE,
    value: FAILURE,
  },
  {
    label: OTHER,
    value: OTHER,
  },
];
