import { SHOW_NOTIFY } from 'actions/types';
import { clearNotify } from 'actions/notify/';

/**
  @description Sets notify
  @param {string} message
  @param {number} timeout
 */
function showNotify(message, timeout = 10000) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: SHOW_NOTIFY,
      payload: {
        message,
        error: '',
      },
    };

    dispatch(action);

    if (timeout) {
      window.setTimeout(() => {
        dispatch(clearNotify());
      }, timeout);
    }
  };
}

export default showNotify;
