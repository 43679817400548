import { objectHasProperty } from 'utils/';
import { AUTHENTICATE_USER } from 'actions/types';

export const status = {
  login: 'Logging In...',
  success: 'Authenticated',
  failed: 'Login attempt failed',
};

const initialState = '';

/**
  @description Updates UI with current login status.
  @param {{}} state
  @param {{}} action
  @returns Object
 */
function logInStatus(state = initialState, action) {
  const { type, payload } = action;

  if (type === AUTHENTICATE_USER) {
    return status.login;
  }

  if (type === `${AUTHENTICATE_USER}_FAILURE`) {
    return status.failed;
  }

  if (type === `${AUTHENTICATE_USER}_SUCCESS`) {
    if (typeof payload === 'object' && objectHasProperty(payload, 'id')) {
      return status.success;
    }
    return payload;
  }

  return state;
}

export default logInStatus;
