import styled from 'styled-components';

import { saturateColor } from 'utils/';
import { white } from 'constants/color';
import warehouse from 'img/warehouse.png';

const saturatedColor = saturateColor(0.75);

export const StyledTileContainer = styled.section`
  --whiteColor: ${({ theme }) => theme.whiteColor};
  --primaryColor: ${({ theme }) => theme.primaryColor};
  --borderRadius: ${({ theme }) => theme.borderRadius};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};
  --sidebarWidthMax: ${({ theme }) => theme.sidebarWidthMax};

  color: var(--whiteColor);
  width: var(--sidebarWidthMax);
  background: var(--primaryColor);
  border-radius: var(--borderRadius);
  background: linear-gradient(${saturatedColor}, ${saturatedColor}),
    url(${warehouse});

  height: 360px;
  margin-top: 15px;
  position: absolute;
`;

export const StyledTileWrapper = styled.div`
  height: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-top: auto;
  }
`;

export const StyledWelcomeHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  font-style: normal;
`;

export const StyledWelcomeBody = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
`;

export const StyledWelcomeFooter = styled.div`
  width: 100%;
  margin-top: auto;
  text-align: center;
  margin-bottom: 15px;
`;

export const StyledHello = styled.label`
  width: 41px;
  height: 23px;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 22px;
  font-style: normal;
`;

export const StyledBodyText = styled.label`
  width: 100%;
  height: 45px;
  font-size: 18px;
  margin-top: 1px;
  font-weight: 500;
  font-style: normal;
`;

export const StyledAvatar = styled.img`
  border-radius: var(--borderRadius);

  width: 100px;
  height: 100px;
`;

export const StyledSkipButton = styled.button`
  color: var(--whiteColor);

  width: 95%;
  height: 23px;
  border: none;
  margin-top: 5px;
  cursor: pointer;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  background: transparent;
  text-decoration-line: underline;
`;

export const StyledIconWrapper = styled.span`
  svg {
    fill: ${white};

    padding-top: 30px;
    width: 83.33238220214844px;
    height: 66.67916870117188px;
  }
`;

export const BackButtonWrapper = styled.div`
  margin-top: 10px;

  button {
    span {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;
