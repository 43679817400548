import React from 'react';
import { bool, func, number, string } from 'prop-types';

import {
  StyledInput,
  StyledLabel,
  StyledAsterisk,
  InputErrorLabel,
  StyledFormGroup,
  StyledDescription,
} from 'components/Inputs/TextArea/TextArea.styled';

/**
 * @param {{
 * name: string,
 * value: string,
 * height: number,
 * disabled: bool,
 * inputId: string,
 * caption: string,
 * isInvalid: bool,
 * isRequired: bool,
 * onBlur: Function,
 * tabIndex: number,
 * onChange: Function,
 * paddingTop: number,
 * placeholder: string,
 * description: string,
 * errorMessage: string,
 * }}
 */
function TextArea({
  name,
  value,
  onBlur,
  inputId,
  caption,
  onChange,
  tabIndex,
  placeholder,
  height = 50,
  errorMessage,
  paddingTop = 7,
  description = '',
  disabled = false,
  isInvalid = false,
  marginBottom = 15,
  isRequired = false,
}) {
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  return (
    <StyledFormGroup $marginBottom={marginBottom}>
      <StyledLabel htmlFor={inputId}>{caption}</StyledLabel>
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      {description ? (
        <StyledDescription>{description}</StyledDescription>
      ) : null}
      <StyledInput
        name={name}
        id={inputId}
        value={value}
        onBlur={onBlur}
        height={height}
        onChange={onChange}
        tabIndex={tabIndex}
        disabled={disabled}
        paddingTop={paddingTop}
        $isInvalid={invalidInput}
        placeholder={placeholder}
      />
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledFormGroup>
  );
}

TextArea.propTypes = {
  name: string,
  onBlur: func,
  value: string,
  onChange: func,
  height: number,
  disabled: bool,
  inputId: string,
  caption: string,
  isInvalid: bool,
  isRequired: bool,
  tabIndex: number,
  paddingTop: number,
  placeholder: string,
  errorMessage: string,
};

export default TextArea;
