import {
  bool,
  func,
  array,
  shape,
  number,
  object,
  string,
  arrayOf,
  oneOfType,
} from 'prop-types';
import React from 'react';

import {
  primary as primaryColor,
  invalid as invalidColor,
} from 'constants/color';
import {
  StyledLabel,
  StyledSelect,
  StyledAsterisk,
  InputErrorLabel,
  StyledFormGroup,
} from 'components/Inputs/CreaTable/CreaTable.styled';

/**
 *  @param {{
 *  options: [],
 *  name: string,
 *  isMulti: bool,
 *  inputId: string,
 *  caption: string,
 *  isInvalid: bool,
 *  isLoading: bool,
 *  tabIndex: number,
 *  isRequired: bool,
 *  disabled: boolean,
 *  isClearable: bool,
 *  onChange: Function,
 *  isSearchable: bool,
 *  value: string | {},
 *  placeholder: string,
 *  errorMessage: string,
 *  ariaLabelledBy: string,
 *  noOptionsMessage: string,
 *  defaultValue: string | {},
 *  }} param
 */
function CreaTable({
  name,
  value,
  inputId,
  caption,
  options,
  onChange,
  tabIndex,
  isLoading,
  placeholder,
  isClearable,
  isSearchable,
  errorMessage,
  defaultValue,
  ariaLabelledBy,
  isMulti = true,
  disabled = false,
  isInvalid = false,
  isRequired = false,
  noOptionsMessage = 'No Options',
}) {
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const baseStyles = {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: disabled ? '#a0a0a0' : primaryColor,
  };
  const selectColor = hasError ? invalidColor : primaryColor;
  const currentColor = disabled ? '#d0d0d0' : selectColor;
  const customStyles = {
    control: function (base) {
      return {
        ...base,
        ...baseStyles,
        borderRadius: 0,
        boxShadow: 'none',
        color: primaryColor,
        border: `1px solid ${currentColor}`,
        '&:hover': {
          border: `1px solid ${selectColor}`,
        },
      };
    },
    option: function (provided) {
      return {
        ...provided,
        ...baseStyles,
      };
    },
    singleValue: function (provided) {
      return {
        ...provided,
        ...baseStyles,
      };
    },
    dropdownIndicator: function (base) {
      return {
        ...base,
        color: currentColor,
        '&:hover': {
          color: currentColor,
        },
      };
    },
    clearIndicator: function (base) {
      return {
        ...base,
        color: currentColor,
        '&:hover': {
          color: currentColor,
        },
      };
    },
    indicatorSeparator: function (base) {
      return {
        ...base,
        backgroundColor: currentColor,
        '&:hover': {
          backgroundColor: selectColor,
        },
      };
    },
    placeholder: function (base) {
      return {
        ...base,
        fontWeight: 500,
        fontSize: '14px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        lineHeight: '1.42857143',
        alignItems: 'flex-start',
        textOverflow: 'ellipsis',
      };
    },
  };

  return (
    <StyledFormGroup>
      <StyledLabel id={ariaLabelledBy}>{caption}</StyledLabel>
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      <StyledSelect
        name={name}
        id={inputId}
        value={value}
        isMulti={isMulti}
        options={options}
        tabIndex={tabIndex}
        onChange={onChange}
        disabled={disabled}
        styles={customStyles}
        isLoading={isLoading}
        isInvalid={invalidInput}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        aria-labelledby={ariaLabelledBy}
        classNamePrefix="creatable-select"
        noOptionsMessage={() => noOptionsMessage}
      />
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledFormGroup>
  );
}

CreaTable.propTypes = {
  name: string,
  isMulti: bool,
  onChange: func,
  options: array,
  disabled: bool,
  inputId: string,
  caption: string,
  isInvalid: bool,
  isLoading: bool,
  tabIndex: number,
  isRequired: bool,
  isClearable: bool,
  isSearchable: bool,
  placeholder: string,
  errorMessage: string,
  ariaLabelledBy: string,
  noOptionsMessage: string,
  defaultValue: oneOfType([string, object]),
  value: oneOfType([
    string,
    object,
    arrayOf(shape({ value: string, label: string })),
  ]),
};

export default CreaTable;
