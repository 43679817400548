import styled, { css } from 'styled-components';

export const NavButtonText = styled.label`
  left: ${({ theme }) => theme.sidebarTextLeftMin};
  transition: ${({ theme }) => theme.transitionSpeed};

  cursor: pointer;
`;

export const StyledNav = styled.nav`
  --navBarSize: ${({ theme }) => theme.navBarSize};
  --menuBarSize: ${({ theme }) => theme.menuBarSize};
  --sidebarWidthMax: ${({ theme }) => theme.sidebarWidthMax};
  --transitionSpeed: ${({ theme }) => theme.transitionSpeed};
  --barSize: calc(var(--navBarSize) * 2 + var(--menuBarSize) * 2);
  --sidebarTextLeftMax: ${({ theme }) => theme.sidebarTextLeftMax};

  z-index: 1;
  position: fixed;
  overflow-y: auto;
  padding-right: 5px;
  ${({ theme }) => theme.scrollbar};
  height: calc(100vh - var(--barSize));
  width: ${({ theme }) => theme.sidebarWidthMin};
  background: ${({ theme }) => theme.bodyBackColor};

  ${function ({ $sideNavOpen }) {
    return (
      $sideNavOpen &&
      css`
        width: var(--sidebarWidthMax);
        transition: var(--transitionSpeed);

        ${NavButtonText} {
          left: var(--sidebarTextLeftMax);
        }
      `
    );
  }}

  ${function ({ $sideNavOpen }) {
    return (
      !$sideNavOpen &&
      css`
        transition: var(--transitionSpeed);
        left: calc(var(--sidebarWidthMax) * -1);
      `
    );
  }}



  /* Mobile Devices */
  @media (max-width: 480px) {
    z-index: 0;
  }
`;

export const StyledNavWrapper = styled.ul`
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
`;

export const StyledMenuSection = styled.label`
  color: ${({ theme }) => theme.primaryColor};

  padding: 7px;
  font-size: 15px;
  text-align: left;
`;
