import { endPoints } from 'api/';
import { GET_TAGS } from 'actions/types';
import { active } from 'constants/status';

/**
  @description Get a list of all tags
  @param {string} companyId Current user's company id.
  @returns Array of tag objects
 */
function getTags(companyId) {
  const query = {
    where: {
      status: active,
      company_id: companyId,
    },
  };

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_TAGS,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const tags = await apiClient(endPoints.GET_TAGS, {}, query);

        return tags;
      },
    };

    dispatch(action);
  };
}

export default getTags;
