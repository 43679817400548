import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Detail } from 'components/Order/';
import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { ORDER_INFO_MODAL } from 'constants/modal';

function OrderInfoModal() {
  const dispatch = useDispatch();
  const modal = useSelector(({ modal }) => modal);
  const modalCaption = 'Order Details';
  const isOpen = modal?.type === ORDER_INFO_MODAL;
  const order = isOpen ? modal.initialValue : {};

  const onModalClose = function () {
    dispatch(clearModal(ORDER_INFO_MODAL));
  };

  return (
    <Modal
      modalTop={5}
      modalLeft={20}
      isOpen={isOpen}
      modalWidth={60}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Detail order={order} />
    </Modal>
  );
}

export default OrderInfoModal;
