import React, { useMemo } from 'react';

import { formatDate } from 'utils/';
import { Search } from 'components/Table/';
import { SelectInput } from 'components/Inputs/';
import { StyledWrapper } from 'components/Table/Filter/Filter.styled';

function Filter({ rows, column }) {
  const {
    id,
    getSize,
    columnDef,
    setFilterValue,
    getFilterValue,
    getFacetedMinMaxValues: getMinMax,
    getFacetedUniqueValues: getUniqueValues,
  } = column;
  const width = getSize() / 2;
  const value = getFilterValue();
  const isDate = columnDef?.meta?.date;
  const firstValue = rows[0]?.getValue(id);
  const totalItems = getUniqueValues().size;
  const isNumFilter = columnDef?.meta?.numFilter;
  const isNumber = typeof firstValue === 'number' && !isNumFilter;
  const sortedUniqueOptions = useMemo(
    function () {
      if (isNumber && !isDate && !isNumFilter) {
        return [];
      }
      const sortedData = Array.from(getUniqueValues().keys()).sort();
      return sortedData.map(function (item) {
        if (isDate) {
          const value = formatDate({ date: item, standard: true });
          return { value: item, label: value };
        }
        return { value: item, label: item };
      });
    },
    [getUniqueValues()],
  );

  function onFilterChange(option) {
    let selected = '';

    if (option) {
      const { value } = option;
      selected = value;
    }

    setFilterValue(selected);
  }

  function onDateChange(option, lower) {
    const date = option?.value;
    setFilterValue(function (old) {
      if (lower) {
        return [date, old?.[1]];
      }
      return [old?.[0], date];
    });
  }

  function onMinValueChange(value) {
    setFilterValue((old) => [value, old?.[1]]);
  }

  function onMaxValueChange(value) {
    setFilterValue((old) => [old?.[0], value]);
  }

  if (isDate) {
    let lowerDate;
    let upperDate;
    const dates = getFilterValue();
    if (dates && dates[0]) {
      lowerDate = {
        value: dates[0],
        label: formatDate({ date: dates[0], standard: true }),
      };
    }
    if (dates && dates[1]) {
      upperDate = {
        value: dates[1],
        label: formatDate({ date: dates[1], standard: true }),
      };
    }

    const uniqueDateOptions = Array.from(
      new Map(sortedUniqueOptions.map((item) => [item.label, item])).values(),
    );

    return (
      <StyledWrapper>
        <SelectInput
          isTableFilter
          errorMessage=""
          isLoading={false}
          value={lowerDate}
          isClearable={true}
          isSearchable={true}
          placeholder="Min date"
          name={`lower-date-${id}`}
          options={uniqueDateOptions}
          caption={`lower-date-label-${id}`}
          ariaLabelledBy={`lower-date-${id}`}
          onChange={(value) => onDateChange(value, true)}
        />
        <SelectInput
          isTableFilter
          errorMessage=""
          value={upperDate}
          isLoading={false}
          isClearable={true}
          isSearchable={true}
          placeholder="Max date"
          name={`upper-date-${id}`}
          options={uniqueDateOptions}
          caption={`upper-date-label-${id}`}
          ariaLabelledBy={`upper-date-${id}`}
          onChange={(value) => onDateChange(value, false)}
        />
      </StyledWrapper>
    );
  }

  if (isNumber) {
    return (
      <StyledWrapper>
        <Search
          filter
          type="number"
          width={width}
          caption={`min-${id}`}
          inputId={`min-${id}`}
          value={value?.[0] ?? ''}
          onChange={onMinValueChange}
          min={Number(getMinMax()?.[0] ?? '')}
          max={Number(getMinMax()?.[1] ?? '')}
          placeholder={`Min${getMinMax()?.[0] ? `:${getMinMax()?.[0]}` : ''}`}
        />
        <Search
          filter
          type="number"
          width={width}
          caption={`max-${id}`}
          inputId={`max-${id}`}
          value={value?.[1] ?? ''}
          onChange={onMaxValueChange}
          min={Number(getMinMax()?.[0] ?? '')}
          max={Number(getMinMax()?.[1] ?? '')}
          placeholder={`Max${getMinMax()?.[1] ? `:${getMinMax()?.[1]}` : ''}`}
        />
      </StyledWrapper>
    );
  }

  return (
    <SelectInput
      isTableFilter
      errorMessage=""
      isLoading={false}
      isClearable={true}
      isSearchable={true}
      onChange={onFilterChange}
      name={`select-input-${id}`}
      options={sortedUniqueOptions}
      caption={`select-label-${id}`}
      ariaLabelledBy={`select-input-${id}`}
      placeholder={`Search (${totalItems})`}
      value={value ? { value, label: value } : undefined}
    />
  );
}

export default Filter;
