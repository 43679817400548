import { apiClient } from 'api/';
import { CANCEL_API_REQUEST } from 'actions/types';

const environments = ['development', 'test'];
const shouldLogErrors = environments.includes(process.env.NODE_ENV);

/**
  @description Passed to redux `store` as middleware to handle all `HTTP` requests.
  @param {{
   dispatch : Function
   }} store
  @returns Promise
 */
function apiMiddleware(store) {
  /**
    @param {Function} next
   */
  return function (next) {
    /**
      @param {{
       request: Function
       }} action
      @example
       action = {
       type: 'ACTION_TYPE',
       request: (apiClient) => apiClient.method
       }
     */
    return async function (action) {
      return new Promise(function (resolve) {
        const { type: actionType, request: actionRequest } = action;

        if (actionType === CANCEL_API_REQUEST) {
          next(action);
        }

        // Only actions with `request` property will make HTTP call to api
        if (!actionRequest) {
          next(action);
        }

        if (action?.request) {
          action
            .request(apiClient)
            .then(
              /**
                @description `AxiosResponse` returned by `apiClient`
                @param {{
                 data: {}
                 }} response
                */
              function (response) {
                if (!response) {
                  const message = 'Api middleware never made any HTTP request';
                  console.error(message);
                } else {
                  const { data: exiosResponseData } = response;
                  const { response: responseData } = exiosResponseData;

                  if (!responseData) {
                    const message = 'No data returned from API middleware';
                    console.error(message);
                  }

                  store.dispatch({
                    type: `${actionType}_SUCCESS`,
                    payload: responseData,
                  });

                  resolve(responseData);
                }
              },
            )
            .catch(
              /**
                @param {{
                 response: {}
                 }} error
               */
              function (error) {
                const { response: errorResponse = {} } = error;
                const { data: errorData = {}, status } = errorResponse;
                const { info = '', error: errorInfo = '' } = errorData;

                store.dispatch({
                  type: `${actionType}_FAILURE`,
                  payload: { info, status, error: errorInfo },
                });

                if (shouldLogErrors) {
                  console.error(
                    'An error occurred in API middleware request : ',
                    error,
                  );
                }
              },
            );

          next(action);
        }
      });
    };
  };
}

export default apiMiddleware;
