import styled from 'styled-components';

export const StyledColor = styled.div`
  color: ${({ color }) => color};

  font-size: 10px;
  line-height: 18px;
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledPopover = styled.div`
  z-index: 2;
  position: absolute;
`;

export const StyledCover = styled.div`
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  position: fixed;
`;

export const StyledColorPickerButton = styled.button`
  background-color: ${({ color }) => color};

  height: 45px;
  width: 45px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 50%;
`;

export const StyledScreenReader = styled.span`
  display: none;
`;
