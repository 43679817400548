import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { CATEGORY_MODAL } from 'constants/modal';
import Category from 'components/Categories/Category';
import { createCategory, updateCategory } from 'actions/category/';

function CategoryModal() {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);

  const { data: categories, isLoading } = useSelector(
    ({ categories }) => categories,
  );

  const isOpen = modal?.type === CATEGORY_MODAL;
  const modalCaption = 'Manage Categories';

  function onModalClose() {
    dispatch(clearModal(CATEGORY_MODAL));
  }

  /**
   * @param {{}} categoryObj
   * @param {boolean} isEditing
   * @param {boolean} isDeleting
   */
  function onSubmit(categoryObj, isEditing, isDeleting) {
    const { company_id: companyId } = user;
    const { id: categoryId } = categoryObj;
    if (isEditing) {
      const payload = {
        ...categoryObj,
        company_id: companyId,
      };
      dispatch(updateCategory(categoryId, payload, companyId, isDeleting));
    } else {
      const payload = {
        ...categoryObj,
        company_id: companyId,
      };
      dispatch(createCategory(payload, companyId));
    }
  }

  return (
    <Modal
      modalTop={5}
      modalLeft={30}
      isOpen={isOpen}
      modalWidth={40}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Category
        onSubmit={onSubmit}
        isLoading={isLoading}
        initialValue={categories}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default CategoryModal;
