import { useEffect, useState } from 'react';

import { MOBILE_VIEW_BREAKPOINT } from 'constants/general';

/**
 * Listen to window resize event and return a boolean value depending on the current window width.
 * @returns {boolean}
 */
function useMobileView() {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_VIEW_BREAKPOINT,
  );

  function onWindowResize() {
    const isMobile = window.innerWidth <= MOBILE_VIEW_BREAKPOINT;
    setIsMobileView(isMobile);
  }

  useEffect(function () {
    window.addEventListener('resize', onWindowResize);

    onWindowResize();

    return function () {
      return window.removeEventListener('resize', onWindowResize);
    };
  }, []);

  return isMobileView;
}

export default useMobileView;
