import HighCharts from 'highcharts';
import React, { useMemo, useState } from 'react';
import HighChartsReact from 'highcharts-react-official';
import { func, arrayOf, object, bool } from 'prop-types';

import {
  StyledDateLabel,
  StyledGraphWrapper,
  StyledFilterWrapper,
} from 'components/Order/Graph/Graph.styled';
import { FlexCol } from 'components/Layout/';
import { Loading } from 'components/Loaders/';
import { InfoAlert } from 'components/Alerts/';
import { RoundButton } from 'components/Buttons/';
import DatePicker from 'components/DatePicker/DatePicker';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { formatDate, objectHasProperty, getLineChartOptions } from 'utils/';

/**
 * @param {{
 * orders : []
 * isLoading : boolean
 * onLoadOrders : Function
 * showDateFilter : boolean
 * }} param
 */
function Graph({ orders, isLoading, onLoadOrders, showDateFilter }) {
  const initialDate = new Date();
  const [graphState, setGraphState] = useState({
    lowerDate: initialDate,
    upperDate: initialDate,
  });
  const [graphErrors, setGraphErrors] = useState({
    lowerDate: '',
    upperDate: '',
  });

  /**
   * @param {string} date
   */
  function totalOrdersByDate(date) {
    return [...orders].filter(function ({ createdat }) {
      return formatDate({ date: createdat }) === date;
    }).length;
  }

  const groupedByDate = useMemo(
    function () {
      return [...orders].reduce(function (accumulator, currentObject) {
        const date = formatDate({ date: currentObject.createdat });
        accumulator[date] = totalOrdersByDate(date);
        return accumulator;
      }, {});
    },
    [orders],
  );

  const order = useMemo(() => {
    const data = [];
    const labels = [];
    if (objectHasProperty(groupedByDate)) {
      for (const [key, value] of Object.entries(groupedByDate)) {
        labels.push(key);
        data.push(value);
      }
    }
    return {
      data,
      labels,
    };
  }, [groupedByDate]);

  const options = getLineChartOptions(
    order.data,
    order.labels,
    'Orders By Date',
    'Orders',
  );

  /**
   * @param {string} date
   */
  function onLowerDateChange(date) {
    setGraphState({
      ...graphState,
      lowerDate: date,
    });
  }

  /**
   * @param {string} date
   */
  function onUpperDateChange(date) {
    setGraphState({
      ...graphState,
      upperDate: date,
    });
  }

  function onFilterGraph() {
    let isValid = true;
    const errorObject = { ...graphErrors };
    const { lowerDate, upperDate } = graphState;
    if (!lowerDate) {
      isValid = false;
      setGraphErrors({
        ...errorObject,
        lowerDate: 'Select date from',
      });
    } else {
      isValid = true;
      setGraphErrors({
        ...errorObject,
        lowerDate: '',
      });
    }
    if (!upperDate) {
      isValid = false;
      setGraphErrors({
        ...errorObject,
        upperDate: 'Select date to',
      });
    } else {
      isValid = true;
      setGraphErrors({
        ...errorObject,
        upperDate: '',
      });
    }
    if (lowerDate > upperDate) {
      isValid = false;
      setGraphErrors({
        ...errorObject,
        upperDate: 'Date to is less than date from',
        lowerDate: 'Date from is greater than date to',
      });
    }
    if (isValid) {
      setGraphErrors({
        lowerDate: '',
        upperDate: '',
      });
      onLoadOrders(lowerDate, upperDate);
    }
  }

  function renderGraph() {
    if (isLoading) {
      return <Loading />;
    }
    if (orders.length > 0) {
      return (
        <StyledGraphWrapper>
          <HighChartsReact
            options={options}
            highcharts={HighCharts}
            containerProps={{ style: { height: '500px' } }}
          />
        </StyledGraphWrapper>
      );
    }
    return <InfoAlert message="No Data To Draw Order Graph" />;
  }

  return (
    <>
      {showDateFilter ? (
        <StyledFilterWrapper>
          <StyledDateLabel>Select Date Range</StyledDateLabel>
          <FlexCol flexWidth={2}>
            <DatePicker
              caption=""
              name="lowerDate"
              iconTopMargin={7}
              inputId="lowerDate"
              onChange={onLowerDateChange}
              placeholder="Select date from"
              selected={graphState.lowerDate}
              errorMessage={graphErrors.lowerDate}
            />
          </FlexCol>
          <FlexCol flexWidth={2}>
            <DatePicker
              caption=""
              name="upperDate"
              iconTopMargin={7}
              inputId="upperDate"
              placeholder="Select date to"
              onChange={onUpperDateChange}
              selected={graphState.upperDate}
              errorMessage={graphErrors.upperDate}
            />
          </FlexCol>
          <FlexCol flexWidth={1}>
            <RoundButton
              caption="Load"
              flexWidth={100}
              icon={<TickIcon />}
              onClick={onFilterGraph}
            />
          </FlexCol>
        </StyledFilterWrapper>
      ) : null}
      {renderGraph()}
    </>
  );
}

Graph.propTypes = {
  isLoading: bool,
  onLoadOrders: func,
  showDateFilter: bool,
  orders: arrayOf(object),
};

export default Graph;
