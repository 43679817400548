import { endPoints } from 'api/';
import { CREATE_PEOPLE } from 'actions/types';
import { getPeoples } from 'actions/peoples/';

/**
  @description Creates people object
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @returns People object
 */
function createPeople(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_PEOPLE,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const people = await apiClient(endPoints.CREATE_USER, payload);

        dispatch(getPeoples({ companyId }));

        return people;
      },
    };

    dispatch(action);
  };
}

export default createPeople;
