import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Tag from 'components/Tags/Tag';
import Modal from 'containers/Modal/Modal';
import { TAG_MODAL } from 'constants/modal';
import { clearModal } from 'actions/modals/';
import { createTag, updateTag } from 'actions/tags/';

const TagModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: tags, isLoading } = useSelector(({ tags }) => tags);

  const isOpen = modal?.type === TAG_MODAL;
  const modalCaption = 'Manage Tags';

  const onModalClose = () => {
    dispatch(clearModal(TAG_MODAL));
  };

  /**
    @param {{}} tagObj
    @param {boolean} isEditing
    @param {boolean} isDeleting
   */
  const onSubmit = (tagObj, isEditing, isDeleting) => {
    const { company_id: companyId } = user;
    const { id: tagId } = tagObj;

    if (isEditing) {
      const payload = {
        ...tagObj,
        company_id: companyId,
      };

      dispatch(updateTag(tagId, payload, companyId, isDeleting));
    } else {
      const payload = {
        ...tagObj,
        company_id: companyId,
      };

      dispatch(createTag(payload, companyId));
    }

    // dispatch(clearModal(TAG_MODAL));
  };

  return (
    <Modal
      modalTop={5}
      modalLeft={30}
      modalWidth={40}
      isOpen={isOpen}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Tag
        onSubmit={onSubmit}
        initialValue={tags}
        isLoading={isLoading}
        onModalClose={onModalClose}
      />
    </Modal>
  );
};

export default TagModal;
