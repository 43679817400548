import styled from 'styled-components';

import { black } from 'constants/color';

export const StyledDescription = styled.p`
  color: ${black};
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;
