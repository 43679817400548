import { func } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  StyledLogo,
  StyledAvatar,
  StyledWrapper,
  StyledUserName,
  StyledInputWrapper,
} from './LockForm.styled';
import avatar from 'img/avatar.png';
import WezzaLogo from 'img/wezzalogo.png';
import { primary } from 'constants/color';
import { TextInput } from 'components/Inputs/';
import { RoundButton } from 'components/Buttons/';
import { FlexCol, FlexRow } from 'components/Layout/';
import { LOGIN_PATH, USER_LOCAL_STORAGE } from 'constants/general';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';
import { parseJSON, validateEmail, capitalizeFirstLetter } from 'utils/';

/**
  @param {{
  onSubmit : Function
  }} param
 */
const LockForm = ({ onSubmit }) => {
  const userStr = window.localStorage.getItem(USER_LOCAL_STORAGE);
  const user = parseJSON(userStr);
  const {
    email = '',
    photo = '',
    last_name: lastName = '',
    first_name: firstName = '',
  } = user || {};

  const [state, setState] = useState({
    password: '',
    email: email,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isPasswordInputInValid = isSubmitting && !state.password;
  const passwordErrorMessage = isPasswordInputInValid
    ? 'Incorrect Password'
    : '';
  const [errorMessage, setErrorMessage] = useState({
    password: passwordErrorMessage,
  });
  const userName = `${firstName} ${lastName}`;

  const onInputChange = (event) => {
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onLogIn = () => {
    setIsSubmitting(true);

    const isFormValid = validateForm();

    if (isFormValid) {
      onSubmit({ ...state });
    }
  };

  const validateForm = () => {
    let errorObject = {
      ...errorMessage,
    };
    let isFormValid = true;

    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }

    setErrorMessage(errorObject);

    return isFormValid;
  };

  useEffect(() => {
    if (!user) {
      window.location.assign(LOGIN_PATH);
    }
  }, [user]);

  useEffect(() => {
    if (isSubmitting) {
      validateForm();
    }
  }, [state, isSubmitting]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledLogo src={WezzaLogo} />
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledWrapper>
            <StyledAvatar src={photo || avatar} />
            <StyledUserName>{userName}</StyledUserName>
          </StyledWrapper>
        </FlexCol>
      </FlexRow>
      <StyledInputWrapper>
        <TextInput
          tabIndex={1}
          name="password"
          inputId="password"
          caption="Password"
          inputType="password"
          value={state.password}
          onChange={onInputChange}
          placeholder="Enter your password"
          isInvalid={isPasswordInputInValid}
          errorMessage={errorMessage.password}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <RoundButton
          tabIndex={2}
          onClick={onLogIn}
          backColor={primary}
          icon={<ArrowIcon />}
          caption="Unlock Screen"
        />
      </StyledInputWrapper>
    </>
  );
};

LockForm.propTypes = {
  onSubmit: func,
};

export default LockForm;
