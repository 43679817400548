import { SketchPicker } from 'react-color';
import { string, func } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  StyledColor,
  StyledCover,
  StyledPopover,
  StyledScreenReader,
  StyledColorPickerButton,
} from './ColorPicker.styled';

/**
  @param {{
  color : string
  onChange : Function
  }} param
 */
const ColorPicker = ({ color, onChange }) => {
  const [colorHex, setColorHex] = useState();
  const [currentColor, setCurrentColor] = useState(color);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useEffect(() => {
    setColorHex(color);
    setCurrentColor(color);
  }, [color, setCurrentColor]);

  const onClick = () => setShowColorPicker(!showColorPicker);

  const onClose = () => setShowColorPicker(false);

  /**
    @param {{
    hex: string
    }} color
   */
  const onColorChange = (color) => {
    const hex = color.hex.toUpperCase();

    onChange(hex);
    setColorHex(hex);
    setCurrentColor(hex);
  };

  return (
    <>
      <StyledColor color={colorHex}>{colorHex}</StyledColor>
      <StyledColorPickerButton
        onClick={onClick}
        data-testid="color-picker-current-color"
        color={currentColor}
      ></StyledColorPickerButton>
      {showColorPicker && (
        <StyledPopover data-testid="color-picker-popover">
          <StyledCover data-testid="color-picker-cover" onClick={onClose}>
            <StyledScreenReader className="sr-only">
              Close Color Picker
            </StyledScreenReader>
          </StyledCover>
          <SketchPicker color={currentColor} onChange={onColorChange} />
        </StyledPopover>
      )}
    </>
  );
};

ColorPicker.propTypes = {
  color: string.isRequired,
  onChange: func.isRequired,
};

export default ColorPicker;
