import styled from 'styled-components';

import { mediumGrey } from 'constants/color';

export const StyledAnimation = styled.div`
  margin: 10px 0;
  animation-name: loading;
  animation-duration: 1.5s;
  background: ${mediumGrey};
  width: ${({ width }) => width}%;
  height: ${({ height }) => height}px;
  animation-iteration-count: infinite;

  @keyframes loading {
    50% {
      opacity: 0.4;
    }
  }
`;
