import { endPoints } from 'api/';
import { getUser, getUsers } from 'actions/users/';
import { UPDATE_USER, DELETE_USER } from 'actions/types';

/**
  @description Updates user object. This will also act as `delete` action by `disabling` status.
  @param {string} userId
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
  @returns User object without token and password.
 */
function updateUser(userId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_USER : UPDATE_USER;

  /**
    @param {Function} getState
    @param {Function} dispatch
   */
  return (dispatch, getState) => {
    const { user } = getState();
    const currentUserId = user?.id;
    payload.append('company_id', companyId);

    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const user = await apiClient(endPoints.UPDATE_USER, payload, userId);

        dispatch(getUsers({ companyId }));
        /**
           If we update current logged in user profile (e.g while onboarding),
           fetch the updated user record to make sure UI is in sync.
        */
        if (currentUserId === userId) {
          dispatch(getUser());
        }

        return user;
      },
    };

    dispatch(action);
  };
}

export default updateUser;
