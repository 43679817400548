import React, { useRef, useEffect } from 'react';
import { string, func, object } from 'prop-types';

import { usePlacesChange } from 'hooks/';
import { StyledSearchInput } from 'components/Map/SearchBox/SearchBox.styled';

/**
 * @param {{
 * mapInstance : {}
 * mapApiInstance : {}
 * placeholder : string
 * onPlacesChanged : Function
 * }} param
 */
function SearchBox({
  mapInstance,
  placeholder,
  mapApiInstance,
  onPlacesChanged,
}) {
  const searchBox = useRef(null);
  const searchInput = useRef(null);
  const [handleOnPlacesChanged] = usePlacesChange(onPlacesChanged, searchBox);

  useEffect(() => {
    if (!searchBox.current && mapApiInstance) {
      const options = {
        // Restrict search to a address only ?
        types: ['address'],
        // Restrict search to a kenya only ?
        componentRestrictions: { country: ['ke'] },
      };
      searchBox.current = new mapApiInstance.places.SearchBox(
        searchInput.current,
        options,
      ); // use `Autocomplete` api instead ?
      searchBox.current.addListener('places_changed', handleOnPlacesChanged);
      searchBox.current.bindTo('bounds', mapInstance);
    }
    return function () {
      if (mapApiInstance) {
        searchBox.current = null;
        mapApiInstance.event.clearInstanceListeners(searchBox);
      }
    };
  }, [mapApiInstance, handleOnPlacesChanged]);

  return (
    <StyledSearchInput
      type="text"
      ref={searchInput}
      data-testid="search-box"
      placeholder={placeholder}
    />
  );
}

SearchBox.propTypes = {
  placeholder: string,
  mapInstance: object,
  onPlacesChanged: func,
  mapApiInstance: object,
};

export default SearchBox;
