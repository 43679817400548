import styled from 'styled-components';

export const StyledNav = styled.nav`
  height: ${({ theme }) => theme.navBarSize};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.primaryColor};
  --boxPaddingTop: ${({ theme }) => theme.boxPaddingTop};
  --menuBarMargin: ${({ theme }) => theme.menuBarMargin};
  top: calc(var(--boxPaddingTop) + var(--menuBarMargin));

  z-index: 1;
  display: flex;
  padding: 0 1rem;
  position: sticky;
  align-items: center;
  justify-content: space-between;

  /* Mobile Devices */
  @media (max-width: 480px) {
    top: 0;
    padding: 0;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    top: 0;
    padding: 0;
  }
`;

export const NavbarNav = styled.ul`
  right: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  list-style: none;
  justify-content: flex-end;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 20%;
    margin-left: auto;
  }
`;

export const NavLogoWrapper = styled.label`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.secondaryColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 25%;

    svg {
      width: 100px;
    }
  }
`;

export const CompanyName = styled.label`
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  text-transform: uppercase;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.whiteColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 50%;
    font-size: 12px;
  }
`;

export const StyledUserImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;
