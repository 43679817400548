import React from 'react';

import { StyledAnimation } from './Animation.styled';

const Loading = () => {
  return (
    <>
      <StyledAnimation height={30} width={100} />
      <StyledAnimation height={30} width={50} />
      <StyledAnimation height={30} width={100} />
      <StyledAnimation height={30} width={90} />
      <StyledAnimation height={30} width={30} />
      <StyledAnimation height={30} width={100} />
    </>
  );
};

export default Loading;
