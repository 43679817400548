import { endPoints } from 'api/';
import { getAssets } from 'actions/assets/';
import { CREATE_ASSET } from 'actions/types';

/**
  @description Creates asset object
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @returns Asset object
 */
function createAsset(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_ASSET,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const asset = await apiClient(endPoints.CREATE_ASSET, payload);

        dispatch(getAssets({ companyId }));

        return asset;
      },
    };

    dispatch(action);
  };
}

export default createAsset;
