import React from 'react';
import { func, bool, node, object } from 'prop-types';

import { StyledNavButton } from 'components/SideNav/SideNavButton/SideNavButton.styled';

/**
 * @param {{
 * children : JSx
 * buttonStyle : {}
 * onClick : Function
 * isActive : boolean
 * }} param
 */
function SideNavButton({ children, onClick, isActive, buttonStyle }) {
  return (
    <StyledNavButton
      onClick={onClick}
      $active={isActive}
      style={buttonStyle}
      data-active={isActive}
    >
      {children}
    </StyledNavButton>
  );
}

SideNavButton.propTypes = {
  onClick: func,
  children: node,
  isActive: bool,
  buttonStyle: object,
};

export default SideNavButton;
