import React, { useState } from 'react';

import { useHttpStatus } from 'hooks/';
import { invalid } from 'constants/color';
import {
  StyledBody,
  StyledFooter,
  StyledDashLine,
} from 'components/Confirm/Confirm.styled';
import { CancelButton, RoundButton } from 'components/Buttons/';

/**
 * @param {{
 * confirm: Function
 * onClose: Function
 * }} param
 */
function Confirm({ confirm, onClose }) {
  const {
    onCancel,
    onConfirm,
    type = '',
    caption = '',
    options = { deleting: false },
  } = confirm || {};
  const isDeleting = options?.deleting || false;
  const buttonText = isDeleting ? 'Delete' : 'Confirm';
  const [isSubmitting, setIsSubmitting] = useState(false);
  useHttpStatus({
    status: 'ALL',
    actions: [type],
    resetCallBack: true,
    callBack: setIsSubmitting,
  });

  function onConfirmClick() {
    setIsSubmitting(true);
    if (onConfirm) {
      onConfirm();
    }
  }

  function onCloseClick() {
    onClose();
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <>
      <StyledBody>{caption}</StyledBody>
      <StyledDashLine />
      <StyledFooter>
        <CancelButton flexWidth={30} caption="Cancel" onClick={onCloseClick} />
        <RoundButton
          flexWidth={30}
          backColor={invalid}
          caption={buttonText}
          onClick={onConfirmClick}
          isDisabled={isSubmitting}
        />
      </StyledFooter>
    </>
  );
}

export default Confirm;
