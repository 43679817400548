import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & > button:not(:first-child) {
    margin-left: 30px;
  }
`;
