import React from 'react';
import { bool, element, func } from 'prop-types';

import { StyledMarker } from 'components/Map/Marker/Marker.styled';
import { StyledWrapper } from 'components/Map/InfoWindow/InfoWindow.styled';

/**
 * @param {{
 * children: JSX,
 * isActive: boolean,
 * onClick: Function,
 * }} param
 */
function InfoWindow({ children, onClick, isActive }) {
  return (
    <>
      <StyledMarker onClick={onClick}></StyledMarker>
      {isActive ? <StyledWrapper>{children}</StyledWrapper> : null}
    </>
  );
}

InfoWindow.propTypes = {
  onClick: func,
  isActive: bool,
  children: element,
};

export default InfoWindow;
