import styled from 'styled-components';

export const StyledInput = styled.input`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
  vertical-align: top;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.secondaryColor};
`;

export const StyledLabel = styled.label`
  height: 18px;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  margin-right: 20px;
  font-weight: normal;
  vertical-align: middle;
`;
