import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { primary, white } from 'constants/color';

export const StyledHeader = styled(motion.div)`
  display: flex;
  color: #333333;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  align-items: center;
  text-decoration: none;
  background: ${primary};
  border: 1px solid white;
`;

export const StyledBodyWrapper = styled(motion.div)`
  ${function ({ $backGroundColor }) {
    return (
      $backGroundColor &&
      css`
        background: ${$backGroundColor};
      `
    );
  }}
`;

export const StyledBody = styled(motion.div)`
  height: auto;
  padding: 10px;
  border-top: 0;
  margin: 0 1px;
  border: 1px solid ${primary};
  min-height: ${function ({ $minHeight }) {
    return $minHeight ? `${$minHeight}px` : '310px';
  }};

  /* Small screens */
  @media only screen and (max-width: 600px) {
    overflow: auto;
  }
`;

export const StyledIconWrapper = styled(motion.span)`
  width: 30px;
  height: 30px;
  display: flex;
  margin-left: auto;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    fill: ${white};
    transition: transform 300ms;
    transform: ${function ({ isOpen }) {
      return isOpen ? 'rotateZ(270deg)' : 'rotateZ(90deg)';
    }};
  }
`;

export const StyledCaption = styled(motion.label)`
  color: ${white};
  font-size: 14px;
  cursor: pointer;
  line-height: 18px;
  font-style: normal;
  padding-left: 10px;
`;
