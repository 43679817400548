import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { ASSET_INFO_MODAL } from 'constants/modal';
import AssignedInfo from 'components/Assets/Assign/AssignedInfo/AssignedInfo';

const AssignedAssetModal = () => {
  const dispatch = useDispatch();

  const modal = useSelector(({ modal }) => modal);

  const modalCaption = 'Assigned Assets';
  const isOpen = modal?.type === ASSET_INFO_MODAL;
  const assets = isOpen ? modal.initialValue : {};

  const onModalClose = function () {
    dispatch(clearModal(ASSET_INFO_MODAL));
  };

  return (
    <Modal
      modalTop={5}
      modalLeft={20}
      isOpen={isOpen}
      modalWidth={60}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <AssignedInfo assets={assets} />
    </Modal>
  );
};

export default AssignedAssetModal;
