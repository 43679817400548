import { endPoints } from 'api/';
import { ASSIGN_ORDER } from 'actions/types';
import { getAssigned } from 'actions/order/';

/**
 * @description Assigns order to a user.
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @returns Assigned oder object.
 */
function create(payload, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: ASSIGN_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const assigned = await apiClient(endPoints.ASSIGN_ORDER, payload);
        dispatch(getAssigned(companyId));
        return assigned;
      },
    };
    dispatch(action);
  };
}

export default create;
