/**
  @description Checks if object has any/specific key.
  @param {{}} object Object to check for key(s).
  @param {string} key If object property `key` is not specified, it will check whether object has `any` key.
 */
function objectHasProperty(object, key) {
  if (!object) {
    return false;
  }

  if (key) {
    return object.hasOwnProperty(key);
  }

  return Object.keys(object).length > 0;
}

export default objectHasProperty;
