import { parseJSON, objectHasProperty, capitalizeFirstLetter } from 'utils/';

function parseResponseError(response) {
  if (!response) {
    return '';
  }
  const result = parseJSON(response);
  if (objectHasProperty(result, 'errors')) {
    const { errors } = result;
    if (Array.isArray(errors) && errors.length > 0) {
      const data = errors.map(function (error) {
        return error.param;
      });
      const formatted = data.map(function (error, index) {
        if (index === 0) {
          return capitalizeFirstLetter(error);
        }
        if (index === data.length - 1) {
          return ` and ${capitalizeFirstLetter(error)}`;
        }
        return `, ${capitalizeFirstLetter(error)}`;
      });
      return `Fix field(s): ${formatted.join('')}`;
    }
  }
  return '';
}

export default parseResponseError;
