/**
 * Converts an array to a human readable sentence.
 * @param {{
 * suffix : string
 * prefix : string
 * separator : string
 * array : Array<string>
 * }} param
 */
function arrayToSentence({
  array,
  suffix = '',
  prefix = '',
  separator = ', ',
}) {
  let message = `${prefix} ${array.join(separator)} ${suffix}`;
  const lastComma = message.lastIndexOf(separator.trim());
  if (lastComma > -1) {
    message = `${message.slice(0, lastComma)} and${message.slice(
      lastComma + 1,
    )}`;
  }
  return message;
}

export default arrayToSentence;
