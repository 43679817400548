import styled from 'styled-components';

export const StyledCheckListCaption = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledCheckListWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`;

export const StyledCheckList = styled.div``;

export const StyledFormGroup = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`;

export const StyledCaption = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledLabel = styled.label`
  color: ${({ color }) => color};

  font-size: 12px;
  margin-top: 5px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledProgressWrapper = styled.div`
  margin-top: 7px;
`;
