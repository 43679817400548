import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';

import { clearNotify } from 'actions/notify/';
import NotifyComponent from 'components/Notify/Notify';

function Notify() {
  const timeout = 10000;
  const dispatch = useDispatch();
  const { type, message, error, processing } = useSelector(
    ({ notify }) => notify,
  );

  function onClose() {
    dispatch(clearNotify());
  }

  const notify = message ? (
    <NotifyComponent
      type={type}
      error={error}
      message={message}
      onClose={onClose}
    />
  ) : null;

  useEffect(() => {
    const shouldClose = !processing && (message || error);
    if (shouldClose) {
      const timer = window.setTimeout(function () {
        onClose();
      }, timeout);
      return function () {
        return window.clearTimeout(timer);
      };
    }
  }, [error, message, onClose, processing]);

  return (
    <AnimatePresence
      /**
        Disable any initial animations on children that
        are present when the component is first rendered
       */
      initial={false}
      /**
        Only render one component at a time.
        The exiting component will finish its exit
        animation before entering component is rendered
       */
      exitBeforeEnter={true}
      /**
        Fires when all exiting nodes have completed animating out
       */
      onExitComplete={function () {}}
    >
      {notify}
    </AnimatePresence>
  );
}

export default Notify;
