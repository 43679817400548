import { GET_USER_FROM_SESSION } from 'actions/types';

const initialState = null;

/**
  @description Stores authenticated user details in redux state
  @param {{} | null} state
  @param {{}} action
  @returns {{} | null}
 */
function user(state = initialState, action) {
  const { type, payload } = action;

  if (type === `${GET_USER_FROM_SESSION}_FAILURE`) {
    return null;
  }

  if (type === `${GET_USER_FROM_SESSION}_SUCCESS`) {
    return payload;
  }

  return state;
}

export default user;
