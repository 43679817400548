import styled from 'styled-components';

export const StyledFooter = styled.section`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  /* Small screens */
  @media only screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }

  /* tablets */
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledPaginationInfo = styled.label`
  padding: 7px;
  display: flex;
  font-size: 12px;
  line-height: 18px;
  margin-left: 20px;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  color: ${({ theme }) => theme.secondaryColor};
  border: 1px solid ${({ theme }) => theme.primaryColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledPagination = styled.div`
  margin-left: auto;

  /* Small screens */
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  /* tablets */
  @media only screen and (max-width: 768px) {
    width: auto;
    margin-left: 0;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  float: left;
  color: #333;
  cursor: pointer;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: ${({ theme }) => theme.primaryColor};

  &:disabled {
    background-color: #d9d9d9;
  }

  &:hover {
    background-color: #c4c4c4;
  }
`;
