import { endPoints } from 'api/';
import { getAssignedAssets } from 'actions/assets/';
import { UPDATE_ASSIGNED_ASSET, DELETE_ASSIGNED_ASSET } from 'actions/types';

/**
  @description Updates assigned asset object.
  @param {string} id
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete.
  @returns Updated assigned asset object
 */
function updateAssignedAsset(id, payload, companyId, isDeleting) {
  const actionType = isDeleting ? DELETE_ASSIGNED_ASSET : UPDATE_ASSIGNED_ASSET;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const asset = await apiClient(
          endPoints.UPDATE_ASSIGNED_ASSET,
          payload,
          id,
        );

        dispatch(getAssignedAssets({ companyId }));

        return asset;
      },
    };

    dispatch(action);
  };
}

export default updateAssignedAsset;
