import { endPoints } from 'api/';
import { CREATE_CONDITION } from 'actions/types';
import { getConditions } from 'actions/condition/';

/**
  @description Creates condition object
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @returns Condition object
 */
function createCondition(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_CONDITION,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const condition = await apiClient(endPoints.CREATE_CONDITION, payload);

        dispatch(getConditions(companyId));

        return condition;
      },
    };

    dispatch(action);
  };
}

export default createCondition;
