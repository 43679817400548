import { func, array } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  TextInput,
  CreaTable,
  PhotoInput,
  SelectInput,
} from 'components/Inputs/';
import { admin } from 'constants/user';
import {
  StyledLogo,
  StyledWrapper,
  StyledLogoWrapper,
  StyledButtonWrapper,
} from 'components/SignUp/SignUp.styled';
import { active } from 'constants/status';
import WezzaLogo from 'img/wezzalogo.png';
import currencyList from 'constants/currencies';
import unitsList from 'constants/quantityUnits';
import { RoundButton } from 'components/Buttons/';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { capitalizeFirstLetter, validateEmail } from 'utils/';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';
import { WrapperHeading } from 'components/Wrapper//Wrapper.styled';

/**
 * @description Signup screen
 * @param {{
 * currencies: []
 * onSubmit : Function,
 * onBackClick : Function,
 * }} param
 */
function SignUp({ onSubmit, onBackClick, currencies = currencyList }) {
  const [state, setState] = useState({
    address: '',
    currency: '',
    lastName: '',
    password: '',
    taxNumber: '',
    vatAmount: '',
    firstName: '',
    userPhone: '',
    userEmail: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    quantityUnits: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({
    address: '',
    lastName: '',
    password: '',
    userPhone: '',
    userEmail: '',
    firstName: '',
    vatAmount: '',
    companyName: '',
    companyEmail: '',
    companyPhone: '',
    quantityUnits: '',
    confirmPassword: '',
  });
  const [companyLogo, setCompanyLogo] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedQuantityUnits, setSelectedQuantityUnits] = useState(null);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  }

  /**
   * @param {{
   *  value: string
   *  }} selectedOption
   */
  function onCurrencyChange(selectedOption) {
    let currency = null;
    setSelectedCurrency(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      currency = value;
    }
    setState({
      ...state,
      currency,
    });
  }

  /**
   * @param {[{
   *  value: string
   *  label: string
   *  }]} selectedOption
   */
  function onQuantityUnitChange(selectedOption) {
    let quantityUnits = null;
    setSelectedQuantityUnits(selectedOption);
    if (selectedOption) {
      quantityUnits = selectedOption?.map(({ value }) => value);
    }
    setState({
      ...state,
      quantityUnits,
    });
  }

  function onSignUp() {
    setIsSubmitting(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      const hasAccess = true;
      let errorObject = { ...errors };
      const userData = new FormData();
      const companyData = new FormData();
      const {
        address,
        currency,
        lastName,
        password,
        taxNumber,
        vatAmount,
        userEmail,
        userPhone,
        firstName,
        companyName,
        companyPhone,
        companyEmail,
        quantityUnits,
        confirmPassword,
      } = state;
      if (password !== confirmPassword) {
        errorObject = {
          ...errorObject,
          password: 'Password Mismatch',
          confirmPassword: 'Password Confirmation Mismatch',
        };
        setErrors(errorObject);
        return;
      }
      const contactDetails = {
        phone: companyPhone,
        email: companyEmail,
      };

      const otherDetailsObj = {
        tax_number: taxNumber,
        vat_amount: vatAmount,
        price_units: [currency],
        quantity_units: quantityUnits,
      };

      userData.append('status', active);
      userData.append('usertype', admin);
      userData.append('email', userEmail);
      if (userPhone) {
        userData.append('phone', userPhone);
      }
      userData.append('password', password);
      userData.append('last_name', lastName);
      userData.append('has_access', hasAccess);
      userData.append('first_name', firstName);
      if (profilePhoto) {
        userData.append('photo', profilePhoto);
      }
      if (companyLogo) {
        companyData.append('photo', companyLogo);
      }
      companyData.append('status', active);
      companyData.append('country', address);
      companyData.append('name', companyName);
      companyData.append('user_email', userEmail);
      if (userPhone) {
        companyData.append('user_phone', userPhone);
      }
      companyData.append('other_details', JSON.stringify(otherDetailsObj));
      companyData.append('contact_details', JSON.stringify(contactDetails));
      onSubmit(companyData, userData);
    }
  }

  const validateForm = () => {
    let isFormValid = true;
    const optionalFields = [
      'taxNumber',
      'vatAmount',
      'userPhone',
      'companyPhone',
    ];
    const emailFields = {
      userEmail: 'userEmail',
      companyEmail: 'companyEmail',
    };
    let errorObject = { ...errors };
    const { companyPhone, userPhone } = state;
    for (const [key, value] of Object.entries(state)) {
      const caption = capitalizeFirstLetter(key, true);
      if (!optionalFields.includes(key) && !value?.length) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${caption}`,
        };
      } else {
        if (emailFields[key]) {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              [emailFields[key]]: `Invalid ${caption}`,
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    if (companyPhone.length > 0 && !(companyPhone.length >= 12)) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        companyPhone: 'Include Country Code',
      };
    } else {
      errorObject = {
        ...errorObject,
        companyPhone: '',
      };
    }
    if (userPhone.length > 0 && !(userPhone.length >= 12)) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        userPhone: 'Include Country Code',
      };
    } else {
      errorObject = {
        ...errorObject,
        userPhone: '',
      };
    }
    setErrors(errorObject);
    return isFormValid;
  };

  useEffect(() => {
    if (isSubmitting) {
      validateForm();
    }
  }, [state, isSubmitting]);

  return (
    <>
      <StyledLogoWrapper>
        <StyledLogo src={WezzaLogo} alt="WezzaLogo" />
      </StyledLogoWrapper>
      <StyledWrapper>
        <WrapperHeading>Company Details</WrapperHeading>
        <FlexRow>
          <FlexCol flexWidth={8}>
            <FlexRow>
              <FlexCol flexWidth={6}>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      inputType="text"
                      name="companyName"
                      inputId="companyName"
                      caption="Company Name"
                      onChange={onInputChange}
                      placeholder="Enter name"
                      value={state.companyName}
                      errorMessage={errors.companyName}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      inputType="text"
                      name="companyEmail"
                      inputId="companyEmail"
                      caption="Company Email"
                      onChange={onInputChange}
                      placeholder="Enter email"
                      value={state.companyEmail}
                      errorMessage={errors.companyEmail}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      inputType="text"
                      name="companyPhone"
                      inputId="companyPhone"
                      caption="Company Phone"
                      onChange={onInputChange}
                      value={state.companyPhone}
                      placeholder="e.g +254712345678"
                      errorMessage={errors.companyPhone}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      name="address"
                      inputType="text"
                      inputId="address"
                      value={state.address}
                      onChange={onInputChange}
                      caption="Company Address"
                      errorMessage={errors.address}
                      placeholder="e.g 123, XYZ Street, City, Country"
                    />
                  </FlexCol>
                </FlexRow>
              </FlexCol>
              <FlexCol flexWidth={6}>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      inputType="text"
                      name="taxNumber"
                      errorMessage=""
                      inputId="taxNumber"
                      value={state.taxNumber}
                      onChange={onInputChange}
                      placeholder="Enter tax number"
                      caption="Company Tax Number (optional)"
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      caption="VAT %"
                      name="vatAmount"
                      inputType="number"
                      inputId="vatAmount"
                      placeholder="Enter VAT"
                      value={state.vatAmount}
                      onChange={onInputChange}
                      errorMessage={errors.vatAmount}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <SelectInput
                      isRequired
                      name="currency"
                      isLoading={false}
                      caption="Currency"
                      isClearable={true}
                      isSearchable={true}
                      options={currencies}
                      value={selectedCurrency}
                      ariaLabelledBy="currency"
                      onChange={onCurrencyChange}
                      placeholder="Select Currency"
                      errorMessage={errors.currency}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <CreaTable
                      isRequired
                      name="units"
                      isLoading={false}
                      isClearable={true}
                      isSearchable={true}
                      options={unitsList}
                      ariaLabelledBy="units"
                      caption="Quantity Units"
                      value={selectedQuantityUnits}
                      onChange={onQuantityUnitChange}
                      errorMessage={errors.quantityUnits}
                      noOptionsMessage="Enter Quantity Units"
                      placeholder="Select Preexisting or Enter Quantity Units e.g cartons or bales"
                    />
                  </FlexCol>
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <FlexCol flexWidth={2}>
            <PhotoInput
              id="companyLogo"
              caption="Company Logo"
              imageUri={companyLogo}
              dataTestId="companyLogo"
              onChange={setCompanyLogo}
              buttonText="Choose From Device"
            />
          </FlexCol>
        </FlexRow>
      </StyledWrapper>
      <StyledWrapper>
        <WrapperHeading>User Details</WrapperHeading>
        <FlexRow>
          <FlexCol flexWidth={8}>
            <FlexRow>
              <FlexCol flexWidth={6}>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      inputType="text"
                      name="firstName"
                      inputId="firstName"
                      caption="First Name"
                      value={state.firstName}
                      onChange={onInputChange}
                      placeholder="Enter first name"
                      errorMessage={errors.firstName}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      name="lastName"
                      inputType="text"
                      inputId="lastName"
                      caption="Last Name"
                      value={state.lastName}
                      onChange={onInputChange}
                      placeholder="Enter last name"
                      errorMessage={errors.lastName}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      name="userEmail"
                      inputType="text"
                      inputId="userEmail"
                      caption="Email Address"
                      value={state.userEmail}
                      onChange={onInputChange}
                      errorMessage={errors.userEmail}
                      placeholder="Enter email address"
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      name="userPhone"
                      inputType="text"
                      inputId="userPhone"
                      caption="Phone Number"
                      value={state.userPhone}
                      onChange={onInputChange}
                      placeholder="e.g +254712345678"
                      errorMessage={errors.userPhone}
                    />
                  </FlexCol>
                </FlexRow>
              </FlexCol>
              <FlexCol flexWidth={6}>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      name="password"
                      inputId="password"
                      caption="Password"
                      disableAutoComplete
                      inputType="password"
                      value={state.password}
                      onChange={onInputChange}
                      placeholder="Enter password"
                      errorMessage={errors.password}
                    />
                  </FlexCol>
                </FlexRow>
                <FlexRow>
                  <FlexCol flexWidth={12}>
                    <TextInput
                      isRequired
                      disableAutoComplete
                      inputType="password"
                      name="confirmPassword"
                      onChange={onInputChange}
                      inputId="confirmPassword"
                      caption="Confirm Password"
                      value={state.confirmPassword}
                      placeholder="Enter confirm password"
                      errorMessage={errors.confirmPassword}
                    />
                  </FlexCol>
                </FlexRow>
              </FlexCol>
            </FlexRow>
          </FlexCol>
          <FlexCol flexWidth={2}>
            <PhotoInput
              id="profilePicture"
              imageUri={profilePhoto}
              caption="Profile Picture"
              onChange={setProfilePhoto}
              dataTestId="profilePicture"
              buttonText="Choose From Device"
            />
          </FlexCol>
        </FlexRow>
      </StyledWrapper>
      <StyledButtonWrapper>
        <RoundButton
          onClick={onSignUp}
          icon={<TickIcon />}
          caption="Create Account"
        />
        <RoundButton
          icon={<ArrowIcon />}
          onClick={onBackClick}
          caption="Back To Login"
          className="back-to-login"
        />
      </StyledButtonWrapper>
    </>
  );
}

SignUp.propTypes = {
  onSubmit: func,
  onBackClick: func,
  currencies: array,
};

export default SignUp;
