import React from 'react';

import {
  StyledTableSelector,
  StyledTableSelectorLabel,
} from 'components/Table/Selector/Selector.styled';

function Selector({ value, onSetPageSize }) {
  const pageSizes = [5, 10, 20, 30, 40, 50, 100];

  function onPageSizeChange(evt) {
    const { value } = evt.target;
    onSetPageSize(Number(value));
  }

  return (
    <StyledTableSelectorLabel>
      Results
      <StyledTableSelector value={value} onChange={onPageSizeChange}>
        {pageSizes.map(function (pageSize) {
          return (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          );
        })}
      </StyledTableSelector>
    </StyledTableSelectorLabel>
  );
}

export default Selector;
