import { endPoints } from 'api/';
import { CREATE_CUSTOMER } from 'actions/types';
import { getCustomers } from 'actions/customers/';

/**
  @description Creates customer object
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @returns Customer object
 */
function createCustomer(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_CUSTOMER,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const customer = await apiClient(endPoints.CREATE_CUSTOMER, payload);

        dispatch(getCustomers({ companyId }));

        return customer;
      },
    };

    dispatch(action);
  };
}

export default createCustomer;
