import styled, { css } from 'styled-components';

export const StyledTableTitle = styled.div`
  width: 100%;
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 359px;
    margin-left: 0;
    max-width: 400px;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 380px;
    margin-left: 0;
    max-width: 450px;
    button {
      margin-left: 25px;
    }
  }
`;

export const StyledTableHeader = styled.th`
  --borderRadius: ${({ theme }) => theme.borderRadius};
  padding: 12px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: start;
  font-style: normal;
  padding-left: 20px;
  color: ${({ theme }) => theme.whiteColor};
  ${function ({ $width }) {
    return (
      $width &&
      css`
        width: ${$width};
      `
    );
  }}

  &:first-child {
    border-top-left-radius: var(--borderRadius);
    border-bottom-left-radius: var(--borderRadius);
  }

  &:last-child {
    border-top-right-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);
  }
`;

export const StyledTableHeading = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* Mobile Devices */
  @media (max-width: 480px) {
    flex-direction: column;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 750px;
  }
`;

export const StyledTableHeadRow = styled.tr`
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const StyledTableRowSearch = styled.tr`
  border-bottom: ${({ theme }) => theme.primaryBorder};
`;

export const StyledTableHeaderSearch = styled.th`
  width: 100%;
  padding-bottom: 10px;
  text-align: -webkit-right;
  /* Mobile Devices */
  @media (max-width: 480px) {
    text-align: left;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    text-align: left;
  }
`;

export const StyledSortDirectionIndicator = styled.span`
  width: 100%;
  white-space: nowrap;
  cursor: ${({ $sort }) => ($sort ? 'pointer' : 'default')};
`;

export const StyledFilterRow = styled.tr`
  padding: 0px;
  margin-top: 0px;
  border-bottom: 1px solid #c4c4c4;
`;

export const StyledFilterHeader = styled.th`
  width: auto;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: start;
  font-style: normal;
  padding-left: 20px;
  padding-bottom: 0px;
`;

export const StyledOption = styled.li`
  color: black;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  &:not(:first-of-type) {
    border-top: 1px solid #ddd;
  }
`;

export const StyleOptionButton = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  border: 1px solid #ddd;
  &:disabled {
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #ddd;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
