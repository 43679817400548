/**
  @description Capitalizes the first letter of a string
  @param {string} string String which first letter will be capitalized
  @param {boolean} splitOnUpperCase Splits camelCase string into a sentence.
  @returns String
  @example
  `firstName` will be transformed into `First Name`
 */
function capitalizeFirstLetter(string, splitOnUpperCase = false) {
  if (!string?.length) {
    return string;
  }

  const transformed = string.charAt(0).toUpperCase() + string.slice(1);

  if (splitOnUpperCase) {
    return transformed
      .match(/([A-Z]?[^A-Z]*)/g)
      .slice(0, -1)
      .join(' ');
  }

  return transformed;
}

export default capitalizeFirstLetter;
