import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { primary, mediumGrey } from 'constants/color';

export const StyledButton = styled(motion.button)`
  height: 35px;
  border: none;
  font-size: 15px;
  box-shadow: none;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  white-space: nowrap;
  color: ${mediumGrey};
  text-transform: none;
  pointer-events: none;
  -moz-box-shadow: none;
  vertical-align: middle;
  background-color: white;
  -webkit-box-shadow: none;
  ${function ({ disabled }) {
    return (
      !disabled &&
      css`
        cursor: pointer;
        color: ${primary};
        pointer-events: auto;
      `
    );
  }}
`;

export const IconWrapper = styled(motion.span)`
  svg {
    width: 21px;
    height: 21px;
    vertical-align: sub;
  }
  padding: 5px;
  vertical-align: baseline;
`;
