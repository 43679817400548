import { CLEAR_CONFIRM } from 'constants/modal';

function clearConfirm() {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      payload: null,
      type: CLEAR_CONFIRM,
    };
    dispatch(action);
  };
}

export default clearConfirm;
