import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const StyledButton = styled(motion.button)`
  height: 40px;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0;
  border-width: 1px;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  -moz-box-shadow: none;
  vertical-align: middle;
  background-image: none;
  line-height: 1.42857143;
  -webkit-box-shadow: none;
  color: ${({ foreColor }) => foreColor};
  font-size: ${({ fontSize }) => fontSize}px;
  min-width: ${({ flexWidth }) => flexWidth}%;
  background-color: ${({ backColor }) => backColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid
    ${({ borderColor }) => (borderColor ? borderColor : 'transparent')};
  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled(motion.span)`
  padding: 5px;
  vertical-align: baseline;
  svg {
    width: 20px;
    height: 20px;
    vertical-align: sub;
    fill: ${({ iconFillColor }) => iconFillColor};
  }
`;

export const StyledSpinner = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
  border: 2px solid ${({ $color }) => $color};
  border-top: 2px solid ${({ $indicator }) => $indicator};
`;
