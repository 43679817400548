import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_CONDITION } from 'actions/types';

/**
  @description Get a list of all conditions
  @param {string} companyId Current user's company id.
  @returns Array of condition objects
 */
function getConditions(companyId) {
  const query = {
    where: {
      status: active,
      company_id: companyId,
    },
  };

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_CONDITION,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const conditions = await apiClient(endPoints.GET_CONDITION, {}, query);

        return conditions;
      },
    };

    dispatch(action);
  };
}

export default getConditions;
