import { transformActions } from 'utils/';
import { GET_CUSTOMERS } from 'actions/types';

const initialState = {
  data: [],
  isLoading: false,
};

/**
  @description Stores customers array details in redux state
  @param {[]} state
  @param {{}} action
  @returns {[]}
 */
function customers(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_CUSTOMERS];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');

  if (type === GET_CUSTOMERS) {
    return {
      data: [],
      isLoading: true,
    };
  }

  if (success.includes(type)) {
    return {
      data: payload,
      isLoading: false,
    };
  }

  if (failure.includes(type)) {
    return {
      data: [],
      isLoading: false,
    };
  }

  return state;
}

export default customers;
