import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  DASHBOARD_PATH,
  ONBOARDING_STEPS,
  CONGRATULATIONS_PATH,
} from 'constants/general';
import {
  StyledHello,
  StyledAvatar,
  StyledBodyText,
  StyledSkipButton,
  StyledIconWrapper,
  StyledTileWrapper,
  StyledWelcomeBody,
  BackButtonWrapper,
  StyledTileContainer,
  StyledWelcomeFooter,
  StyledWelcomeHeader,
} from './SideNavTile.styled';
import avatar from 'img/avatar.png';
import { RoundButton } from 'components/Buttons/';
import { white, secondary } from 'constants/color';
import onboarding from 'actions/onboarding/onboarding';
import setThemeStyles from 'actions/theme/setThemeStyles';
import { ReactComponent as UserIcon } from 'icons/users.svg';
import { ReactComponent as AssetsIcon } from 'icons/assets.svg';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';
import { ReactComponent as InventoryIcon } from 'icons/inventory.svg';
import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg';

const SideNavTile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const { step = 1, progress = 0 } = useSelector(
    ({ onboarding }) => onboarding,
  );

  const onSkipClick = () => {
    history.push(DASHBOARD_PATH);

    dispatch(setThemeStyles(white));
    dispatch(onboarding(step, true, 'Skipped Setup'));
  };

  const onNextClick = () => {
    if (step < ONBOARDING_STEPS) {
      let newProgress = 0;
      const progressStep = 16.6;
      const nextStep = step + 1;
      let caption = 'First Time Login';

      switch (step) {
        case 1:
          caption = 'Setup Overview';
          newProgress = progressStep;
          break;

        case 6:
          caption = 'Setup Progress';
          newProgress = 100;
          break;

        default:
          caption = 'Setup Progress';
          newProgress = parseFloat(progress) + progressStep;
          break;
      }

      dispatch(onboarding(nextStep, false, caption, parseInt(newProgress)));
    }

    if (step === ONBOARDING_STEPS - 1) {
      dispatch(setThemeStyles(white));
      history.push(CONGRATULATIONS_PATH);
    }
  };

  const NextButton = getNextButton('Continue', onNextClick);

  const onBackClick = () => {
    if (step > 1) {
      let newProgress = 0;
      const progressStep = 16.6;
      const nextStep = step - 1;
      let caption = 'First Time Login';

      switch (step) {
        case 2:
          caption = 'First Time Login';
          newProgress = 0;
          break;

        default:
          caption = 'Setup Progress';
          newProgress = parseFloat(progress) - progressStep;
          break;
      }

      dispatch(onboarding(nextStep, false, caption, parseInt(newProgress)));
    }
  };

  const BackButton = (
    <BackButtonWrapper>
      <RoundButton
        tabIndex={0}
        fontSize={12}
        caption="Back"
        flexWidth={100}
        foreColor={white}
        icon={<ArrowIcon />}
        backColor={secondary}
        onClick={onBackClick}
        iconFillColor={secondary}
      />
    </BackButtonWrapper>
  );

  // welcome
  if (step === 1) {
    return (
      <StyledTileContainer>
        <StyledTileWrapper>
          <StyledWelcomeHeader>Welcome to Wezza!</StyledWelcomeHeader>
          <StyledWelcomeBody>
            Let’s make sure your account is secure by changing the following
            information.
          </StyledWelcomeBody>
          <StyledWelcomeFooter>{NextButton}</StyledWelcomeFooter>
        </StyledTileWrapper>
      </StyledTileContainer>
    );
  }

  // overview
  if (step === 2) {
    const NextButton = getNextButton('Begin The Setup', onNextClick);

    return (
      <StyledTileContainer>
        <StyledTileWrapper>
          <StyledHello>Hello,</StyledHello>
          <StyledBodyText>{user?.first_name}</StyledBodyText>
          <StyledAvatar src={user?.photo || avatar} />
          <StyledWelcomeBody>
            We are glad to have you on board. Let’s get you set up and ready to
            go...
          </StyledWelcomeBody>
          <StyledWelcomeFooter>
            {NextButton}
            {BackButton}
            <StyledSkipButton onClick={onSkipClick}>
              Skip Setup
            </StyledSkipButton>
          </StyledWelcomeFooter>
        </StyledTileWrapper>
      </StyledTileContainer>
    );
  }

  // others
  if (step < ONBOARDING_STEPS) {
    const { icon: Icon, caption } = iconsMap[step];
    let SkipButton = null;

    if (step === 6) {
      SkipButton = (
        <StyledSkipButton onClick={onSkipClick}>
          Skip Asset Setup
        </StyledSkipButton>
      );
    }

    return (
      <StyledTileContainer>
        <StyledTileWrapper>
          <StyledIconWrapper>
            <Icon />
          </StyledIconWrapper>
          <StyledBodyText>{caption}</StyledBodyText>
          <StyledWelcomeFooter>
            {NextButton}
            {BackButton}
            {SkipButton}
          </StyledWelcomeFooter>
        </StyledTileWrapper>
      </StyledTileContainer>
    );
  }

  return null;
};

export default SideNavTile;

/**
  @param {string} caption
  @param {Function} onClick
 */
function getNextButton(caption, onClick) {
  return (
    <RoundButton
      tabIndex={0}
      fontSize={12}
      flexWidth={100}
      caption={caption}
      onClick={onClick}
      backColor={white}
      icon={<ArrowIcon />}
      foreColor={secondary}
      iconFillColor={secondary}
    />
  );
}

const iconsMap = {
  // [1]: Profile,
  // [2]: Overview,
  [3]: { icon: UserIcon, caption: 'Company & Users' },
  [4]: { icon: WarehouseIcon, caption: 'Inventory Warehouses & Customers' },
  [5]: { icon: InventoryIcon, caption: 'Categories, Conditions & Tags' },
  [6]: { icon: AssetsIcon, caption: 'Assets & People' },
  [7]: { icon: AssetsIcon, caption: 'Assets' },
};
