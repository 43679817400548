import { useCallback } from 'react';

/**
  @description Handles places changed custom event
  @param {Function} onChange
  @param {{
   current : {
   getPlaces : Function
   }
   }} searchBox
 */
function usePlacesChange(onChange, searchBox) {
  const onPlacesChanged = useCallback(() => {
    if (onChange) {
      onChange(searchBox.current.getPlaces());
    }
  }, [onChange, searchBox]);

  return [onPlacesChanged];
}

export default usePlacesChange;
