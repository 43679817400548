import { endPoints } from 'api/';
import { getTasks } from 'actions/task/';
import { DELETE_TASK, UPDATE_TASK } from 'actions/types';

/**
 * @description Updates task object. This will also act as `delete` action by `disabling` status.
 * @param {string} taskId
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns Task object
 */
function update(taskId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_TASK : UPDATE_TASK;

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const task = await apiClient(endPoints.UPDATE_TASK, payload, taskId);
        dispatch(getTasks({ companyId }));
        return task;
      },
    };
    dispatch(action);
  };
}

export default update;
