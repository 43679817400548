import { endPoints } from 'api/';
import { UPDATE_NOTIFICATION } from 'actions/types';
import { getNotifications } from 'actions/notification/';

/**
 * @description Updates notification object as read.
 * @param {[]} notifications
 * @param {string} companyId Current user's company id.
 * @returns Notification object
 */
function updateNotification(notifications, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_NOTIFICATION,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const notification = await apiClient(endPoints.UPDATE_NOTIFICATION, {
          notifications,
        });
        dispatch(getNotifications({ companyId }));
        return notification;
      },
    };
    dispatch(action);
  };
}

export default updateNotification;
