import { transformActions } from 'utils/';
import { GET_ORDERS } from 'actions/types';

const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Stores orders array details in redux state.
 * @param {[]} state
 * @param {{}} action
 */
function order(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_ORDERS];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');
  if (type === GET_ORDERS) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (success.includes(type)) {
    return {
      data: payload,
      isLoading: false,
    };
  }
  if (failure.includes(type)) {
    return {
      ...state,
      isLoading: false,
    };
  }
  return state;
}

export default order;
