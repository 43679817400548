import styled, { css } from 'styled-components';

import { maroon, primary, mediumGrey } from 'constants/color';

export const StyledTableRow = styled.tr`
  ${function ({ $sub }) {
    return (
      !$sub &&
      css`
        &:not(:first-child) {
          & td {
            border-top: 1px solid ${primary};
          }
        }
      `
    );
  }}
  &:last-child {
    & td {
      border-bottom: 1px solid ${primary};
    }
  }
`;

export const StyledRoundButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  color: white;
  display: flex;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${function ({ $minus }) {
    return $minus ? mediumGrey : primary;
  }};
`;

export const StyledQuantity = styled.label`
  min-width: 10px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  padding-left: 33px;
  text-align: center;
  font-weight: normal;
  &[aria-disabled='true'] {
    color: #a0a0a0;
    padding-left: 13px;
  }
`;

export const StyledQuantityWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRowButton = styled.button`
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 15px;
  color: ${primary};
  text-align: center;
  font-weight: normal;
  align-items: center;
  background-color: white;
  justify-content: center;
`;

export const StyledRoundIcon = styled.span`
  width: 20px;
  height: 20px;
  border: none;
  color: white;
  display: flex;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  margin-right: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: ${primary};
`;

export const StyledErrorMessage = styled.p`
  font-size: 12px;
  color: ${maroon};
  font-weight: 400;
  margin: 2px 0 0 0;

  ${function ({ $main }) {
    return (
      $main &&
      css`
        font-size: 14px;
        padding: 5px 10px;
        margin: 5px 0 10px 5px;
        background-color: #f8d7da;
      `
    );
  }}
`;

export const StyledWrapper = styled.div`
  padding-top: 10px;
`;

export const StyledDeleteButton = styled.button`
  width: 25px;
  border: none;
  height: 25px;
  color: #c4c4c4;
  cursor: pointer;
  background-color: white;
  &:hover {
    color: ${({ theme }) => theme.invalidColor};
  }
`;

export const StyledSubTableData = styled.td`
  height: 30px;
  font-size: 12px;
  color: ${primary};
  line-height: 18px;
  font-style: normal;
  padding-left: 20px;
  font-weight: normal;
  &[aria-disabled='true'] {
    color: #a0a0a0;
  }
  ${function ({ $last }) {
    return (
      !$last &&
      css`
        &:not(:first-child) {
          border-bottom: 1px solid ${mediumGrey};
        }
      `
    );
  }}
  ${function ({ $flex }) {
    return (
      $flex &&
      css`
        display: flex;
        padding-bottom: 2px;
        flex-direction: column;
        align-content: flex-start;
      `
    );
  }}
`;

export const StyledInput = styled.input`
  border: none;
  cursor: text;
  outline: none;
  font-size: 12px;
  color: ${primary};
  font-weight: normal;
  box-sizing: border-box;
  border-bottom: 1px solid ${mediumGrey};
  &[type='number'] {
    width: 30px;
    text-align: center;
  }
  &:disabled {
    color: #a0a0a0;
    background-color: transparent;
  }
  &:focus {
    border-bottom: 1px solid ${primary};
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
`;

export const StyledUnit = styled.span`
  font-size: 12px;
  line-height: 18px;
  padding-left: 5px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledCurrencyInput = styled.select`
  border: none;
  outline: none;
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${primary};
  line-height: 18px;
  padding-left: 5px;
  font-style: normal;
  font-weight: normal;
  border-bottom: 1px solid ${mediumGrey};
  &:disabled {
    color: #a0a0a0;
  }
  &:focus {
    border-bottom: 1px solid ${primary};
  }
`;

export const StyledOption = styled.option`
  padding: 5px;
  font-size: 12px;
  color: ${primary};
  background-color: white;
`;

export const StyledAttribute = styled.span`
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  ${function ({ $sku }) {
    return (
      $sku &&
      css`
        color: #a0a0a0;
        font-size: 11px;
        margin-top: -2px;
        font-style: italic;
      `
    );
  }}
`;
