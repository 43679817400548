import React from 'react';

import { Selector } from 'components/Table/';
import {
  StyledButton,
  StyledFooter,
  StyledWrapper,
  StyledPagination,
  StyledPaginationInfo,
} from 'components/Table/Footer/Footer.styled';

function Footer({
  pageSize,
  nextPage,
  pageIndex,
  pageCount,
  setPageSize,
  canNextPage,
  previousPage,
  setPageIndex,
  canPreviousPage,
}) {
  return (
    <StyledFooter>
      <Selector value={pageSize} onSetPageSize={setPageSize} />
      <StyledWrapper>
        <StyledPaginationInfo>
          Showing Page {pageIndex + 1} of {pageCount}
        </StyledPaginationInfo>
      </StyledWrapper>
      <StyledPagination>
        <StyledButton
          disabled={!canPreviousPage}
          onClick={setPageIndex.bind(null, 0)}
        >
          &lt;&lt;
        </StyledButton>
        <StyledButton onClick={previousPage} disabled={!canPreviousPage}>
          &lt;
        </StyledButton>
        <StyledButton onClick={nextPage} disabled={!canNextPage}>
          &gt;
        </StyledButton>
        <StyledButton
          disabled={!canNextPage}
          onClick={setPageIndex.bind(null, pageCount - 1)}
        >
          &gt;&gt;
        </StyledButton>
      </StyledPagination>
    </StyledFooter>
  );
}

export default Footer;
