import { setTokenCookie } from 'utils/';
import menuList from 'constants/menuList';
import { AUTHENTICATE_USER } from 'actions/types';
import { ACCOUNT_SETUP_PATH } from 'constants/general';

/**
  Authentication middleware. 
 */
function authMiddleware() {
  /**
    @param {Function} next
   */
  return (next) => {
    /**
       @param {{
       request: Function
       }} action
     */
    return async (action) => {
      const { type, payload } = action;
      const targetActions = [`${AUTHENTICATE_USER}_SUCCESS`];

      if (targetActions.includes(type)) {
        const {
          token,
          company_id: companyId,
          onboard_user: onboardUser,
        } = payload;
        if (token) {
          // Set token for authenticated user
          setTokenCookie({
            token,
            companyId,
          });

          // If `admin` user is logging in for first time, onboard!
          if (typeof onboardUser === 'boolean' && onboardUser) {
            window.location.assign(ACCOUNT_SETUP_PATH);
          } else {
            window.location.assign(menuList[0].path); // '/dashboard' component to be rendered after authentication}
          }
        }
      }

      return next(action);
    };
  };
}

export default authMiddleware;
