import { css, createGlobalStyle } from 'styled-components';

import { LOGIN_PATH, PASSWORD_RESET_PATH } from 'constants/general';

const fullScreen = [LOGIN_PATH, PASSWORD_RESET_PATH].includes(
  window.location.pathname,
);

export const GlobalStyles = createGlobalStyle`
  * {
    font-size: 15px;
    font-weight: 600;
    font-family: 'Poppins';
  }

  html,
  body {
    color: ${({ theme }) => theme.textColor};
    background: ${({ theme }) => theme.bodyBackground};
    --boxPaddingTop: ${({ theme }) => theme.boxPaddingTop};
    --boxPaddingSides: ${({ theme }) => theme.boxPaddingSides};
    
    font-family: 'Poppins';
    transition: all 0.50s linear;
    -webkit-font-smoothing: antialiased;
    margin: var(--boxPaddingTop) var(--boxPaddingSides);

    /* Mobile Devices */
    @media (max-width: 480px) {
      margin: 1px;
    }

    /* Portrait Tablets and Ipads */
    @media (min-width: 768px) and (max-width: 1024px) {
      margin: 1px;
    }

    /* Remove margin and padding in login page for the background image to cover and animated git logo to use full screen */
    ${
      fullScreen &&
      css`
        margin: 0;
        padding: 0;
      `
    }

  }

  button.back-to-login svg {
  transform: rotate(180deg);
}
  
  body::-webkit-scrollbar {
    width: 0.40rem;
  }
  
  body::-webkit-scrollbar-track {
    background: #1e1e24;
  }
  
  body::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.primaryColor};
  }
  
  /* CSSTransition classes  */

  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }

  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all ${({ theme }) => theme.transitionSpeed} ease;
  }

  .menu-primary-exit {
    position: absolute;
  }

  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all ${({ theme }) => theme.transitionSpeed} ease;
  }

  .menu-secondary-enter {
    transform: translateX(110%);
  }

  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all ${({ theme }) => theme.transitionSpeed} ease;
  }

  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all ${({ theme }) => theme.transitionSpeed} ease;
  }

  `;
