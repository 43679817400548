import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

export const StyledFormGroup = styled.div`
  --whiteColor: ${({ theme }) => theme.whiteColor};
  --inputBorder: ${({ theme }) => theme.inputBorder};
  --primaryColor: ${({ theme }) => theme.primaryColor};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};

  margin-bottom: ${({ $marginBottom }) => $marginBottom}px;

  & .react-datepicker-wrapper {
    display: block !important;
  }

  & .react-datepicker {
    border: var(--inputBorder);
  }

  & .react-datepicker__month-container {
    color: var(--primaryColor);
  }

  & .react-datepicker__navigation {
    border-color: var(--primaryColor);
  }

  & .react-datepicker__day {
    color: var(--primaryColor);
  }

  & .react-datepicker__day--selected {
    color: var(--whiteColor);
    background-color: var(--primaryColor);
  }

  & .react-datepicker__day--keyboard-selected {
    color: var(--whiteColor);
    background-color: var(--primaryColor);
  }

  & .react-datepicker__current-month {
    color: var(--primaryColor);
  }

  & .react-datepicker__day-name {
    color: var(--primaryColor);
  }

  & .react-datepicker__year-dropdown {
    border-color: var(--primaryColor);
    border-radius: 0;

    &::-webkit-scrollbar {
      width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
      background: var(--primaryColor);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--whiteColor);
    }
  }

  &.react-datepicker__year-option {
    padding: 1px 0;

    & :hover {
      background: var(--whiteColor);
    }
  }

  & .react-datepicker__header {
    border-bottom: var(--inputBorder);
    background-color: var(--whiteColor);
  }
`;

export const StyledLabel = styled.label`
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledDatePicker = styled(DatePicker)`
  --inputBorder: ${({ theme }) => theme.inputBorder};
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};
  --focusedInputColor: ${({ theme }) => theme.focusedInputColor};

  width: 100%;
  height: 35px;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  border: var(--inputBorder);
  color: ${({ theme }) => theme.textColor};
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};
  padding-left: ${({ $hasInputIcon }) => ($hasInputIcon ? '35px' : '12px')};

  &:disabled {
    ${({ theme }) => theme.disabled}
  }

  ${function ({ isInvalid }) {
    return (
      isInvalid &&
      css`
        color: var(--invalidColor);
        border: 1px solid var(--invalidColor);
      `
    );
  }}

  &:focus {
    ${function ({ isInvalid }) {
      return isInvalid
        ? ({ theme }) => theme.invalidColor
        : ({ theme }) => theme.secondaryColor;
    }};
    outline: 0;
    box-shadow: none;
    border: 1px solid;
    color: var(--focusedInputColor);
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }
`;

export const StyledInputIcon = styled.span`
  --invalidColor: ${({ theme }) => theme.invalidColor};

  z-index: 0;
  width: 34px;
  right: 15px;
  height: 34px;
  display: block;
  cursor: pointer;
  padding-left: 2px;
  line-height: 34px;
  position: absolute;
  text-align: center;
  top: ${({ top }) => top}px;

  svg {
    color: ${({ theme }) => theme.textColor};
    width: 30px;
    height: 30px;

    ${function ({ isInvalid }) {
      return (
        isInvalid &&
        css`
          color: var(--invalidColor);
        `
      );
    }}

    ${function ({ $disabled }) {
      return (
        $disabled &&
        css`
          color: #d0d0d0;
        `
      );
    }}
  }
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const InputWrapper = styled.label``;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;
