import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Router, useLocation } from 'react-router-dom';

import {
  LOGIN_PATH,
  LOCK_SCREEN_PATH,
  PASSWORD_RESET_PATH,
  ACCOUNT_SIGNUP_PATH,
} from 'constants/general';
import { appTheme } from 'theme';
import { history } from 'store/store';
import { Loader } from 'components/Loaders/';
import SignIn from 'containers/SignIn/SignIn';
import SignUp from 'containers/SignUp/SignUp';
import { GlobalStyles } from 'theme/globalStyles';
import Container from 'containers/Container/Container';
import LockScreen from 'containers/LockScreen/LockScreen';
import RootModal from 'containers/Modals/RootModal/RootModal';
import { clearTokenCookie, getTokenFromCookie } from 'utils/';

function App() {
  const unGuardedRoutes = [
    LOCK_SCREEN_PATH,
    ACCOUNT_SIGNUP_PATH,
    PASSWORD_RESET_PATH,
  ];
  const routerLocation = useLocation();
  const isLoggedOut = useSelector(({ isLoggedOut }) => isLoggedOut);
  const accessToken = getTokenFromCookie();
  const { pathname: pathName } = routerLocation;
  const signUpRoute = pathName === ACCOUNT_SIGNUP_PATH;
  const lockScreenRoute = pathName === LOCK_SCREEN_PATH;
  const unAuthenticated = !accessToken || isLoggedOut;
  const passwordResetRoute = pathName === PASSWORD_RESET_PATH;

  useEffect(() => {
    const shouldClearToken =
      unAuthenticated && !unGuardedRoutes.includes(pathName);
    if (shouldClearToken) {
      clearTokenCookie();
    }
  }, [pathName, unAuthenticated, unGuardedRoutes]);

  return (
    <Router history={history}>
      <ThemeProvider theme={appTheme}>
        <GlobalStyles />
        <RootModal />
        <Suspense fallback={<Loader />}>
          <Switch>
            <RenderElements />
          </Switch>
        </Suspense>
      </ThemeProvider>
    </Router>
  );

  function RenderElements() {
    if (signUpRoute) {
      return (
        <Route
          exact={true}
          name="signup"
          path={ACCOUNT_SIGNUP_PATH}
          render={(props) => <SignUp {...props} />}
        />
      );
    }
    if (lockScreenRoute) {
      return (
        <Route
          name="lock"
          exact={true}
          path={LOCK_SCREEN_PATH}
          render={(props) => <LockScreen {...props} />}
        />
      );
    }
    if (passwordResetRoute) {
      return (
        <Route
          exact={true}
          name="password-reset"
          path={PASSWORD_RESET_PATH}
          render={(props) => <SignIn {...props} target="password-reset" />}
        />
      );
    }
    if (unAuthenticated) {
      return (
        <Route
          name="login"
          exact={true}
          path={LOGIN_PATH}
          render={(props) => <SignIn {...props} target="sign-in" />}
        />
      );
    }
    return <Container />;
  }
}

export default App;
