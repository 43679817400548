import styled from 'styled-components';

export const StyledLogo = styled.img`
  width: 316px;
  height: 114px;
`;

export const StyledInputWrapper = styled.div``;

export const StyledCaption = styled.h1`
  color: #097f98;
  margin-top: 0px;
  font-weight: 600;
  text-align: center;
  line-height: 37.5px;
  font-size: ${function ({ $fontSize }) {
    return $fontSize || '25px';
  }};
  margin-bottom: ${function ({ $marginBottom }) {
    return $marginBottom || '0px';
  }};
`;

export const StyledInstructions = styled.p`
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  line-height: 22.5px;
`;

export const StyledMessage = styled.p`
  color: #29becd;
  font-size: 15px;
  margin-top: 0px;
  font-weight: 400;
  padding: 5px 0px;
  text-align: center;
  margin-bottom: 5px;
  line-height: 22.5px;
  background-color: #e7ffec;
`;

export const StyledIcon = styled.span`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  svg {
    width: 110px;
    fill: #54cc7c;
    height: 110px;
  }
`;
