import React from 'react';

import packageInfo from '../../../package.json';
import { StyledLink, StyledCopyright, StyledVersion } from './Footer.styled';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <StyledLink href="https://henga.co/">Privacy Policy</StyledLink>
      <StyledLink href="https://henga.co/">Terms & Condition</StyledLink>
      <StyledVersion>Version {packageInfo.version}</StyledVersion>
      <StyledCopyright>
        Copyright {currentYear}. All Rights Reserved. Henga Systems Limited
      </StyledCopyright>
    </>
  );
};

export default Footer;
