import { ONBOARDING_PROGRESS } from 'actions/types';

const initialState = {
  step: 1,
  skip: false,
  progress: 0,
  caption: 'First Time Login',
};

/**
  @description Stores onboarding steps in redux state
  @param {{}} state
  @param {{}} action
  @returns {{}}
 */
function onboarding(state = initialState, action) {
  const { type: actionType, payload } = action;

  if (actionType === ONBOARDING_PROGRESS) {
    return payload;
  }

  return state;
}

export default onboarding;
