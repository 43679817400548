import { node } from 'prop-types';
import React, { Component } from 'react';

import {
  Wrapper,
  StyledButton,
  StyledHeader,
  StyledDescription,
} from 'components/ErrorBoundary/ErrorBoundary.styled';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // TODO : Log error messages to an error reporting service here ?
    console.error(error, errorInfo);
  }

  refreshWindow() {
    window.location.reload();
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Wrapper>
          <StyledHeader>Something Went Wrong :(</StyledHeader>
          <StyledDescription>
            There was a problem loading this page. Please try a browser refresh
            and if the problem persists, reach out to client support.
          </StyledDescription>
          <StyledButton onClick={this.refreshWindow}>Refresh</StyledButton>
        </Wrapper>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node,
};

export default ErrorBoundary;
