import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

const backColor = '#f5f5f5';

export const StyledWrapper = styled.div`
  margin-top: 5px;
  position: relative;
`;

export const StyledInput = styled(motion.input)`
  --inputBorder: ${({ theme }) => theme.inputBorder};
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};
  --inputBackColor: ${({ theme }) => theme.inputBackColor};
  --focusedInputColor: ${({ theme }) => theme.focusedInputColor};

  width: 100%;
  border: none;
  height: 35px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0;
  box-shadow: none;
  appearance: none;
  padding-left: 5px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  color: ${({ foreColor }) => foreColor};
  text-decoration: ${function ({ isComplete }) {
    return isComplete ? 'line-through' : 'none';
  }};
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${function ({ isEditing }) {
    return !isEditing ? backColor : 'var(--inputBackColor)';
  }};
  padding-right: ${({ $hasInputIcon }) => ($hasInputIcon ? '35px' : '12px')};

  ${function ({ $isInvalid }) {
    return (
      $isInvalid &&
      css`
        border: 1px solid var(--inputBorder);
      `
    );
  }}

  ${function ({ isEditing }) {
    return (
      isEditing &&
      css`
        border: var(--inputBorder);
      `
    );
  }}

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
    color: var(--focusedInputColor);

    ${function ({ $isInvalid }) {
      return $isInvalid
        ? ({ theme }) => theme.invalidColor
        : ({ theme }) => theme.secondaryColor;
    }};
  }

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

export const StyledButton = styled(motion.button)`
  --invalidColor: ${({ theme }) => theme.invalidColor};
  --inputBackColor: ${({ theme }) => theme.inputBackColor};

  z-index: 0;
  height: 31px;
  bottom: 2px;
  cursor: pointer;
  box-shadow: none;
  box-shadow: none;
  margin-bottom: 0;
  padding: 2px 12px;
  user-select: none;
  line-height: 34px;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  -moz-box-shadow: none;
  -ms-user-select: none;
  -moz-user-select: none;
  background-image: none;
  vertical-align: middle;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 1px solid transparent;
  -ms-touch-action: manipulation;
  right: ${({ right }) => right}px;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${function ({ isEditing }) {
    return !isEditing ? backColor : 'var(--inputBackColor)';
  }};

  ${function ({ isInvalid }) {
    return (
      isInvalid &&
      css`
        background-color: var(--invalidColor);
      `
    );
  }}

  svg {
    width: 23px;
    height: 23px;
    vertical-align: text-middle;
    fill: ${({ theme }) => theme.primaryColor};
  }
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
