const permission = [
  {
    value: 'admin',
    label: 'admin',
  },
  {
    value: 'guest',
    label: 'guest',
  },
  {
    value: 'employee',
    label: 'employee',
  },
];

export default permission;
