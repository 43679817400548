import styled from 'styled-components';

import RobotImage from 'img/login.jpg';

const WIDTH = '360px';

export const SignInContainer = styled.section`
  height: 100vh;
  background: rgba(56, 174, 194, 0.39);
  background-image: url(${RobotImage});
  background-repeat: no-repeat;
  background-size: cover;

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin: -2px;
    height: 100vh;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin: -2px;
    height: 100vh;
  }
`;

export const StyledContainer = styled.div`
  width: calc(${WIDTH} - 10px);
  margin: auto;
  padding-top: 15%;
  padding-left: 60%;

  /* Mobile Devices */
  @media (max-width: 480px) {
    padding-left: 0;
    padding-top: 20%;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-left: 0;
    width: ${WIDTH};
    padding-top: 40%;
  }
`;

export const StyledWrapper = styled.div`
  padding: 20px;
  border-top: 0;
  color: ${({ theme }) => theme.textColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.wrapperBackColor};
`;

export const StyledAlert = styled.label`
  padding: 10px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  max-width: ${WIDTH};
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.wrapperBackColor};
`;
