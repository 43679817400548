import styled from 'styled-components';

export const StyledNavItem = styled.li`
  width: 100%;
  cursor: pointer;
  margin-bottom: 0.1rem;

  button : {
    cursor: pointer;
  }

  /* Small screens */
  @media only screen and (max-width: 600px) {
    margin-bottom: 0;
  }
`;
