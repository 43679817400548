import styled from 'styled-components';

import { white } from 'constants/color';

export const StyledWrapper = styled.form`
  width: auto;
  display: flex;
  padding-top: 10px;
  position: relative;
  margin-bottom: 10px;
  justify-content: center;
  background-color: ${white};
  margin-top: ${({ $marginTop }) => $marginTop}px;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-right: ${({ $marginRight }) => $marginRight}px;
  border: 1px solid ${({ theme }) => theme.primaryColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    padding: 10px;
    margin-left: 5px;
    margin-right: 10px;
    flex-direction: column;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 0;
  }

  &:before {
    top: -10px;
    left: 10px;
    padding: 0 5px;
    font-size: 14px;
    background: white;
    position: absolute;
    content: 'Filter by date';
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const StyledInputWrapper = styled.div`
  width: 20%;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    padding-left: 0;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 30%;
  }
`;
