import { array, func, object } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  TextInput,
  CreaTable,
  PhotoInput,
  SelectInput,
} from 'components/Inputs/';
import currencyList from 'constants/currencies';
import unitsList from 'constants/quantityUnits';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { StyledWrapper } from 'components/Company/Form/Form.styled';
import { ReactComponent as EditIcon } from 'icons/pencil_circle.svg';
import { capitalizeFirstLetter, parseJSON, validateEmail } from 'utils/';

/**
 * @param {{
 *  currencies : []
 *  initialValue : {}
 *  onSubmit : Function
 *  onModalClose : Function
 * }} param
 */
function Form({
  onSubmit,
  initialValue,
  onModalClose,
  currencies = currencyList,
}) {
  const {
    photo,
    name = '',
    country = '',
    id: companyId,
    contact_details: contactDetails,
    other_details: otherDetails = null,
  } = initialValue || {};
  const contactsObj = parseJSON(contactDetails);
  const { email = '', phone = '' } = contactsObj || {};
  const {
    tax_number: taxNumber = '',
    vat_amount: vatAmount = '',
    price_units: priceUnits = [],
    quantity_units: quantityUnits = [],
  } = otherDetails || {};
  const [currency] = priceUnits;
  const currentQuantityUnits = quantityUnits?.map(function (unit) {
    return {
      label: unit,
      value: unit,
    };
  });
  const currentCurrency = currencies.find(function ({ value }) {
    return value === currency;
  });
  const [companyState, setCompanyState] = useState({
    currency,
    taxNumber,
    vatAmount,
    quantityUnits,
    name: unescape(name),
    email: unescape(email),
    phone: unescape(phone),
    address: unescape(country),
  });
  const [companyErrors, setCompanyErrors] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    vatAmount: '',
    quantityUnits: '',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(photo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedQuantityUnits, setSelectedQuantityUnits] =
    useState(currentQuantityUnits);
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrency);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setCompanyState({
      ...companyState,
      [name]: value,
    });
  }

  function onSubmitCompany() {
    setIsSubmitting(true);
    const isFormValid = validateCompanyForm();
    if (isFormValid) {
      const formData = new FormData();
      const {
        name,
        phone,
        email,
        address,
        currency,
        taxNumber,
        vatAmount,
        quantityUnits,
      } = companyState;
      const contactDetails = {
        phone,
        email,
      };
      const otherDetailsObj = {
        ...otherDetails,
        tax_number: taxNumber,
        vat_amount: vatAmount,
        price_units: [currency],
        quantity_units: quantityUnits,
      };
      if (companyLogo) {
        formData.append('photo', companyLogo);
      }
      formData.append('name', name);
      formData.append('id', companyId);
      formData.append('country', address);
      formData.append('other_details', JSON.stringify(otherDetailsObj));
      formData.append('contact_details', JSON.stringify(contactDetails));
      onSubmit(formData, companyId);
    }
  }

  function validateCompanyForm() {
    let isFormValid = true;
    let errorObject = { ...companyErrors };
    const optionalFields = ['taxNumber'];
    for (const [key, value] of Object.entries(companyState)) {
      if (!optionalFields.includes(key) && !value?.length) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setCompanyErrors(errorObject);
    return isFormValid;
  }

  /**
   * @param {{
   *  value: string
   *  }} selectedOption
   */
  function onCurrencyChange(selectedOption) {
    let currency = null;
    setSelectedCurrency(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      currency = value;
    }
    setCompanyState({
      ...companyState,
      currency,
    });
  }

  function onEditToggle() {
    setIsEditing(!isEditing);
  }

  /**
   * @param {[{
   *  value: string
   *  label: string
   *  }]} selectedOption
   */
  function onQuantityUnitChange(selectedOption) {
    let quantityUnits = null;
    setSelectedQuantityUnits(selectedOption);
    if (selectedOption) {
      quantityUnits = selectedOption?.map(function ({ value }) {
        return value;
      });
    }
    setCompanyState({
      ...companyState,
      quantityUnits,
    });
  }

  useEffect(() => {
    if (isSubmitting) {
      validateCompanyForm();
    }
  }, [companyState, isSubmitting]);

  return (
    <StyledWrapper>
      <FlexRow marginBottom={20}>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                inputType="text"
                disabled={!isEditing}
                caption="Company Name"
                onChange={onInputChange}
                placeholder="Enter name"
                value={companyState.name}
                errorMessage={companyErrors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="email"
                inputId="email"
                inputType="text"
                disabled={!isEditing}
                caption="Company Email"
                onChange={onInputChange}
                placeholder="Enter email"
                value={companyState.email}
                errorMessage={companyErrors.email}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                inputType="text"
                disabled={!isEditing}
                caption="Company Phone"
                onChange={onInputChange}
                value={companyState.phone}
                placeholder="e.g +254712345678"
                errorMessage={companyErrors.phone}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="address"
                inputType="text"
                inputId="address"
                disabled={!isEditing}
                onChange={onInputChange}
                caption="Company Address"
                value={companyState.address}
                errorMessage={companyErrors.address}
                placeholder="e.g 123, XYZ Street, City, Country"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                inputType="text"
                name="taxNumber"
                errorMessage=""
                inputId="taxNumber"
                disabled={!isEditing}
                onChange={onInputChange}
                placeholder="Enter tax number"
                value={companyState.taxNumber}
                caption="Company Tax Number (optional)"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                caption="VAT %"
                name="vatAmount"
                inputType="number"
                inputId="vatAmount"
                disabled={!isEditing}
                placeholder="Enter VAT"
                onChange={onInputChange}
                value={companyState.vatAmount}
                errorMessage={companyErrors.vatAmount}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="currency"
                isLoading={false}
                caption="Currency"
                isClearable={true}
                isSearchable={true}
                options={currencies}
                disabled={!isEditing}
                value={selectedCurrency}
                ariaLabelledBy="currency"
                onChange={onCurrencyChange}
                placeholder="Select Currency"
                errorMessage={companyErrors.currency}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <CreaTable
                isRequired
                name="units"
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                options={unitsList}
                disabled={!isEditing}
                ariaLabelledBy="units"
                caption="Quantity Units"
                value={selectedQuantityUnits}
                onChange={onQuantityUnitChange}
                noOptionsMessage="Enter Quantity Units"
                errorMessage={companyErrors.quantityUnits}
                placeholder="Select Preexisting or Enter Quantity Units e.g cartons or bales"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="companyLogo"
                caption="Company Logo"
                imageUri={companyLogo}
                dataTestId="companyLogo"
                onChange={setCompanyLogo}
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Edit"
          icon={<EditIcon />}
          onClick={onEditToggle}
          className="edit-button"
        />
        <RoundButton
          caption="Save"
          icon={<ArrowIcon />}
          className="save-button"
          onClick={onSubmitCompany}
        />
        {onModalClose ? <CancelButton onClick={onModalClose} /> : null}
      </FlexRow>
    </StyledWrapper>
  );
}

Form.defaultProps = {
  initialValue: {},
};

Form.propTypes = {
  onSubmit: func,
  currencies: array,
  onModalClose: func,
  initialValue: object,
};

export default Form;
