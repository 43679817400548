import React, { useState } from 'react';

import {
  StyledButton,
  StyledOption,
  StyledContent,
  StyleOptionButton,
  DropDownContainer,
} from 'components/Buttons/DropDown/DropDown.styled';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';

function DropDown({
  options,
  caption,
  fontSize,
  backColor,
  flexWidth,
  foreColor,
  onCallBack,
  borderColor,
  marginLeft = 20,
  marginRight = 20,
  buttonStyle = {},
  exportButton = null,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function onOptionSelect(option) {
    setIsOpen(false);
    if (onCallBack) {
      onCallBack(option);
    }
  }

  function onToggleDropDown() {
    setIsOpen(!isOpen);
  }

  return (
    <DropDownContainer
      $flexWidth={flexWidth}
      $marginReft={marginLeft}
      $marginRight={marginRight}
      $borderColor={borderColor}
      className="dropdown-button"
    >
      <StyledButton
        $open={isOpen}
        style={buttonStyle}
        $fontSize={fontSize}
        $backColor={backColor}
        $foreColor={foreColor}
        onClick={onToggleDropDown}
      >
        {caption}
        <ChevronIcon />
      </StyledButton>
      <StyledContent $open={isOpen}>
        {exportButton}
        {options.map(function ({ value, label, disabled = false }) {
          return (
            <StyledOption key={value}>
              <StyleOptionButton
                disabled={disabled}
                onClick={onOptionSelect.bind(null, value)}
              >
                {label}
              </StyleOptionButton>
            </StyledOption>
          );
        })}
      </StyledContent>
    </DropDownContainer>
  );
}

export default DropDown;
