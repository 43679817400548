import { useMemo } from 'react';

/**
 * Receives array of object and transform it to array of select options,
 * value will always be id of object and label will be user defined.
 * @param {{
 *   label : string
 *   items : [{
 *      id : string
 *      [key : string] : string
 *  }]
 *}} param
 */
function useSelectOption({ items, label = 'name' }) {
  const options = useMemo(
    function () {
      return items.map(function (item) {
        return {
          value: item.id,
          label: String(item[label]),
        };
      });
    },
    [items],
  );

  return options;
}

export default useSelectOption;
