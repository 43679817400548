import { array, func, object } from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';

import { assigned } from 'constants/status';
import { capitalizeFirstLetter } from 'utils/';
import { FlexCol, FlexRow } from 'components/Layout/';
import DatePicker from 'components/DatePicker/DatePicker';
import { TextArea, SelectInput } from 'components/Inputs/';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { CancelButton, RoundButton } from 'components/Buttons/';

/**
  @param {{
   users: []
   assets: []
   initialValue: {}
   onSubmit: Function
   onModalClose: Function
   }}
 */
const AssetForm = ({ onSubmit, users, initialValue, onModalClose, assets }) => {
  const {
    notes,
    user_id: assignee,
    asset_id: assetId,
    date_assigned: issueDate,
    date_returned: returnDate,
  } = initialValue || {};

  const terms = notes ? unescape(notes) : '';
  const isEditing = initialValue?.hasOwnProperty('id');
  let issueDateVal = null;
  let returnDateVal = null;

  if (isEditing) {
    if (issueDate) {
      issueDateVal = new Date().setTime(issueDate);
    }

    if (returnDate) {
      returnDateVal = new Date().setTime(returnDate);
    }
  }

  const [assetState, setAssetState] = useState({
    terms,
    assignee,
    asset: assetId,
    issueDate: issueDateVal,
    returnDate: returnDateVal,
  });
  const [assetErrors, setAssetErrors] = useState({
    terms: '',
    asset: '',
    assignee: '',
    issueDate: '',
    returnDate: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  /**
    @param {{
     value : string
     }} selectedOption
   */
  const onAssigneeChange = (selectedOption) => {
    let assignee = null;
    setSelectedOption(selectedOption);

    if (selectedOption) {
      const { value } = selectedOption;
      assignee = value;
    }

    setAssetState({
      ...assetState,
      assignee,
    });
  };

  /**
    @param {{
     value : string
     }} selectedOption
   */
  const onAssetChange = (selectedOption) => {
    let asset = null;
    setSelectedAsset(selectedOption);

    if (selectedOption) {
      const { value } = selectedOption;
      asset = value;
    }

    setAssetState({
      ...assetState,
      asset,
    });
  };

  /**
    @param {{
     currentTarget: {
     name: string
     value: string
     }
     }} event
   */
  const onInputChange = (event) => {
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    setAssetState({
      ...assetState,
      [name]: value,
    });
  };

  /**
    @param {string} date
   */
  const onReturnDateChange = (date) => {
    setAssetState({
      ...assetState,
      returnDate: date,
    });
  };

  /**
    @param {string} date
   */
  const onIssueDateChange = (date) => {
    setAssetState({
      ...assetState,
      issueDate: date,
    });
  };

  const onSubmitAsset = () => {
    setIsSubmitting(true);
    const isValidForm = validateAssetForm();

    if (isValidForm) {
      const { returnDate, issueDate, terms, assignee, asset } = assetState;

      const assetObj = {
        notes: terms,
        asset_id: asset,
        status: assigned,
        user_id: assignee,
        date_assigned: new Date(issueDate).getTime(),
        date_returned: new Date(returnDate).getTime(),
      };

      onSubmit(assetObj);
    }
  };

  const validateAssetForm = () => {
    let isFormValid = true;
    const ignoredFields = ['terms'];
    let errorObject = { ...assetErrors };

    for (const [key, value] of Object.entries(assetState)) {
      const shouldValidate = !ignoredFields.includes(key);

      if (!value && shouldValidate) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }

    setAssetErrors(errorObject);

    return isFormValid;
  };

  useEffect(() => {
    if (isSubmitting) {
      validateAssetForm();
    }
  }, [assetState, isSubmitting]);

  const assignees = useMemo(() => {
    return users?.map(({ id, name }) => ({ value: id, label: name }));
  }, [users]);

  useEffect(() => {
    if (assignee) {
      const user = users?.find(({ id }) => id === assignee);

      if (user) {
        const { id, name } = user;

        const assigned = {
          value: id,
          label: name,
        };

        onAssigneeChange(assigned);
      }
    }
  }, [assignee]);

  const transformedAssets = useMemo(() => {
    return assets?.map(({ id, item_name: name }) => ({
      value: id,
      label: name,
    }));
  }, [assets]);

  useEffect(() => {
    if (assets) {
      const item = assets?.find(({ id }) => id === assetId);

      if (item) {
        const { id, item_name: name } = item;

        const asset = {
          value: id,
          label: name,
        };

        onAssetChange(asset);
      }
    }
  }, [assets]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={5}>
          <SelectInput
            isRequired
            name="assignee"
            isLoading={false}
            isClearable={true}
            options={assignees}
            isSearchable={true}
            value={selectedOption}
            caption="Assignee Name"
            ariaLabelledBy="assignee"
            onChange={onAssigneeChange}
            placeholder="Select assignee"
            errorMessage={assetErrors.assignee}
          />
        </FlexCol>
        <FlexCol flexWidth={3}>
          <DatePicker
            isRequired
            name="issueDate"
            inputId="issueDate"
            caption="Issue Date"
            onChange={onIssueDateChange}
            placeholder="Enter issue date"
            selected={assetState.issueDate}
            errorMessage={assetErrors.issueDate}
          />
        </FlexCol>
        <FlexCol flexWidth={3}>
          <DatePicker
            isRequired
            name="returnDate"
            inputId="returnDate"
            caption="Return Date"
            onChange={onReturnDateChange}
            placeholder="Enter return date"
            selected={assetState.returnDate}
            errorMessage={assetErrors.returnDate}
          />
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={5}>
          <SelectInput
            isRequired
            name="asset"
            isLoading={false}
            isClearable={true}
            isSearchable={true}
            caption="Asset Name"
            value={selectedAsset}
            ariaLabelledBy="asset"
            onChange={onAssetChange}
            placeholder="Select asset"
            options={transformedAssets}
            errorMessage={assetErrors.asset}
          />
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <TextArea
            name="terms"
            height={150}
            inputId="terms"
            onChange={onInputChange}
            value={assetState.terms}
            caption="Terms & Conditions"
            errorMessage={assetErrors.terms}
            placeholder="Enter terms and conditions"
          />
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          icon={<TickIcon />}
          onClick={onSubmitAsset}
          className="modal-button"
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
};

AssetForm.propTypes = {
  users: array,
  assets: array,
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default AssetForm;
