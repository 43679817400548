import { GET_USER_FROM_SESSION, LOG_OUT_USER } from 'actions/types';

const initialState = false;

/**
  @description Tracks user login status.
   If user is to be logged out, it `dispatches action`  to `store` which `clears` user token.
  @param {Boolean} state
  @param {{}} action
  @returns Boolean
 */
function isLoggedOut(state = initialState, action) {
  const { type } = action;

  if (type === LOG_OUT_USER) {
    return true;
  }

  if (type === `${GET_USER_FROM_SESSION}_FAILURE`) {
    return true;
  }

  return state;
}

export default isLoggedOut;
