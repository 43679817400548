import { endPoints } from 'api/';
import { CREATE_WAREHOUSE } from 'actions/types';
import { getWarehouses } from 'actions/warehouses/';

/**
  @description Creates warehouse object
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @returns Warehouse object
 */
function createWarehouse(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_WAREHOUSE,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const warehouse = await apiClient(endPoints.CREATE_WAREHOUSE, payload);

        dispatch(getWarehouses({ companyId }));

        return warehouse;
      },
    };

    dispatch(action);
  };
}

export default createWarehouse;
