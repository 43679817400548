import {
  CREATE_TAG,
  UPDATE_TAG,
  DELETE_TAG,
  SHOW_NOTIFY,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  CREATE_ITEM,
  UPLOAD_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  EMAIL_ORDER,
  PRINT_ORDER,
  CREATE_ORDER,
  CREATE_ADMIN,
  CLEAR_NOTIFY,
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET,
  RETURN_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
  ASSIGN_ASSET,
  CREATE_PEOPLE,
  UPDATE_PEOPLE,
  DELETE_PEOPLE,
  CREATE_ACCOUNT,
  UPDATE_COMPANY,
  CREATE_STORAGE,
  UPDATE_STORAGE,
  DELETE_STORAGE,
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CREATE_WAREHOUSE,
  UPDATE_WAREHOUSE,
  DELETE_WAREHOUSE,
  CREATE_CONDITION,
  UPDATE_CONDITION,
  DELETE_CONDITION,
  CREATE_ATTRIBUTE,
  UPDATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  UPDATE_BULK_ITEM,
  UPDATE_BULK_ORDER,
  UPDATE_NOTIFICATION,
  UPDATE_ASSIGNED_ASSET,
} from 'actions/types';
import {
  interpretAction,
  transformActions,
  parseResponseError,
  capitalizeFirstLetter,
} from 'utils/';
import { INFO, ERROR, SUCCESS, WARNING } from 'constants/notify';

const initialState = {
  error: '',
  action: '',
  message: '',
  processing: false,
};

/**
  @description Stores current message in alert panel in redux state
  @param {{}} state
  @param {{
   type : string
   payload : {
     info : string
     status: number
     error : string
     message : string
   }
  }} action
  @returns {{}}
 */
function notify(state = initialState, action) {
  const { type: actionType, payload } = action;

  const actions = [
    CREATE_TAG,
    UPDATE_TAG,
    DELETE_TAG,
    CREATE_ITEM,
    UPLOAD_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
    CREATE_USER,
    UPDATE_USER,
    DELETE_USER,
    CREATE_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    EMAIL_ORDER,
    PRINT_ORDER,
    CREATE_ASSET,
    UPDATE_ASSET,
    DELETE_ASSET,
    RETURN_ORDER,
    CREATE_ORDER,
    UPDATE_ORDER,
    DELETE_ORDER /* @deprecated This will be deprecated in favor of `close` order. */,
    ASSIGN_ASSET,
    CREATE_ADMIN,
    CREATE_PEOPLE,
    UPDATE_PEOPLE,
    DELETE_PEOPLE,
    CREATE_ACCOUNT,
    UPDATE_COMPANY,
    CREATE_STORAGE,
    UPDATE_STORAGE,
    DELETE_STORAGE,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    CREATE_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    CREATE_CONDITION,
    UPDATE_CONDITION,
    DELETE_CONDITION,
    CREATE_WAREHOUSE,
    UPDATE_WAREHOUSE,
    DELETE_WAREHOUSE,
    CREATE_ATTRIBUTE,
    UPDATE_ATTRIBUTE,
    DELETE_ATTRIBUTE,
    UPDATE_BULK_ITEM,
    UPDATE_BULK_ORDER,
    UPDATE_NOTIFICATION,
    UPDATE_ASSIGNED_ASSET,
  ];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');

  if (actionType === SHOW_NOTIFY) {
    return payload;
  }

  if (actionType === CLEAR_NOTIFY) {
    return payload;
  }

  // Creating, Updating or Deleting
  if (actions.includes(actionType)) {
    const currentAction = actions.find(function (action) {
      return action === actionType;
    });
    const {
      notify: { crudType, endpoint },
    } = interpretAction(currentAction);
    let verbiage = `${crudType.slice(0, -1)}ing`;
    if (crudType === 'Return') {
      verbiage = 'Returning';
    }
    if (crudType === 'Print') {
      verbiage = 'Printing';
    }
    if (crudType === 'Email') {
      verbiage = 'Emailing';
    }
    return {
      error: '',
      type: INFO,
      processing: true,
      action: actionType,
      message: `${verbiage} ${endpoint}`,
    };
  }

  // Successfully Created, Updated or Deleted
  if (success.includes(actionType)) {
    const currentAction = success.find(function (action) {
      return action === actionType;
    });
    const {
      notify: { crudType, endpoint },
    } = interpretAction(currentAction);
    let verbiage = `${crudType}d`;
    if (crudType === 'Return') {
      verbiage = 'Returned';
    }
    if (crudType === 'Print') {
      verbiage = 'Printed';
    }
    if (crudType === 'Email') {
      verbiage = 'Emailed';
    }
    return {
      error: '',
      type: SUCCESS,
      response: payload,
      processing: false,
      action: actionType,
      message: `${endpoint} Successfully ${verbiage}`,
    };
  }

  // Failed To Create, Update or Delete
  if (failure.includes(actionType)) {
    const { info, error, status } = payload;
    const currentAction = failure
      .find((action) => action === actionType)
      .toLowerCase();
    const actionParts = currentAction.split('_');
    const crudType = capitalizeFirstLetter(actionParts[0]);
    const endpoint = capitalizeFirstLetter(actionParts[1]);
    const message = info ? error : `Failed To ${crudType} ${endpoint}`;

    return {
      message,
      processing: false,
      action: actionType,
      type: status === 400 ? WARNING : ERROR,
      error: info ? parseResponseError(info) : error,
    };
  }

  return state;
}

export default notify;
