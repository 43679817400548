import { ONBOARDING_PROGRESS } from 'actions/types';

/**
  @description Tracks first time login onboarding progress
  @param {number} step
  @param {boolean} skip
  @param {string} caption
  @param {number} progress
 */
function onboarding(step, skip, caption, progress) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: ONBOARDING_PROGRESS,
      payload: {
        step,
        skip,
        caption,
        progress,
      },
    };

    dispatch(action);
  };
}

export default onboarding;
