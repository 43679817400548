import React from 'react';

import {
  StyledTable,
  StyledTableData,
  StyledTableHead,
  StyledTableBody,
  StyledTableFoot,
} from 'components/Table/Table/Table.styled';
import { Loading } from 'components/Loaders/';
import {
  StyledTableHeader,
  StyledTableHeadRow,
} from 'components/Table/Header/Header.styled';
import {
  StyledWrapper,
  StyledTableRow,
  StyledErrorMessage,
  StyledSubTableData,
} from 'components/Order/Returned/Returned.styled';

function Returned({ items }) {
  if (!items) {
    return <Loading />;
  }
  if (items && items.length === 0) {
    return (
      <StyledErrorMessage aria-live="polite">No items found</StyledErrorMessage>
    );
  }
  return (
    <StyledWrapper>
      <StyledTable>
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeader>Product Name</StyledTableHeader>
            <StyledTableHeader>Attribute</StyledTableHeader>
            <StyledTableHeader>Quantity</StyledTableHeader>
            <StyledTableHeader>Reason</StyledTableHeader>
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {items.map(function (row) {
            const { id, name, quantity, condition, attributes = [] } = row;
            const showAttributes = attributes.length > 0;
            return (
              <>
                <StyledTableRow key={id}>
                  <StyledTableData $mini>{name}</StyledTableData>
                  <StyledTableData $mini></StyledTableData>
                  <StyledTableData $mini>{quantity}</StyledTableData>
                  <StyledTableData $mini>{condition}</StyledTableData>
                </StyledTableRow>
                {showAttributes
                  ? attributes.map(function (attr) {
                      const { id, name, quantity, condition } = attr;
                      const last = id === attributes[attributes.length - 1].id;
                      return (
                        <StyledTableRow $sub={true} key={id}>
                          <StyledSubTableData />
                          <StyledSubTableData $last={last}>
                            {name}
                          </StyledSubTableData>
                          <StyledSubTableData $last={last}>
                            {quantity}
                          </StyledSubTableData>
                          <StyledSubTableData $last={last}>
                            {condition}
                          </StyledSubTableData>
                        </StyledTableRow>
                      );
                    })
                  : null}
              </>
            );
          })}
        </StyledTableBody>
        <StyledTableFoot>
          <StyledTableRow>
            <StyledTableData $mini></StyledTableData>
            <StyledTableData $mini></StyledTableData>
            <StyledTableData $mini></StyledTableData>
            <StyledTableData $mini></StyledTableData>
          </StyledTableRow>
        </StyledTableFoot>
      </StyledTable>
    </StyledWrapper>
  );
}

export default Returned;
