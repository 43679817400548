import styled from 'styled-components';

export const StyledFlexRow = styled.div`
  display: flex;
  margin-right: 0;
  margin-left: -15px;

  margin-bottom: ${({ marginBottom }) => marginBottom}px;

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-left: 0;
    flex-direction: column;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
  }
`;
