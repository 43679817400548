import styled from 'styled-components';

export const StyledProgressBar = styled.div`
  width: ${({ barWidth }) => barWidth}%;
  height: ${({ barHeight }) => barHeight}px;

  position: relative;
  border-radius: 13px;
  background-color: #c4c4c4;
`;

export const StyledProgress = styled.div`
  width: ${({ progress }) => progress}%;
  height: ${({ barHeight }) => barHeight}px;
  background-color: ${({ barColor }) => barColor};

  text-align: center;
  border-radius: 10px;
`;

export const StyledProgressValue = styled.label`
  left: 0;
  width: 100%;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  font-style: normal;
  position: absolute;
  display: inline-block;
`;
