import React from 'react';
import { bool } from 'prop-types';

import { StyledFooter } from './Footer.styled';
import FooterComponent from 'components/Footer/Footer';

/**
  @param {{
   sideNavOpen : boolean
   }} param
 */
const Footer = ({ sideNavOpen }) => {
  return (
    <StyledFooter sideNavOpen={sideNavOpen}>
      <FooterComponent />
    </StyledFooter>
  );
};

Footer.propTypes = {
  sideNavOpen: bool,
};

export default Footer;
