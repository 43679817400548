import React from 'react';
import { func, string } from 'prop-types';

import { hoverAndTap } from 'constants/animation';
import { maroon, primary } from 'constants/color';
import { ReactComponent as EditIcon } from 'icons/pencil.svg';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';
import { StyledButton } from 'components/Buttons/TableButton/TableButton.styled';

function TableButton({ title, onClick, buttonType }) {
  const buttonIcons = {
    edit: {
      icon: EditIcon,
      color: primary,
    },
    delete: {
      color: maroon,
      icon: DeleteIcon,
    },
  };
  const { color, icon: Icon } = buttonIcons[buttonType];
  return (
    <StyledButton
      title={title}
      $color={color}
      {...hoverAndTap}
      onClick={onClick}
    >
      <Icon />
    </StyledButton>
  );
}

TableButton.propTypes = {
  onClick: func.isRequired,
  buttonType: string.isRequired,
};

export default TableButton;
