import { maroon, orange, green } from 'constants/color';

const orderStatus = [
  {
    color: maroon,
    label: 'Pending',
    value: 'pending',
  },
  {
    color: orange,
    value: 'transit',
    label: 'In-Transit',
  },
  {
    color: green,
    label: 'Complete',
    value: 'complete',
  },
  {
    color: green,
    label: 'Delivered',
    value: 'delivered',
  },
];

export default orderStatus;
