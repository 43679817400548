import styled from 'styled-components';

export const StyledLogo = styled.img`
  width: 316px;
  height: 114px;
`;

export const StyledWrapper = styled.div`
  display: flex;
`;

export const StyledAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

export const StyledUserName = styled.label`
  color: ${({ theme }) => theme.primaryColor};

  height: 50px;
  display: flex;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  margin-left: 10px;
  font-style: normal;
  align-items: center;
`;

export const StyledInputWrapper = styled.div``;
