import React from 'react';
import { node, number, bool, object } from 'prop-types';

import { StyledFlexCol } from './FlexCol.styled';

/**
  @param {
  style: {},
  children: JSX,
  flexWidth: number,
  centerContent: boolean,
  } param
 */
const FlexCol = ({
  children,
  flexWidth,
  style = {},
  centerContent = false,
}) => {
  const colWidth = getFlexWidth(flexWidth);

  return (
    <StyledFlexCol
      flexWidth={colWidth}
      style={{ ...style }}
      centerContent={centerContent}
    >
      {children}
    </StyledFlexCol>
  );
};

export function getFlexWidth(width) {
  let flexWidth = 100;

  switch (width) {
    case 12:
      flexWidth = 100;
      break;
    case 11:
      flexWidth = 91.66666667;
      break;
    case 10:
      flexWidth = 83.33333333;
      break;
    case 9:
      flexWidth = 75;
      break;
    case 8:
      flexWidth = 66.66666667;
      break;
    case 7:
      flexWidth = 58.33333333;
      break;
    case 6:
      flexWidth = 50;
      break;
    case 5:
      flexWidth = 41.66666667;
      break;
    case 4:
      flexWidth = 33.33333333;
      break;
    case 3:
      flexWidth = 25;
      break;
    case 2:
      flexWidth = 16.66666667;
      break;
    case 1:
      flexWidth = 8.33333333;
      break;
    default:
      flexWidth = 100;
      break;
  }

  return flexWidth;
}

FlexCol.propTypes = {
  style: object,
  children: node,
  flexWidth: number,
  centerContent: bool,
};

export default FlexCol;
