import styled from 'styled-components';

export const IconWrapper = styled.label`
  --button-size: calc(${({ theme }) => theme.navBarSize} * 0.6);

  color: ${({ theme }) => theme.primaryColor};

  margin: 2px;
  padding: 5px;
  border: none;
  filter: none;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  margin-right: 0.5rem;
  justify-content: center;
  transition: filter 300ms;
  width: var(--button-size);
  height: var(--button-size);

  svg {
    fill: ${({ theme }) => theme.textColor};

    width: 30px;
    height: 30px;
  }

  &:focus {
    outline: none;
  }
`;

export const MenuItemButton = styled.button`
  color: ${({ theme }) => theme.primaryColor};
  background-color: ${({ theme }) => theme.background};
  transition: background ${({ theme }) => theme.transitionSpeed};
  border-bottom: ${({ theme }) => `1px solid ${theme.primaryColor}`};

  width: 100%;
  border: none;
  height: 50px;
  filter: none;
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;

  &:last-child {
    border-bottom: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    border-radius: ${({ theme }) => theme.borderRadius};
    cursor: pointer;
    filter: brightness(1.2);

    ${IconWrapper} {
      transition: background-color 1000ms linear;
      -ms-transition: background-color 1000ms linear;
      -webkit-transition: background-color 1000ms linear;
    }
  }
`;
