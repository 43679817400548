import { arrayOf, func, object, string } from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';

import {
  TextArea,
  TextInput,
  PhotoInput,
  SelectInput,
} from 'components/Inputs/';
import { active } from 'constants/status';
import { INVALID_URL } from 'constants/general';
import { FlexCol, FlexRow } from 'components/Layout/';
import { capitalizeFirstLetter, parseJSON } from 'utils/';
import DatePicker from 'components/DatePicker/DatePicker';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { RoundButton, CancelButton } from 'components/Buttons/';

const invalidData = ['', 'null', null, undefined, 'undefined'];

/**
 * @param {{
 * tags: [],
 * storages: [],
 * conditions: [],
 * categories: [],
 * initialValue: {},
 * quantityUnits: [],
 * onSubmit: Function,
 * onModalClose: Function,
 * }} param
 */
function AssetForm({
  tags,
  onSubmit,
  storages,
  conditions,
  categories,
  initialValue,
  onModalClose,
  quantityUnits,
}) {
  const {
    quantity = '',
    status = active,
    manufacturer = '',
    item_tags: itemTags,
    item_name: name = '',
    item_images: itemImages,
    storage_id: storageId = '',
    item_state: itemState = '',
    expiry_date: expiryDate = '',
    price_unit: priceUnit = 'KES',
    item_category: itemCategory = '',
    purchase_date: purchaseDate = '',
    leasing_price: leasingPrice = '',
    other_details: otherDetails = '',
    quantity_unit: quantityUnit = '',
    item_description: description = '',
    purchase_price: purchasePrice = '',
    warranty_expiry: warrantyExpiry = '',
  } = initialValue || {};
  let itemTag = null;
  let photoUrl = null;
  const currentQuantityUnit = !quantityUnit
    ? null
    : {
        label: quantityUnit,
        value: quantityUnit,
      };
  const otherDetailsObj = parseJSON(otherDetails);
  if (Array.isArray(itemTags)) {
    itemTag = itemTags[0];
  }
  if (Array.isArray(itemImages)) {
    photoUrl = itemImages[0].link;
  }
  const { productionDate = '', sellPrice = '' } = otherDetailsObj;
  //  TODO : This was for cleanup purposes, remove in future ?
  if (typeof itemImages === 'string' && !INVALID_URL.includes(itemImages)) {
    photoUrl = itemImages;
  }
  const [state, setState] = useState({
    name: unescape(name),
    tag: unescape(itemTag),
    status: unescape(status),
    storage: unescape(storageId),
    quantity: unescape(quantity),
    itemState: unescape(itemState),
    sellPrice: unescape(sellPrice),
    category: unescape(itemCategory),
    description: unescape(description),
    quantityUnit: unescape(quantityUnit),
    manufacturer: unescape(manufacturer),
    leasingPrice: unescape(leasingPrice),
    purchasePrice: unescape(purchasePrice),
    priceUnit: unescape(priceUnit) || 'KES',
    // dates
    expiryDate: expiryDate ? new Date(expiryDate) : '',
    purchaseDate: purchaseDate ? new Date(purchaseDate) : '',
    warrantyExpiry: warrantyExpiry ? new Date(warrantyExpiry) : '',
    productionDate: productionDate ? new Date(productionDate) : '',
  });
  const [itemErrors, setItemErrors] = useState({
    tag: '',
    name: '',
    storage: '',
    category: '',
    quantity: '',
    priceUnit: '',
    itemState: '',
    sellPrice: '',
    expiryDate: '',
    description: '',
    manufacturer: '',
    purchaseDate: '',
    leasingPrice: '',
    quantityUnit: '',
    purchasePrice: '',
    warrantyExpiry: '',
    productionDate: '',
  });
  const [tagOption, setTagOption] = useState(null);
  const [itemLogo, setItemLogo] = useState(photoUrl);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storageOption, setStorageOption] = useState(null);
  const [categoryOption, setCategoryOption] = useState(null);
  const [conditionOption, setConditionOption] = useState(null);
  const [unitOption, setUnitOption] = useState(currentQuantityUnit);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onSubmitItem() {
    setIsSubmitting(true);
    const isFormValid = validateItemForm();
    if (isFormValid) {
      const {
        tag,
        name,
        storage,
        category,
        quantity,
        priceUnit,
        itemState,
        sellPrice,
        expiryDate,
        description,
        leasingPrice,
        manufacturer,
        purchaseDate,
        quantityUnit,
        purchasePrice,
        warrantyExpiry,
        productionDate,
      } = state;
      const otherDetails = {
        sellPrice,
      };
      const formData = new FormData();
      formData.append('status', active);
      formData.append('item_name', name);
      formData.append('quantity', quantity);
      formData.append('storage_id', storage);
      formData.append('item_state', itemState);
      formData.append('price_unit', priceUnit);
      formData.append('item_category', category);
      formData.append('manufacturer', manufacturer);
      if (!invalidData.includes(leasingPrice)) {
        formData.append('leasing_price', leasingPrice);
      }
      formData.append('quantity_unit', quantityUnit);
      formData.append('item_description', description);
      if (!invalidData.includes(purchasePrice)) {
        formData.append('purchase_price', purchasePrice);
      }
      if (!invalidData.includes(tag)) {
        formData.append('item_tags', JSON.stringify([tag]));
      }
      if (!invalidData.includes(itemLogo)) {
        formData.append('item_images', itemLogo);
      }
      // dates
      if (isDateObject(expiryDate)) {
        formData.append('expiry_date', new Date(expiryDate).toISOString());
      }
      if (isDateObject(warrantyExpiry)) {
        formData.append(
          'warranty_expiry',
          new Date(warrantyExpiry).toISOString(),
        );
      }
      if (isDateObject(purchaseDate)) {
        formData.append('purchase_date', new Date(purchaseDate).toISOString());
      }
      if (isDateObject(productionDate)) {
        otherDetails.productionDate = new Date(productionDate).toISOString();
      }
      // Other details
      formData.append('other_details', JSON.stringify(otherDetails));
      onSubmit(formData);
    }
  }

  /**
   * @param {Date | undefined} date
   */
  function isDateObject(date) {
    return date && typeof date === 'object';
  }

  function validateItemForm() {
    let isFormValid = true;
    const optionalFields = [
      'tag',
      'itemTags',
      'sellPrice',
      'expiryDate',
      'description',
      'purchaseDate',
      'purchasePrice',
      'leasingPrice',
      'quantityUnit',
      'manufacturer',
      'warrantyExpiry',
      'productionDate',
    ];
    let errorObject = { ...itemErrors };
    for (const [key, value] of Object.entries(state)) {
      if (!optionalFields.includes(key) && !value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }
    setItemErrors(errorObject);
    return isFormValid;
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   */
  function onStorageChange(option) {
    let storage = null;
    setStorageOption(option);
    if (option) {
      const { value } = option;
      storage = value;
    }
    setState({
      ...state,
      storage,
    });
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   */
  function onCategoryChange(option) {
    let category = null;
    setCategoryOption(option);
    if (option) {
      const { value } = option;
      category = value;
    }
    setState({
      ...state,
      category,
    });
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   */
  function onConditionChange(option) {
    let itemState = null;
    setConditionOption(option);
    if (option) {
      const { value } = option;
      itemState = value;
    }
    setState({
      ...state,
      itemState,
    });
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   */
  function onTagChange(option) {
    let tag = null;
    setTagOption(option);
    if (option) {
      const { value } = option;
      tag = value;
    }
    setState({
      ...state,
      tag,
    });
  }

  /**
   * @param {{
   *   label: string
   *   value: string
   * }} option
   */
  function onQuantityUnitChange(option) {
    let quantityUnit = null;
    setUnitOption(option);
    if (option) {
      const { value } = option;
      quantityUnit = value;
    }
    setState({
      ...state,
      quantityUnit,
    });
  }

  /**
   * @param {Date} date
   * @param {string} name
   */
  function onDateChange(name, date) {
    setState({
      ...state,
      [name]: date,
    });
  }

  const storageOptions = useMemo(() => {
    return [...storages].map(function ({ id, name }) {
      return { value: id, label: name };
    });
  }, [storages]);

  const conditionOptions = useMemo(() => {
    return [...conditions].map(function ({ id, name }) {
      return { value: id, label: name };
    });
  }, [conditions]);

  const categoryOptions = useMemo(() => {
    return [...categories].map(function ({ id, category }) {
      return {
        value: id,
        label: capitalizeFirstLetter(category),
      };
    });
  }, [categories]);

  const tagOptions = useMemo(() => {
    return [...tags].map(function ({ id, tagname }) {
      return {
        value: id,
        label: tagname,
      };
    });
  }, [tags]);

  const unitsOptions = useMemo(() => {
    return quantityUnits?.map(function (unit) {
      return {
        value: unit,
        label: unit,
      };
    });
  }, [quantityUnits]);

  useEffect(() => {
    if (isSubmitting) {
      validateItemForm();
    }
  }, [state, isSubmitting]);

  useEffect(() => {
    if (storageId && storageOptions) {
      const storage = [...storageOptions].find(function ({ value }) {
        return value === storageId;
      });
      if (storage) {
        onStorageChange(storage);
      }
    }
  }, [storageId, storageOptions]);

  useEffect(() => {
    if (itemCategory && categoryOptions) {
      const category = [...categoryOptions].find(function ({ value }) {
        return value === itemCategory;
      });
      if (category) {
        onCategoryChange(category);
      }
    }
  }, [itemCategory, categoryOptions]);

  useEffect(() => {
    if (itemState && conditionOptions) {
      const condition = [...conditionOptions].find(function ({ value }) {
        return value === itemState;
      });
      if (condition) {
        onConditionChange(condition);
      }
    }
  }, [itemState, conditionOptions]);

  useEffect(() => {
    if (itemTag && tagOptions) {
      const tag = [...tagOptions].find(({ value }) => value === itemTag);
      if (tag) {
        onTagChange(tag);
      }
    }
  }, [itemTag, tagOptions]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={3}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                caption="Name"
                inputType="text"
                value={state.name}
                placeholder="Enter name"
                onChange={onInputChange}
                errorMessage={itemErrors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="storage"
                caption="Storage"
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                value={storageOption}
                options={storageOptions}
                ariaLabelledBy="storage"
                onChange={onStorageChange}
                placeholder="Select storage"
                errorMessage={itemErrors.storage}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextArea
                height={35}
                inputType="text"
                name="description"
                inputId="description"
                caption="Description"
                onChange={onInputChange}
                value={state.description}
                placeholder="Enter description"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                inputType="number"
                name="purchasePrice"
                inputId="purchasePrice"
                caption="Purchase Price"
                onChange={onInputChange}
                value={state.purchasePrice}
                placeholder="Enter purchase price"
                errorMessage={itemErrors.purchasePrice}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                name="sellPrice"
                inputType="number"
                inputId="sellPrice"
                caption="Sell Price"
                value={state.sellPrice}
                onChange={onInputChange}
                placeholder="Enter sell price"
                errorMessage={itemErrors.sellPrice}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={3}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                inputType="number"
                name="leasingPrice"
                inputId="leasingPrice"
                caption="Leasing Price"
                onChange={onInputChange}
                value={state.leasingPrice}
                placeholder="Enter leasing price"
                errorMessage={itemErrors.leasingPrice}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="quantity"
                inputId="quantity"
                caption="Quantity"
                inputType="number"
                value={state.quantity}
                onChange={onInputChange}
                placeholder="Enter quantity"
                errorMessage={itemErrors.quantity}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="category"
                isLoading={false}
                caption="Category"
                isClearable={true}
                isSearchable={true}
                value={categoryOption}
                options={categoryOptions}
                ariaLabelledBy="category"
                onChange={onCategoryChange}
                placeholder="Select category"
                errorMessage={itemErrors.category}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                name="itemState"
                isLoading={false}
                isClearable={true}
                caption="Condition"
                isSearchable={true}
                value={conditionOption}
                ariaLabelledBy="itemState"
                options={conditionOptions}
                onChange={onConditionChange}
                errorMessage={itemErrors.itemState}
                placeholder="e.g New, Used, Refurbished"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                name="tag"
                caption="Tag"
                isLoading={false}
                value={tagOption}
                isClearable={true}
                isSearchable={true}
                ariaLabelledBy="tag"
                options={tagOptions}
                onChange={onTagChange}
                placeholder="Select tag"
                errorMessage={itemErrors.tag}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={3}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                inputType="text"
                name="manufacturer"
                caption="Manufacturer"
                inputId="manufacturer"
                onChange={onInputChange}
                value={state.manufacturer}
                placeholder="Enter manufacturer"
                errorMessage={itemErrors.manufacturer}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <DatePicker
                name="purchaseDate"
                inputId="purchaseDate"
                caption="Purchase Date"
                selected={state.purchaseDate}
                placeholder="Select purchase date"
                errorMessage={itemErrors.purchaseDate}
                onChange={onDateChange.bind(null, 'purchaseDate')}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <DatePicker
                name="warrantyExpiry"
                inputId="warrantyExpiry"
                caption="Warranty Expiry"
                selected={state.warrantyExpiry}
                placeholder="Select warranty expiry"
                errorMessage={itemErrors.warrantyExpiry}
                onChange={onDateChange.bind(null, 'warrantyExpiry')}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <DatePicker
                name="productionDate"
                inputId="productionDate"
                caption="Production Date"
                selected={state.productionDate}
                placeholder="Select production date"
                errorMessage={itemErrors.productionDate}
                onChange={onDateChange.bind(null, 'productionDate')}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <DatePicker
                name="expiryDate"
                inputId="expiryDate"
                caption="Expiry Date"
                selected={state.expiryDate}
                placeholder="Select expiry date"
                errorMessage={itemErrors.expiryDate}
                onChange={onDateChange.bind(null, 'expiryDate')}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={3}>
          <FlexRow marginBottom={37}>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="itemLogo"
                caption="Item Logo"
                imageUri={itemLogo}
                onChange={setItemLogo}
                dataTestId="assetLogo"
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                name="units"
                isLoading={false}
                isClearable={true}
                value={unitOption}
                isSearchable={true}
                ariaLabelledBy="units"
                options={unitsOptions}
                caption="Quantity Unit"
                placeholder="Select unit"
                onChange={onQuantityUnitChange}
                errorMessage={itemErrors.quantityUnit}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          icon={<ArrowIcon />}
          onClick={onSubmitItem}
          className="modal-button"
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

AssetForm.defaultProps = {
  initialValue: {},
};

AssetForm.propTypes = {
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
  tags: arrayOf(object),
  storages: arrayOf(object),
  conditions: arrayOf(object),
  categories: arrayOf(object),
  quantityUnits: arrayOf(string),
};

export default AssetForm;
