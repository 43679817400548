import React from 'react';
import { bool, func, node, oneOf, string, object } from 'prop-types';

import { primary } from 'constants/color';
import {
  StyledInput,
  StyledButton,
  StyledWrapper,
  InputErrorLabel,
  StyledButtonWrapper,
} from 'components/Inputs/EditInput/EditInput.styled';

/**
 * @param {{
 * name: string,
 * value: string,
 * editIcon: node,
 * inputId: string,
 * caption: string,
 * isInvalid: bool,
 * onBlur: Function,
 * deleteIcon: node,
 * isComplete: bool,
 * tickIcon: object,
 * foreColor: string,
 * inputType: string,
 * onChange: Function,
 * onKeyDown: Function,
 * placeholder: string,
 * inputTestId: string,
 * errorMessage: string,
 * onEditClick: Function,
 * onTickClick: Function,
 * onDeleteClick: Function,
 * editButtonTitle: string,
 * tickButtonTitle: string,
 * tickButtonTestId: string,
 * editButtonTestId: string,
 * deleteButtonTitle: string,
 * deleteButtonTestId: string,
 * }} param
 */
function EditInput({
  name,
  value,
  onBlur,
  inputId,
  tickIcon,
  onChange,
  editIcon,
  isEditing,
  onKeyDown,
  foreColor,
  inputType,
  deleteIcon,
  isComplete,
  inputTestId,
  onEditClick,
  placeholder,
  onTickClick,
  errorMessage,
  onDeleteClick,
  tickButtonTitle,
  editButtonTitle,
  tickButtonTestId,
  editButtonTestId,
  isInvalid = false,
  deleteButtonTitle,
  deleteButtonTestId,
}) {
  const animation = {
    whileHover: {
      scale: 0.99,
    },
    whileTap: {
      scale: 0.95,
    },
  };
  const hasEditIcon = editIcon !== undefined;
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const showError = hasError && isEditing;

  return (
    <StyledWrapper>
      <StyledInput
        name={name}
        id={inputId}
        value={value}
        onBlur={onBlur}
        type={inputType}
        onChange={onChange}
        disabled={!isEditing}
        isEditing={isEditing}
        onKeyDown={onKeyDown}
        foreColor={foreColor}
        isComplete={isComplete}
        placeholder={placeholder}
        $isInvalid={invalidInput}
        data-testid={inputTestId}
        $hasEditIcon={hasEditIcon}
        whileTap={{ scale: 0.95 }}
        whileHover={{ scale: 0.99 }}
      />
      <StyledButtonWrapper>
        {tickIcon ? (
          <StyledButton
            right={80}
            {...animation}
            isEditing={isEditing}
            onClick={onTickClick}
            title={tickButtonTitle}
            data-testid={tickButtonTestId}
          >
            {tickIcon}
          </StyledButton>
        ) : null}
        {editIcon ? (
          <StyledButton
            right={1}
            {...animation}
            isEditing={isEditing}
            onClick={onEditClick}
            title={editButtonTitle}
            data-testid={editButtonTestId}
          >
            {editIcon}
          </StyledButton>
        ) : null}
        {deleteIcon ? (
          <StyledButton
            right={40}
            {...animation}
            isEditing={isEditing}
            onClick={onDeleteClick}
            title={deleteButtonTitle}
            data-testid={deleteButtonTestId}
          >
            {deleteIcon}
          </StyledButton>
        ) : null}
      </StyledButtonWrapper>
      {showError ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledWrapper>
  );
}

EditInput.defaultProps = {
  isComplete: false,
  inputType: 'text',
  foreColor: primary,
};

EditInput.propTypes = {
  name: string,
  onBlur: func,
  value: string,
  onChange: func,
  editIcon: node,
  inputId: string,
  caption: string,
  isInvalid: bool,
  onKeyDown: func,
  isEditing: bool,
  deleteIcon: node,
  isComplete: bool,
  tickIcon: object,
  foreColor: string,
  onEditClick: func,
  onTickClick: func,
  placeholder: string,
  onDeleteClick: func,
  inputTestId: string,
  errorMessage: string,
  editButtonTitle: string,
  tickButtonTitle: string,
  tickButtonTestId: string,
  editButtonTestId: string,
  deleteButtonTitle: string,
  deleteButtonTestId: string,
  inputType: oneOf(['number', 'text']),
};

export default EditInput;
