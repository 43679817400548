import styled from 'styled-components';

export const StyledDateLabel = styled.label`
  font-size: 12px;
  margin-top: 15px;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;

  /* Small screens */
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
`;

export const StyledGraphWrapper = styled.div`
  overflow: auto;
`;

export const StyledFilterWrapper = styled.div`
  width: 100%;
  display: flex;

  /* Mobile Devices */
  @media (max-width: 480px) {
    flex-direction: column;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 91%;
    flex-direction: column;
  }
`;
