import React from 'react';
import { string, func, bool, node, object, oneOfType } from 'prop-types';

import { NavButtonText } from 'containers/SideNav/SideNav.styled';
import { ReactComponent as ChevronIcon } from 'icons/chevron_right.svg';
import SideNavButton from 'components/SideNav/SideNavButton/SideNavButton';
import { StyledNavItem } from 'components/SideNav/SideNavItem/SideNavItem.styled';

/**
 * @param {{
 * Icon : JSX
 * buttonStyle : {}
 * caption : string
 * redirect : string
 * onClick : Function
 * isActive : boolean
 * }} param
 */
function SideNavItem({ Icon, onClick, caption, isActive, buttonStyle }) {
  return (
    <StyledNavItem onClick={onClick}>
      <SideNavButton
        onClick={onClick}
        isActive={isActive}
        buttonStyle={buttonStyle}
      >
        {Icon}
        <NavButtonText>{caption}</NavButtonText>
        <ChevronIcon className="chevron" />
      </SideNavButton>
    </StyledNavItem>
  );
}

SideNavItem.propTypes = {
  onClick: func,
  isActive: bool,
  caption: string,
  redirect: string,
  buttonStyle: object,
  Icon: oneOfType([node, object]),
};

export default SideNavItem;
