import Select from 'react-select';
import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

export const StyledFormGroup = styled.div`
  --marginBottom: ${({ $marginBottom }) => $marginBottom}px;
  margin-bottom: ${function ({ $isBorderLess, $isTableFilter }) {
    return $isBorderLess && $isTableFilter ? '0px' : 'var(--marginBottom)';
  }};
`;

export const StyledLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }

  ${function ({ $isTableFilter }) {
    return (
      $isTableFilter &&
      css`
        font-size: 10px;
        min-width: 100px;
      `
    );
  }}
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;

export const StyledSrOnlyLabel = styled.label`
  width: 1px;
  padding: 0;
  height: 1px;
  margin: -1px;
  border-width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
`;

export const StyledDescription = styled.p`
  color: #5d5d5d;
  font-size: 10px;
  margin-top: 0px;
  font-weight: 300;
  line-height: 12px;
`;
