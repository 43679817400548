import { primary, secondary } from 'constants/color';

/**
  @param {[]} labels xAxis data
  @param {string} legend yAxis text
  @param {[]} data Data to render map
  @param {string} caption Chart title
  @description Returns options to render chart
 */
function getLineChartOptions(data, labels, caption, legend) {
  const styles = {
    color: primary,
    fontWeight: 'normal',
    fontSize: '12px',
  };
  const options = {
    title: {
      text: caption,
      style: styles,
    },
    subtitle: {
      text: '',
    },
    yAxis: {
      title: {
        text: legend,
        style: styles,
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: primary,
        },
      ],
      min: 0,
      labels: {
        style: styles,
      },
    },
    xAxis: {
      categories: labels,
      min: 0,
      labels: {
        style: styles,
      },
    },
    tooltip: {
      valueSuffix: '',
      formatter: function () {
        return `
            <span style = "font-size: 10px; color: ${primary}">
            ${this.x}  
            </span>
            <br>
            <span style = "font-size: 10px; color: ${secondary}">
            ${this.y} ${legend}  
            </span>
            `;
      },
      useHTML: true,
    },
    colors: [secondary],
    legend: {
      enabled: false,
    },
    series: [
      {
        name: '', // displayed in legend
        type: 'line',
        lineWidth: 0.5,
        data: data,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  return options;
}

export default getLineChartOptions;
