import styled from 'styled-components';

import { white } from 'constants/color';

export const StyledWrapper = styled.div`
  padding: 1rem;
  margin-right: 10px;
  margin-bottom: 1rem;
  background-color: ${white};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const StyledTileWrapper = styled.div`
  margin-bottom: 1rem;

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-right: 10px;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 10px;
  }
`;

export const StyledContainer = styled.div`
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    overflow-x: hidden;
    max-height: calc(calc(${({ theme }) => theme.mobileViewHeight}) - 4rem);
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    overflow-x: hidden;
    max-height: calc(calc(${({ theme }) => theme.mobileViewHeight}) - 6rem);
  }
`;
