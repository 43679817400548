import styled from 'styled-components';

export const StyledButton = styled.button`
  height: 40px;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  text-transform: none;
  vertical-align: middle;
  background-image: none;
  color: ${({ $foreColor }) => $foreColor};
  font-size: ${({ $fontSize }) => $fontSize}px;
  background-color: ${({ $backColor }) => $backColor};
  svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    vertical-align: middle;
    transition: transform 0.3s;
    fill: ${({ $foreColor }) => $foreColor};
    transform: ${function ({ $open }) {
      return $open ? 'rotate(270deg)' : 'rotate(90deg)';
    }};
  }
`;

export const StyledContent = styled.ul`
  z-index: 1;
  width: 100%;
  margin-top: 1px;
  padding-left: 0px;
  position: absolute;
  background-color: #f1f1f1;
  display: ${({ $open }) => ($open ? 'block' : 'none')};
`;

export const StyledOption = styled.li`
  color: black;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  &:not(:first-of-type) {
    border-top: 1px solid #ddd;
  }
`;

export const DropDownContainer = styled.div`
  --borderRadius: ${({ theme }) => theme.borderRadius};
  position: relative;
  display: inline-block;
  margin-left: ${({ $marginReft }) => $marginReft}px;
  margin-right: ${({ $marginRight }) => $marginRight}px;
  ${StyledButton} {
    border-radius: var(--borderRadius);
    min-width: ${({ flexWidth }) => flexWidth}%;
    border: 1px solid
      ${({ $borderColor }) => ($borderColor ? $borderColor : 'transparent')};
  }
  ${StyledContent} {
    border-radius: var(--borderRadius);
    border: 1px solid
      ${({ $borderColor }) => ($borderColor ? $borderColor : 'transparent')};
  }
`;

export const StyleOptionButton = styled.button`
  width: 100%;
  border: none;
  cursor: pointer;
  border: 1px solid #ddd;
  &:disabled {
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    background-color: #ddd;
    color: ${({ theme }) => theme.primaryColor};
  }
`;
