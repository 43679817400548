const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const centerLatLng = {
  lat: -1.286389,
  lng: 36.817223,
};

const apiProps = {
  key: googleMapApiKey,
  libraries: ['places', 'geometry', 'drawing', 'visualization'],
  language: 'en',
};

const mapProps = {
  center: centerLatLng,
  zoom: 11,
  hoverDistance: 30,
};

const mapOptions = () => {
  return {
    panControl: true,
    mapTypeControl: false,
    scrollwheel: true,
    styles: [
      {
        stylers: [
          {
            saturation: 0,
          },
          {
            gamma: 0.8,
          },
          {
            lightness: 4,
          },
          {
            visibility: 'on',
          },
        ],
      },
    ],
  };
};

export { centerLatLng, apiProps, mapProps, mapOptions };
