import { GET_USERS } from 'actions/types';
import { transformActions, capitalizeFirstLetter } from 'utils/';

const initialState = {
  data: [],
  isLoading: false,
};

/**
  @description Stores users array details in redux state
  @param {[]} state
  @param {{
  payload : []
  type : string
   }} action
  @returns {[]}
 */
function users(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_USERS];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');

  if (type === GET_USERS) {
    return {
      data: [],
      isLoading: true,
    };
  }

  if (success.includes(type)) {
    // transform
    const users = payload.map((user) => {
      return {
        ...user,
        name: `${user.first_name} ${user.last_name}`,
        permission: capitalizeFirstLetter(user.usertype),
      };
    });

    return {
      data: users,
      isLoading: false,
    };
  }

  if (failure.includes(type)) {
    return {
      data: [],
      isLoading: false,
    };
  }

  return state;
}

export default users;
