import React from 'react';
import { array, string } from 'prop-types';

import { Tile } from 'components/Widgets/';
import {
  StyledLabel,
  StyledStatus,
  StyledAvatar,
  StyledTileBody,
  StyledTileItem,
  StyledBoldFont,
  StyledLogoWrapper,
  StyledLeftSection,
  StyledRightSection,
  StyledAvatarSection,
  StyledCustomerNameLabel,
} from 'components/Order/Current/Current.styled';
import { getImageUrl, getOrderNumber } from 'utils/';
import { ReactComponent as ArrowIcon } from 'icons/order.svg';

/**
 * @param {{
 * data : []
 * priceUnit: string
 * }} param
 */
function Current({ data, priceUnit }) {
  return (
    <Tile caption="Current Orders">
      <StyledTileBody>
        {data.map(function (item) {
          const { id, customer, status, total_cost: totalCost } = item;
          const { name = '', photo } = customer || {};
          const url = getImageUrl(photo);
          const orderNumber = getOrderNumber({ id, prefix: '#' });
          const orderTotal = parseFloat(totalCost).toLocaleString();
          return (
            <StyledTileItem key={id}>
              <StyledLogoWrapper>
                <ArrowIcon />
              </StyledLogoWrapper>
              <StyledLeftSection>
                <StyledLabel>
                  <StyledBoldFont>Order # </StyledBoldFont>
                  {orderNumber}
                </StyledLabel>
                <StyledCustomerNameLabel>{name}</StyledCustomerNameLabel>
              </StyledLeftSection>
              <StyledAvatarSection>
                <StyledAvatar src={url} alt="customer" />
              </StyledAvatarSection>
              <StyledRightSection>
                <StyledLabel>
                  {priceUnit}.<StyledBoldFont> {orderTotal}</StyledBoldFont>
                </StyledLabel>
                <StyledStatus>{status}</StyledStatus>
              </StyledRightSection>
            </StyledTileItem>
          );
        })}
      </StyledTileBody>
    </Tile>
  );
}

Current.propTypes = {
  data: array,
  priceUnit: string,
};

export default Current;
