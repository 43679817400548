import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledButton = styled(motion.button)`
  --whiteColor: ${({ theme }) => theme.whiteColor};
  --blackColor: ${({ theme }) => theme.blackColor};
  --primaryColor: ${({ theme }) => theme.primaryColor};

  color: var(--primaryColor);
  background-color: var(--whiteColor);

  width: 100%;
  height: 30px;
  display: flex;
  font-size: 13px;
  cursor: pointer;
  box-shadow: none;
  line-height: 19px;
  border-width: 1px;
  padding: 6px 10px;
  user-select: none;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  white-space: nowrap;
  text-transform: none;
  -moz-box-shadow: none;
  -ms-user-select: none;
  vertical-align: middle;
  -moz-user-select: none;
  background-image: none;
  line-height: 1.42857143;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 1px solid transparent;
  -ms-touch-action: manipulation;
`;
