import { capitalizeFirstLetter } from 'utils/';

/**
 * @param {string} action
 */
function interpretAction(action) {
  const actionParts = action.toLowerCase().split('_');
  const crudType = actionParts[0];
  let modalType = actionParts[1];
  let endpoint = capitalizeFirstLetter(actionParts[1]);
  if (actionParts.length > 3) {
    modalType = `${actionParts[1]}_${actionParts[2]}`;
    endpoint = `${capitalizeFirstLetter(
      actionParts[1],
    )} ${capitalizeFirstLetter(actionParts[2])}`;
  }
  return {
    modal: {
      crudType: crudType.toUpperCase(),
      modalType: modalType.toUpperCase(),
    },
    notify: {
      endpoint,
      crudType: capitalizeFirstLetter(crudType),
    },
  };
}

export default interpretAction;
