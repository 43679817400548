import { useState, useEffect } from 'react';

function useOnBoarding() {
  const [isOnBoarding, setIsOnBoarding] = useState(
    window.location.pathname.includes('/account/setup'),
  );

  useEffect(() => {
    function onCheckRoute() {
      return setIsOnBoarding(
        window.location.pathname.includes('/account/setup'),
      );
    }

    // Listen for changes in the pathname
    window.addEventListener('popstate', onCheckRoute);

    // Cleanup: remove the event listener when the component unmounts
    return function () {
      return window.removeEventListener('popstate', onCheckRoute);
    };
  }, []);

  return isOnBoarding;
}

export default useOnBoarding;
