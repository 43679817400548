import { AnimatePresence } from 'framer-motion';
import React, { useState, useEffect } from 'react';

import {
  StyledNav,
  StyledMain,
  StyledList,
  StyledBody,
  StyledCaption,
  StyledWrapper,
  StyledListItem,
  StyledIndicator,
} from 'components/Tab/Tab.styled';
import { Loading } from 'components/Loaders/';

function Tab({
  options,
  caption,
  onChangeTab,
  activeTab = '',
  marginRight = 0,
  minHeight = 500,
  maxHeight = 2000,
  marginBottom = 0,
}) {
  const { hash } = window.location;
  const defaultTab = activeTab ? activeTab : options[0]?.name;
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  function onTabClick(name) {
    setSelectedTab(name);
    if (onChangeTab) {
      onChangeTab(name);
    }
    const { history, location } = window;
    const { origin, pathname } = location;
    const newUrl = `${origin}${pathname}#${name}`;
    history.replaceState({}, '', newUrl);
  }

  useEffect(() => {
    const name = hash.replace('#', '');
    const tab = options.find(function (item) {
      return item.name === name;
    });
    if (tab) {
      onTabClick(tab.name);
    }
  }, [hash, options]);

  return (
    <StyledWrapper
      $maxHeight={maxHeight}
      $minHeight={minHeight}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
    >
      <StyledCaption>{caption}</StyledCaption>
      {options.length === 0 ? <Loading /> : null}
      <StyledNav>
        <StyledList>
          {options.map(function (tab) {
            const { name, caption, disabled = false, ...rest } = tab;
            const active = name === selectedTab;
            const clickHandler = disabled ? null : onTabClick.bind(null, name);
            return (
              <StyledListItem
                {...rest}
                key={name}
                $active={active}
                $disabled={disabled}
                onClick={clickHandler}
              >
                {caption}
                {active ? <StyledIndicator layoutId="underline" /> : null}
              </StyledListItem>
            );
          })}
        </StyledList>
      </StyledNav>
      <StyledMain>
        <AnimatePresence>
          {options.map(function (tab) {
            const { name, children, autoScroll = true } = tab;
            const active = name === selectedTab;
            const animation = active
              ? {
                  exit: { y: -10, opacity: 0 },
                  animate: { y: 0, opacity: 1 },
                  transition: { duration: 0.2 },
                  initial: { y: 10, opacity: 0 },
                }
              : {};
            return (
              <StyledBody
                key={name}
                {...animation}
                $active={active}
                $maxHeight={maxHeight}
                $autoScroll={autoScroll}
              >
                {children}
              </StyledBody>
            );
          })}
        </AnimatePresence>
      </StyledMain>
    </StyledWrapper>
  );
}

export default Tab;
