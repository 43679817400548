import {
  func,
  node,
  bool,
  object,
  string,
  element,
  oneOfType,
} from 'prop-types';
import React from 'react';

import { hoverAndTap } from 'constants/animation';
import {
  IconWrapper,
  StyledButton,
} from 'components/Buttons/ActionButton/ActionButton.styled';

function ActionButton({ icon, onClick, caption, active, ...props }) {
  return (
    <StyledButton
      {...props}
      {...hoverAndTap}
      onClick={onClick}
      disabled={!active}
    >
      {icon ? <IconWrapper {...hoverAndTap}>{icon}</IconWrapper> : null}
      {caption}
    </StyledButton>
  );
}

ActionButton.propTypes = {
  active: bool,
  caption: string,
  onClick: func.isRequired,
  icon: oneOfType([node, object, element]),
};

export default ActionButton;
