import React from 'react';
import { array } from 'prop-types';

import { getImageUrl } from 'utils/';
import { Tile } from 'components/Widgets/';
import Avatar from 'components/Avatar/Avatar';
import {
  StyledLabel,
  StyledTileBody,
  StyledTileItem,
  StyledTopOrderItem,
} from 'components/Order/TopOrder/TopOrder.styled';

/**
 * @param {{
 * data : []
 * }} param
 */
function TopOrder({ data }) {
  return (
    <Tile caption="Top Ordered Items">
      <StyledTileBody>
        {data.map((item) => {
          const {
            id,
            name,
            images,
            category,
            totalCost,
            occurrence,
            // quantity,
          } = item;
          // TODO: API should handle this and return data in the correct format instead of return various formats
          let image = images;
          const orders = `${occurrence} Orders`;
          if (Array.isArray(images) && images.length > 0) {
            image = images[0].link;
          }
          const imageUrl = getImageUrl(image);
          const orderTotal = parseFloat(totalCost).toLocaleString();
          return (
            <StyledTileItem key={id}>
              <StyledTopOrderItem>
                <Avatar
                  borderRadius="40%"
                  imageAltText="item"
                  imageSource={imageUrl}
                />
                <StyledLabel>
                  {name}
                  <br />
                  {category}
                </StyledLabel>
                <StyledLabel>{orders}</StyledLabel>
                <StyledLabel>{orderTotal}</StyledLabel>
              </StyledTopOrderItem>
            </StyledTileItem>
          );
        })}
      </StyledTileBody>
    </Tile>
  );
}

TopOrder.propTypes = {
  data: array,
};

export default TopOrder;
