import React from 'react';

import Notify from 'containers/Notify/Notify';
import TagModal from 'containers/Modals/TagModal/TagModal';
import ConfirmModal from 'containers/Modals/Confirm/Confirm';
import UserModal from 'containers/Modals/UserModal/UserModal';
import TaskModal from 'containers/Modals/TaskModal/TaskModal';
import AssetModal from 'containers/Modals/AssetModal/AssetModal';
import EmailModal from 'containers/Modals/EmailModal/EmailModal';
import PeopleModal from 'containers/Modals/PeopleModal/PeopleModal';
import StorageModal from 'containers/Modals/StorageModal/StorageModal';
import CompanyModal from 'containers/Modals/CompanyModal/CompanyModal';
import CategoryModal from 'containers/Modals/CategoryModal/CategoryModal';
import CustomerModal from 'containers/Modals/CustomerModal/CustomerModal';
import ConditionModal from 'containers/Modals/ConditionModal/ConditionModal';
import WarehouseModal from 'containers/Modals/WarehouseModal/WarehouseModal';
import OrderInfoModal from 'containers/Modals/OrderInfoModal/OrderInfoModal';
import StorageListModal from 'containers/Modals/StorageListModal/StorageListModal';
import UserProfileModal from 'containers/Modals/UserProfileModal/UserProfileModal';
import AssignAssetModal from 'containers/Modals/AssignAssetModal/AssignAssetModal';
import WarehouseListModal from 'containers/Modals/WarehouseListModal/WarehouseListModal';
import AssignedAssetModal from 'containers/Modals/AssignedAssetModal/AssignedAssetModal';

function RootModal() {
  return (
    <>
      <TagModal key="tag-modal" />
      <Notify key="notify-modal" />
      <UserModal key="user-modal" />
      <TaskModal key="task-modal" />
      <EmailModal key="email-modal" />
      <AssetModal key="asset-modal" />
      <PeopleModal key="people-modal" />
      <ConfirmModal key="confirm-modal" />
      <StorageModal key="storage-modal" />
      <CustomerModal key="customer-modal" />
      <CategoryModal key="category-modal" />
      <ConditionModal key="condition-modal" />
      <WarehouseModal key="warehouse-modal" />
      <OrderInfoModal key="order-info-modal" />
      <CompanyModal key="company-profile-modal" />
      <StorageListModal key="storage-list-modal" />
      <UserProfileModal key="user-profile-modal" />
      <AssignAssetModal key="assign-asset-modal" />
      <AssignedAssetModal key="assigned-asset-modal" />
      <WarehouseListModal key="warehouse-list-modal" />
    </>
  );
}

export default RootModal;
