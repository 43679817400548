import { endPoints } from 'api/';
import { ASSIGN_ASSET } from 'actions/types';
import { getAssignedAssets } from 'actions/assets/';

/**
 * @description Assigns asset to user
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @returns Assigned asset object
 */
function assignAsset(payload, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: ASSIGN_ASSET,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const asset = await apiClient(endPoints.ASSIGN_ASSET, payload);
        dispatch(getAssignedAssets({ companyId }));
        return asset;
      },
    };
    dispatch(action);
  };
}

export default assignAsset;
