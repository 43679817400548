import React, { useMemo, useState } from 'react';

import { CheckBox } from 'components/Inputs/';

function useCheckTable({
  idField = 'id',
  showCheckAll = true,
  checkBoxId = 'checkbox',
}) {
  const [checkedIds, setCheckedIds] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  function onCheckChange(data) {
    if (Array.isArray(data)) {
      if (isChecked) {
        setCheckedIds([]);
        setIsChecked(!isChecked);
        return;
      } else {
        const ids = data.map(function ({ original }) {
          return original[idField];
        });
        setCheckedIds(ids);
        setIsChecked(!isChecked);
        return;
      }
    }
    const id = data[idField];
    setCheckedIds(function (prevCheckedIds) {
      const index = prevCheckedIds.indexOf(id);
      if (index === -1) {
        return [...prevCheckedIds, id];
      } else {
        return prevCheckedIds.filter((item) => item !== id);
      }
    });
  }

  const checkBox = useMemo(() => {
    return {
      id: checkBoxId,
      enableSorting: false,
      header: function ({ table }) {
        if (!showCheckAll) {
          return null;
        }
        return (
          <CheckBox
            isHeader
            showCaption={false}
            onChange={onCheckChange.bind(null, table.getRowModel().rows)}
            isChecked={isChecked && checkedIds.length === 0 ? false : isChecked}
          />
        );
      },
      cell: function ({ row: { original } }) {
        const id = original[idField];
        const checked = checkedIds.includes(id);
        return (
          <CheckBox
            inputTestId={id}
            isChecked={checked}
            showCaption={false}
            caption={`checkbox-${id}`}
            inputId={`checkbox-id-${id}`}
            inputName={`checkbox-name-${id}`}
            onChange={onCheckChange.bind(null, original)}
          />
        );
      },
    };
  }, [checkedIds, onCheckChange]);

  return {
    checkBox,
    checkedIds,
    setCheckedIds,
    onCheckChange,
  };
}

export default useCheckTable;
