import { array, func, object } from 'prop-types';
import React, { useState, useEffect } from 'react';

import {
  CheckBox,
  TextInput,
  PhotoInput,
  SelectInput,
} from 'components/Inputs/';
import { active } from 'constants/status';
import { FlexCol, FlexRow } from 'components/Layout/';
import { validateEmail, capitalizeFirstLetter } from 'utils/';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';
import { StyledDescription } from 'components/Users/UserForm/UserForm.styled';

/**
 * @param {{
 *  permissions : []
 *  initialValue : {}
 *  onSubmit : Function
 *  onModalClose : Function
 *  }} param
 */
function UserForm({ onSubmit, permissions, initialValue, onModalClose }) {
  const {
    phone,
    email,
    photo,
    usertype,
    last_name: lastName,
    first_name: firstName,
    has_access: hasAccess = true,
  } = initialValue || {};

  const [userState, setUserState] = useState({
    phone,
    email,
    lastName,
    hasAccess,
    firstName,
    permission: usertype,
  });

  const [userErrors, setUserErrors] = useState({
    phone: '',
    email: '',
    lastName: '',
    firstName: '',
    permission: '',
  });
  const [profilePhoto, setProfilePhoto] = useState(photo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  /**
   * @param {{
   *  value: string
   *  }} selectedOption
   */
  function onPermissionChange(selectedOption) {
    let permission = null;
    setSelectedOption(selectedOption);
    if (selectedOption) {
      const { value } = selectedOption;
      permission = value;
    }
    setUserState({
      ...userState,
      permission,
    });
  }

  /**
   * @param {{
   *  currentTarget: {
   *  name: string
   *  value: string
   *  }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setUserState({
      ...userState,
      [name]: value,
    });
  }

  function onSubmitUser() {
    setIsSubmitting(true);
    const isValidForm = validateUserForm();
    if (isValidForm) {
      const { email, phone, lastName, permission, firstName, hasAccess } =
        userState;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('status', active);
      formData.append('last_name', lastName);
      formData.append('photo', profilePhoto);
      formData.append('usertype', permission);
      formData.append('has_access', hasAccess);
      formData.append('first_name', firstName);
      onSubmit(formData);
    }
  }

  function validateUserForm() {
    let isFormValid = true;
    let errorObject = { ...userErrors };
    const optionalFields = ['hasAccess'];
    for (const [key, value] of Object.entries(userState)) {
      if (!value && !optionalFields.includes(key)) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        if (key === 'email') {
          if (!validateEmail(value)) {
            isFormValid = false;
            errorObject = {
              ...errorObject,
              email: 'Invalid Email',
            };
          } else {
            errorObject = {
              ...errorObject,
              [key]: '',
            };
          }
        } else {
          errorObject = {
            ...errorObject,
            [key]: '',
          };
        }
      }
    }
    setUserErrors(errorObject);
    return isFormValid;
  }

  function onHasAccessChange() {
    setUserState({
      ...userState,
      hasAccess: !userState.hasAccess,
    });
  }

  useEffect(() => {
    if (isSubmitting) {
      validateUserForm();
    }
  }, [userState, isSubmitting]);

  useEffect(() => {
    if (usertype) {
      const userType = permissions.find(({ value }) => value === usertype);
      onPermissionChange(userType);
    }
  }, [usertype]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                inputType="text"
                name="firstName"
                inputId="firstName"
                caption="First Name"
                onChange={onInputChange}
                value={userState.firstName}
                placeholder="Enter first name"
                errorMessage={userErrors.firstName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="lastName"
                inputType="text"
                inputId="lastName"
                caption="Last Name"
                onChange={onInputChange}
                value={userState.lastName}
                placeholder="Enter last name"
                errorMessage={userErrors.lastName}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="phone"
                inputId="phone"
                caption="Phone"
                inputType="text"
                value={userState.phone}
                onChange={onInputChange}
                placeholder="e.g +254712345678"
                errorMessage={userErrors.phone}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="email"
                inputId="email"
                inputType="text"
                caption="Email Address"
                value={userState.email}
                onChange={onInputChange}
                errorMessage={userErrors.email}
                placeholder="Enter email address"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={6}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <SelectInput
                isRequired
                isLoading={false}
                isClearable={true}
                isSearchable={true}
                name="userPermission"
                options={permissions}
                value={selectedOption}
                caption="Set Permission"
                placeholder="Set permission"
                onChange={onPermissionChange}
                ariaLabelledBy="userPermission"
                errorMessage={userErrors.permission}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <CheckBox
                caption="Has Access"
                inputName="hasAccess"
                onChange={onHasAccessChange}
                isChecked={userState.hasAccess}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <PhotoInput
                id="profilePicture"
                imageUri={profilePhoto}
                caption="Profile Picture"
                onChange={setProfilePhoto}
                dataTestId="profilePicture"
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Send"
          icon={<ArrowIcon />}
          onClick={onSubmitUser}
          className="modal-button"
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledDescription>
            The User will get an email inviting them to the platform under your
            company. <br />
            They can then set up their profile as well as password.
          </StyledDescription>
        </FlexCol>
      </FlexRow>
    </>
  );
}

UserForm.propTypes = {
  onSubmit: func,
  permissions: array,
  onModalClose: func,
  initialValue: object,
};

export default UserForm;
