import menuList from 'constants/menuList';
import { SET_ACTIVE_MENU } from 'actions/types';
import { SIDE_NAV_ACTIVE_MENU } from 'constants/general';

const defaultActiveMenu = menuList[0];
const localSetMenu = window.localStorage.getItem(SIDE_NAV_ACTIVE_MENU);
const localActiveMenu = JSON.parse(localSetMenu);
const initialState =
  menuList.find((menu) => menu.name == localActiveMenu) || defaultActiveMenu;

/**
  @description Sets active side nav menu.
  @param {{}} state
  @param {{}} action
  @returns {{}}
 */
function activeMenu(state = initialState, action) {
  const { type: actionType, payload } = action;

  if (actionType === SET_ACTIVE_MENU) {
    return payload;
  }

  return state;
}

export default activeMenu;
