/**
  @param {[]} data Array to search from.
  @param {string} field Array field to search from.
  @param {string} value Array item value to compare with.
 */
function findFromArray(data, field, value) {
  return data?.find((item) => item[field] === value);
}

export default findFromArray;
