import styled from 'styled-components';

export const StyledMenuNav = styled.nav`
  --marginBottom: calc(var(--menuBarMargin) * 2);
  --topBarMargin: calc(var(--menuBarMargin) * 2);
  --navBarSize: ${({ theme }) => theme.navBarSize};
  --menuBarMargin: ${({ theme }) => theme.menuBarMargin};
  --boxPaddingTop: ${({ theme }) => theme.boxPaddingTop};

  margin-top: var(--menuBarMargin);
  margin-bottom: var(--marginBottom);
  height: ${({ theme }) => theme.menuBarSize};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.whiteColor};
  top: calc(var(--navBarSize) + var(--topBarMargin) + var(--boxPaddingTop));

  z-index: 1;
  display: flex;
  position: sticky;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Mobile Devices */
  @media (max-width: 480px) {
    top: calc(var(--navBarSize) + var(--topBarMargin) / 2);
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    top: calc(var(--navBarSize) + var(--topBarMargin) / 2);
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 86%;
  padding-right: 10px;
  margin-top: 13px !important;
`;
