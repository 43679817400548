import React from 'react';
import { string } from 'prop-types';

import { StyledInfo } from './InfoAlert.styled';

/**
   @param {{
     message : string
   }} param 
 */
const InfoAlert = ({ message }) => {
  return <StyledInfo>{message}</StyledInfo>;
};

InfoAlert.propTypes = {
  message: string,
};

export default InfoAlert;
