/**
  @description Truncates string at a given length
  @param {string} string String to truncate
  @param {number} length Length to truncate at.
  @param {boolean} ellipsis If true it will ellipsis/...
 */
function truncateString(string, length, ellipsis = false) {
  if (!string) {
    return '';
  }

  if (string.length <= length) {
    return string;
  }

  if (ellipsis) {
    return string.substring(0, length) + '...';
  }

  return string.substring(0, length);
}

export default truncateString;
