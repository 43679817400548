import { endPoints } from 'api/';
import { CREATE_STORAGE } from 'actions/types';
import { getStorages } from 'actions/storages/';

/**
  @description Creates storage object
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @returns Storage object
 */
function createStorage(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_STORAGE,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const storage = await apiClient(endPoints.CREATE_STORAGE, payload);

        dispatch(getStorages({ companyId }));

        return storage;
      },
    };

    dispatch(action);
  };
}

export default createStorage;
