/**
 * @description Gets the first ten characters from order id or item code.
 * @param {{
 * id : string
 * prefix : string
 * }}
 */
function getOrderNumber({ id, prefix = '' }) {
  if (!id) {
    return '';
  }
  const orderNumber = id?.replace(/-/g, '')?.substring(0, 10)?.toUpperCase();
  if (prefix) {
    return `${prefix}${orderNumber}`;
  }
  return orderNumber;
}

export default getOrderNumber;
