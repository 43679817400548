import styled, { css } from 'styled-components';

export const StyledFlexCol = styled.div`
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  ${function ({ centerContent }) {
    return (
      centerContent &&
      css`
        text-align: center;
      `
    );
  }};
  width: ${({ flexWidth }) => flexWidth}%;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    padding-left: 0;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;
