import { endPoints } from 'api/';
import { getUsers } from 'actions/users/';
import { CREATE_ADMIN, CREATE_USER } from 'actions/types';

/**
  @description Creates user object
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isSigningUp If `true` different action type will be dispatched
  reducer won't track this user since they're not `logged in` yet.
  @returns User object without token and password.
 */
function createUser(payload, companyId, isSigningUp) {
  /**
    @param {Function} dispatch
   */
  return function (dispatch) {
    const query = isSigningUp ? { companyId } : '';
    const actionType = isSigningUp ? CREATE_ADMIN : CREATE_USER;
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async function (apiClient) {
        const user = await apiClient(endPoints.CREATE_USER, payload, query);
        if (!isSigningUp) {
          dispatch(getUsers({ companyId }));
        }
        return user;
      },
    };
    dispatch(action);
  };
}

export default createUser;
