import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import menuList from 'constants/menuList';
import { lightGrey } from 'constants/color';
import Footer from 'containers/Footer/Footer';
import Navbar from 'containers/Navbar/Navbar';
import MenuBar from 'containers/MenuBar/MenuBar';
import SideNav from 'containers/SideNav/SideNav';
import { useMobileView, useSideNav } from 'hooks/';
import { ACCOUNT_SETUP_PATH } from 'constants/general';
import setThemeStyles from 'actions/theme/setThemeStyles';
import SideNavTile from 'containers/SideNavTile/SideNavTile';
import { Wrapper, StyledTileWrapper } from './Container.styled';

const Container = () => {
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const isMobileView = useMobileView();
  const [sideNavOpen, onSideNavToggle] = useSideNav();
  const [isSetupRoute, setIsSetupRoute] = useState(false);
  const showSideTile = isMobileView && isSetupRoute;
  const { containerBackColor } = useSelector(({ theme }) => theme);

  const { pathname } = routerLocation;

  const menu = menuList.map(({ name, path, exact, component: Component }) => {
    return Component ? (
      <Route
        key={path}
        path={path}
        name={name}
        exact={exact}
        render={(props) => <Component {...props} />}
      />
    ) : null; // we can render a default Component here ?
  });

  useEffect(() => {
    const setupRoute = pathname === ACCOUNT_SETUP_PATH;
    setIsSetupRoute(setupRoute);

    if (setupRoute) {
      dispatch(setThemeStyles(lightGrey));
    }
  }, [pathname]);

  useEffect(() => {
    if (!isMobileView && !sideNavOpen && isSetupRoute) {
      onSideNavToggle();
    }
  }, [sideNavOpen, isMobileView, isSetupRoute]);

  return (
    <>
      <Navbar />
      <MenuBar onSideNavToggle={onSideNavToggle} />
      <SideNav
        sideNavOpen={sideNavOpen}
        isSetupRoute={isSetupRoute}
        onSideNavToggle={onSideNavToggle}
      />
      {showSideTile ? (
        <StyledTileWrapper>
          <SideNavTile />
        </StyledTileWrapper>
      ) : null}
      <Wrapper $sideNavOpen={sideNavOpen} backColor={containerBackColor}>
        {menu}
      </Wrapper>
      <Footer sideNavOpen={sideNavOpen} />
    </>
  );
};

export default Container;
