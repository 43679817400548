import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_ASSETS } from 'actions/types';

/**
  @description Get a list of all assets
  @param {{
    companyId : string,
    lowerDate : string,
    upperDate : string,
  }} params
  @returns Array of asset objects
 */
function getAssets({ companyId, lowerDate, upperDate }) {
  /**
    @param {Function} dispatch
    @param {Function} getState
   */
  return (dispatch, getState) => {
    const {
      storages: { data: storages },
    } = getState();

    /**
         Guard to ensure we're not iterating over an empty array.
         Which will pipe error to apiClient making HTTP request.
       */
    if (storages?.length > 0) {
      const hasDate = !!lowerDate && !!upperDate;
      const dateFilter = hasDate
        ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
        : '';
      const storageIds = [...storages].map(({ id }) => id);
      const query = `?where={"company_id": "${companyId}", "storage_id": "in::${storageIds.toString()}", "status": "${active}"${dateFilter}}`;

      const action = {
        type: GET_ASSETS,
        /**
          @param {Function} apiClient
         */
        request: async (apiClient) => {
          const items = await apiClient(endPoints.GET_ASSETS, {}, query);

          return items;
        },
      };

      dispatch(action);
    }
  };
}

export default getAssets;
