import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const modalProps = css`
  top: 9%;
  left: 5%;
  width: 90%;
`;
const bodyProps = css`
  height: auto;
  overflow-y: auto;
  max-height: 600px;
  overflow-x: hidden;
`;
const borderRadius = css`
  --borderRadius: ${({ theme }) => theme.borderRadius};
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
`;

export const ModalBackDrop = styled(motion.div)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const StyledModalBody = styled(motion.div)`
  padding: 10px;
  button.modal-button,
  button.modal-cancel-button {
    margin-left: 15px;
  }
  /* button.modal-cancel-button */
  div.modal-buttons-row {
    button:nth-child(2) {
      margin-left: 10px;
    }
  }
  button.modal-button,
  button.modal-cancel-button {
    min-width: 200px !important;
  }
  /* Mobile Devices */
  @media (max-width: 480px) {
    button.modal-button {
      margin-left: 0;
    }
    button.modal-cancel-button {
      margin-left: 0;
    }
    button.modal-button,
    button.modal-cancel-button {
      min-width: 45% !important;
    }
    div.modal-buttons-row {
      flex-direction: row !important;
      button:nth-child(2) {
        margin-left: 10%;
      }
    }
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 10px;
    padding-left: 25px;
    padding-right: 40px;
    button.modal-button,
    button.modal-cancel-button {
      min-width: 45% !important;
    }
    div.modal-buttons-row {
      flex-direction: row !important;
      button:nth-child(2) {
        margin-left: 10%;
      }
    }
  }
`;

export const StyledModal = styled(motion.div)`
  z-index: 1000;
  max-width: 90%;
  min-width: 40%;
  position: fixed;
  max-height: 95%;
  top: ${({ modalTop }) => modalTop}%;
  left: ${({ modalLeft }) => modalLeft}%;
  width: ${({ modalWidth }) => modalWidth}%;
  background: ${({ theme }) => theme.modalBackground};
  border-radius: ${({ theme }) => theme.borderRadius};
  /* Mobile Devices */
  @media (max-width: 480px) {
    ${modalProps}
    top: 2%;
    ${function ({ $confirm }) {
      return $confirm
        ? css`
            top: 10%;
            left: 15px;
            width: 380px;
            min-width: 150px;
          `
        : css`
            left: 0;
            top: auto;
            bottom: 0;
            ${borderRadius};
          `;
    }}
    ${StyledModalBody} {
      ${bodyProps}
      max-height: 690px;
      margin-bottom: 10px;
      ${({ theme }) => theme.scrollbar}
    }
  }
  /* Tablets and Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
    ${modalProps}
    ${function ({ $confirm }) {
      if ($confirm) {
        return css`
          top: 9%;
          left: 15%;
          width: 70%;
        `;
      }
      return modalProps;
    }}
    ${StyledModalBody} {
      ${bodyProps}
      max-height: 750px;
    }
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    ${modalProps}
    ${StyledModalBody} {
      ${bodyProps}
      max-height: 950px;
      margin-bottom: 10px;
      ${({ theme }) => theme.scrollbar}
    }
  }
  /* Laptops and Desktops */
  @media (min-width: 1025px) and (max-width: 1280px) {
    ${StyledModalBody} {
      ${bodyProps}
    }
    ${function ({ $confirm }) {
      if ($confirm) {
        return css`
          top: 9%;
          left: 25%;
          width: 50%;
        `;
      }
      return modalProps;
    }}
  }
`;

export const StyledModalHeader = styled(motion.h1)`
  margin-top: 0;
  ${borderRadius}
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.modalCaption};
  background: ${({ theme }) => theme.background};
`;

export const StyledCloseButton = styled(motion.button)`
  border: none;
  height: 20px;
  float: right;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.background};
  transition: ${({ theme }) => theme.transitionSpeed};
  &:focus {
    outline: none;
  }
  svg {
    margin: 0;
    width: 2rem;
    height: 20px;
    min-width: 2rem;
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.modalCaption};
    &:hover {
      transform: rotate(120deg);
      filter: grayscale(0%) opacity(1);
      fill: ${({ theme }) => theme.textColor};
      transition: ${({ theme }) => theme.transitionSpeed};
    }
  }
`;
