import React, { useState } from 'react';
import { arrayOf, func, object, bool } from 'prop-types';

import Loader from 'img/loader.gif';
import {
  StyledLoader,
  StyledCaption,
  StyledCondition,
  StyledDescription,
  StyledConditionWrapper,
} from 'components/Condition/Condition.styled';
import { InfoAlert } from 'components/Alerts/';
import { INVENTORY } from 'constants/inventory';
import { CancelButton } from 'components/Buttons/';
import { active, disabled } from 'constants/status';
import { FlexCol, FlexRow } from 'components/Layout/';
import { EditInput, InputButton } from 'components/Inputs/';
import { ReactComponent as EditIcon } from 'icons/pencil_grey.svg';
import { ReactComponent as DeleteIcon } from 'icons/cancel_sm.svg';

/**
 * @param {{
 * initialValue : []
 * onSubmit : Function
 * isLoading : boolean
 * onModalClose : Function
 * }} param
 */
function Condition({ onSubmit, initialValue, isLoading, onModalClose }) {
  const reserved = ['expired'];
  const [condition, setCondition] = useState('');
  const [conditionEdit, setConditionEdit] = useState('');
  const [conditionError, setConditionError] = useState('');
  const [conditionErrorEdit, setConditionErrorEdit] = useState('');
  const [selectedCondition, setSelectedCondition] = useState(null);

  function onCreateCondition() {
    if (condition) {
      if (reserved.includes(condition.toLocaleLowerCase())) {
        setConditionError(`Condition "${condition}" is reserved`);
        return;
      }
      const isEditing = false;
      const isDeleting = false;
      const payload = {
        status: active,
        name: condition,
        status_type: INVENTORY,
      };
      onSubmit(payload, isEditing, isDeleting);
      setSelectedCondition(null);
    } else {
      setConditionError('Enter condition');
    }
  }

  /**
   * @param {{
   * currentTarget : {
   * value : string
   * }
   * }} event
   */
  function onConditionChange(event) {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter condition';
    setCondition(value);
    setConditionError(errorMsg);
  }

  /**
   * @param {{
   * currentTarget : {
   * value : string
   * }
   * }} event
   */
  function onConditionEditChange(event) {
    const { currentTarget } = event;
    const { value } = currentTarget;
    const errorMsg = value.length > 0 ? '' : 'Enter condition';
    setConditionEdit(value);
    setConditionErrorEdit(errorMsg);
  }

  /**
   * @param {{
   * key : string
   * }} event
   */
  function onConditionKeyDown(event) {
    if (event.key === 'Enter') {
      onCreateCondition();
    }
  }

  /**
   * @param {{
   * key : string
   * }} event
   */
  function onConditionEditKeyDown(event) {
    if (event.key === 'Enter') {
      onConditionClick(selectedCondition);
    }
  }

  /**
   * @param {{
   * name: string
   * id: string
   * }} data
   */
  function onConditionClick(data) {
    const { id, name } = data;
    // Editing
    if (selectedCondition?.id === id) {
      // When user clicks edit, does nothing, then clicks update button
      if (conditionEdit === name) {
        setSelectedCondition(null);
      } else {
        if (conditionEdit) {
          if (reserved.includes(conditionEdit.toLocaleLowerCase())) {
            setConditionError(`Condition "${conditionEdit}" is reserved`);
            return;
          }
          const { id } = selectedCondition;
          const isEditing = true;
          const isDeleting = false;
          const payload = {
            id: id,
            name: conditionEdit,
          };
          onSubmit(payload, isEditing, isDeleting);
          setSelectedCondition(null);
        }
      }
    } else {
      setSelectedCondition(data);
      setConditionEdit(unescape(name));
    }
    setConditionErrorEdit('');
  }

  /**
   * @param {{
   * id: string
   * }} data
   */
  function onDeleteClick(data) {
    const { id } = data;
    const isEditing = true;
    const isDeleting = true;
    const payload = {
      id: id,
      status: disabled,
    };
    onSubmit(payload, isEditing, isDeleting);
  }

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledConditionWrapper>
            <StyledCaption>
              Name
              <StyledDescription>
                This is the health of an item e.g. New, Damaged etc
              </StyledDescription>
            </StyledCaption>
            <InputButton
              value={condition}
              dataTestId="condition"
              onClick={onCreateCondition}
              onChange={onConditionChange}
              placeholder="Enter condition"
              errorMessage={conditionError}
              onKeyDown={onConditionKeyDown}
            />
            {isLoading ? <StyledLoader src={Loader} alt="Loader" /> : null}
            <StyledCondition>
              {!initialValue?.length ? (
                <InfoAlert message="No Condition Data" />
              ) : (
                initialValue?.map(function (item) {
                  const { name, id } = item;
                  const dataTestId = name.replace(' ', '-');
                  const inputTestId = `input-${dataTestId}`;
                  const conditionTestId = `condition-${dataTestId}`;
                  const isEditing = selectedCondition?.id === id;
                  const value = isEditing ? conditionEdit : name;
                  const editTitle = `Click to edit : ${name}`;
                  const deleteTitle = `Click to delete : ${name}`;
                  const editButtonTestId = `edit-button-${dataTestId}`;
                  const deleteButtonTestId = `delete-button-${dataTestId}`;
                  return (
                    <EditInput
                      key={id}
                      value={value}
                      isEditing={isEditing}
                      editIcon={<EditIcon />}
                      inputTestId={inputTestId}
                      deleteIcon={<DeleteIcon />}
                      editButtonTitle={editTitle}
                      data-testid={conditionTestId}
                      placeholder="Update condition"
                      deleteButtonTitle={deleteTitle}
                      onChange={onConditionEditChange}
                      errorMessage={conditionErrorEdit}
                      onKeyDown={onConditionEditKeyDown}
                      editButtonTestId={editButtonTestId}
                      deleteButtonTestId={deleteButtonTestId}
                      onDeleteClick={onDeleteClick.bind(null, item)}
                      onEditClick={onConditionClick.bind(null, item)}
                    />
                  );
                })
              )}
            </StyledCondition>
          </StyledConditionWrapper>
        </FlexCol>
      </FlexRow>
      {onModalClose ? (
        <FlexRow className="modal-buttons-row">
          <CancelButton onClick={onModalClose} />
        </FlexRow>
      ) : null}
    </>
  );
}

Condition.propTypes = {
  onSubmit: func,
  isLoading: bool,
  onModalClose: func,
  initialValue: arrayOf(object),
};

export default Condition;
