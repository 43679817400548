import React, { useEffect, useState } from 'react';

import {
  StyledSearch,
  StyledFormGroup,
  StyledSrOnlyLabel,
} from 'components/Table/Search/Search.styled';

function Search({
  caption,
  inputId,
  onChange,
  width = 200,
  debounce = 500,
  filter = false,
  value: initialValue,
  placeholder = 'Search...',
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  function onInputChange(evt) {
    setValue(evt.target.value);
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = window.setTimeout(function () {
      onChange(value);
    }, debounce);

    return function () {
      return window.clearTimeout(timeout);
    };
  }, [value]);

  const Input = (
    <StyledSearch
      {...props}
      id={inputId}
      value={value}
      $width={width}
      $filter={filter}
      onChange={onInputChange}
      placeholder={placeholder}
    />
  );

  if (filter) {
    return (
      <StyledFormGroup>
        <StyledSrOnlyLabel htmlFor={inputId}>{caption}</StyledSrOnlyLabel>
        {Input}
      </StyledFormGroup>
    );
  }

  return Input;
}

export default Search;
