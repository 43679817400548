import { css } from 'styled-components';

import {
  white,
  primary,
  darkGrey,
  lightGrey,
  secondary,
} from 'constants/color';

const whiteColor = white;
const navBarSize = '60px';
const blackColor = '#000';
const borderRadius = '8px';
const menuBarSize = '40px';
const darkColor = '#363537';
const boxPaddingTop = '5px';
const bottomNavHeight = '5rem';
const invalidColor = '#dd4b39';
const boxPaddingSides = '35px';
const menuBarMargin = '0.7rem';
const transitionSpeed = '500ms';
const sidebarWidthMax = '15rem';
const sidebarWidthMin = '.5rem';
const bodyBackColor = lightGrey;
const secondaryColor = secondary;
const sidebarTextLeftMax = '6rem';
const sidebarTextLeftMin = '-999px';
const tableBorder = '1px solid #ddd';
const boxShadow = '0 0 0.9rem #00000080';
const focusedInputColor = secondaryColor;
const inputBorder = `1px solid ${primary}`;
const activeMenuBarBorder = `2px solid ${primary}`;
const mobileViewHeight = `calc(100vh - calc(${navBarSize} + ${menuBarSize}))`;

const disabled = css`
  color: #a0a0a0;
  cursor: not-allowed;
  background-color: #f0f0f0;
  border: 1px solid #d0d0d0;
`;

const scrollbar = css`
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: #1e1e24;
  }

  &::-webkit-scrollbar-thumb {
    background: #6649b8;
  }
`;

export const appTheme = {
  darkGrey,
  disabled,
  boxShadow,
  scrollbar,
  whiteColor,
  navBarSize,
  inputBorder,
  menuBarSize,
  tableBorder,
  borderRadius,
  invalidColor,
  bodyBackColor,
  boxPaddingTop,
  menuBarMargin,
  secondaryColor,
  boxPaddingSides,
  transitionSpeed,
  sidebarWidthMax,
  bottomNavHeight,
  sidebarWidthMin,
  mobileViewHeight,
  focusedInputColor,
  sidebarTextLeftMin,
  sidebarTextLeftMax,
  textColor: primary,
  activeMenuBarBorder,
  tabColor: whiteColor,
  borderColor: primary,
  primaryColor: primary,
  background: '#f8f8f8',
  modalCaption: primary,
  tabBackColor: primary,
  inputIconColor: '#777',
  tableHeaderColor: primary,
  buttonBackColor: darkColor,
  tableHoverColor: '#f5f5f5',
  inputBackColor: whiteColor,
  modalBackground: whiteColor,
  buttonHoverColor: blackColor,
  wrapperBackColor: whiteColor,
  tabHoverColor: secondaryColor,
  backgroundDark: bodyBackColor,
  bodyBackground: bodyBackColor,
  activeTabBackColor: whiteColor,
  activeTabTextColor: blackColor,
  border: `1px solid ${primary}`,
  wrapperBorderTop: `3px solid ${blackColor}`,
  tabHoverBorderTop: `3px solid ${blackColor}`,
  activeSidebarNavBorder: `5px solid ${primary}`,
  activeTabBorderTop: `3px solid ${secondaryColor}`,
};
