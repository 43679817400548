import styled from 'styled-components';

export const StyledNavItem = styled.li`
  margin: 4px;
  padding: 7px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: filter 300ms;
  background-color: ${({ theme }) => theme.primaryColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin: 1px;
    padding: 1px;
  }
`;

export const IconButton = styled.button`
  --button-size: calc(${({ theme }) => theme.navBarSize} * 0.9);

  width: var(--button-size);
  height: var(--button-size);
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.primaryColor};

  margin: 2px;
  padding: 5px;
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;

  svg {
    fill: ${({ theme }) => theme.whiteColor};
    width: 30px;
    height: 30px;
  }

  &:hover {
    filter: brightness(1.2);
  }

  &:focus {
    outline: none;
  }
`;

export const StyledNotificationWrapper = styled.div`
  border: none;
  display: flex;
  outline: none;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const StyledNotificationIndicator = styled.label`
  top: -7px;
  width: 19px;
  right: -5px;
  height: 19px;
  display: flex;
  font-size: 32px;
  cursor: pointer;
  font-weight: 600;
  position: absolute;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;
