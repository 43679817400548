import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Email } from 'components/Order/';
import Modal from 'containers/Modal/Modal';
import { shareOrder } from 'actions/order/';
import { clearModal } from 'actions/modals/';
import { EMAIL_MODAL } from 'constants/modal';

function EmailModal() {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const isOpen = modal?.type === EMAIL_MODAL;
  const order = isOpen && modal?.initialValue;
  const modalCaption = 'Share order to email';

  function onModalClose() {
    dispatch(clearModal(EMAIL_MODAL));
  }

  /**
   * @param {{}} payload
   */
  function onSubmit(payload) {
    const data = { ...payload, company_id: user.company_id };
    dispatch(shareOrder({ payload: data }));
  }

  return (
    <Modal
      modalTop={25}
      modalLeft={30}
      isOpen={isOpen}
      modalWidth={40}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <Email order={order} onSubmit={onSubmit} />
    </Modal>
  );
}

export default EmailModal;
