import React, { useRef, useState } from 'react';
import { string, oneOfType, element, bool, node, number } from 'prop-types';

import {
  StyledBody,
  StyledHeader,
  StyledCaption,
  StyledIconWrapper,
  StyledBodyWrapper,
} from 'components/Collapsible/Collapsible.styled';
import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';

/**
 * @param {{
 * children : JSX
 * open : boolean
 * caption : string
 * minHeight : number
 * backGroundColor : string
 * }} param
 */
function Collapsible({
  caption,
  children,
  open = false,
  minHeight = 310,
  backGroundColor = '',
}) {
  const easing = 'linear';
  const innerRef = useRef();
  const outerRef = useRef();
  const overflow = 'hidden';
  const transitionTime = 400;
  const [isOpen, setIsOpen] = useState(open);
  const height = isOpen ? 'auto' : 0;
  const [transition, setTransition] = useState(
    `height ${transitionTime}ms ${easing}`,
  );

  /**
   * @param {{
   * preventDefault: Function
   * }} event
   */
  function onCollapseToggle(event) {
    event.preventDefault();
    setTransition(`height ${transitionTime}ms ${easing}`);
    setIsOpen(!isOpen);
  }

  const dropdownStyle = {
    height,
    overflow: overflow,
    transition: transition,
    msTransition: transition,
    WebkitTransition: transition,
  };

  return (
    <>
      <StyledHeader data-testid="collapse-header" onClick={onCollapseToggle}>
        <StyledCaption>{caption}</StyledCaption>
        <StyledIconWrapper isOpen={isOpen}>
          <ChevronIcon />
        </StyledIconWrapper>
      </StyledHeader>
      <StyledBodyWrapper
        ref={outerRef}
        style={dropdownStyle}
        $backGroundColor={backGroundColor}
      >
        <StyledBody ref={innerRef} $minHeight={minHeight}>
          {children}
        </StyledBody>
      </StyledBodyWrapper>
    </>
  );
}

Collapsible.defaultProps = {
  open: true,
};

Collapsible.propTypes = {
  open: bool,
  minHeight: number,
  backGroundColor: string,
  caption: string.isRequired,
  children: oneOfType([string, element, node]),
};

export default Collapsible;
