import { endPoints } from 'api/';
import { GET_WAREHOUSE_USAGE } from 'actions/types';

/**
  @description Get a list of all warehouses and their inventory usage.
  @param {string} companyId Current user's company id.
  @returns Array of warehouse objects
 */
function getWarehouseUsage(companyId) {
  const query = `?company_id=${companyId}`;
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_WAREHOUSE_USAGE,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const usage = await apiClient(endPoints.GET_WAREHOUSE_USAGE, {}, query);

        return usage;
      },
    };

    dispatch(action);
  };
}

export default getWarehouseUsage;
