import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { permissionMock } from 'fixture/';
import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { USER_MODAL } from 'constants/modal';
import { createUser, updateUser } from 'actions/users/';
import UserForm from 'components/Users/UserForm/UserForm';

const UserModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);

  const isOpen = modal?.type === USER_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const modalCaption = isEditing ? 'Update User' : 'Create A User';
  const { initialValue } = modal;

  const onModalClose = () => {
    dispatch(clearModal(USER_MODAL));
  };

  /**
    @param {FormData} payload
   */
  const onSubmit = (payload) => {
    const { company_id: companyId } = user;
    const { id: userId } = initialValue || {};

    payload.append('company_id', companyId);

    if (isEditing) {
      payload.append('id', userId);

      dispatch(updateUser(userId, payload, companyId, false));
    } else {
      dispatch(createUser(payload, companyId, false));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} caption={modalCaption}>
      <UserForm
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
        permissions={permissionMock}
      />
    </Modal>
  );
};

export default UserModal;
