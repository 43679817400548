import styled from 'styled-components';
import Select from 'react-select/creatable';

import { maroon } from 'constants/color';

export const StyledFormGroup = styled.div`
  margin-bottom: 15px;
`;

export const StyledLabel = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  display: block;
  margin-top: 5px;
  font-size: 12px;
  box-shadow: none;
  appearance: none;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  -moz-appearance: none;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-appearance: none;
  transition: all 0.5s linear;
  transition: ${({ theme }) => theme.transitionSpeed};
  background-color: ${({ theme }) => theme.inputBackColor};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  ::-moz-placeholder,
  :-ms-input-placeholder,
  ::-webkit-input-placeholder {
    color: #bbb; // change to theme color ?
    opacity: 1;
  }

  div.creatable-select__control {
    min-height: 35px;
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    div.creatable-select__control {
      max-height: 200px;
    }
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-height: 150px;
  }
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 12px;
  font-weight: 400;
`;

export const StyledAsterisk = styled.span`
  font-weight: 500;
  padding-left: 2px;
  color: ${({ theme }) => theme.invalidColor};
`;
