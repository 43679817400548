import { endPoints } from 'api/';
import { GET_NOTIFICATION } from 'actions/types';

/**
 * @description Get a list of all notifications
 * @param {{
 * companyId : string,
 * }} params
 * @returns Array of notification objects
 */
function getNotifications({ companyId }) {
  const query = {
    where: {
      company_id: companyId,
    },
  };

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_NOTIFICATION,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const notifications = await apiClient(
          endPoints.GET_NOTIFICATION,
          {},
          query,
        );
        return notifications;
      },
    };
    dispatch(action);
  };
}

export default getNotifications;
