import styled from 'styled-components';

export const StyledDropdownMenu = styled.div`
  --whiteColor: ${({ theme }) => theme.whiteColor};
  --primaryColor: ${({ theme }) => theme.primaryColor};
  --borderRadius: ${({ theme }) => theme.borderRadius};

  border-radius: var(--borderRadius);
  border: ${({ theme }) => theme.border};
  top: calc(${({ theme }) => theme.navBarSize} * 1.9);
  background-color: ${({ theme }) => theme.background};
  transition: height ${({ theme }) => theme.transitionSpeed} ease;

  width: 250px;
  padding: 1rem;
  overflow: hidden;
  position: absolute;
  transform: translateX(-30%);

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 200px;
    transform: translateX(-38%);
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 200px;
    transform: translateX(-35%);
  }
`;

export const StyledMenuWrapper = styled.div`
  width: 100%;
`;

export const StyledUserNameWrapper = styled.div`
  background-color: var(--primaryColor);
  color: var(--whiteColor);
  border-radius: var(--borderRadius);

  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const StyledUserName = styled.label`
  width: 100%;
  padding-top: 5px;
  padding-left: 5px;
`;

export const StyledUserTitle = styled(StyledUserName)`
  font-size: 13px;
  font-style: italic;
`;
