import { SET_ACTIVE_TOP_MENU } from 'actions/types';

const initialState = [];

/**
  @description Sets active top nav menu.
  @param {{}} state
  @param {{}} action
  @returns {{}}
 */
function activeTopMenu(state = initialState, action) {
  const { type: actionType, payload } = action;

  if (actionType === SET_ACTIVE_TOP_MENU) {
    return payload;
  }

  return state;
}

export default activeTopMenu;
