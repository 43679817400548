import styled, { css } from 'styled-components';

export const StyledNavButton = styled.button`
  color: ${({ theme }) => theme.whiteColor};
  background: ${({ theme }) => theme.primaryColor};
  transition: ${({ theme }) => theme.transitionSpeed};

  padding: 0;
  width: 100%;
  height: 3rem;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.secondaryColor};
    border-left: ${({ theme }) => theme.activeSidebarNavBorder};

    filter: grayscale(0%) opacity(1);

    &[data-active='true'] {
      border-left: ${({ theme }) => theme.activeSidebarNavBorder};
    }
  }

  &:focus {
    outline: none;
  }

  svg {
    fill: ${({ theme }) => theme.textColor};

    width: 1.5rem;
    margin: 0 1rem;
    min-width: 1.5rem;
  }

  svg.chevron {
    fill: ${({ theme }) => theme.textColor};

    padding: 0;
    width: 1rem;
    min-width: 1rem;
    margin-left: auto;
  }

  /* Small screens */
  @media only screen and (max-width: 600px) {
    justify-content: center;

    ${({ $active }) =>
      $active &&
      css`
        border: 0;
      `}
  }

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.secondaryColor};
      border-left: ${({ theme }) => theme.activeSidebarNavBorder};

      filter: grayscale(0%) opacity(1);
    `}
`;
