import {
  func,
  node,
  bool,
  string,
  object,
  number,
  oneOfType,
} from 'prop-types';
import React from 'react';

import { white, primary } from 'constants/color';
import { hoverAndTap } from 'constants/animation';
import {
  IconWrapper,
  StyledButton,
  StyledSpinner,
} from 'components/Buttons/RoundButton/RoundButton.styled';

/**
 * @param {{
 * ref : {}
 * icon : JSX
 * caption : string
 * tabIndex : number
 * fontSize : number
 * onClick : Function
 * className : string
 * flexWidth : number
 * backColor : string
 * foreColor : string
 * buttonType : string
 * isDisabled : boolean
 * borderColor : string
 * buttonTestId : string
 * formToSubmit : string
 * isSubmitting : boolean
 * iconFillColor : string
 * }} param
 */
function RoundButton({
  icon,
  caption,
  onClick,
  tabIndex,
  ref = null,
  borderColor,
  formToSubmit,
  buttonTestId,
  iconFillColor,
  fontSize = 15,
  flexWidth = 100,
  className = null,
  foreColor = white,
  isDisabled = false,
  backColor = primary,
  isSubmitting = false,
  buttonType = 'button',
}) {
  return (
    <StyledButton
      ref={ref}
      {...hoverAndTap}
      onClick={onClick}
      type={buttonType}
      fontSize={fontSize}
      form={formToSubmit}
      tabIndex={tabIndex}
      flexWidth={flexWidth}
      backColor={backColor}
      disabled={isDisabled}
      foreColor={foreColor}
      className={className}
      borderColor={borderColor}
      data-testid={buttonTestId}
      iconFillColor={iconFillColor}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {isSubmitting ? (
        <StyledSpinner $color={white} $indicator={primary} />
      ) : (
        caption
      )}
    </StyledButton>
  );
}

RoundButton.propTypes = {
  ref: object,
  onClick: func,
  caption: string,
  isDisabled: bool,
  fontSize: number,
  tabIndex: number,
  flexWidth: number,
  backColor: string,
  foreColor: string,
  className: string,
  buttonType: string,
  borderColor: string,
  formToSubmit: string,
  buttonTestId: string,
  iconFillColor: string,
  icon: oneOfType([node, object]),
};

export default RoundButton;
