import styled from 'styled-components';

const color = '#9b2012';

export const Wrapper = styled.section`
  margin: auto;
  padding: 32px;
  font-size: 16px;
  color: ${color};
  background-color: #f5c0ba;
`;

export const StyledHeader = styled.h1`
  margin: 8px 0;
  font-size: 28px;
  color: ${color};
  line-height: 32px;
`;

export const StyledDescription = styled.p`
  margin: 8px 0;
`;

export const StyledButton = styled.button`
  width: 20%;
  border: none;
  color: white;
  height: 40px;
  cursor: pointer;
  box-shadow: none;
  margin-bottom: 0;
  padding: 6px 12px;
  text-align: center;
  white-space: nowrap;
  -moz-box-shadow: none;
  vertical-align: middle;
  -webkit-box-shadow: none;
  background-color: ${color};
  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover {
    background-color: #dd4b39;
  }
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
  }
`;
