import { endPoints } from 'api/';
import { PRINT } from 'constants/inventory';
import { EMAIL_ORDER, PRINT_ORDER } from 'actions/types';

/**
 * @description Shares order to email or print out.].
 * @param {{}} payload
 */
function share({ payload }) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const { option } = payload;
    const print = option === PRINT;
    const type = print ? PRINT_ORDER : EMAIL_ORDER;
    const action = {
      type,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const shared = await apiClient(endPoints.SHARE_ORDER, payload);
        const { data } = shared;
        if (print) {
          const blob = new Blob([data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          if (shared.data) {
            return shared;
          }
          return {
            ...shared,
            data: {
              response: 'Order successfully shared',
            },
          };
        }
        return shared;
      },
    };
    dispatch(action);
  };
}

export default share;
