import styled from 'styled-components';

export const StyledBody = styled.div`
  text-align: left;
  margin-bottom: 10px;
`;

export const StyledFooter = styled.div`
  text-align: right;
  margin: 10px 0 10px 0;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
  /* Mobile Devices */
  @media (max-width: 480px) {
    button {
      width: 45% !important;

      &:last-child {
        margin-left: 30px;
      }
    }
  }
  /* Tablets and Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
    button {
      width: 45% !important;
      &:last-child {
        margin-left: 30px;
      }
    }
  }
`;

export const StyledDashLine = styled.div`
  width: 100%;
  height: 1px;
  border: none;
  margin: 2px 0 0 auto;
  background-color: transparent;
  border-top: 1px dashed ${({ theme }) => theme.primaryColor};
`;
