import { endPoints } from 'api/';
import { GET_USERS } from 'actions/types';
import { active } from 'constants/status';

/**
  @description Get a list of all users
  @param {{
    companyId : string,
    lowerDate : string,
    upperDate : string,
  }} params
  @returns Array of user objects
 */
function getUsers({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "${active}"${dateFilter}}`;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_USERS,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const users = await apiClient(endPoints.GET_USERS, {}, query);

        return users;
      },
    };

    dispatch(action);
  };
}

export default getUsers;
