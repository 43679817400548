/**
 * @param {number} lowerDate
 * @param {number} upperDate
 * @param {'<' | '<=' | '===' | '>' | '>='} operator
 * @returns boolean
 */
function compareDate(lowerDate, upperDate, operator) {
  let result = false;
  const startDate = new Date(lowerDate).setHours(0, 0, 0, 0);
  const finishDate = new Date(upperDate).setHours(0, 0, 0, 0);

  switch (operator) {
    case '<':
      result = startDate < finishDate;
      break;
    case '<=':
      result = startDate <= finishDate;
      break;
    case '>':
      result = startDate > finishDate;
      break;
    case '>=':
      result = startDate >= finishDate;
      break;
    case '===':
      result = startDate === finishDate;
      break;
    default:
      result = false;
      break;
  }

  return result;
}

export default compareDate;
