import React from 'react';
import { string, func } from 'prop-types';

import {
  StyledMarker,
  StyledMarkerInfo,
} from 'components/Map/Marker/Marker.styled';

/**
 * @param {{
 * text: string,
 * onClick: Function,
 * }} param
 */
function Marker({ onClick, text }) {
  return (
    <>
      <StyledMarkerInfo>{text}</StyledMarkerInfo>
      <StyledMarker onClick={onClick}></StyledMarker>
    </>
  );
}

Marker.propTypes = {
  text: string,
  onClick: func,
};

export default Marker;
