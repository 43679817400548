import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_WAREHOUSES } from 'actions/types';

/**
  @description Get a list of all warehouses
  @param {{
    companyId : string,
    lowerDate : string,
    upperDate : string,
  }} params
  @returns Array of warehouse objects
 */
function getWarehouses({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "${active}" ${dateFilter}}`;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_WAREHOUSES,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const warehouses = await apiClient(endPoints.GET_WAREHOUSES, {}, query);

        return warehouses;
      },
    };

    dispatch(action);
  };
}

export default getWarehouses;
