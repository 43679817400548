import styled from 'styled-components';

const fontSize = '15px';
const smFontSize = '13px';

export const StyledLink = styled.a`
  border: none;
  filter: none;
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  align-items: center;
  font-size: ${fontSize};
  color: ${({ theme }) => theme.primaryColor};

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${smFontSize};
  }
`;

export const StyledCopyright = styled.label`
  border: none;
  filter: none;
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
  font-size: ${fontSize};
  color: ${({ theme }) => theme.primaryColor};

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${smFontSize};
  }
`;

export const StyledVersion = styled.label`
  font-size: ${fontSize};
  color: ${({ theme }) => theme.whiteColor};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.primaryColor};

  border: none;
  filter: none;
  height: 10px;
  display: flex;
  cursor: pointer;
  padding: 0.5rem;
  margin-top: auto;
  align-items: center;
  margin-bottom: auto;
  vertical-align: middle;

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${smFontSize};
  }
`;
