import styled from 'styled-components';

import { mediumGrey } from 'constants/color';

export const StyledInfo = styled.p`
  --borderRadius: ${({ theme }) => theme.borderRadius};

  width: 100%;
  padding: 5px 0;
  text-align: center;
  background-color: ${mediumGrey};
  border-radius: var(--borderRadius);
`;
