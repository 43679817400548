import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { transformActions } from 'utils/';

/**
 * @description Custom hook to handle http status where `status` is `SUCCESS`, `FAILURE` or ALL
 * @param {{
 * actions: [],
 * status: string,
 * callBack: function,
 * resetCallBack: boolean
 * }} param
 */
function useHttpStatus({
  actions,
  callBack,
  status = 'SUCCESS',
  resetCallBack = false,
}) {
  const target = transformActions(actions, status);
  if (status === 'ALL') {
    const success = transformActions(actions, 'SUCCESS');
    const failure = transformActions(actions, 'FAILURE');
    target.push(...success, ...failure);
  }
  let { action, response } = useSelector(function ({ notify }) {
    return notify;
  });
  const positive = target.includes(action);
  const result = useMemo(() => {
    if (!positive) {
      response = null;
    }
    if (callBack) {
      callBack(resetCallBack ? false : response);
    }
    return positive;
  }, [action, response]);
  return { result, response };
}

export default useHttpStatus;
