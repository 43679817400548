import React from 'react';
import { bool, func, number, object, oneOfType, string } from 'prop-types';

import {
  StyledLabel,
  InputWrapper,
  StyledAsterisk,
  StyledInputIcon,
  StyledFormGroup,
  InputErrorLabel,
  StyledDatePicker,
  StyledSrOnlyLabel,
} from 'components/DatePicker/DatePicker.styled';
import { ReactComponent as CalendarIcon } from 'icons/calendar.svg';

/**
 * @param {{
 * name : string
 * inputId : string
 * caption : string
 * tabIndex : number
 * selected : string
 * disabled : boolean
 * onChange : Function
 * isInvalid : boolean
 * isRequired : boolean
 * placeholder : string
 * showCaption : boolean
 * errorMessage : string
 * marginBottom : number
 * iconTopMargin : number
 * }} param
 */
function DatePicker({
  name,
  inputId,
  caption,
  onChange,
  tabIndex,
  selected,
  isInvalid,
  placeholder,
  errorMessage,
  iconTopMargin,
  disabled = false,
  marginBottom = 15,
  showCaption = true,
  isRequired = false,
}) {
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const Label = showCaption ? StyledLabel : StyledSrOnlyLabel;

  return (
    <StyledFormGroup $marginBottom={marginBottom}>
      <Label htmlFor={inputId}>{caption}</Label>
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      <InputWrapper disabled={disabled}>
        <StyledDatePicker
          name={name}
          id={inputId}
          showYearDropdown
          selected={selected}
          onChange={onChange}
          tabIndex={tabIndex}
          disabled={disabled}
          scrollableYearDropdown
          isInvalid={invalidInput}
          dateFormat={'yyyy-MM-dd'}
          placeholderText={placeholder}
        />
        <StyledInputIcon
          top={iconTopMargin}
          $disabled={disabled}
          isInvalid={invalidInput}
        >
          <CalendarIcon />
        </StyledInputIcon>
      </InputWrapper>
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledFormGroup>
  );
}

DatePicker.defaultProps = {
  isInvalid: false,
  iconTopMargin: 30,
};

DatePicker.propTypes = {
  name: string,
  onChange: func,
  disabled: bool,
  isInvalid: bool,
  inputId: string,
  caption: string,
  isRequired: bool,
  tabIndex: number,
  showCaption: bool,
  placeholder: string,
  marginBottom: number,
  errorMessage: string,
  iconTopMargin: number,
  selected: oneOfType([object, string, number]),
};

export default DatePicker;
