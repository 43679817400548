import { endPoints } from 'api/';
import { getTasks } from 'actions/task/';
import { CREATE_TASK } from 'actions/types';

/**
 * @description Creates task object
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 */
function create(payload, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_TASK,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const task = await apiClient(endPoints.CREATE_TASK, payload);
        dispatch(getTasks({ companyId }));
        return task;
      },
    };
    dispatch(action);
  };
}

export default create;
