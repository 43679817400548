import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  LOCK_SCREEN_PATH,
  NOTIFICATION_PATH,
  USER_LOCAL_STORAGE,
} from 'constants/general';
import { history } from 'store/store';
import { signOut } from 'actions/users';
import { useLocalStorage } from 'hooks/';
import {
  StyledNav,
  NavbarNav,
  CompanyName,
  NavLogoWrapper,
  StyledUserImage,
} from 'containers/Navbar/Navbar.styled';
import { showModal } from 'actions/modals';
import { USER_PROFILE_MODAL } from 'constants/modal';
import NavItem from 'components/Navbar/NavItem/NavItem';
import { ReactComponent as BellIcon } from 'icons/bell.svg';
import { ReactComponent as UserIcon } from 'icons/user.svg';
import { ReactComponent as WezzaLogo } from 'icons/wezza_logo_one.svg';
import DropdownMenu from 'components/Navbar/DropdownMenu/DropdownMenu';

const Navbar = () => {
  const dispatch = useDispatch();

  const { data: notifications } = useSelector(
    ({ notification }) => notification,
  );
  const user = useSelector(({ user }) => user);
  const company = useSelector(({ company }) => company);
  const [, setLocalUser] = useLocalStorage(USER_LOCAL_STORAGE, null);
  const { name: companyName = 'Company Name' } = company || {};

  const {
    photo,
    usertype: userType,
    last_name: lastName,
    first_name: firstName,
  } = user || {};
  const userName = `${firstName} ${lastName}`;
  const userPhoto = photo ? (
    <StyledUserImage src={photo} alt={userName} />
  ) : (
    <UserIcon />
  );

  const onSignOut = () => {
    dispatch(signOut());

    window.localStorage.removeItem(USER_LOCAL_STORAGE);
  };

  const onLockScreen = () => {
    setLocalUser(user);

    dispatch(signOut());

    window.location.assign(LOCK_SCREEN_PATH);
  };

  const onShowProfileModal = () => {
    dispatch(showModal(USER_PROFILE_MODAL));
  };

  function onGoToNotification() {
    history.push(NOTIFICATION_PATH);
  }

  return (
    <StyledNav>
      <NavLogoWrapper>
        <WezzaLogo />
      </NavLogoWrapper>
      <CompanyName>{companyName}</CompanyName>
      <NavbarNav>
        <NavItem
          icon={<BellIcon />}
          onClick={onGoToNotification}
          notification={notifications?.length}
        />
        <NavItem icon={userPhoto}>
          <DropdownMenu
            userName={userName}
            userType={userType}
            onSignOut={onSignOut}
            onLockScreen={onLockScreen}
            onShowProfileModal={onShowProfileModal}
          />
        </NavItem>
      </NavbarNav>
    </StyledNav>
  );
};

export default Navbar;
