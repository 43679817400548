export const white = '#FFF';
export const green = '#54CC7C';
export const black = '#5D5D5D';
export const maroon = '#EC0404';
export const orange = '#F36F1C';
export const mustard = '#FCBC0C';
export const primary = '#38AEC2';
export const invalid = '#DD4B39';
export const darkGrey = '#5D5D5D';
export const secondary = '#097F98';
export const lightGrey = '#F4F4F4';
export const mediumGrey = '#C4C4C4';
export const secondaryDark = '#098199';
