import styled from 'styled-components';

export const StyledFooter = styled.footer`
  --sidebarWidthMax: ${({ theme }) => theme.sidebarWidthMax};

  background-color: ${({ theme }) => theme.bodyBackColor};
  margin-left: ${function ({ sideNavOpen }) {
    return sideNavOpen ? 'var(--sidebarWidthMax)' : 'var(--sidebarWidthMin)';
  }};

  padding: 5px;
  display: flex;
  position: sticky;
  align-items: center;
  justify-content: space-between;

  /* Mobile Devices */
  @media (max-width: 480px) {
    display: none;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0;
  }
`;
