import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { maroon } from 'constants/color';

const fontSize = '11px';

export const ImagePlaceholder = styled(motion.section)`
  --whiteColor: ${({ theme }) => theme.whiteColor};
  --primaryColor: ${({ theme }) => theme.primaryColor};
  --borderRadius: ${({ theme }) => theme.borderRadius};
  --secondaryColor: ${({ theme }) => theme.secondaryColor};

  border-radius: var(--borderRadius);

  width: 160px;
  height: 90px;
  display: flex;
  align-items: left;
  justify-content: left;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100px;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100px;
  }
`;

export const StyledPreviewImage = styled(motion.img)`
  --borderRadius: ${({ theme }) => theme.borderRadius};
  border-radius: var(--borderRadius);

  width: 100px;
  height: 90px;
  object-fit: cover;
`;

export const FileInputContainer = styled.div`
  width: 100%;
  align-items: left;
  justify-content: space-between;

  input {
    font-size: ${fontSize};
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-bottom: 20px;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const StyledFileInput = styled(motion.input)`
  border: 0;
  width: 1px;
  padding: 0;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip-path: inset(50%);
  clip: rect(0, 0, 0, 0);
`;

export const ImagePreviewWrapper = styled(motion.span)`
  display: flex;
  margin-top: 5px;
`;

export const FileInputWrapper = styled(motion.span)`
  display: flex;
  margin-left: 10px;
  flex-direction: column;
`;

export const FileInputLabel = styled(motion.label)`
  font-size: ${fontSize};

  width: 120px;
  cursor: pointer;
  text-align: left;
  margin-bottom: 0;
  display: inline-block;

  ${function ({ hasFileName }) {
    return (
      !hasFileName &&
      css`
        margin-top: 15px;
      `
    );
  }}
`;

export const SelectedFileName = styled(motion.label)`
  font-size: ${fontSize};

  max-width: 100%;
  overflow: hidden;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;

  /* Mobile Devices */
  @media (max-width: 480px) {
    max-width: 180px;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    max-width: 300px;
  }
`;

export const StyledCaption = styled(motion.label)`
  color: var(--primaryColor);
  font-size: ${fontSize};

  font-weight: 500;
  line-height: 16px;
  font-style: normal;
`;

export const StyledRemoveButton = styled(motion.button)`
  font-size: ${fontSize};

  width: 50px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -6px;
  background: transparent;
`;

export const InputErrorLabel = styled.label`
  color: ${maroon};
  font-size: 11px;
  font-weight: 400;
`;
