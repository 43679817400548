import { endPoints } from 'api/';
import { getAssets } from 'actions/assets/';
import { DELETE_ASSET, UPDATE_ASSET } from 'actions/types';

/**
  @description Updates asset object. This will also act as `delete` action by `disabling` status.
  @param {string} assetId
  @param {FormData} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
  @returns Asset object
 */
function updateAsset(assetId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_ASSET : UPDATE_ASSET;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const asset = await apiClient(endPoints.UPDATE_ASSET, payload, assetId);

        dispatch(getAssets({ companyId }));

        return asset;
      },
    };

    dispatch(action);
  };
}

export default updateAsset;
