import { objectHasProperty } from 'utils/';
import geocodeErrors from 'constants/geocodeErrors';

/**
  @description Geocode google map location address
  @param {{}} mapInstance
  @param {{}} coordinates
  @param {Function} callback
  @returns
 */
function geocodeAddress(mapInstance, coordinates, callback) {
  const validCoordinates =
    coordinates &&
    objectHasProperty(coordinates, 'lat') &&
    objectHasProperty(coordinates, 'lng');

  if (!mapInstance) {
    return callback(geocodeErrors.MAP_NOT_INITIALISED);
  }

  if (!validCoordinates) {
    return callback(geocodeErrors.INVALID_COORDINATES);
  }

  const geocoder = new mapInstance.Geocoder();

  geocoder.geocode({ location: { ...coordinates } }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        const [streetObj] = results;
        const { formatted_address: street } = streetObj;
        callback(street);
      } else {
        callback(geocodeErrors.NO_RESULTS);
      }
    } else {
      window.console.info('Geocoder failed due to: ' + status);
      callback(geocodeErrors.UNABLE_TO_GEOCODE);
    }
  });
}

export default geocodeAddress;
