import { endPoints } from 'api/';
import { ASC } from 'constants/general';
import { GET_ORDERS } from 'actions/types';

/**
 * @description Query orders
 * @param {{
 *   companyId : string,
 *   lowerDate : string,
 *   upperDate : string,
 * }} params
 * @returns Array of order objects
 */
function query({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}"${dateFilter}}&order={"createdat":"${ASC}"}`;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_ORDERS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const orders = await apiClient(endPoints.GET_ORDERS, {}, query);
        return orders;
      },
    };
    dispatch(action);
  };
}

export default query;
