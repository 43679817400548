import { endPoints } from 'api/';
import { GET_ASSIGNED_ORDERS } from 'actions/types';

/**
 * @description Get a list of all assigned orders
 * @param {string} companyId Current user's company id.
 * @returns Array of assigned objects
 */
function query(companyId) {
  const query = {
    where: {
      company_id: companyId,
    },
  };

  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: GET_ASSIGNED_ORDERS,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const assigned = await apiClient(
          endPoints.GET_ASSIGNED_ORDERS,
          {},
          query,
        );
        return assigned;
      },
    };
    dispatch(action);
  };
}

export default query;
