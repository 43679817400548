import styled from 'styled-components';

export const StyledTableSelector = styled.select`
  --primaryColor: ${({ theme }) => theme.primaryColor};

  width: 50px;
  height: 35px;
  font-size: 12px;
  font-weight: 500;
  box-shadow: none;
  transition: 500ms;
  line-height: 18px;
  margin-left: 20px;
  border-radius: 8px;
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  background-image: none;
  box-sizing: border-box;
  -webkit-transition: 0.5s;
  -webkit-transition: 500ms;
  color: var(--primaryColor);
  transition: all 0.5s linear;
  -webkit-transition: all 0.5s linear;
  border: 1px solid var(--primaryColor);

  &:focus {
    outline: 0;
    box-shadow: none;
    border: 1px solid;
  }
`;

export const StyledTableSelectorLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondaryColor};
`;
