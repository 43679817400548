import React, { useRef, useMemo } from 'react';

import {
  SALE,
  LEASE,
  EMAIL,
  PRINT,
  ARCHIVED,
  CANCELLED,
} from 'constants/inventory';
import { Tab } from 'components/Tab/';
import { history } from 'store/store';
import { useCheckTable } from 'hooks/';
import { Table } from 'components/Table/';
import { primary } from 'constants/color';
import { DropDown } from 'components/Buttons/';
import { Animation } from 'components/Loaders/';
import { TableButton } from 'components/Reusable/';
import { EDIT_ORDER_PATH, NEW_ORDER_PATH } from 'constants/general';
import { StyledWrapper } from 'components/Order/Record/Record.styled';
import { formatDate, getOrderNumber, capitalizeFirstLetter } from 'utils/';

function Record({ orders, onShare, onArchive, isLoading, isMobileView }) {
  const tableRef = useRef();
  const { checkBox, checkedIds, onCheckChange } = useCheckTable({});
  const columns = useMemo(() => {
    return [
      checkBox,
      {
        header: 'Order Name',
        accessorFn: function ({ name }) {
          return name;
        },
      },
      {
        header: 'Customer Name',
        accessorFn: function ({ customer }) {
          return customer?.name || '';
        },
      },
      {
        header: 'Order No',
        accessorFn: function ({ id }) {
          return getOrderNumber({ id, prefix: '#' });
        },
      },
      {
        header: 'Type',
        accessorKey: 'order_type',
        cell: function ({ getValue }) {
          return capitalizeFirstLetter(getValue());
        },
      },
      {
        meta: {
          date: true,
        },
        accessorKey: 'date',
        header: 'Order Date',
        cell: function ({ getValue }) {
          return formatDate({
            showTime: true,
            date: getValue(),
          });
        },
      },
      {
        header: 'Items',
        accessorKey: 'items',
        cell: function ({ getValue }) {
          return getValue();
        },
        accessorFn: function ({ items }) {
          return items.length;
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: function ({ getValue }) {
          return getValue();
        },
        accessorFn: function ({ status }) {
          return capitalizeFirstLetter(status);
        },
      },
      {
        header: 'Amount',
        accessorKey: 'total_cost',
        cell: function ({
          getValue,
          row: {
            original: { settings },
          },
        }) {
          const hidePrice = settings?.includeLeasePrice === false;
          const price = parseFloat(getValue()).toLocaleString();
          return hidePrice ? '-' : price;
        },
        accessorFn: function ({ total_cost: cost }) {
          return parseFloat(cost);
        },
      },
    ];
  }, [onCheckChange]);

  const saleOrders = useMemo(() => {
    return orders.filter(function ({ order_type: type }) {
      return type === SALE;
    });
  }, [orders]);

  const leaseOrders = useMemo(() => {
    return orders.filter(function ({ order_type: type }) {
      return type === LEASE;
    });
  }, [orders]);

  const activeOrders = useMemo(() => {
    const inactive = [ARCHIVED];
    return orders.filter(function ({ status }) {
      return !inactive.includes(status);
    });
  }, [orders]);

  const cancelledOrders = useMemo(() => {
    return orders.filter(function ({ status }) {
      return status === CANCELLED;
    });
  }, [orders]);

  const returnedItems = useMemo(() => {
    return orders.filter(function ({ items }) {
      return items.every(function (item) {
        return item?.returned === true;
      });
    });
  }, [orders]);

  const enableSharing = useMemo(() => {
    const inactive = [ARCHIVED];
    if (checkedIds.length === 1) {
      const order = orders.find(function ({ id }) {
        return id === checkedIds[0];
      });
      return !inactive.includes(order?.status);
    }
    return false;
  }, [checkedIds]);

  const statusOptions = [
    {
      value: PRINT,
      label: 'Print',
      disabled: !enableSharing,
    },
    {
      value: EMAIL,
      label: 'Email',
      disabled: !enableSharing,
    },
  ];

  const buttonStyle = {
    height: '35px',
    cursor: 'pointer',
    fontSize: '15px',
    fontStyle: 'normal',
    borderRadius: '0px',
    fontWeight: 'normal',
    backgroundColor: 'white',
  };

  const tableProps = {
    columns,
    tableRef,
    headComponent: (
      <TableButton
        caption="New Order"
        onAddClick={onAddOder}
        checkedIds={checkedIds}
        onEditClick={onViewOder}
        isMobileView={isMobileView}
        onArchiveClick={onArchiveClick}
        exportButton={function ({ exportButton }) {
          return (
            <TableButton
              caption="New Order"
              onAddClick={onAddOder}
              checkedIds={checkedIds}
              onEditClick={onViewOder}
              exportButton={exportButton}
              isMobileView={isMobileView}
              onArchiveClick={onArchiveClick}
            />
          );
        }}
      />
    ),
    actionsDropDown: <ActionsDropDown />,
  };

  function ActionsDropDown({ exportButton }) {
    return (
      <DropDown
        marginLeft={30}
        marginRight={6}
        caption="Actions"
        foreColor={primary}
        options={statusOptions}
        buttonStyle={buttonStyle}
        exportButton={exportButton}
        onCallBack={onSelectAction}
      />
    );
  }

  /**
   * @param {string} option
   */
  function onSelectAction(option) {
    const id = checkedIds[0];
    const payload = { id, option };
    if (option === EMAIL) {
      const order = orders.find(function (ord) {
        return ord.id === id;
      });
      const email = order?.customer?.email || '';
      payload.email = email;
    }
    onShare({ payload });
  }

  function onArchiveClick() {
    onArchive(checkedIds);
  }

  function onAddOder() {
    history.push(NEW_ORDER_PATH);
  }

  function onViewOder() {
    const id = checkedIds[0];
    const path = EDIT_ORDER_PATH.replace(':id', id);
    history.push(path);
  }

  const options = [
    {
      name: 'all',
      caption: 'All Orders',
      children: (
        <StyledWrapper>
          {isLoading ? (
            <Animation />
          ) : (
            <Table
              {...tableProps}
              data={activeOrders}
              exportName="all-orders"
              noDataMessage="No Orders"
            />
          )}
        </StyledWrapper>
      ),
    },
    {
      name: 'sale',
      caption: 'Sale Orders',
      children: (
        <StyledWrapper>
          {isLoading ? (
            <Animation />
          ) : (
            <Table
              {...tableProps}
              data={saleOrders}
              exportName="sale-orders"
              noDataMessage="No Sale Orders"
            />
          )}
        </StyledWrapper>
      ),
    },
    {
      name: 'lease',
      caption: 'Lease Orders',
      children: (
        <StyledWrapper>
          {isLoading ? (
            <Animation />
          ) : (
            <Table
              {...tableProps}
              data={leaseOrders}
              exportName="lease-orders"
              noDataMessage="No Lease Orders"
            />
          )}
        </StyledWrapper>
      ),
    },
    {
      name: 'returned',
      caption: 'Returned Orders',
      children: (
        <StyledWrapper>
          {isLoading ? (
            <Animation />
          ) : (
            <Table
              {...tableProps}
              data={returnedItems}
              exportName="returned-orders"
              noDataMessage="No Returned Orders"
            />
          )}
        </StyledWrapper>
      ),
    },
    {
      name: 'cancelled',
      caption: 'Cancelled Orders',
      children: (
        <StyledWrapper>
          {isLoading ? (
            <Animation />
          ) : (
            <Table
              {...tableProps}
              data={cancelledOrders}
              exportName="cancelled-orders"
              noDataMessage="No Cancelled Orders"
            />
          )}
        </StyledWrapper>
      ),
    },
  ];

  return <Tab options={options} marginRight={10} marginBottom={15} />;
}

export default Record;
