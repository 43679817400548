import avatar from 'img/avatar.png';
import { INVALID_URL } from 'constants/general';

/**
   Validates url or path to default avatar
   @param {string} url
 */
function getImageUrl(url) {
  return !INVALID_URL.includes(url) ? url : avatar;
}

export default getImageUrl;
