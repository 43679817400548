import { endPoints } from 'api/';
import { createUser } from 'actions/users';
import { CREATE_ACCOUNT } from 'actions/types';

/**
  @description Creates company and user profile.
  @param {FormData} userData
  @param {FormData} companyData
  @returns {{}} Created company profile
 */
function signup(companyData, userData) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_ACCOUNT,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const result = await apiClient(endPoints.CREATE_COMPANY, companyData);
        const { data } = result;
        const company = data?.response;

        if (company) {
          const { id: companyId } = company;

          userData.append('company_id', companyId);

          dispatch(createUser(userData, companyId, true));

          return result;
        }

        return null;
      },
    };

    dispatch(action);
  };
}

export default signup;
