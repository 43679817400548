import { primary, secondary } from 'constants/color';
import { MOBILE_VIEW_BREAKPOINT } from 'constants/general';

/**
  @description Returns options to render pie chart
  @param {[]} data Data to render map
  @param {string} caption Chart title
  @param {string} legend yAxis text
  @param {string} subTitle sub title text displayed at the center
  @param {{}} subTitleStyles sub title css styles
 */
function getPieChartOptions(data, caption, legend, subTitle, subTitleStyles) {
  const styles = {
    color: primary,
    fontSize: '12px',
    fontWeight: 'normal',
  };
  const options = {
    chart: {
      type: 'pie',
      height: 350,
      color: primary,
      marginBottom: 60,
      backgroundColor: '',
      renderTo: 'container',
    },
    title: {
      text: caption,
      style: styles,
    },
    exporting: {
      enabled: false,
    },
    subtitle: {
      text: subTitle,
      align: 'center',
      verticalAlign: 'middle',
      floating: true,
      x: 0,
      style: styles,
      ...subTitleStyles,
    },
    yAxis: {
      title: {
        text: legend,
      },
    },
    plotOptions: {
      pie: {
        shadow: false,
        dataLabels: {
          enabled: false,
        },
        borderColor: null,
        cursor: 'pointer',
        showInLegend: true,
        allowPointSelect: true,
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
            <span style = "font-size: 10px; color: ${primary}">
            ${this.key}  
            </span>
            <br>
            <span style = "font-size: 10px; color: ${secondary}">
            ${this.y}  
            </span>
            `;
      },
    },
    legend: {
      margin: 0,
      padding: 0,
      lineHeight: 0,
      align: 'center',
      floating: true,
      symbolRadius: 0,
      symbolPadding: 0,
      itemMarginTop: 0,
      itemStyle: styles,
      alignColumns: false,
      layout: 'horizontal', // or vertical to stack legends on top of each other.
      verticalAlign: 'bottom',
    },
    series: [
      {
        name: '',
        data: data,
        size: '60%',
        innerSize: '70%',
        verticalAlign: 'top',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        marker: {
          radius: 12,
          symbol: 'square',
        },
      },
    ],
    credits: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: MOBILE_VIEW_BREAKPOINT,
          },
          chartOptions: {
            legend: {
              align: 'center',
              layout: 'horizontal',
              verticalAlign: 'bottom',
            },
          },
        },
      ],
    },
  };

  return options;
}

export default getPieChartOptions;
