import { LOG_OUT_USER } from 'actions/types';

/**
  @description Logs out current user.
 */
function signOut() {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      payload: null,
      type: LOG_OUT_USER,
    };

    dispatch(action);
  };
}

export default signOut;
