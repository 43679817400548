import styled from 'styled-components';

import { black } from 'constants/color';

export const StyledCaption = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledDescription = styled.label`
  color: ${black};
  font-size: 10px;
  line-height: 18px;
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledConditionWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
`;

export const StyledCondition = styled.div`
  padding: 5px;
  height: 350px;
  margin-top: 10px;
  max-height: 350px;
  overflow-x: hidden;
  ${({ theme }) => theme.scrollbar};
`;

export const StyledLoader = styled.img`
  width: 30px;
  height: 30px;
  margin: 2px auto;
`;
