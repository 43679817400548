import styled, { css } from 'styled-components';

export const Wrapper = styled.main`
  --navBarSize: ${({ theme }) => theme.navBarSize};
  --menuBarSize: ${({ theme }) => theme.menuBarSize};
  --boxPaddingTop: ${({ theme }) => theme.boxPaddingTop};
  --menuBarMargin: ${({ theme }) => theme.menuBarMargin};
  --sidebarWidthMax: ${({ theme }) => theme.sidebarWidthMax};
  --bottomNavHeight: ${({ theme }) => theme.bottomNavHeight};
  --boxPaddingSides: ${({ theme }) => theme.boxPaddingSides};
  --leftMargin: calc(var(--sidebarWidthMax) + var(--boxPaddingSides) - 20px);
  --barSize: calc(var(--menuBarSize) * 2);

  background: ${({ backColor }) => backColor};
  transition: ${({ theme }) => theme.transitionSpeed};
  border-radius: ${({ theme }) => theme.borderRadius};

  --navHeight: calc(
    var(--navBarSize) + var(--barSize) + var(--menuBarMargin) +
      var(--boxPaddingTop) + var(--bottomNavHeight)
  );

  --minHeight: calc(100vh - calc(var(--navHeight) - 35px));

  overflow-y: auto;
  overflow-x: hidden;
  height: var(--minHeight);
  ${({ theme }) => theme.scrollbar};

  margin-left: ${function ({ $sideNavOpen }) {
    return $sideNavOpen ? 'var(--leftMargin)' : '0';
  }};

  /* Mobile Devices */
  @media (max-width: 480px) {
    --minHeight: calc(100vh - calc(var(--navHeight) - 75px));
    overflow: hidden;
    margin-right: 3px;
    height: var(--minHeight);
    ${({ theme }) => theme.scrollbar};

    ${function ({ $sideNavOpen }) {
      return $sideNavOpen
        ? css`
            visibility: hidden;
          `
        : css`
            margin-left: 3px;
          `;
    }}

    overflow-x: auto;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    overflow: hidden;
    margin-right: 5px;
    padding-right: 0.8rem;
    height: calc(100vh - calc(var(--navHeight) - 50px));
  }
`;

export const StyledTileWrapper = styled.div`
  display: flex;
  margin-top: -20px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  section {
    position: relative;
  }
`;
