import React from 'react';
import { bool, func, oneOf, string } from 'prop-types';

import {
  StyledInput,
  IconWrapper,
  StyledButton,
  InputErrorLabel,
  StyledInputWrapper,
} from 'components/Inputs/InputButton/InputButton.styled';
import { ReactComponent as TickIcon } from 'icons/tick.svg';

/**
 * @param {{
 * name: string
 * value: string
 * isInvalid: bool
 * inputId: string
 * disabled: boolean
 * onClick: Function
 * inputType: string
 * onChange: Function
 * dataTestId: string
 * onKeyDown: Function
 * placeholder: string
 * errorMessage: string
 * }} param
 */
function InputButton({
  name,
  value,
  onClick,
  inputId,
  onChange,
  isInvalid,
  onKeyDown,
  inputType,
  dataTestId,
  placeholder,
  errorMessage,
  disabled = false,
}) {
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const buttonTestId = `button-${dataTestId}`;
  const inputTestId = `input-${dataTestId}`;

  return (
    <StyledInputWrapper>
      <StyledInput
        name={name}
        id={inputId}
        value={value}
        type={inputType}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        isInvalid={invalidInput}
        placeholder={placeholder}
        data-testid={inputTestId}
        errorMessage={errorMessage}
      />
      <StyledButton
        onClick={onClick}
        disabled={disabled}
        isInvalid={invalidInput}
        data-testid={buttonTestId}
      >
        <IconWrapper>
          <TickIcon />
        </IconWrapper>
      </StyledButton>
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledInputWrapper>
  );
}

InputButton.defaultProps = {
  isInvalid: false,
  inputType: 'text',
};

InputButton.propTypes = {
  name: string,
  onClick: func,
  value: string,
  onChange: func,
  disabled: bool,
  onKeyDown: func,
  isInvalid: bool,
  inputId: string,
  dataTestId: string,
  placeholder: string,
  errorMessage: string,
  inputType: oneOf(['text', 'email', 'number']),
};

export default InputButton;
