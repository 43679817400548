import { utils, writeFile } from 'xlsx';
import { flexRender } from '@tanstack/react-table';
import React, { useMemo, cloneElement } from 'react';

import { formatDate } from 'utils/';
import {
  StyledOption,
  StyledFilterRow,
  StyledTableTitle,
  StyledTableHeader,
  StyleOptionButton,
  StyledFilterHeader,
  StyledTableHeading,
  StyledTableHeadRow,
  StyledTableRowSearch,
  StyledTableHeaderSearch,
  StyledSortDirectionIndicator,
} from 'components/Table/Header/Header.styled';
import { ActionButton } from 'components/Buttons/';
import { Search, Filter } from 'components/Table/';
import { ReactComponent as ExportIcon } from 'icons/export.svg';

function Header({
  rows,
  flatRows,
  exportName,
  excelExport,
  globalFilter,
  headerGroups,
  headComponent,
  visibleColumns,
  setGlobalFilter,
  actionsDropDown,
}) {
  function onInputChange(value) {
    setGlobalFilter(String(value));
  }

  function onExportExcel() {
    let hasButtons = false;
    const data = rows.map(function (row) {
      return row.getVisibleCells().map(function (cell) {
        const actionColumns = ['actions', 'checkbox'];
        const { id, meta } = cell.column.columnDef;
        if (actionColumns.includes(id)) {
          hasButtons = true;
          return '';
        }
        if (meta?.date) {
          return formatDate({ date: cell.getValue(), standard: true });
        }
        return cell.getValue();
      });
    });

    const headers = headerGroups.map(function ({ headers }) {
      return headers.map(function ({ column }) {
        return column.columnDef.header;
      });
    });
    const combined = [...headers, ...data];
    let cleanData = combined;
    if (hasButtons) {
      cleanData = combined.map(function (row) {
        return row.slice(0, row.length - 1);
      });
    }
    const workSheet = utils.json_to_sheet(cleanData);
    const workBook = utils.book_new();
    const sheetName = exportName || 'Sheet1';
    utils.book_append_sheet(workBook, workSheet, sheetName);
    const timeStamp = new Date().getTime();
    const fileName = `${sheetName}-${timeStamp}.xlsx`;
    writeFile(workBook, fileName);
  }

  const ExportButton = !excelExport ? null : (
    <ActionButton
      caption="Export"
      role="excel-export"
      icon={<ExportIcon />}
      onClick={onExportExcel}
      active={rows.length > 0}
      title="Export filtered data to excel"
    />
  );

  const headerComponent = useMemo(() => {
    let component = ExportButton;
    if (actionsDropDown) {
      component = cloneElement(actionsDropDown, {
        exportButton: (
          <StyledOption>
            <StyleOptionButton onClick={onExportExcel}>
              Export
            </StyleOptionButton>
          </StyledOption>
        ),
      });
    }
    if (!headComponent) {
      return component;
    }
    return cloneElement(headComponent, {
      exportButton: component,
    });
  }, [headComponent, actionsDropDown]);

  return (
    <>
      <StyledTableRowSearch>
        <StyledTableHeaderSearch colSpan={visibleColumns + 2}>
          <StyledTableHeading>
            <StyledTableTitle>{headerComponent}</StyledTableTitle>
            <Search onChange={onInputChange} value={globalFilter ?? ''} />
          </StyledTableHeading>
        </StyledTableHeaderSearch>
      </StyledTableRowSearch>
      {headerGroups.map(function ({ id, headers }) {
        return (
          <StyledTableHeadRow key={id}>
            {headers.map(function ({ id, column, getContext, isPlaceholder }) {
              const {
                getSize,
                getCanSort,
                getIsSorted,
                getNextSortingOrder,
                columnDef: { header },
                getToggleSortingHandler,
              } = column;
              const sort = getCanSort();
              const asc = getNextSortingOrder() === 'asc';
              const desc = getNextSortingOrder() === 'desc';
              const firstValue = rows[0]?.getValue(column.id);
              const isNumber = typeof firstValue === 'number';
              const width = isNumber ? `${getSize()}px` : 'auto';
              return (
                <StyledTableHeader key={id} $width={width}>
                  {isPlaceholder ? null : (
                    <>
                      <StyledSortDirectionIndicator
                        $sort={sort}
                        title={getTitle(sort, asc, desc)}
                        onClick={getToggleSortingHandler()}
                      >
                        {flexRender(header, getContext())}
                        {{
                          asc: ' ↑',
                          desc: ' ↓',
                        }[getIsSorted()] ?? null}
                      </StyledSortDirectionIndicator>
                    </>
                  )}
                </StyledTableHeader>
              );
            })}
          </StyledTableHeadRow>
        );
      })}
      {headerGroups.map(function ({ id, headers }) {
        return (
          <StyledFilterRow key={id}>
            {headers.map(function ({ id, column }) {
              const filter = column.getCanFilter();
              return (
                <StyledFilterHeader key={id}>
                  {filter ? <Filter rows={flatRows} column={column} /> : null}
                </StyledFilterHeader>
              );
            })}
          </StyledFilterRow>
        );
      })}
    </>
  );

  function getTitle(sort, asc, desc) {
    let title;
    if (sort) {
      if (asc) {
        title = 'Sort ascending';
      } else if (desc) {
        title = 'Sort descending';
      } else {
        title = 'Clear sort';
      }
    } else {
      title = undefined;
    }
    return title;
  }
}

export default Header;
