import styled, { css } from 'styled-components';

import { secondary, black, primary, white } from 'constants/color';

const labelStyles = css`
  width: 100%;
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledDateLabel = styled.label`
  font-size: 10px;
  margin-top: 15px;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledMapWrapper = styled.div`
  width: 100%;
  height: 600px;

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;
export const StyledWrapper = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const StyledLocation = styled.div`
  width: 100%;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  color: ${secondary};
`;

export const StyledNotesTitle = styled.div`
  ${labelStyles}
  color: ${black};
  font-size: 10px;
  font-weight: bold;
`;

export const StyledNotes = styled.div`
  ${labelStyles}
  color: ${black};
  font-size: 10px;
`;

export const StyledLabel = styled.label`
  ${labelStyles}
  color: ${black};
`;

export const StyledOrderNumber = styled.label`
  ${labelStyles}
  font-size: 10px;
  color: ${primary};
`;

export const StyledButton = styled.button`
  width: 120px;
  height: 20px;
  font-size: 12px;
  color: ${white};
  cursor: pointer;
  box-shadow: none;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: ${primary};
  border: 1px solid ${primary};
`;

export const StyledCloseButton = styled.button`
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  cursor: pointer;
  box-shadow: none;
  color: ${primary};
  font-style: normal;
  text-align: center;
  position: absolute;
  font-weight: normal;
  align-items: center;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -webkit-box-shadow: none;
`;
