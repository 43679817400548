import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearConfirm } from 'actions/confirm/';
import { Confirm as Form } from 'components/Confirm/';

function Confirm() {
  const dispatch = useDispatch();
  const confirm = useSelector(({ confirm }) => confirm);
  const { type = '', title = '', options = {}, onCancel = null } = confirm;
  const { deleting } = options;
  const caption = deleting ? 'Confirm record deletion' : title;

  function onCloseModal() {
    dispatch(clearConfirm());
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <Modal
      isConfirmModal
      modalWidth={40}
      caption={caption}
      isOpen={type !== ''}
      onClose={onCloseModal}
    >
      <Form confirm={confirm} onClose={onCloseModal} />
    </Modal>
  );
}

export default Confirm;
