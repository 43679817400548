import { endPoints } from 'api/';
import { FORGOT_PASSWORD } from 'actions/types';

/**
  @description Instantiates a forgot password process.
  @param {string} email - User's email address.
  @returns Response object after sending email.
 */
function forgotPassword(email) {
  /**
    @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: FORGOT_PASSWORD,
      /**
        @param {Function} apiClient
       */
      request: async function (apiClient) {
        const response = await apiClient(endPoints.FORGOT_PASSWORD, { email });
        return response;
      },
    };

    dispatch(action);
  };
}

export default forgotPassword;
