import React from 'react';
import { func, node, string, bool } from 'prop-types';

import {
  IconWrapper,
  MenuItemButton,
  MenuItemCaption,
} from 'components/MenuBar/MenuBarItem.styled';

/**
 * @param {{
 * menuIcon: JSX,
 * caption: string,
 * onClick: Function,
 * isActive: boolean,
 * }} param
 */
function MenuBarItem({ onClick, menuIcon, caption, isActive }) {
  return (
    <MenuItemButton
      type="button"
      onClick={onClick}
      $active={isActive}
      data-active={isActive}
    >
      <IconWrapper>{menuIcon}</IconWrapper>
      <MenuItemCaption> {caption}</MenuItemCaption>
    </MenuItemButton>
  );
}

MenuBarItem.propTypes = {
  onClick: func.isRequired,
  menuIcon: node.isRequired,
  caption: string.isRequired,
  isActive: bool,
};

export default MenuBarItem;
