import styled from 'styled-components';

export const LoaderImage = styled.img``;

export const LoaderWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    height: 100vh;

    ${LoaderImage} {
      width: 100%;
    }
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
    height: 100vh;

    ${LoaderImage} {
      width: 100%;
    }
  }
`;
