import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { TASK_MODAL } from 'constants/modal';
import { createTask, updateTask } from 'actions/task/';
import TaskForm from 'components/Tasks/TaskForm/TaskForm';

function TaskModal() {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: users } = useSelector(({ users }) => users);

  const isOpen = modal?.type === TASK_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const modalCaption = isEditing ? 'Update Task' : 'Create A Task';
  const { initialValue } = modal;

  function onModalClose() {
    dispatch(clearModal(TASK_MODAL));
  }

  function onSubmit(taskObj) {
    const { company_id: companyId } = user;
    const { id: taskId } = initialValue || {};

    if (isEditing) {
      const payload = {
        ...taskObj,
        id: taskId,
        company_id: companyId,
      };

      dispatch(updateTask(taskId, payload, companyId, false));
    } else {
      const payload = {
        ...taskObj,
        company_id: companyId,
      };

      dispatch(createTask(payload, companyId));
    }

    // dispatch(clearModal(TASK_MODAL));
  }

  return (
    <Modal
      modalTop={5}
      modalLeft={20}
      isOpen={isOpen}
      modalWidth={60}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <TaskForm
        users={users}
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
      />
    </Modal>
  );
}

export default TaskModal;
