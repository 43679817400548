import React from 'react';
import { node, number } from 'prop-types';

import { StyledFlexRow } from './FlexRow.styled';

/**
  @param {{
   children: JSX,
   marginBottom: number,
  }} param
 */
const FlexRow = ({ children, marginBottom = 0, className = '' }) => {
  return (
    <StyledFlexRow marginBottom={marginBottom} className={className}>
      {children}
    </StyledFlexRow>
  );
};

FlexRow.propTypes = {
  children: node,
  marginBottom: number,
};

export default FlexRow;
