import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { clearModal } from 'actions/modals/';
import { ASSIGN_ASSET_MODAL } from 'constants/modal';
import { assignAsset, updateAssignedAsset } from 'actions/assets/';
import AssignForm from 'components/Assets/Assign/AssignForm/AssignForm';

const AssignAssetModal = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);
  const { data: users } = useSelector(({ users }) => users);
  const { data: assets } = useSelector(({ assets }) => assets);

  const isOpen = modal?.type === ASSIGN_ASSET_MODAL;
  const isEditing = isOpen && modal.initialValue;
  const actionType = isEditing ? 'Return' : 'Assign';
  const modalCaption = `${actionType} Inventory Asset`;
  const { initialValue } = modal;

  const onModalClose = () => {
    dispatch(clearModal(ASSIGN_ASSET_MODAL));
  };

  /**
    @param {{}} payload
   */
  const onSubmit = (payload) => {
    const { company_id: companyId } = user;

    if (isEditing) {
      const { id: assetId } = initialValue || {};

      payload = {
        ...payload,
        id: assetId,
      };

      dispatch(updateAssignedAsset(assetId, payload, companyId, false));
    } else {
      payload = {
        ...payload,
        company_id: companyId,
      };

      dispatch(assignAsset(payload, companyId));
    }

    // dispatch(clearModal(ASSIGN_ASSET_MODAL));
  };

  return (
    <Modal
      modalTop={5}
      modalLeft={25}
      isOpen={isOpen}
      modalWidth={50}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <AssignForm
        users={users}
        assets={assets}
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
      />
    </Modal>
  );
};

export default AssignAssetModal;
