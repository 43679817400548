/**
  @description Generates `random` colors which are not highly saturated.
  @returns hex color in string format.
 */
function getRandomColor({ graphColor = false }) {
  let color = '#';
  const letters = '0123456789ABCDEF';
  const graph = ['#1A608D', '#EE4657', '#F68D2F', '#175670', '#29BECD'];

  if (graphColor) {
    return graph[Math.floor(Math.random() * graph.length)];
  }

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export default getRandomColor;
