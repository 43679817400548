import {
  GET_ITEMS,
  UPDATE_ITEM,
  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ATTRIBUTE,
  CREATE_ATTRIBUTE,
} from 'actions/types';
import { transformActions } from 'utils/';

let itemList = [];
const initialState = {
  data: [],
  isLoading: false,
};

/**
 * @description Processes and stores items array details in redux state.
 * @param {{}} state
 * @param {{}} action
 */
function item(state = initialState, action) {
  const actions = [GET_ITEMS];
  const { type, payload } = action;
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');
  if (type === `${CREATE_ITEM}_SUCCESS`) {
    const item = { ...payload, attributes: [] };
    itemList.push(item);
    return {
      data: itemList,
      isLoading: false,
    };
  }
  if (type === `${DELETE_ITEM}_SUCCESS`) {
    const { id } = payload;
    const data = itemList.filter(function (item) {
      return item.id !== id;
    });
    itemList = data;
    return {
      data: itemList,
      isLoading: false,
    };
  }
  if (type === `${CREATE_ATTRIBUTE}_SUCCESS`) {
    const created = payload.created_attributes || [];
    const updated = payload.updated_attributes || [];
    const attributes = [...created, ...updated];
    const undeleted = attributes.filter(function (attribute) {
      return !attribute.is_deleted;
    });
    const itemId = attributes[0]?.entity_id;
    const data = itemList.map(function (item) {
      if (item.id === itemId) {
        return {
          ...item,
          attributes: [...undeleted],
        };
      }
      return item;
    });
    itemList = data;
    return {
      data,
      isLoading: false,
    };
  }
  if (type === `${UPDATE_ATTRIBUTE}_SUCCESS`) {
    const { itemId, attributes } = payload;
    const undeleted = attributes.filter(function (attribute) {
      return !attribute.is_deleted;
    });
    const data = itemList.map(function (item) {
      if (item.id === itemId) {
        return {
          ...item,
          attributes: [...undeleted],
        };
      }
      return item;
    });
    itemList = data;
    return {
      data,
      isLoading: false,
    };
  }
  if (type === `${UPDATE_ITEM}_SUCCESS`) {
    const { id, updatedValues } = payload;
    const data = itemList.map(function (item) {
      if (item.id === id) {
        return {
          ...item,
          ...updatedValues,
        };
      }
      return item;
    });
    itemList = data;
    return {
      data: itemList,
      isLoading: false,
    };
  }
  if (type === GET_ITEMS) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (success.includes(type)) {
    itemList = payload;
    return {
      data: payload,
      isLoading: false,
    };
  }
  if (failure.includes(type)) {
    return {
      ...state,
      isLoading: false,
    };
  }
  return state;
}

export default item;
