import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledAlert,
  StyledWrapper,
  StyledContainer,
  SignInContainer,
} from './SignIn.styled';
import { Loader } from 'components/Loaders/';
import LoginForm from 'components/LoginForm/LoginForm';
import { status } from 'reducers/logInStatus/logInStatus';
import { authenticate, forgotPassword, resetPassword } from 'actions/users/';

/**
 *
 * @param {{
 * target : string
 * }} param
 */
const SignIn = ({ target }) => {
  const dispatch = useDispatch();
  const { logInStatus } = useSelector((state) => state);
  const password = useSelector(({ password }) => password);
  const isResetting = target && target === 'password-reset';

  /**
    @param {{ 
     email : string, 
     password : string
    }} credentials
   */
  const onSubmit = (credentials) => {
    const { email, password } = credentials;

    dispatch(authenticate(email, password));
  };

  /**
   * @param {string} email
   */
  function onForgotPassword(email) {
    dispatch(forgotPassword(email));
  }

  /**
   * @param {{}} payload
   */
  function onPasswordReset(payload) {
    dispatch(resetPassword(payload));
  }

  if ([status.login, status.success].includes(logInStatus)) {
    return <Loader />;
  }

  return (
    <SignInContainer>
      <StyledContainer>
        {logInStatus && <StyledAlert>{logInStatus}</StyledAlert>}
        <StyledWrapper>
          <LoginForm
            password={password}
            onSubmit={onSubmit}
            isResetting={isResetting}
            onPasswordReset={onPasswordReset}
            onForgotPassword={onForgotPassword}
          />
        </StyledWrapper>
      </StyledContainer>
    </SignInContainer>
  );
};

export default SignIn;
