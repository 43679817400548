import { bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import {
  StyledIcon,
  StyledLogo,
  StyledMessage,
  StyledCaption,
  StyledInputWrapper,
  StyledInstructions,
} from './LoginForm.styled';
import { validateEmail } from 'utils/';
import WezzaLogo from 'img/wezzalogo.png';
import { TextInput } from 'components/Inputs/';
import { RoundButton } from 'components/Buttons/';
import { FlexCol, FlexRow } from 'components/Layout/';
import { ReactComponent as TickIcon } from 'icons/right.svg';
import { white, secondary, mediumGrey } from 'constants/color';
import { ACCOUNT_SIGNUP_PATH, LOGIN_PATH } from 'constants/general';
import { ReactComponent as ArrowIcon } from 'icons/arrow_right.svg';

/**
  @param {{
    onSubmit : Function
    isResetting : boolean
    password : {{
      message : string
      isLoading : boolean
      successful : boolean
    }}
    onPasswordReset : Function
    onForgotPassword : Function
  }} param
 */
const LoginForm = ({
  password,
  onSubmit,
  isResetting,
  onPasswordReset,
  onForgotPassword,
}) => {
  const [state, setState] = useState({
    email: '',
    confirm: '',
    password: '',
  });
  const { message, isLoading, successful } = password;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const isEmailInputInValid =
    isSubmitting && (!state.email || !validateEmail(state.email));
  const isPasswordInputInValid = isSubmitting && !state.password;
  const emailErrorMessage = isEmailInputInValid ? 'Invalid Email' : '';
  const passwordErrorMessage = isPasswordInputInValid ? 'Invalid Password' : '';
  const [errorMessage, setErrorMessage] = useState({
    confirm: '',
    email: emailErrorMessage,
    password: passwordErrorMessage,
  });
  const buttonColor = useCallback(() => {
    const { email, password } = state;
    const isValid = email && password && validateEmail(email);
    return isValid ? secondary : mediumGrey;
  }, [state]);
  const resetLink = useMemo(() => {
    const link = new URLSearchParams(location.search).get('resetLink');
    return link;
  }, [location.search]);

  const onInputChange = (event) => {
    const { currentTarget } = event;
    const { name, value } = currentTarget;

    setState({
      ...state,
      [name]: value,
    });
  };

  const onLogIn = () => {
    setIsSubmitting(true);
    const isFormValid = validateForm();
    if (isFormValid) {
      const { email, password } = state;
      onSubmit({ email, password });
    }
  };

  const validateForm = () => {
    let isFormValid = true;
    const { email, password, confirm } = state;
    let errorObject = { ...errorMessage };
    if (!validateEmail(email)) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        email: 'Invalid Email',
      };
    } else {
      errorObject = {
        ...errorObject,
        email: '',
      };
    }
    if (!password) {
      isFormValid = false;
      errorObject = {
        ...errorObject,
        password: 'Invalid Password',
      };
    } else {
      errorObject = {
        ...errorObject,
        password: '',
      };
    }
    if (isResetting) {
      if (!confirm.length > 0 || password !== confirm) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          confirm: 'Passwords do not match',
        };
      } else {
        errorObject = {
          ...errorObject,
          confirm: '',
        };
      }
    }
    setErrorMessage(errorObject);
    return isFormValid;
  };

  const onSignUp = () => {
    window.location.assign(ACCOUNT_SIGNUP_PATH);
  };

  function onLogInRedirect() {
    window.location.assign(LOGIN_PATH);
  }

  function onForgotClick() {
    setForgotPassword(true);
  }

  function onRequestReset() {
    const { email } = state;
    let errorObject = {
      ...errorMessage,
    };
    if (!validateEmail(email)) {
      errorObject = {
        ...errorObject,
        email: 'Invalid Email',
      };
    } else {
      errorObject = {
        ...errorObject,
        email: '',
      };
      onForgotPassword(email);
    }
    setErrorMessage(errorObject);
  }

  function onResetPassword() {
    setIsSubmitting(true);
    const isValid = validateForm();
    const { email, password } = state;
    if (isValid) {
      onPasswordReset({
        email,
        password,
        reset_link: resetLink,
      });
    }
  }

  useEffect(() => {
    if (isSubmitting) {
      validateForm();
    }
  }, [state, isSubmitting]);

  function renderAlert() {
    const showAlert = isLoading || message?.length > 0;
    const caption = isLoading ? 'Processing...' : message;
    if (showAlert) {
      return <StyledMessage>{caption}</StyledMessage>;
    }
    return null;
  }

  if (forgotPassword) {
    return (
      <>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <StyledLogo src={WezzaLogo} />
          </FlexCol>
        </FlexRow>
        <StyledCaption>Forgot Your Password?</StyledCaption>
        {renderAlert()}
        <StyledInstructions>
          Enter your primary email address and we’ll send you instructions on
          how to reset your password.
        </StyledInstructions>
        <TextInput
          name="email"
          inputId="email"
          caption="Email"
          inputType="text"
          value={state.email}
          onChange={onInputChange}
          isInvalid={isEmailInputInValid}
          errorMessage={errorMessage.email}
          placeholder="Enter your primary email address"
        />
        <RoundButton
          fontSize={12}
          foreColor={white}
          icon={<ArrowIcon />}
          caption="Send Email"
          backColor={secondary}
          isDisabled={isLoading}
          onClick={onRequestReset}
        />
      </>
    );
  }

  if (successful) {
    return (
      <>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <StyledLogo src={WezzaLogo} />
          </FlexCol>
        </FlexRow>
        <StyledIcon>
          <TickIcon />
        </StyledIcon>
        <StyledCaption $fontSize={'20px'} $marginBottom={'10px'}>
          New Password Set Successfully
        </StyledCaption>
        <RoundButton
          fontSize={12}
          caption="Log In"
          foreColor={white}
          icon={<ArrowIcon />}
          backColor={secondary}
          isDisabled={isLoading}
          onClick={onLogInRedirect}
        />
      </>
    );
  }

  if (isResetting) {
    return (
      <>
        <FlexRow>
          <FlexCol flexWidth={12}>
            <StyledLogo src={WezzaLogo} />
          </FlexCol>
        </FlexRow>
        <StyledCaption>Set New Password</StyledCaption>
        {renderAlert()}
        <TextInput
          name="email"
          inputId="email"
          caption="Email"
          inputType="text"
          value={state.email}
          onChange={onInputChange}
          isInvalid={isEmailInputInValid}
          errorMessage={errorMessage.email}
          placeholder="primary@email.address"
        />
        <TextInput
          name="password"
          inputId="password"
          inputType="password"
          caption="New Password"
          value={state.password}
          onChange={onInputChange}
          isInvalid={isPasswordInputInValid}
          errorMessage={errorMessage.password}
          placeholder="Enter new strong password"
        />
        <TextInput
          name="confirm"
          inputId="confirm"
          inputType="password"
          value={state.confirm}
          onChange={onInputChange}
          caption="Confirm Password"
          isInvalid={isPasswordInputInValid}
          errorMessage={errorMessage.confirm}
          placeholder="Confirm new strong password"
        />
        <RoundButton
          fontSize={12}
          foreColor={white}
          icon={<ArrowIcon />}
          backColor={secondary}
          isDisabled={isLoading}
          onClick={onResetPassword}
          caption="Set New Password"
        />
      </>
    );
  }

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={12}>
          <StyledLogo src={WezzaLogo} />
        </FlexCol>
      </FlexRow>
      <StyledInputWrapper>
        <TextInput
          name="email"
          inputId="email"
          caption="Email"
          inputType="email"
          value={state.email}
          onChange={onInputChange}
          placeholder="Enter your email"
          isInvalid={isEmailInputInValid}
          errorMessage={errorMessage.email}
        />
        <TextInput
          name="password"
          inputId="password"
          caption="Password"
          inputType="password"
          value={state.password}
          onChange={onInputChange}
          placeholder="Enter your password"
          isInvalid={isPasswordInputInValid}
          errorMessage={errorMessage.password}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <RoundButton
          caption="Log In"
          onClick={onLogIn}
          icon={<ArrowIcon />}
          backColor={buttonColor()}
        />
      </StyledInputWrapper>
      <FlexRow>
        <FlexCol fluidWidth={6} style={{ paddingTop: '10px' }}>
          <RoundButton
            fontSize={12}
            backColor={white}
            foreColor={secondary}
            onClick={onForgotClick}
            caption="Forgot Your Password ?"
          />
        </FlexCol>
        <FlexCol fluidWidth={6} style={{ paddingTop: '10px' }}>
          <RoundButton
            fontSize={12}
            backColor={white}
            onClick={onSignUp}
            foreColor={secondary}
            caption="Create Account"
          />
        </FlexCol>
      </FlexRow>
    </>
  );
};

LoginForm.propTypes = {
  onSubmit: func,
  password: shape({
    message: string,
    isLoading: bool,
    successful: bool,
  }),
  isResetting: bool,
  onPasswordReset: func,
  onForgotPassword: func,
};

export default LoginForm;
