import { endPoints } from 'api/';
import { RESET_PASSWORD } from 'actions/types';

/**
  @description Reset user's password.
  @param {{}} payload - User details.
  @returns Response object after resetting password.
 */
function resetPassword(payload) {
  /**
    @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: RESET_PASSWORD,
      /**
        @param {Function} apiClient
       */
      request: async function (apiClient) {
        const response = await apiClient(endPoints.RESET_PASSWORD, payload);
        return response;
      },
    };

    dispatch(action);
  };
}

export default resetPassword;
