import { endPoints } from 'api/';
import { active } from 'constants/status';
import { GET_STORAGES } from 'actions/types';

/**
  @description Get a list of all storages
  @param {{
    companyId : string,
    lowerDate : string,
    upperDate : string,
  }} params
  @returns Array of storage objects
 */
function getStorages({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "${active}" ${dateFilter}}`;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_STORAGES,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const storages = await apiClient(endPoints.GET_STORAGES, {}, query);

        return storages;
      },
    };

    dispatch(action);
  };
}

export default getStorages;
