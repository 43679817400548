import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledButton = styled(motion.button)`
  height: 23px;
  font-size: 15px;
  cursor: pointer;
  box-shadow: none;
  border-width: 1px;
  user-select: none;
  font-style: normal;
  text-align: center;
  font-weight: normal;
  align-items: center;
  white-space: nowrap;
  text-transform: none;
  -moz-box-shadow: none;
  -ms-user-select: none;
  vertical-align: middle;
  -moz-user-select: none;
  background-image: none;
  background-color: white;
  -webkit-box-shadow: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border: 1px solid transparent;
  -ms-touch-action: manipulation;
  color: ${({ $color }) => $color};
  svg {
    width: 20px;
    min-width: 20px;
    fill: ${({ $color }) => $color};
  }
`;
