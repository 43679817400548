import React from 'react';

import Loader from 'img/loader.gif';
import { StyledLoader } from './Loading.styled';

const Loading = () => {
  return <StyledLoader src={Loader} alt="Loading..." />;
};

export default Loading;
