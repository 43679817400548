import { endPoints } from 'api/';
import { assigned } from 'constants/status';
import { GET_ASSIGNED_ASSETS } from 'actions/types';

/**
  @description Get a list of all assigned assets
  @param {{
    companyId : string,
    lowerDate : string,
    upperDate : string,
  }} params
  @returns Array of assigned asset objects
 */
function getAssignedAssets({ companyId, lowerDate, upperDate }) {
  const hasDate = !!lowerDate && !!upperDate;
  const dateFilter = hasDate
    ? `,"createdat": "btwn::${lowerDate},${upperDate}"`
    : '';
  const query = `?where={"company_id": "${companyId}", "status": "${assigned}" ${dateFilter}}`;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: GET_ASSIGNED_ASSETS,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const assets = await apiClient(
          endPoints.GET_ASSIGNED_ASSETS,
          {},
          query,
        );

        return assets;
      },
    };

    dispatch(action);
  };
}

export default getAssignedAssets;
