/**
  @description Saturates theme color to given RGBA
  @param {number} colorCode
  @returns RGBA color string
 */
function saturateColor(colorCode) {
  const defaultColor = 'rgba(56, 174, 194, 0.75)';

  if (!colorCode) return defaultColor;

  if (typeof colorCode !== 'number') return defaultColor;

  return `rgba(56, 174, 194, ${colorCode})`;
}

export default saturateColor;
