const quantityUnits = [
  {
    value: 'kgs',
    label: 'kgs',
  },
  {
    value: 'litres',
    label: 'litres',
  },
  {
    value: 'pieces',
    label: 'pieces',
  },
];

export default quantityUnits;
