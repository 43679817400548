import styled from 'styled-components';

import { black, primary } from 'constants/color';

export const StyledUserTypeWrapper = styled.div`
  color: ${black};
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledUserType = styled.label`
  color: ${black};
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  margin-right: 10px;
`;

export const StyledRequestChange = styled.span`
  color: ${primary};
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  font-style: italic;
  font-weight: normal;
`;

export const StyledAppSupport = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  transition: all 0.5s linear;
  color: ${({ theme }) => theme.darkGrey};
`;

export const StyledAppSupportLink = styled.span`
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
  color: ${({ theme }) => theme.primaryColor};
`;
