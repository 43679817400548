/**
  @description Maps `actions` with `status` `dispatched` by `redux`.
  @param {[]} actions Any valid redux action.
  @param {string} status Either SUCCESS or FAILURE
 */
function transformActions(actions, status) {
  const transformed = actions.map((action) => `${action}_${status}`);

  return transformed;
}

export default transformActions;
