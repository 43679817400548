import { GET_PEOPLES } from 'actions/types';
import { transformActions, capitalizeFirstLetter } from 'utils/';

const initialState = {
  data: [],
  isLoading: false,
};

/**
  @description Stores peoples array details in redux state
  @param {[]} state
  @param {{}} action
  @returns {[]}
 */
function peoples(state = initialState, action) {
  const { type, payload } = action;
  const actions = [GET_PEOPLES];
  const success = transformActions(actions, 'SUCCESS');
  const failure = transformActions(actions, 'FAILURE');

  if (type === GET_PEOPLES) {
    return {
      data: [],
      isLoading: true,
    };
  }

  if (success.includes(type)) {
    // transform
    const peoples = [...payload].map((people) => {
      return {
        ...people,
        name: `${people.first_name} ${people.last_name}`,
        permission: capitalizeFirstLetter(people.usertype),
      };
    });

    return {
      data: peoples,
      isLoading: false,
    };
  }

  if (failure.includes(type)) {
    return {
      data: [],
      isLoading: false,
    };
  }

  return state;
}

export default peoples;
