import React from 'react';

import { ReactComponent as PlusIcon } from 'icons/add.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ActionButton, RoundButton } from 'components/Buttons/';
import { ReactComponent as ImportIcon } from 'icons/import.svg';
import { ReactComponent as ArchiveIcon } from 'icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from 'icons/unarchive.svg';
import { StyledButtonWrapper } from 'components/Reusable/TableButton/TableButton.styled';

function TableButton({
  caption,
  checkedIds,
  onAddClick,
  onEditClick,
  exportButton,
  isMobileView,
  onImportClick,
  onArchiveClick,
  onUnArchiveClick,
  isOnBoarding = false,
}) {
  const hasCheckedItems = checkedIds?.length > 0;
  if (isOnBoarding) {
    return null;
  }
  return (
    <StyledButtonWrapper>
      {onAddClick ? (
        <RoundButton
          caption={caption}
          icon={<PlusIcon />}
          onClick={onAddClick}
          flexWidth={isMobileView ? 40 : 10}
        />
      ) : null}
      {onImportClick ? (
        <ActionButton
          active
          icon={<ImportIcon />}
          onClick={onImportClick}
          caption={`Import ${caption.split(' ').slice(-1)[0]}s`}
        />
      ) : null}
      {onArchiveClick ? (
        <ActionButton
          caption="Archive"
          icon={<ArchiveIcon />}
          onClick={onArchiveClick}
          active={hasCheckedItems}
        />
      ) : null}
      {exportButton}
      {onUnArchiveClick ? (
        <ActionButton
          caption="Unarchive"
          active={hasCheckedItems}
          icon={<UnArchiveIcon />}
          onClick={onUnArchiveClick}
        />
      ) : null}
      {onEditClick ? (
        <ActionButton
          caption="View/Edit"
          icon={<EditIcon />}
          onClick={onEditClick}
          active={checkedIds?.length === 1}
        />
      ) : null}
    </StyledButtonWrapper>
  );
}

export default TableButton;
