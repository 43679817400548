import React, { useState } from 'react';

import { formatDate, rollBackDate } from 'utils/';
import { RoundButton } from 'components/Buttons/';
import DatePicker from 'components/DatePicker/DatePicker';
import {
  StyledWrapper,
  StyledInputWrapper,
} from 'components/Reusable/DateFilter/DateFilter.styled';
import { ReactComponent as TickIcon } from 'icons/tick.svg';

function DateFilter({
  onLoadData,
  marginTop = 0,
  marginRight = 15,
  isLoading = false,
  buttonText = 'Load',
}) {
  const today = new Date();
  const { upperDate, lowerDate } = rollBackDate({
    days: 30,
    date: today,
  });
  const [state, setState] = useState({
    upperDate,
    lowerDate,
  });
  const [errors, setErrors] = useState({
    lowerDate: '',
    upperDate: '',
  });
  function onLowerDateChange(date) {
    setState({
      ...state,
      lowerDate: date,
    });
  }

  function onUpperDateChange(date) {
    setState({
      ...state,
      upperDate: date,
    });
  }

  function onFilterClick() {
    let isValid = true;
    const errorObject = { ...errors };
    const { lowerDate, upperDate } = state;
    if (!lowerDate) {
      isValid = false;
      setErrors({
        ...errorObject,
        lowerDate: 'Select date from',
      });
    } else {
      isValid = true;
      setErrors({
        ...errorObject,
        lowerDate: '',
      });
    }
    if (!upperDate) {
      isValid = false;
      setErrors({
        ...errorObject,
        upperDate: 'Select date to',
      });
    } else {
      isValid = true;
      setErrors({
        ...errorObject,
        upperDate: '',
      });
    }
    if (lowerDate > upperDate) {
      isValid = false;
      setErrors({
        ...errorObject,
        upperDate: 'Date to is less than date from',
        lowerDate: 'Date from is greater than date to',
      });
    }
    if (isValid) {
      setErrors({
        lowerDate: '',
        upperDate: '',
      });
      onLoadData({
        lowerDate: formatDate({ date: lowerDate, standard: true }),
        upperDate: formatDate({ date: upperDate, standard: true }),
      });
    }
  }

  return (
    <StyledWrapper $marginTop={marginTop} $marginRight={marginRight}>
      <StyledInputWrapper>
        <DatePicker
          name="lowerDate"
          iconTopMargin={7}
          showCaption={false}
          inputId="lowerDate"
          caption="Lower Date"
          selected={state.lowerDate}
          onChange={onLowerDateChange}
          placeholder="Select date from"
          errorMessage={errors.lowerDate}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <DatePicker
          name="upperDate"
          iconTopMargin={7}
          showCaption={false}
          inputId="upperDate"
          caption="Upper Date"
          selected={state.upperDate}
          placeholder="Select date to"
          onChange={onUpperDateChange}
          errorMessage={errors.upperDate}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <RoundButton
          flexWidth={100}
          icon={<TickIcon />}
          caption={buttonText}
          isDisabled={isLoading}
          onClick={onFilterClick}
        />
      </StyledInputWrapper>
    </StyledWrapper>
  );
}

export default DateFilter;
