import React from 'react';
import { func, string } from 'prop-types';

import { white, primary } from 'constants/color';
import { RoundButton } from 'components/Buttons/';
import { ReactComponent as CancelIcon } from 'icons/cancel_circle.svg';

/**
 * @param {{
 * caption : string
 * onClick : Function
 * }} param
 */
function CancelButton({ onClick, caption = 'Cancel' }) {
  return (
    <RoundButton
      caption={caption}
      onClick={onClick}
      backColor={white}
      foreColor={primary}
      icon={<CancelIcon />}
      borderColor={primary}
      className="modal-cancel-button"
    />
  );
}

CancelButton.propTypes = {
  onClick: func,
  caption: string,
};

export default CancelButton;
