import styled from 'styled-components';

export const StyledMarker = styled.div`
  --whiteColor: ${({ theme }) => theme.whiteColor};

  top: 50%;
  left: 50%;
  width: 38px;
  height: 37px;
  font-size: 12px;
  user-select: none;
  position: absolute;
  border-radius: 100%;
  -ms-user-select: none;
  -moz-user-select: none;
  background-size: contain;
  -webkit-user-select: none;
  background-repeat: no-repeat;
  transform: translate(-50%, -50%);
  border: 2px solid var(--whiteColor);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);

  &:hover {
    z-index: 1;
  }
`;

export const StyledMarkerInfo = styled.label`
  top: -30px;
  left: -50px;
  width: 360px;
  font-size: 10px;
  position: absolute;
`;
