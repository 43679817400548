import { endPoints } from 'api/';
import { getConditions } from 'actions/condition/';
import { DELETE_CONDITION, UPDATE_CONDITION } from 'actions/types';

/**
  @description Updates condition object. This will also act as `delete` action by `disabling` condition.
  @param {string} conditionId
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI condition will be delete
  @returns Condition object
 */
function updateCondition(conditionId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_CONDITION : UPDATE_CONDITION;

  /**
    @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async function (apiClient) {
        const condition = await apiClient(
          endPoints.UPDATE_CONDITION,
          payload,
          conditionId,
        );
        dispatch(getConditions(companyId));
        return condition;
      },
    };
    dispatch(action);
  };
}

export default updateCondition;
