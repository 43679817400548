import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { bool, arrayOf, object, func } from 'prop-types';

import {
  mapProps,
  apiProps,
  mapOptions,
  centerLatLng,
} from 'constants/googleMap';
import { getOrderNumber } from 'utils/';
import {
  StyledNotes,
  StyledLabel,
  StyledButton,
  StyledWrapper,
  StyledLocation,
  StyledDateLabel,
  StyledMapWrapper,
  StyledNotesTitle,
  StyledOrderNumber,
  StyledCloseButton,
} from 'components/Order/Map/Map.styled';
import { InfoWindow } from 'components/Map/';
import { Loading } from 'components/Loaders/';
import { InfoAlert } from 'components/Alerts/';
import { primary, black } from 'constants/color';
import { RoundButton } from 'components/Buttons/';
import { FlexRow, FlexCol } from 'components/Layout/';
import DatePicker from 'components/DatePicker/DatePicker';
import { ReactComponent as TickIcon } from 'icons/tick.svg';

/**
 * @param {{
 * orders : []
 * isLoading : boolean
 * onLoadOrders : Function
 * showDateFilter : boolean
 * onShowViewModal : Function
 * }} param
 */
function Map({
  orders,
  isLoading,
  onLoadOrders,
  showDateFilter,
  onShowViewModal,
}) {
  const initialDate = new Date();
  const [mapState, setMapState] = useState({
    lowerDate: initialDate,
    upperDate: initialDate,
  });
  const [mapErrors, setMapErrors] = useState({
    lowerDate: '',
    upperDate: '',
  });
  const [mapZoom, setMapZoom] = useState(11);
  const [activeMarker, setActiveMarker] = useState('');
  const [isDraggable, setIsDraggable] = useState(true);
  const [mapCenter, setMapCenter] = useState(centerLatLng);

  function onGoogleApiLoaded(map) {
    const bounds = new google.maps.LatLngBounds();
    orders.forEach((order) => {
      const { customer } = order;
      const { location } = customer || {};
      if (location) {
        const { lat, lng } = location;
        bounds.extend(new google.maps.LatLng(lat, lng));
      }
    });
    map.fitBounds(bounds);
  }

  function onMapClick() {}

  function onMarkerInteraction() {
    setIsDraggable(false);
  }

  function onMarkerInteractionMouseUp() {}

  function onMapChange({ center, zoom }) {
    setMapZoom(zoom);
    setMapCenter(center);
  }

  function onChildClick() {}

  /**
   * @param {string} date
   */
  function onLowerDateChange(date) {
    setMapState({
      ...mapState,
      lowerDate: date,
    });
  }

  /**
   *  @param {string} date
   */
  function onUpperDateChange(date) {
    setMapState({
      ...mapState,
      upperDate: date,
    });
  }

  function onFilterMap() {
    let isValid = true;
    const errorObject = { ...mapErrors };
    const { lowerDate, upperDate } = mapState;
    if (!lowerDate) {
      isValid = false;
      setMapErrors({
        ...errorObject,
        lowerDate: 'Select date from',
      });
    } else {
      isValid = true;
      setMapErrors({
        ...errorObject,
        lowerDate: '',
      });
    }
    if (!upperDate) {
      isValid = false;
      setMapErrors({
        ...errorObject,
        upperDate: 'Select date to',
      });
    } else {
      isValid = true;
      setMapErrors({
        ...errorObject,
        upperDate: '',
      });
    }
    if (lowerDate > upperDate) {
      isValid = false;
      setMapErrors({
        ...errorObject,
        lowerDate: 'Date from is greater than date to',
        upperDate: 'Date to is less than date from',
      });
    }
    if (isValid) {
      setMapErrors({
        lowerDate: '',
        upperDate: '',
      });
      onLoadOrders(lowerDate, upperDate);
    }
  }

  /**
   * @param {{}} order
   */
  function onViewOrder(order) {
    onShowViewModal(order);
  }

  /**
   * @param {string} id
   */
  function onMarkerClick(id) {
    setActiveMarker(id);
  }

  function renderMap() {
    if (isLoading) {
      return <Loading />;
    }
    if (orders.length > 0) {
      return (
        <StyledMapWrapper>
          <GoogleMapReact
            zoom={mapZoom}
            center={mapCenter}
            options={mapOptions}
            onClick={onMapClick}
            onChange={onMapChange}
            draggable={isDraggable}
            bootstrapURLKeys={apiProps}
            defaultZoom={mapProps.zoom}
            onChildClick={onChildClick}
            defaultCenter={mapProps.center}
            yesIWantToUseGoogleMapApiInternals
            hoverDistance={mapProps.hoverDistance}
            onChildMouseDown={onMarkerInteraction}
            onChildMouseMove={onMarkerInteraction}
            onChildMouseUp={onMarkerInteractionMouseUp}
            onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
          >
            {orders.map(function (order) {
              const { id, customer, notes, items } = order;
              const { location, name, address } = customer || {};
              const customerName = `Customer Name: ${name}`;
              const totalItems = `Total Items: ${items.length}`;
              const orderNumber = getOrderNumber({ id, prefix: '#' });
              if (!location) {
                return null;
              }
              return (
                <InfoWindow
                  {...location}
                  key={id}
                  isActive={activeMarker === id}
                  onClick={onMarkerClick.bind(null, id)}
                >
                  <StyledWrapper>
                    <StyledCloseButton
                      data-testid={`close-button-${id}`}
                      onClick={onMarkerClick.bind(null, null)}
                    >
                      x
                    </StyledCloseButton>
                    <StyledLocation>{unescape(address)}</StyledLocation>
                    <StyledOrderNumber color={primary}>
                      Order {orderNumber}
                    </StyledOrderNumber>
                    <StyledLabel color={black}>{totalItems}</StyledLabel>
                    <StyledLabel color={black}>
                      {unescape(customerName)}
                    </StyledLabel>
                    <StyledNotes>
                      <StyledNotesTitle>Notes</StyledNotesTitle>
                      {unescape(notes)}
                    </StyledNotes>
                    <StyledButton onClick={onViewOrder.bind(null, order)}>
                      View Order
                    </StyledButton>
                  </StyledWrapper>
                </InfoWindow>
              );
            })}
          </GoogleMapReact>
        </StyledMapWrapper>
      );
    }
    return <InfoAlert message="No Data To Draw Order Map" />;
  }

  return (
    <>
      {showDateFilter ? (
        <FlexRow>
          <StyledDateLabel>Select Date Range</StyledDateLabel>
          <FlexCol flexWidth={2}>
            <DatePicker
              caption=""
              name="lowerDate"
              iconTopMargin={7}
              inputId="lowerDate"
              onChange={onLowerDateChange}
              selected={mapState.lowerDate}
              placeholder="Select date from"
              errorMessage={mapErrors.lowerDate}
            />
          </FlexCol>
          <FlexCol flexWidth={2}>
            <DatePicker
              caption=""
              name="upperDate"
              iconTopMargin={7}
              inputId="upperDate"
              placeholder="Select date to"
              onChange={onUpperDateChange}
              selected={mapState.upperDate}
              errorMessage={mapErrors.upperDate}
            />
          </FlexCol>
          <FlexCol flexWidth={1}>
            <RoundButton
              caption="Load"
              flexWidth={100}
              icon={<TickIcon />}
              onClick={onFilterMap}
            />
          </FlexCol>
        </FlexRow>
      ) : null}
      <FlexRow>
        <FlexCol flexWidth={12}>{renderMap()}</FlexCol>
      </FlexRow>
    </>
  );
}

Map.propTypes = {
  isLoading: bool,
  onLoadOrders: func,
  showDateFilter: bool,
  orders: arrayOf(object),
};

export default Map;
