import taskStatuses from 'constants/taskStatuses';

/**
  @description Evaluate task status based on progress of checklist
  @param {{
    checklist : []
    due_date : string
    status : string
    }} task
   @returns status and progress
  */
function getTaskStatus(task) {
  // no checklist
  if (!task.checklist) {
    return {
      status: '',
      progress: 0,
    };
  }

  const { checklist, due_date: dateDue, status } = task;
  const isCheckListArray = Array.isArray(checklist);

  // Evaluate `completed` based on status
  const completed = [
    'done',
    'deleted',
    'archive',
    'removed',
    'inactive',
    'complete',
    'archived',
    'disabled',
    'cancelled',
    'completed',
    'deactivated',
  ];
  if (completed.includes(status.toLowerCase())) {
    return {
      progress: 100,
      status: taskStatuses.complete,
    };
  }

  // Evaluate `pending` based on status
  if (isCheckListArray && checklist.length === 0) {
    const pending = ['pending', 'active', 'unassigned'];

    if (pending.includes(status.toLowerCase())) {
      return {
        progress: 0,
        status: taskStatuses.pending,
      };
    }
  }

  // Evaluate status based on checklist
  if (isCheckListArray && checklist.length > 0) {
    const today = new Date();
    const dueDate = new Date(dateDue);
    const totalCheckList = checklist.length;
    const completed = checklist.filter(({ done }) => done === true).length;
    const progress = parseInt((completed / totalCheckList) * 100);

    // complete
    if (totalCheckList === completed) {
      return {
        progress,
        status: taskStatuses.complete,
      };
    }
    // overdue
    if (today.getTime() > dueDate.getTime()) {
      return {
        progress,
        status: taskStatuses.overdue,
      };
    }
    // pending
    if (completed === 0) {
      return {
        progress,
        status: taskStatuses.pending,
      };
    }
    // in progress
    if (completed > 0) {
      return {
        progress,
        status: taskStatuses.progress,
      };
    }
  }
}

export default getTaskStatus;
