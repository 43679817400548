import { endPoints } from 'api/';
import { getTags } from 'actions/tags/';
import { CREATE_TAG } from 'actions/types';

/**
  @description Creates tag object
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @returns Tag object
 */
function createTag(payload, companyId) {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CREATE_TAG,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const tag = await apiClient(endPoints.CREATE_TAG, payload);

        dispatch(getTags(companyId));

        return tag;
      },
    };

    dispatch(action);
  };
}

export default createTag;
