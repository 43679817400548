import React from 'react';
import { string, func, number, bool } from 'prop-types';

import { white, primary } from 'constants/color';
import { hoverAndTap } from 'constants/animation';
import { StyledButton } from './LinkButton.styled';

/**
  @param {{
  caption : string
  tabIndex : number
  fontSize : number
  onClick : Function
  flexWidth : number
  backColor : string
  foreColor : string
  buttonType : string
  isDisabled : boolean
  buttonTestId : string
  }} param
 */
const LinkButton = ({
  caption,
  onClick,
  tabIndex,
  buttonTestId,
  fontSize = 15,
  flexWidth = 100,
  backColor = white,
  isDisabled = false,
  foreColor = primary,
  buttonType = 'button',
}) => {
  return (
    <StyledButton
      {...hoverAndTap}
      onClick={onClick}
      type={buttonType}
      tabIndex={tabIndex}
      fontSize={fontSize}
      flexWidth={flexWidth}
      backColor={backColor}
      disabled={isDisabled}
      foreColor={foreColor}
      data-testid={buttonTestId}
    >
      {caption}
    </StyledButton>
  );
};

LinkButton.propTypes = {
  tabIndex: number,
  isDisabled: bool,
  fontSize: number,
  foreColor: string,
  flexWidth: number,
  backColor: string,
  buttonType: string,
  buttonTestId: string,
  onClick: func.isRequired,
  caption: string.isRequired,
};

export default LinkButton;
