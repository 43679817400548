import { endPoints } from 'api/';
import { getCustomers } from 'actions/customers/';
import { DELETE_CUSTOMER, UPDATE_CUSTOMER } from 'actions/types';

/**
  @description Updates customer object. This will also act as `delete` action by `disabling` status.
  @param {string} customerId
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
  @returns Customer object
 */
function updateCustomer(customerId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_CUSTOMER : UPDATE_CUSTOMER;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const customer = await apiClient(
          endPoints.UPDATE_CUSTOMER,
          payload,
          customerId,
        );

        dispatch(getCustomers({ companyId }));

        return customer;
      },
    };

    dispatch(action);
  };
}

export default updateCustomer;
