import React from 'react';
import { func, string } from 'prop-types';

import { white, maroon } from 'constants/color';
import { RoundButton } from 'components/Buttons/';
import { ReactComponent as DeleteIcon } from 'icons/delete.svg';

/**
 * @param {{
 * caption : string
 * onClick : Function
 * }} param
 */
function DeleteButton({ onClick, caption = 'Delete' }) {
  return (
    <RoundButton
      caption={caption}
      onClick={onClick}
      backColor={white}
      foreColor={maroon}
      borderColor={maroon}
      icon={<DeleteIcon />}
      className="delete-button"
    />
  );
}

DeleteButton.propTypes = {
  onClick: func,
  caption: string,
};

export default DeleteButton;
