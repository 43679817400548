import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledWrapper = styled(motion.li)`
  display: flex;
  padding-right: 20px;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    padding-right: 0;
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-right: 0;
  }
`;

export const StyledBarTitle = styled(motion.div)`
  width: 100%;
  display: flex;
`;

export const StyledBarBody = styled(motion.div)``;

export const StyledBar = styled(motion.div)`
  background-color: ${({ color }) => color};

  width: 100%;
  height: 20px;
  border-radius: 10px;
`;

export const StyledCaption = styled(motion.label)`
  color: ${({ color }) => color};

  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledLabel = styled(motion.label)`
  color: ${({ color }) => color};

  font-size: 12px;
  margin-left: auto;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;
