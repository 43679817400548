import { endPoints } from 'api/';
import { CREATE_CATEGORY } from 'actions/types';
import { getCategories } from 'actions/category/';

/**
 * @description Creates category object
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @returns Category object
 */
function create(payload, companyId) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: CREATE_CATEGORY,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const category = await apiClient(endPoints.CREATE_CATEGORY, payload);
        dispatch(getCategories(companyId));
        return category;
      },
    };
    dispatch(action);
  };
}

export default create;
