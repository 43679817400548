import { array, func, bool } from 'prop-types';
import React, { useRef, useMemo } from 'react';

import Loader from 'img/loader.gif';
import { Table } from 'components/Table/';
import { InfoAlert } from 'components/Alerts/';
import { DateFilter } from 'components/Reusable/';
import { CancelButton } from 'components/Buttons/';
import { FlexCol, FlexRow } from 'components/Layout/';
import {
  StyledLoader,
  StyledStorageName,
  StyledTableWrapper,
  StyledLoaderWrapper,
} from 'components/Storages/StorageList/StorageList.styled';
import { formatDate, getDateStamp, parseJSON } from 'utils/';

/**
  @param {{
  storages : []
  isLoading : boolean
  onLoadData : Function
  isOnBoarding : boolean
  onEditClick : Function
  onModalClose : Function
  onDeleteClick : Function
  }} param
 */
const StorageList = ({
  storages,
  isLoading,
  onLoadData,
  onEditClick,
  isOnBoarding,
  onModalClose,
  onDeleteClick,
}) => {
  const tableRef = useRef();
  const columns = useMemo(function () {
    return [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: function ({ row: { original } }) {
          return (
            <StyledStorageName onClick={onEditClick.bind(null, original)}>
              {original.name}
            </StyledStorageName>
          );
        },
      },
      {
        meta: {
          date: true,
        },
        header: 'Date Created',
        accessorKey: 'createdat',
        cell: function ({ getValue }) {
          return formatDate({
            standard: true,
            date: getValue(),
          });
        },
        accessorFn: function ({ createdat }) {
          return getDateStamp(new Date(createdat).getTime());
        },
      },
      {
        header: 'Location',
        accessorFn: function ({ settings }) {
          const settingsObj = parseJSON(settings);
          const { address = '' } = settingsObj;
          return address;
        },
      },
      {
        size: 150,
        header: 'Square Feet',
        cell: function ({ getValue }) {
          return parseInt(getValue());
        },
        accessorFn: function ({ settings }) {
          const settingsObj = parseJSON(settings);
          const { feets } = settingsObj;
          return parseInt(feets);
        },
      },
      {
        id: 'actions',
        header: 'Actions',
        enableSorting: false,
      },
    ];
  }, []);

  return (
    <>
      {isOnBoarding ? null : (
        <DateFilter isLoading={isLoading} onLoadData={onLoadData} />
      )}
      {isLoading ? (
        <StyledLoaderWrapper>
          <StyledLoader src={Loader} alt="Loader" />
        </StyledLoaderWrapper>
      ) : storages.length ? (
        <FlexRow marginBottom={20}>
          <FlexCol flexWidth={12}>
            <StyledTableWrapper>
              <Table
                width={95}
                data={storages}
                titleField="name"
                columns={columns}
                tableRef={tableRef}
                exportName="storages"
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
              />
            </StyledTableWrapper>
          </FlexCol>
        </FlexRow>
      ) : (
        <InfoAlert message="No Storage Data" />
      )}
      {onModalClose ? (
        <FlexRow className="modal-buttons-row">
          <CancelButton onClick={onModalClose} />
        </FlexRow>
      ) : null}
    </>
  );
};

StorageList.propTypes = {
  isLoading: bool,
  onEditClick: func,
  isOnBoarding: bool,
  onModalClose: func,
  onDeleteClick: func,
  storages: array.isRequired,
};

export default StorageList;
