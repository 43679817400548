import React from 'react';
import { shape, number, string } from 'prop-types';

import {
  StyledBar,
  StyledLabel,
  StyledWrapper,
  StyledBarBody,
  StyledCaption,
  StyledBarTitle,
} from './Bar.styled';
import { getRandomColor } from 'utils/';
import { maroon } from 'constants/color';

/** 
   @param {{
    data : {
      name : string 
      quantity : number
    }
  }} param 
*/
const Bar = ({ data }) => {
  const { name, quantity } = data;
  const stockOut = quantity === 0;
  const label = stockOut ? 'Out of Stock' : `${quantity} Left`;
  const color = stockOut ? maroon : getRandomColor({ graphColor: true });

  return (
    <StyledWrapper>
      <StyledBarTitle>
        <StyledCaption color={color}>{name}</StyledCaption>
        <StyledLabel color={color}>{label}</StyledLabel>
      </StyledBarTitle>
      <StyledBarBody>
        <StyledBar color={color}></StyledBar>
      </StyledBarBody>
    </StyledWrapper>
  );
};

Bar.propTypes = {
  data: shape({
    name: string,
    quantity: number,
  }),
};

export default Bar;
