import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';

import avatar from 'img/avatar.png';
import { Map } from 'components/Map/';
import { active } from 'constants/status';
import { INVALID_URL } from 'constants/general';
import { FlexCol, FlexRow } from 'components/Layout/';
import { parseJSON, capitalizeFirstLetter } from 'utils/';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { CancelButton, RoundButton } from 'components/Buttons/';
import {
  StyledMapHeader,
  StyledMapWrapper,
} from 'components/Warehouses/WarehouseForm/WarehouseForm.styled';
import { TextInput, PhotoInput, TextArea } from 'components/Inputs/';

/**
 * @param {{
 *  onSubmit : Function
 *  initialValue: Function
 *  onModalClose: Function }} param
 */
function WarehouseForm({ onSubmit, initialValue, onModalClose }) {
  const {
    photos,
    name = '',
    settings = '',
    location = undefined,
  } = initialValue || {};
  let photoUrl = avatar;
  const settingsObj = parseJSON(settings);
  const { address = '', notes = '', feets = '' } = settingsObj;
  if (Array.isArray(photos)) {
    photoUrl = photos[0].link;
  }
  if (typeof photos === 'string' && !INVALID_URL.includes(photos)) {
    photoUrl = photos;
  }

  const [warehouseState, setWarehouseState] = useState({
    status: active,
    name: unescape(name),
    feets: unescape(feets),
    notes: unescape(notes),
    address: unescape(address),
    location: JSON.stringify(location),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warehouseErrors, setWarehouseErrors] = useState({
    name: '',
    address: '',
    location: '',
  });
  const [warehouseLogo, setWarehouseLogo] = useState(photoUrl);

  /**
   * @param {{
   *  currentTarget: {
   *  name: string
   *  value: string
   *  }
   *  }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setWarehouseState({
      ...warehouseState,
      [name]: value,
    });
  }

  function onSubmitWarehouse() {
    setIsSubmitting(true);
    const isFormValid = validateWarehouseForm();
    if (isFormValid) {
      const { name, feets, notes, address, status, location } = warehouseState;
      const formData = new FormData();
      const settings = { feets, notes, address };
      formData.append('name', name);
      formData.append('status', status);
      formData.append('location', location);
      formData.append('photos', warehouseLogo);
      formData.append('settings', JSON.stringify(settings));
      onSubmit(formData);
    }
  }

  function validateWarehouseForm() {
    let isFormValid = true;
    let errorObject = { ...warehouseErrors };
    const required = ['name', 'location', 'address'];
    for (const [key, value] of Object.entries(warehouseState)) {
      if (required.includes(key) && !value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }
    setWarehouseErrors(errorObject);
    return isFormValid;
  }

  useEffect(() => {
    if (isSubmitting) {
      validateWarehouseForm();
    }
  }, [warehouseState, isSubmitting]);

  /**
   * @param {{}} coordinates
   * @param {string} street
   */
  function onCoordinatesChanged(coordinates, street) {
    const warehouse = { ...warehouseState };
    setWarehouseState({
      ...warehouse,
      address: street,
      location: JSON.stringify(coordinates),
    });
  }

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={4}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                caption="Name"
                inputType="text"
                placeholder="Enter name"
                onChange={onInputChange}
                value={warehouseState.name}
                errorMessage={warehouseErrors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                name="feets"
                inputId="feets"
                inputType="text"
                caption="Square Feet"
                onChange={onInputChange}
                value={warehouseState.feets}
                placeholder="Enter square feet"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextArea
                name="notes"
                inputId="notes"
                inputType="text"
                caption="Extra Note"
                onChange={onInputChange}
                placeholder="Enter note"
                value={warehouseState.notes}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="warehouseLogo"
                caption="Warehouse Logo"
                imageUri={warehouseLogo}
                dataTestId="companyLogo"
                onChange={setWarehouseLogo}
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={8}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <StyledMapHeader>Pick location from Google Maps</StyledMapHeader>
              <StyledMapWrapper>
                <Map
                  centerLatLng={location}
                  onCoordinatesChanged={onCoordinatesChanged}
                />
              </StyledMapWrapper>
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <TextInput
                disabled
                isRequired
                name="address"
                inputType="text"
                inputId="address"
                caption="Address"
                onChange={onInputChange}
                value={warehouseState.address}
                placeholder="Pick address from map"
                errorMessage={warehouseErrors.address}
              />
            </FlexCol>
            <FlexCol flexWidth={5}>
              <TextInput
                disabled
                isRequired
                name="location"
                inputType="text"
                inputId="location"
                caption="Location"
                onChange={onInputChange}
                value={warehouseState.location}
                placeholder="Pick location from map"
                errorMessage={warehouseErrors.location}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          icon={<ArrowIcon />}
          className="modal-button"
          onClick={onSubmitWarehouse}
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

WarehouseForm.defaultProps = {
  initialValue: {},
};

WarehouseForm.propTypes = {
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default WarehouseForm;
