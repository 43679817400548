import styled, { css } from 'styled-components';

import { black } from 'constants/color';

export const StyledHistoryWrapper = styled.section`
  max-height: 60%;
  margin-top: 10px;
  padding-left: 5px;
  overflow-x: hidden;
  padding-right: 5px;
  ${({ theme }) => theme.scrollbar};
  /* Mobile Devices */
  @media (max-width: 480px) {
    overflow: auto;
    max-height: 90%;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    overflow: auto;
    margin-right: -30px;
  }
`;

export const StyledHeading = styled.h3`
  border-bottom: 1px solid #e0e0e0;
`;

export const StyledList = styled.ul`
  padding-left: 0;
`;

export const StyledListItem = styled.li`
  display: flex;
  list-style: none;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const StyledParagraph = styled.p`
  margin-top: 0;
  color: ${black};
  font-size: 11px;
  margin-bottom: 5px;
  ${function ({ $hasBorder }) {
    return $hasBorder
      ? css`
          padding-bottom: 5px;
          border-bottom: 1px solid #e0e0e0;
        `
      : '';
  }};
`;

export const StyledDetails = styled.div`
  display: flex;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
`;

export const StyledAction = styled.div`
  width: 30%;
  font-size: 11px;
  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 50%;
  }
  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 40%;
  }
`;

export const StyledResult = styled.div`
  font-size: 11px;
  text-align: left;
`;
