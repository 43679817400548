import { func, node, number } from 'prop-types';
import React, { useState } from 'react';

import {
  IconButton,
  StyledNavItem,
  StyledNotificationWrapper,
  StyledNotificationIndicator,
} from 'components/Navbar/NavItem/NavItem.styled';

/**
 * @param {{
 * icon: JSX,
 * children: JSX,
 * onClick: Function,
 * notification: number | undefined
 * }} param
 */
function NavItem({ icon, onClick, children, notification }) {
  const [isNavItemOpen, setIsNavItemOpen] = useState(false);

  function onNavItemClick() {
    if (onClick) {
      onClick();
    }
    setIsNavItemOpen(!isNavItemOpen);
  }

  return (
    <StyledNavItem>
      <IconButton type="button" onClick={onNavItemClick}>
        {notification ? (
          <StyledNotificationWrapper>
            {icon}
            <StyledNotificationIndicator>•</StyledNotificationIndicator>
          </StyledNotificationWrapper>
        ) : (
          icon
        )}
      </IconButton>
      {isNavItemOpen && children}
    </StyledNavItem>
  );
}

NavItem.propTypes = {
  icon: node,
  onClick: func,
  children: node,
  notification: number,
};

export default NavItem;
