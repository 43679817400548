/**
  @description Removes duplicate from given `array`
  @param {[]} data Array to filter.
  @param {string} key Field to filter with.
 */
function getUniqueArrayBy(data, key) {
  return [...new Map(data.map((item) => [item[key], item])).values()];
}

export default getUniqueArrayBy;
