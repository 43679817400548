import { endPoints } from 'api/';
import { getTags } from 'actions/tags/';
import { DELETE_TAG, UPDATE_TAG } from 'actions/types';

/**
  @description Updates tag object. This will also act as `delete` action by `disabling` status.
  @param {string} tagId
  @param {{}} payload
  @param {string} companyId Current user's company id.
  @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
  @returns Tag object
 */
function updateTag(tagId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_TAG : UPDATE_TAG;

  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: actionType,
      /**
        @param {Function} apiClient
       */
      request: async (apiClient) => {
        const tag = await apiClient(endPoints.UPDATE_TAG, payload, tagId);

        dispatch(getTags(companyId));

        return tag;
      },
    };

    dispatch(action);
  };
}

export default updateTag;
