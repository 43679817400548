import React from 'react';
import { array } from 'prop-types';

import { getImageUrl } from 'utils/';
import { Tile } from 'components/Widgets/';
import Avatar from 'components/Avatar/Avatar';
import {
  StyledLabel,
  StyledTileBody,
  StyledTileItem,
  StyledTopOrderItem,
  StyledItemNameLabel,
} from 'components/Order/UserOrder/UserOrder.styled';

/**
 * @param {{
 * data : []
 * }} param
 */
function UserOrder({ data }) {
  const hasData = data?.length > 0;
  return (
    <Tile caption="Orders Per User">
      <StyledTileBody>
        {hasData &&
          data.map(function (order) {
            const { id, total, name, orders, photo } = order;
            const url = getImageUrl(photo);
            const orderTotal = parseFloat(total).toLocaleString();
            const ordered = `${orders} Orders`;
            return (
              <StyledTileItem key={`${id}-${name}`}>
                <StyledTopOrderItem>
                  <Avatar
                    imageSource={url}
                    borderRadius="50%"
                    imageAltText="customer"
                  />
                  <StyledItemNameLabel>{name}</StyledItemNameLabel>
                  <StyledLabel>{ordered}</StyledLabel>
                  <StyledLabel>{orderTotal}</StyledLabel>
                </StyledTopOrderItem>
              </StyledTileItem>
            );
          })}
      </StyledTileBody>
    </Tile>
  );
}

UserOrder.propTypes = {
  data: array,
};

export default UserOrder;
