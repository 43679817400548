import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
} from '@tanstack/react-table';
import React, { useState } from 'react';

import {
  StyledTable,
  StyledMessage,
  StyledTableRow,
  StyledTableData,
  StyledTableHead,
  StyledTableBody,
  StyledNoDataTableData,
  StyledTableButtonWrapper,
} from 'components/Table/Table/Table.styled';
import { TableButton } from 'components/Buttons/';
import { Footer, Header } from 'components/Table/';
import { ErrorBoundary } from 'components/ErrorBoundary/';
import tableButtonTypes from 'constants/tableButtonTypes';

function Table({
  data,
  columns,
  tableRef,
  exportName,
  width = 100,
  onEditClick,
  onDeleteClick,
  titleField = '',
  onRowClick = null,
  excelExport = true,
  headComponent = null,
  actionsDropDown = null,
  noDataMessage = 'No Data',
  enableColumnFilters = true,
}) {
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState([]);
  const {
    getState,
    nextPage,
    getRowModel,
    setPageSize,
    getPageCount,
    previousPage,
    setPageIndex,
    getCanNextPage,
    getHeaderGroups,
    getCanPreviousPage,
    getVisibleLeafColumns,
    getPreFilteredRowModel,
    getPrePaginationRowModel,
  } = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
      columnFilters,
    },
    enableColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <ErrorBoundary>
      <StyledTable $width={width} ref={tableRef}>
        <StyledTableHead>
          <Header
            exportName={exportName}
            excelExport={excelExport}
            setPageSize={setPageSize}
            globalFilter={globalFilter}
            headComponent={headComponent}
            headerGroups={getHeaderGroups()}
            setGlobalFilter={setGlobalFilter}
            actionsDropDown={actionsDropDown}
            rows={getPrePaginationRowModel().rows}
            pageSize={getState().pagination.pageSize}
            flatRows={getPreFilteredRowModel().flatRows}
            visibleColumns={getVisibleLeafColumns().length}
          />
        </StyledTableHead>
        <StyledTableBody>
          {!data.length > 0 ? (
            <StyledTableRow>
              <StyledNoDataTableData colSpan={columns.length}>
                <StyledMessage>{noDataMessage}</StyledMessage>
              </StyledNoDataTableData>
            </StyledTableRow>
          ) : (
            getRowModel().rows.map(function (row) {
              const props = {};
              if (onRowClick) {
                props.onClick = onRowClick.bind(null, row.original);
              }
              return (
                <StyledTableRow {...props} key={row.id}>
                  {row.getVisibleCells().map(function (cell) {
                    const {
                      id,
                      getValue,
                      getContext,
                      row: { original },
                      column: { columnDef },
                    } = cell;
                    const isDate = !!columnDef?.meta?.date;
                    const isNumber = typeof getValue() === 'number';
                    const shouldBeCentered = isNumber && !isDate;
                    if (columnDef.id === 'actions') {
                      const title = original[titleField];
                      return (
                        <StyledTableData $width={0} key={id}>
                          <StyledTableButtonWrapper>
                            {!onEditClick ? null : (
                              <TableButton
                                title={`Edit ${title}`}
                                buttonType={tableButtonTypes.edit}
                                onClick={onEditClick.bind(null, original)}
                              />
                            )}
                            {!onDeleteClick ? null : (
                              <TableButton
                                title={`Delete ${title}`}
                                buttonType={tableButtonTypes.delete}
                                onClick={onDeleteClick.bind(null, original)}
                              />
                            )}
                          </StyledTableButtonWrapper>
                        </StyledTableData>
                      );
                    }
                    return (
                      <StyledTableData key={id} $center={shouldBeCentered}>
                        {flexRender(columnDef.cell, getContext())}
                      </StyledTableData>
                    );
                  })}
                </StyledTableRow>
              );
            })
          )}
        </StyledTableBody>
      </StyledTable>
      <Footer
        nextPage={nextPage}
        setPageSize={setPageSize}
        pageCount={getPageCount()}
        setPageIndex={setPageIndex}
        previousPage={previousPage}
        canNextPage={getCanNextPage()}
        canPreviousPage={getCanPreviousPage()}
        pageSize={getState().pagination.pageSize}
        pageIndex={getState().pagination.pageIndex}
      />
    </ErrorBoundary>
  );
}

export default Table;
