import React from 'react';
import { createPortal } from 'react-dom';
import { bool, element, func, number, string } from 'prop-types';

import {
  StyledModal,
  ModalBackDrop,
  StyledModalBody,
  StyledCloseButton,
  StyledModalHeader,
} from 'containers/Modal/Modal.styled';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';

/**
 * @param {{
 * children : JSX
 * isOpen : boolean
 * caption : string
 * modalTop : number
 * onClose : Function
 * modalLeft : number
 * modalWidth : number
 * }} param
 */
const Modal = ({
  isOpen,
  onClose,
  caption,
  children,
  modalTop = 20,
  modalLeft = 30,
  modalWidth = 50,
  isConfirmModal = false,
}) => {
  const portal = window.document.querySelector('#portal');
  if (!isOpen) return null;
  if (!portal) return null;

  /**
   * @description When modal is clicked, don't rerender
   * @param {{
   *  stopPropagation : Function
   *  }} e
   */
  function onModalClick(e) {
    return e.stopPropagation();
  }

  function Modal() {
    return (
      <>
        <ModalBackDrop />
        <StyledModal
          isOpen={isOpen}
          modalTop={modalTop}
          data-testid="modal"
          modalLeft={modalLeft}
          onClick={onModalClick}
          modalWidth={modalWidth}
          $confirm={isConfirmModal}
        >
          <StyledModalHeader>
            {caption}
            <StyledCloseButton onClick={onClose} role="close-modal">
              <PlusIcon />
            </StyledCloseButton>
          </StyledModalHeader>
          <StyledModalBody>{children}</StyledModalBody>
        </StyledModal>
      </>
    );
  }
  return createPortal(<Modal />, portal);
};

Modal.propTypes = {
  isOpen: bool,
  onClose: func,
  caption: string,
  modalTop: number,
  children: element,
  modalLeft: number,
  modalWidth: number,
};

export default Modal;
