import { CLEAR_NOTIFY } from 'actions/types';

/**
  @description Clears notify
 */
function clearNotify() {
  /**
    @param {Function} dispatch
   */
  return (dispatch) => {
    const action = {
      type: CLEAR_NOTIFY,
      payload: {
        message: '',
        error: '',
      },
    };

    dispatch(action);
  };
}

export default clearNotify;
