import React, { useState, useEffect } from 'react';
import { func, object } from 'prop-types';

import avatar from 'img/avatar.png';
import { Map } from 'components/Map/';
import { active } from 'constants/status';
import { INVALID_URL } from 'constants/general';
import { FlexCol, FlexRow } from 'components/Layout/';
import { parseJSON, capitalizeFirstLetter } from 'utils/';
import {
  StyledMapHeader,
  StyledMapWrapper,
} from 'components/Storages/StorageForm/StorageForm.styled';
import { ReactComponent as ArrowIcon } from 'icons/tick.svg';
import { CancelButton, RoundButton } from 'components/Buttons/';
import { TextInput, PhotoInput, TextArea } from 'components/Inputs/';

/**
 * @param {{
 * onSubmit : Function
 * initialValue: Function
 * onModalClose: Function
 * }} param
 */
function StorageForm({ onSubmit, initialValue, onModalClose }) {
  const { photos, name = '', settings = '', location } = initialValue || {};
  let photoUrl = avatar;
  const settingsObj = parseJSON(settings);
  const { address = '', notes = '', feets = '' } = settingsObj;
  const locationObj = parseJSON(location);
  if (Array.isArray(photos)) {
    photoUrl = photos[0].link;
  }
  if (typeof photos === 'string' && !INVALID_URL.includes(photos)) {
    photoUrl = photos;
  }
  const [storageState, setStorageState] = useState({
    status: active,
    name: unescape(name),
    location: locationObj,
    feets: unescape(feets),
    notes: unescape(notes),
    address: unescape(address),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [storageErrors, setStorageErrors] = useState({
    name: '',
    address: '',
    location: '',
  });
  const [storageLogo, setStorageLogo] = useState(photoUrl);

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { name, value } = currentTarget;
    setStorageState({
      ...storageState,
      [name]: value,
    });
  }

  function onSubmitStorage() {
    setIsSubmitting(true);
    const isFormValid = validateStorageForm();
    if (isFormValid) {
      const { name, feets, notes, address, status, location } = storageState;
      const formData = new FormData();
      const settings = { feets, notes, address };
      formData.append('name', name);
      formData.append('status', status);
      formData.append('location', JSON.stringify(location));
      formData.append('settings', JSON.stringify(settings));
      if (storageLogo) {
        formData.append('photos', storageLogo);
      }
      onSubmit(formData);
    }
  }

  const validateStorageForm = () => {
    let isFormValid = true;
    let errorObject = { ...storageErrors };
    const required = ['name', 'location', 'address'];
    for (const [key, value] of Object.entries(storageState)) {
      if (required.includes(key) && !value) {
        isFormValid = false;
        errorObject = {
          ...errorObject,
          [key]: `Incorrect ${capitalizeFirstLetter(key, true)}`,
        };
      } else {
        errorObject = {
          ...errorObject,
          [key]: '',
        };
      }
    }
    setStorageErrors(errorObject);
    return isFormValid;
  };

  /**
   * @param {{}} coordinates
   * @param {string} street
   */
  function onCoordinatesChanged(coordinates, street) {
    const storage = { ...storageState };
    setStorageState({
      ...storage,
      address: street,
      location: coordinates,
    });
  }

  useEffect(() => {
    if (isSubmitting) {
      validateStorageForm();
    }
  }, [storageState, isSubmitting]);

  return (
    <>
      <FlexRow>
        <FlexCol flexWidth={4}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                isRequired
                name="name"
                inputId="name"
                caption="Name"
                inputType="text"
                placeholder="Enter name"
                onChange={onInputChange}
                value={storageState.name}
                errorMessage={storageErrors.name}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextInput
                name="feets"
                inputId="feets"
                inputType="text"
                caption="Square Feet"
                onChange={onInputChange}
                value={storageState.feets}
                placeholder="Enter square feet"
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <TextArea
                name="notes"
                inputId="notes"
                inputType="text"
                caption="Extra Note"
                onChange={onInputChange}
                placeholder="Enter note"
                value={storageState.notes}
              />
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <PhotoInput
                id="storageLogo"
                caption="Storage Logo"
                imageUri={storageLogo}
                dataTestId="companyLogo"
                onChange={setStorageLogo}
                buttonText="Choose From Device"
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol flexWidth={8}>
          <FlexRow>
            <FlexCol flexWidth={12}>
              <StyledMapHeader>Pick location from Google Maps</StyledMapHeader>
              <StyledMapWrapper>
                <Map
                  centerLatLng={location}
                  onCoordinatesChanged={onCoordinatesChanged}
                />
              </StyledMapWrapper>
            </FlexCol>
          </FlexRow>
          <FlexRow>
            <FlexCol flexWidth={7}>
              <TextInput
                disabled
                isRequired
                name="address"
                inputType="text"
                inputId="address"
                caption="Address"
                onChange={onInputChange}
                value={storageState.address}
                placeholder="Pick address from map"
                errorMessage={storageErrors.address}
              />
            </FlexCol>
            <FlexCol flexWidth={5}>
              <TextInput
                disabled
                isRequired
                name="location"
                inputType="text"
                inputId="location"
                caption="Location"
                onChange={onInputChange}
                placeholder="Pick location from map"
                errorMessage={storageErrors.location}
                value={JSON.stringify(storageState.location)}
              />
            </FlexCol>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow className="modal-buttons-row">
        <RoundButton
          caption="Save"
          icon={<ArrowIcon />}
          className="modal-button"
          onClick={onSubmitStorage}
        />
        <CancelButton onClick={onModalClose} />
      </FlexRow>
    </>
  );
}

StorageForm.defaultProps = {
  initialValue: {},
};

StorageForm.propTypes = {
  onSubmit: func,
  onModalClose: func,
  initialValue: object,
};

export default StorageForm;
