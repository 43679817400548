import { endPoints } from 'api/';
import { getOrders } from 'actions/order/';
import { UPDATE_BULK_ORDER } from 'actions/types';

/**
 * Bulk update orders.
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 */
function update({ payload, companyId }) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: UPDATE_BULK_ORDER,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const order = await apiClient(endPoints.BULK_UPDATE_ORDER, payload);
        dispatch(getOrders({ companyId }));
        return order;
      },
    };
    dispatch(action);
  };
}

export default update;
