import React from 'react';
import { func, string } from 'prop-types';

import {
  StyledBody,
  StyledError,
  StyledNotify,
  StyledHeader,
  StyledButton,
  StyledMessage,
} from 'components/Notify/Notify.styled';
import { ReactComponent as TickIcon } from 'icons/tick.svg';
import { ReactComponent as QuestionIcon } from 'icons/ask.svg';
import { ReactComponent as InfoIcon } from 'icons/activity.svg';
import { INFO, ERROR, SUCCESS, WARNING } from 'constants/notify';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';

/**
 * @param {{
 * type: string,
 * error: string,
 * message: string,
 * onClose: Function
 * }} param
 */
function Notify({ type, onClose, message, error }) {
  const hasError = error.length > 0;
  const colors = {
    [INFO]: { color: '#38AEC2', icon: <InfoIcon /> },
    [SUCCESS]: { color: '#54CC7C', icon: <TickIcon /> },
    [ERROR]: { color: '#D00606', icon: <WarningIcon /> },
    [WARNING]: { color: '#FCBD0C', icon: <QuestionIcon /> },
  };
  const props = colors[type];

  const animation = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  const transition = {
    duration: 0.4,
    ease: [0.43, 0.13, 0.23, 0.96],
  };

  const slideIn = {
    hidden: {
      x: '200vw',
      opacity: 0,
    },
    visible: {
      x: '0',
      opacity: 1,
      transition: {
        damping: 25,
        ...transition,
        type: 'spring',
        stiffness: 500,
        staggerChildren: 0.1,
      },
    },
    exit: {
      x: '100vw',
      opacity: 0,
      scale: 0.2,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.1,
        ease: 'easeIn',
      },
    },
  };

  return (
    <StyledNotify
      exit="exit"
      {...animation}
      initial="hidden"
      animate="visible"
      variants={slideIn}
      whileHover="hover"
      hasError={hasError}
      $color={props.color}
    >
      <StyledHeader hasError={hasError}>
        {props.icon}
        <StyledMessage> {message} </StyledMessage>
        <StyledButton onClick={onClose} title="close">
          X
        </StyledButton>
      </StyledHeader>
      {hasError && (
        <StyledBody>
          <StyledError>{error}</StyledError>
        </StyledBody>
      )}
    </StyledNotify>
  );
}

Notify.propTypes = {
  type: string,
  error: string,
  onClose: func,
  message: string,
};

export default Notify;
