import React from 'react';

import { Loading } from 'components/Loaders/';
import {
  StyledList,
  StyledAction,
  StyledResult,
  StyledHeading,
  StyledDetails,
  StyledListItem,
  StyledParagraph,
  StyledHistoryWrapper,
} from 'components/Order/History/History.styled';
import { Collapsible } from 'components/Collapsible/';

function History({ orderHistory }) {
  const { data = [], isLoading } = orderHistory || {};

  /**
   * @param {Date} date
   */
  function formatDate(date) {
    const options = {
      hour12: true,
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    };
    let formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    // Add ordinal suffix
    const day = date.getDate();
    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) {
      suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
      suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      suffix = 'rd';
    }
    formattedDate = formattedDate.replace(/\d+/, function (match) {
      return `${match}${suffix}`;
    });
    return formattedDate.replace(',', '').replace(',', ' |');
  }

  /**
   *
   * @param {string | Object} value
   * @returns
   */
  function stringify(value) {
    const ignore = ['string', 'number', 'boolean', 'undefined', 'null'];
    if (ignore.includes(typeof value) || value === null) {
      return value;
    }
    return JSON.stringify(value);
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <StyledHeading>Order History</StyledHeading>
      <StyledHistoryWrapper>
        <StyledList>
          {data.map(function (order) {
            const {
              timestamp,
              user_email: email,
              change_details: details,
            } = order;
            const updatedValues = details.find(function (order) {
              return order.field === 'updatedValues';
            })?.curr_value;
            const action = updatedValues ? 'updated' : 'created';
            return (
              <StyledListItem key={timestamp}>
                <StyledParagraph>
                  {formatDate(new Date(timestamp))}
                </StyledParagraph>
                <StyledParagraph>{email}</StyledParagraph>
                <StyledParagraph $hasBorder={true}>
                  Order was {action}
                </StyledParagraph>
                <Collapsible open={false} caption="Details">
                  {updatedValues
                    ? Object.keys(updatedValues).map(function (key) {
                        return (
                          <StyledDetails key={key}>
                            <StyledAction>{key}</StyledAction>
                            <StyledResult>
                              {stringify(updatedValues[key])}
                            </StyledResult>
                          </StyledDetails>
                        );
                      })
                    : details.map(function (change) {
                        const { field, curr_value: value } = change;
                        return (
                          <StyledDetails key={field}>
                            <StyledAction>{field}</StyledAction>
                            <StyledResult>{stringify(value)}</StyledResult>
                          </StyledDetails>
                        );
                      })}
                </Collapsible>
              </StyledListItem>
            );
          })}
        </StyledList>
      </StyledHistoryWrapper>
    </>
  );
}

export default History;
