import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'containers/Modal/Modal';
import { ACCOUNT_SETUP_PATH } from 'constants/general';
import { showModal, clearModal } from 'actions/modals/';
import { createWarehouse, updateWarehouse } from 'actions/warehouses/';
import { WAREHOUSE_LIST_MODAL, WAREHOUSE_MODAL } from 'constants/modal';
import WarehouseForm from 'components/Warehouses/WarehouseForm/WarehouseForm';

const WarehouseModal = () => {
  const dispatch = useDispatch();
  const routerLocation = useLocation();
  const { pathname } = routerLocation;
  const isOnBoarding = pathname === ACCOUNT_SETUP_PATH;

  const user = useSelector(({ user }) => user);
  const modal = useSelector(({ modal }) => modal);

  const isOpen = modal?.type === WAREHOUSE_MODAL;
  const isEditing = isOpen && modal?.initialValue;
  const modalCaption = isEditing ? 'Update Warehouse' : 'Create A Warehouse';
  const { initialValue } = modal;

  const onModalClose = () => {
    dispatch(clearModal(WAREHOUSE_MODAL));

    if (isEditing && !isOnBoarding) {
      dispatch(showModal(WAREHOUSE_LIST_MODAL));
    }
  };

  /**
    @param {FormData} payload
   */
  const onSubmit = (payload) => {
    const { company_id: companyId } = user;

    if (isEditing) {
      const { id: warehouseId } = initialValue;

      payload.append('id', warehouseId);

      dispatch(updateWarehouse(warehouseId, payload, companyId, false));
    } else {
      payload.append('company_id', companyId);

      dispatch(createWarehouse(payload, companyId));
    }

    // dispatch(clearModal(WAREHOUSE_MODAL));
  };

  return (
    <Modal
      modalTop={10}
      modalLeft={10}
      modalWidth={80}
      isOpen={isOpen}
      onClose={onModalClose}
      caption={modalCaption}
    >
      <WarehouseForm
        onSubmit={onSubmit}
        initialValue={initialValue}
        onModalClose={onModalClose}
      />
    </Modal>
  );
};

export default WarehouseModal;
