import React from 'react';
import { bool, func, node, number, string } from 'prop-types';

import {
  StyledInput,
  StyledLabel,
  StyledAsterisk,
  StyledInputIcon,
  StyledFormGroup,
  InputErrorLabel,
  StyledDescription,
} from 'components/Inputs/TextInput/TextInput.styled';

/**
 * @param {{
 * name: string,
 * value: string,
 * inputId: string,
 * caption: string,
 * inputIcon: node,
 * onBlur: Function,
 * tabIndex: number,
 * disabled: boolean,
 * inputType: string,
 * onChange: Function,
 * isInvalid: boolean,
 * isRequired: boolean,
 * placeholder: string,
 * description: string,
 * errorMessage: string,
 * disableAutoComplete: boolean,
 * }} param
 */
function TextInput({
  name,
  value,
  onBlur,
  inputId,
  caption,
  onChange,
  tabIndex,
  inputIcon,
  placeholder,
  errorMessage,
  disabled = false,
  description = '',
  isInvalid = false,
  marginBottom = 15,
  isRequired = false,
  inputType = 'text',
  disableAutoComplete = false,
}) {
  const hasInputIcon = inputIcon !== undefined;
  const hasError = errorMessage?.length > 0;
  const invalidInput = isInvalid || hasError;
  const props = {};
  if (disableAutoComplete) {
    props.autoComplete = 'new-password';
  }

  return (
    <StyledFormGroup $marginBottom={marginBottom}>
      <StyledLabel htmlFor={inputId}>{caption}</StyledLabel>
      {isRequired ? <StyledAsterisk>*</StyledAsterisk> : null}
      {description ? (
        <StyledDescription>{description}</StyledDescription>
      ) : null}
      <StyledInput
        {...props}
        name={name}
        id={inputId}
        value={value}
        onBlur={onBlur}
        type={inputType}
        onChange={onChange}
        tabIndex={tabIndex}
        disabled={disabled}
        placeholder={placeholder}
        $isInvalid={invalidInput}
        $hasInputIcon={hasInputIcon}
      />
      {inputIcon ? <StyledInputIcon>{inputIcon}</StyledInputIcon> : null}
      {errorMessage ? <InputErrorLabel>{errorMessage}</InputErrorLabel> : null}
    </StyledFormGroup>
  );
}

TextInput.propTypes = {
  name: string,
  onBlur: func,
  value: string,
  onChange: func,
  disabled: bool,
  inputId: string,
  caption: string,
  isInvalid: bool,
  inputIcon: node,
  tabIndex: number,
  isRequired: bool,
  inputType: string,
  placeholder: string,
  description: string,
  errorMessage: string,
  disableAutoComplete: bool,
};

export default TextInput;
