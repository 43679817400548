import styled from 'styled-components';

export const StyledMapHeader = styled.h1`
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
`;

export const StyledMapWrapper = styled.div`
  --borderRadius: ${({ theme }) => theme.borderRadius};

  width: 100%;
  height: 350px;
  border-radius: var(--borderRadius);
`;
