import formatDate from 'utils/formatDate/formatDate';

/**
 * Returns lower date and current date after rolling it back to given days.
 * @param {{
 * days: number
 * extraDays: number
 * standard: boolean
 * }} param
 */
function rollBackDate({ days, extraDays = 0, standard = false }) {
  const today = new Date();
  const earlier = new Date();

  const lowerDate = earlier.setDate(earlier.getDate() - days);
  const upperDate = today.setDate(today.getDate() + extraDays);

  if (standard) {
    return {
      lowerDate: formatDate({ date: lowerDate, standard: true }),
      upperDate: formatDate({ date: upperDate, standard: true }),
    };
  }

  return {
    upperDate,
    lowerDate,
  };
}

export default rollBackDate;
