import styled from 'styled-components';

import { black } from 'constants/color';

export const StyledWrapper = styled.div`
  height: auto;
  display: 100%;
  min-height: 250px;
  padding: 5px 15px;
  margin-right: -12px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  ${({ theme }) => theme.scrollbar};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.whiteColor};

  /* Mobile Devices */
  @media (max-width: 480px) {
    margin: 0px;
    overflow-x: hidden;
    max-height: calc(calc(${({ theme }) => theme.mobileViewHeight}) - 5rem);
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-right: 5px;
    margin-right: -10px;
  }
`;

export const WrapperHeading = styled.h1`
  color: ${black};
`;
