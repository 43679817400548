import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  div.modal-buttons-row {
    display: flex;
    flex-direction: row;
    margin-left: 0px !important;

    button.save-button,
    button.edit-button {
      min-width: 150px;
      width: 22% !important;
    }

    button.edit-button {
      margin-right: 20px;
    }
  }

  /* Mobile Devices */
  @media (max-width: 480px) {
    width: 100%;
    padding-left: 0;

    div.modal-buttons-row {
      margin-left: 0px !important;

      button.edit-button {
        margin-right: 2px;
        width: 30% !important;
        min-width: 50px !important;
      }

      button.save-button {
        margin-left: 5%;
        width: 30% !important;
        min-width: 50px !important;
      }

      button.modal-cancel-button {
        margin-left: 5%;
        width: 30% !important;
        min-width: 50px !important;
      }
    }
  }

  /* Portrait Tablets and Ipads */
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 95%;
    div.modal-buttons-row {
      margin-left: 0px !important;

      button.edit-button {
        margin-right: 2px;
        width: 30% !important;
        min-width: 50px !important;
      }

      button.save-button {
        margin-left: 5%;
        width: 30% !important;
        min-width: 50px !important;
      }

      button.modal-cancel-button {
        margin-left: 5%;
        width: 30% !important;
        min-width: 50px !important;
      }
    }
  }
`;
