const taskStatuses = {
  complete: {
    color: '#54CC7C',
    label: 'Complete',
  },
  pending: {
    color: '#38AEC2',
    label: 'Pending',
  },
  overdue: {
    color: '#D00606',
    label: 'Overdue',
  },
  progress: {
    color: '#FDC52B',
    label: 'In Progress',
  },
};

export default taskStatuses;
