import { SET_THEME_STYLES } from 'actions/types';

/**
 * @description Sets current theme styles.
 * @param {string} containerBackColor
 */
function setThemeStyles(containerBackColor) {
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: SET_THEME_STYLES,
      payload: {
        containerBackColor,
      },
    };
    dispatch(action);
  };
}

export default setThemeStyles;
