import styled from 'styled-components';

export const StyledTileBody = styled.ul`
  height: 290px;
  padding-left: 0;
  margin-top: 5px;
  margin-bottom: 0;
  overflow-x: hidden;
  ${({ theme }) => theme.scrollbar};
`;

export const StyledTileItem = styled.li`
  padding: 5px;
  display: flex;
  margin-right: 4px;
  margin-bottom: 10px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.primaryColor};

  :not(:first-child) {
    margin-top: 10px;
  }
`;

export const StyledLabel = styled.label`
  margin: 0 10px;
  font-size: 12px;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  color: ${({ color }) => color};
  background-color: ${({ backColor }) => backColor};

  &:last-child {
  }

  :not(:first-of-type) {
    margin-left: auto;
  }
`;

export const StyledCustomerNameLabel = styled.label`
  margin: 0 10px;
  min-width: 50%;
  max-width: 100%;
  font-size: 12px;
  overflow: hidden;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ color }) => color};
`;

export const StyledLogoWrapper = styled.div`
  padding-right: 2px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const StyledAvatar = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 2px;
  border-radius: 50%;
`;

export const StyledLeftSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAvatarSection = styled.div`
  display: flex;
  margin-left: auto;
  align-self: center;
`;

export const StyledRightSection = styled.div`
  display: flex;
  margin-left: auto;
  flex-direction: column;
`;

export const StyledStatus = styled.label`
  font-size: 10px;
  margin-top: 5px;
  font-weight: 400;
  padding: 1px 5px;
  font-style: normal;
  border-radius: 3px;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.primaryColor};
`;

export const StyledBoldFont = styled.label`
  font-size: 10px;
  margin-top: 5px;
  font-weight: bold;
`;
