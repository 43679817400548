import { useEffect } from 'react';

/**
 * Listen to click events outside of a given ref.
 * @param {{
 * callBack: Function,
 * ref: React.RefObject,
 * dependencies: Array<unknown>
 * }} param
 */
function useOutSideClick({ ref, callBack, dependencies = [] }) {
  function onClickOutSide(event) {
    const { current } = ref;
    const { target } = event;
    if (current && !current.contains(target)) {
      callBack();
    }
  }

  useEffect(function () {
    window.document.addEventListener('mousedown', onClickOutSide);
    return function () {
      window.document.removeEventListener('mousedown', onClickOutSide);
    };
  }, dependencies);
}

export default useOutSideClick;
