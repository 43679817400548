import React from 'react';
import { string, func, node } from 'prop-types';

import {
  IconWrapper,
  MenuItemButton,
} from 'components/Navbar/DropDownItem/DropDownItem.styled';

/**
 * @param {{
 * leftIcon: JSX,
 * children: JSX,
 * onClick: Function,
 * redirectMenu: string,
 * setActiveMenu: Function,
 * }} param
 */
function DropDownItem({
  onClick,
  children,
  leftIcon,
  redirectMenu,
  setActiveMenu,
}) {
  function onSetActiveMenu() {
    if (redirectMenu && setActiveMenu) setActiveMenu(redirectMenu);
    onClick && onClick();
  }
  return (
    <MenuItemButton type="button" onClick={onSetActiveMenu}>
      <IconWrapper>{leftIcon}</IconWrapper>
      {children}
    </MenuItemButton>
  );
}

DropDownItem.propTypes = {
  onClick: func,
  leftIcon: node,
  children: node,
  setActiveMenu: func,
  redirectMenu: string,
};

export default DropDownItem;
