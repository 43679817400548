import { endPoints } from 'api/';
import { getCategories } from 'actions/category/';
import { DELETE_CATEGORY, UPDATE_CATEGORY } from 'actions/types';

/**
 * @description Updates category object. This will also act as `delete` action by `disabling` status.
 * @param {string} categoryId
 * @param {{}} payload
 * @param {string} companyId Current user's company id.
 * @param {boolean} isDeleting If set to true, it will still update the record but UI status will be delete
 * @returns Category object
 */
function update(categoryId, payload, companyId, isDeleting = false) {
  const actionType = isDeleting ? DELETE_CATEGORY : UPDATE_CATEGORY;
  /**
   * @param {Function} dispatch
   */
  return function (dispatch) {
    const action = {
      type: actionType,
      /**
       * @param {Function} apiClient
       */
      request: async function (apiClient) {
        const category = await apiClient(
          endPoints.UPDATE_CATEGORY,
          payload,
          categoryId,
        );
        dispatch(getCategories(companyId));
        return category;
      },
    };
    dispatch(action);
  };
}

export default update;
