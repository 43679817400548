import styled from 'styled-components';

export const StyledTableWrapper = styled.div`
  overflow-y: auto;
  max-height: 420px;
  padding-right: 10px;
  ${({ theme }) => theme.scrollbar};
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
`;

export const StyledLoader = styled.img`
  width: 30px;
  height: 30px;
  margin: 2px auto;
`;

export const StyledWarehouseName = styled.label`
  font-size: 12px;
  cursor: pointer;
  line-height: 18px;
  font-style: normal;
  font-weight: normal;
  text-decoration: underline;
`;
